import { ScheduleInfoData, ScheduleStatus } from '../types';
import iClinicApi from 'services/iclinic/iClinicApi';

export function getScheduleInfo(token: string) {
  return iClinicApi.get<ScheduleInfoData>(`/messages/event/`, {
    params: { token },
  });
}

export function sendSchedulingResponse(status: ScheduleStatus, token: string) {
  return iClinicApi.post(
    `/messages/event/reply/`,
    { status },
    { params: { token } },
  );
}
