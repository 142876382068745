import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';

import { Alert, Box, Link, MaterialIcons } from '@iclinic/design-system';
import { existingEmailCode } from '../../constants';
import { emailSelector, emailUIStateSelector } from '../../state/selectors';
import { SignupFormValues } from '../../types';
import { SignupField } from './Form.styles';

const EmailInput = () => {
  const { message, emailError, error } = useSelector(emailUIStateSelector);
  const stateEmail = useSelector(emailSelector);

  const {
    errors,
    touched,
    handleChange,
    handleBlur,
    values,
    setFieldValue,
    setFieldTouched,
  } = useFormikContext<SignupFormValues>();

  useEffect(() => {
    setFieldValue('email', stateEmail);
  }, [stateEmail, setFieldValue, setFieldTouched]);

  let helperText: React.ReactNode = emailError && message;
  if (error === existingEmailCode) {
    helperText = (
      <span>
        {helperText}{' '}
        <Link variant="caption" bold href="/new/usuarios/login">
          Faça seu login para continuar.
        </Link>
      </span>
    );
  }

  helperText =
    helperText ||
    (touched.email && errors.email
      ? errors.email
      : 'Ele será utilizado para acessar o sistema');

  const hasEmailError = !!(errors.email && touched.email) || emailError;

  return (
    <>
      {!emailError && message && (
        <Box>
          <Alert
            severity="error"
            icon={<MaterialIcons.ErrorOutlineOutlined />}
            message={message}
          />
        </Box>
      )}
      <SignupField
        label="Email"
        id="email"
        name="email"
        type="email"
        placeholder="Digite seu email"
        autoComplete="email"
        margin="normal"
        error={hasEmailError}
        helperText={helperText}
        onBlur={handleBlur}
        fullWidth
        onChange={handleChange}
        value={values.email}
        data-ga="setup-email"
      />
    </>
  );
};

export default EmailInput;
