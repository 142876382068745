import * as auth from './auth';
import * as campaign from './campaign';
import * as campaignManager from './campaignManager';
import * as prescription from './prescription';
import * as subscription from './subscription';
import * as upgrade from './upgrade';
import * as upgradeAddon from './upgradeAddon';
import * as creditcard from './creditcard';
import * as product from './product';
import * as feature from './feature';
import * as billing from './billing';
import * as marketing from './marketing';
import * as records from './records';
import * as schedule from './schedule';
import * as pdfGenerator from './pdfGenerator';
import * as signatures from './signature';
import * as email from './email';
import acceptTerms from './terms';

export default {
  auth,
  campaign,
  campaignManager,
  prescription,
  subscription,
  upgrade,
  upgradeAddon,
  product,
  creditcard,
  feature,
  billing,
  marketing,
  records,
  schedule,
  pdfGenerator,
  signatures,
  email,
  acceptTerms,
};
