import { createSlice, createAction, PayloadAction } from '@reduxjs/toolkit';

import { RequestStatus } from 'shared/constants/State';
import {
  SignupStepsOrder,
  SIGNUP_STORAGE_KEY,
  AccountFormValues,
} from '../constants';
import { Redirect } from 'state/shared/types';
import {
  SignupState,
  SubscriptionSubmit,
  FetchListCBORequest,
  CBOList,
} from '../types';

export const initialStateValues: SignupState = {
  progressStep: SignupStepsOrder.Email,
  email: '',
  name: '',
  billing_phone: '',
  profession: '',
  cbo: '',
  professionals_count: '',
  status: RequestStatus.Ready,
  error: '',
  listCBO: {
    meta: {},
    objects: [],
  },
  socialToken: '',
  autoLogin: '',
  embed: false,
};

const storedStateString = localStorage.getItem(SIGNUP_STORAGE_KEY) || '';

// state should always be restored as 'ready' so it doesn't lock the UI
export const initialState: SignupState = storedStateString
  ? { ...initialStateValues, ...JSON.parse(storedStateString) }
  : initialStateValues;

const nameStore = '@webapp/auth2/signup';

const slice = createSlice({
  name: nameStore,
  initialState,
  reducers: {
    submitEmail: (state, action: PayloadAction<string>) => ({
      ...state,
      email: action.payload,
      status: RequestStatus.Pending,
      error: '',
    }),
    requestError: (state, action: PayloadAction<string>) => ({
      ...state,
      status: RequestStatus.Error,
      error: action.payload,
    }),
    requestPending: (state) => ({
      ...state,
      status: RequestStatus.Pending,
      error: '',
    }),
    submitAccount: (state, action: PayloadAction<AccountFormValues>) => {
      const { name, billing_phone, profession, cbo, professionals_count } =
        action.payload;
      return {
        ...state,
        name,
        billing_phone,
        profession,
        professionals_count,
        cbo,
        error: '',
        status: RequestStatus.Pending,
      };
    },
    submitListCBO: (state, action: PayloadAction<FetchListCBORequest>) => ({
      ...state,
      listCBO: action.payload,
    }),
    submitNewCBOTerm: (state, action: PayloadAction<CBOList>) => ({
      ...state,
      listCBO: {
        ...state.listCBO,
        objects: [...state.listCBO.objects, action.payload],
      },
    }),
    setStep: (state, { payload }: { payload: SignupStepsOrder }) => ({
      ...state,
      progressStep: payload,
    }),
    finishSignup: () => ({
      ...initialStateValues,
      status: RequestStatus.Success,
      progressStep: SignupStepsOrder.Password,
    }),
    setAutoLogin: (state, { payload }: { payload: string }) => ({
      ...state,
      autoLogin: payload,
      status: RequestStatus.Success,
    }),
    resetStatus: (state) => ({ ...state, status: RequestStatus.Ready }),
    setProfession: (state, action: PayloadAction<string>) => ({
      ...state,
      profession: action.payload,
    }),
    setEmbed: (state, action: PayloadAction<boolean>) => ({
      ...state,
      embed: action.payload,
    }),
  },
});

export default slice.reducer;

export const listCBO = createAction<void>(`${nameStore}/listCBO`);
export const listFilteredCBO = createAction<string>(
  `${nameStore}/listFilteredCBO`,
);

export const redirectModal = createAction<Redirect>(
  `${nameStore}/redirectModal`,
);

export const createCBO = createAction<string>(`${nameStore}/createCBO`);

export const triggerSubmitTrial = createAction<SubscriptionSubmit>(
  `${nameStore}/submitTrial`,
);

export const triggerSubmitAutoLogin = createAction(
  `${nameStore}/triggerSubmitAutoLogin`,
);

export const storeProfession = createAction<string>(
  `${nameStore}/storeProfession`,
);

export const {
  submitEmail,
  submitAccount,
  submitListCBO,
  requestPending,
  submitNewCBOTerm,
  setStep,
  requestError,
  finishSignup,
  resetStatus,
  setAutoLogin,
  setProfession,
  setEmbed,
} = slice.actions;
