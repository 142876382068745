import React from 'react';

import {
  MaterialUICore,
  theme,
  Box,
  Typography,
  Link,
} from '@iclinic/design-system';

const { makeStyles } = MaterialUICore;

const useStyles = makeStyles(() => ({
  typography: {
    fontSize: 14,
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
      textAlign: 'left',
    },
  },
  link: {
    display: 'block',
  },
}));

export default function Terms(): JSX.Element {
  const classes = useStyles();
  return (
    <Box mt={4}>
      <Typography className={classes.typography}>
        Ao clicar no botão acima você concorda com nossos
        <Link className={classes.link} href="#terms">
          {' '}
          termos de uso
        </Link>
      </Typography>
    </Box>
  );
}
