import { MaterialUICore, axiomTokens } from '@iclinic/design-system';

const { styled } = MaterialUICore;
const { border, color, spacing } = axiomTokens;

export const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: spacing[6],
  padding: `0 ${spacing[4]} ${spacing[4]}`,

  [theme.breakpoints.up('sm')]: {
    padding: `0 ${spacing[8]} ${spacing[4]}`,
  },
}));

export const SectionHeaderWrapper = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr',
  gap: spacing[4],

  [theme.breakpoints.up('sm')]: {
    gridTemplateColumns: '2fr 1fr',
  },
}));

export const HeaderWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  rowGap: spacing[2],
  gridColumn: '1',
});

export const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: spacing[6],
  background: color.background[1],
  borderRadius: border.radius.sm,
  border: `1px solid ${color.border.subtle}`,
  padding: `${spacing[6]} ${spacing[4]}`,

  [theme.breakpoints.up('sm')]: {
    padding: spacing[6],
  },
}));

export const ChipsWrapper = styled('div')({
  gridColumn: '1',
  gridRow: '2',
  display: 'flex',
  flexWrap: 'wrap',
  gap: spacing[2],
});

export const SelectWrapper = styled('div')(({ theme }) => ({
  gridColumn: '1',

  [theme.breakpoints.up('sm')]: {
    gridColumn: '2',
  },
}));

export const ChartsWrapper = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr',
  gap: spacing[6],

  [theme.breakpoints.up('sm')]: {
    gridTemplateColumns: '1fr 2fr',
  },
}));

export const AnswersWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  rowGap: spacing[6],
});

export const SubtitleWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  rowGap: spacing[2],
});

export const Card = styled('div')(() => ({
  backgroundColor: color.background[1],
  border: `${border.width.xs} solid ${color.border.subtle}`,
  borderRadius: border.radius.sm,
}));

export const AnswersItemsWrapper = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr',
  gap: spacing[4],

  [theme.breakpoints.up('sm')]: {
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: spacing[6],
  },
}));

export const LoadMoreWrapper = styled('div')({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});
