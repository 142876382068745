import React from 'react';
import { Heading, axiomTokens } from '@iclinic/design-system';
import { ChartData, ChartOptions } from 'chart.js';
import { useSelector } from 'react-redux';

import { ChartLegend, LineChart } from '../../components';
import {
  getDashboardData,
  getDashboardTimeline,
} from '../../state/dashboard/selectors';
import * as S from './TimelineChart.styles';
import { USA, formatDate } from 'shared/utils/date';
import { addOpacityToHexColor } from '../../utils';

const { color } = axiomTokens;

function TimelineChart() {
  const timelineData = useSelector(getDashboardTimeline);
  const dashboardData = useSelector(getDashboardData);

  const data: ChartData<'line'> = {
    labels: timelineData.map(({ date }) => formatDate(date, USA, 'DD/MM/YY')),
    datasets: [
      {
        backgroundColor: addOpacityToHexColor(color.highlight.ruby[2], 0.06),
        borderColor: color.highlight.ruby[2],
        data: timelineData.map(({ detractors }) => detractors),
        tension: 0.3,
        fill: true,
      },
      {
        backgroundColor: addOpacityToHexColor(color.highlight.purple[2], 0.06),
        borderColor: color.highlight.purple[2],
        data: timelineData.map(({ passives }) => passives),
        tension: 0.3,
        fill: true,
      },
      {
        backgroundColor: addOpacityToHexColor(color.highlight.blue[1], 0.06),
        borderColor: color.highlight.blue[1],
        data: timelineData.map(({ promoters }) => promoters),
        tension: 0.3,
        fill: true,
      },
    ],
  };

  const options: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      intersect: false,
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <S.ChartCard>
      <Heading variant="overline">
        NPS - Satisfação dos clientes ao longo do tempo
      </Heading>

      <div style={{ width: '100%', height: '200px' }}>
        <LineChart data={data} options={options} />
      </div>

      <S.LegendsWrapper>
        <ChartLegend
          bulletColor={color.highlight.ruby[2]}
          label={`(${dashboardData?.detractors || 0}) Detratores - 0 a 6`}
        />
        <ChartLegend
          bulletColor={color.highlight.purple[2]}
          label={`(${dashboardData?.passives || 0}) Neutros - 7 a 8`}
        />
        <ChartLegend
          bulletColor={color.highlight.blue[1]}
          label={`(${dashboardData?.promoters || 0}) Promotores - 9 a 10`}
        />
      </S.LegendsWrapper>
    </S.ChartCard>
  );
}

export default React.memo(TimelineChart);
