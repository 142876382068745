import { MaterialUICore, axiomTokens } from '@iclinic/design-system';

const { styled } = MaterialUICore;
const { border, color, spacing } = axiomTokens;

const CardWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  rowGap: spacing[8],
  backgroundColor: color.background[1],
  border: `1px solid ${color.border.subtle}`,
  borderRadius: border.radius.sm,
  padding: spacing[6],
});

export { CardWrapper };
