import { call, put, select, takeLatest } from 'redux-saga/effects';
import { Response } from '@types';

import * as api from '../../services/dashboard';
import * as actions from '.';
import {
  DashboardData,
  Physician,
  RecentNps,
  RecentNpsResponse,
} from '../../services/types';
import { getRecentNpsList } from './selectors';

type PayloadFetchSurveyDashboardData = ReturnType<
  typeof actions.fetchSurveyDashboardData
>;
type PayloadFetchRecentNps = ReturnType<typeof actions.fetchRecentNps>;

export function* fetchPhysiciansWorker() {
  try {
    const { data }: Response<Physician[]> = yield call(api.getPhysicians);

    yield put(actions.fetchPhysiciansSuccess(data));
  } catch {
    yield put(actions.fetchPhysiciansFailure());
  }
}

export function* fetchDashboardDataWorker({
  payload,
}: PayloadFetchSurveyDashboardData) {
  try {
    yield put(actions.fetchSurveyDashboardDataStart());
    const { data }: Response<DashboardData> = yield call(
      api.getSurveyDashboardData,
      payload,
    );

    yield put(actions.fetchSurveyDashboardDataSuccess(data));
  } catch (error) {
    yield put(actions.fetchSurveyDashboardDataFailure());
  }
}

export function* fetchRecentNpsWorker({ payload }: PayloadFetchRecentNps) {
  try {
    yield put(actions.fetchRecentNpsStart());

    const { offset } = payload;
    const recentNpsList: RecentNps[] = yield select(getRecentNpsList);

    const { data }: Response<RecentNpsResponse> = yield call(
      api.getSurveyRecentNps,
      payload,
    );

    let updatedRecentNps: RecentNps[];

    if (offset > 0) {
      updatedRecentNps = [...recentNpsList, ...data.results];
    } else {
      updatedRecentNps = data.results;
    }

    yield put(
      actions.fetchRecentNpsSuccess({ ...data, results: updatedRecentNps }),
    );
  } catch (error) {
    yield put(actions.fetchRecentNpsFailure());
  }
}

export default function* surveyPatientsSagas() {
  yield takeLatest(actions.fetchPhysicians, fetchPhysiciansWorker);
  yield takeLatest(actions.fetchSurveyDashboardData, fetchDashboardDataWorker);
  yield takeLatest(actions.fetchRecentNps, fetchRecentNpsWorker);
}
