import { axiomTokens, MaterialUICore } from '@iclinic/design-system';

const { styled } = MaterialUICore;
const { color, spacing } = axiomTokens;

export const ListSubscriptionsWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  rowGap: spacing[2],
});

export const Divider = styled('div')({
  height: 1,
  paddingTop: 1,
  backgroundColor: color.background[3],
});

export const SubtotalWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  columnGap: spacing[6],
});

export const ScratchedText = styled('span')({
  color: color.typeface.meld,
  textDecoration: 'line-through',
  marginRight: spacing[4],
});
