import { Pagination } from '@iclinic/design-system';
import React from 'react';

import * as S from './PaginationHistory.styles';

interface PaginationHistoryProps {
  page: number;
  count: number;
  onChange?: (newPage: number) => void;
}

export const PaginationHistory = ({
  page,
  count,
  onChange,
}: PaginationHistoryProps) => {
  const onChangePageHandler = (
    _: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    if (!onChange) return;

    onChange(value);
  };

  return (
    <S.PaginationWrapper>
      <Pagination page={page} count={count} onChange={onChangePageHandler} />
    </S.PaginationWrapper>
  );
};
