import React from 'react';
import { Reducer } from 'redux';
import { useStore } from 'react-redux';
import { Saga } from 'redux-saga';

import { Store } from 'state/createConfigureStore';

interface ReducerImport {
  (): Promise<{ default: Reducer }>;
}

interface SagaImport {
  (): Promise<{ default: Saga }>;
}

interface ComponentImport {
  (): Promise<{ default: React.ComponentType<any> }>;
}

interface StoreTask {
  (store: Store): Promise<void>;
}

interface LazyStoreInjector {
  (): Promise<(store: Store) => void>;
}

/**
 *
 * @param key chave para mapeamento dos reducers injetados e combineReducers
 * @param reducerImport função que importa o módulo do reducer
 * @returns função que injeta o reducer na store do parâmetro
 */
export const lazyReducer = (
  key: string,
  reducerImport: ReducerImport,
): LazyStoreInjector => async () => {
  const module = await reducerImport();
  return (store) => store.injectReducer(key, module.default);
};

/**
 *
 * @param key chave para mapeamento dos reducers injetados e combineReducers
 * @param reducerImport função que importa o módulo da saga
 * @returns função que injeta a saga na store do parâmetro
 */
export const lazySaga = (
  key: string,
  sagaImport: SagaImport,
): LazyStoreInjector => async () => {
  const module = await sagaImport();
  return (store) => store.injectSaga(key, module.default);
};

/**
 * O carregamento dos módulos é assíncrono/simultâneo, mas a injeção ocorre na ordem passada nos parâmetros.
 * Portanto, sagas que dependem de um reducer devem ser passadas por último.
 * Essa forma permite carregar chunks diferentes simultaneamente, porém é mais eficiente que os módulos estejam no mesmo chunk.
 */
export const injectAll = (...injectors: LazyStoreInjector[]) => async (
  store: Store,
) =>
  (
    await Promise.all(injectors.map((injector) => injector()))
  ).forEach((inject) => inject(store));

/**
 * HOC que carrega um componente lazy, permitindo executar uma função que receba a store como argumento.
 * É melhor que todos os módulos de uma feature estejam no mesmo chunk. Ver webpackChunkName.
 * @param importComponent função de import assíncrono do componente
 * @param storeTask função que recebe a store como argumento
 * @returns componente lazy
 */
export const lazyFeature = (
  importComponent: ComponentImport,
  storeTask: StoreTask,
) => (props: any): JSX.Element => {
  const store = useStore() as Store;

  const Lazy = React.lazy(async () => {
    // inicia o carregamento do componente mas espera a task da store antes de retornar o componente async
    // Permite carregar chunks diferentes simultaneamente
    const componentPromise = importComponent();
    await storeTask(store);
    return componentPromise;
  });

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Lazy {...props} />;
};
