// External
import {
  createStore,
  applyMiddleware,
  AnyAction,
  combineReducers,
} from 'redux';
import { createLogger } from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import createSagaMiddleware, { Saga } from 'redux-saga';

// Internal
import { addBreadcrumb } from 'shared/sentryClient';
import { pubSubMiddleware } from 'migration/pubsub';
import { setForceLogoutCallback } from 'services/iclinic/interceptors';
import { unauthorize as logoutManual } from 'features/new-auth/state/actions';
import { GoogleAnalytics } from 'shared/components';
import trackMiddleware from './middlewares/trackMiddleware';
import {
  createInjectReducer,
  createInjectSaga,
  ReducersMap,
} from './storeInjection';

export default function createConfigureStore(
  reducersMap: ReducersMap,
  allSagas: Saga,
  addFormSubmitSagaTo?: any,
) {
  return function configureStore() {
    const sagaMiddleware = createSagaMiddleware();

    const GA = GoogleAnalytics(process.env.GOOGLE_ANALYTICS_UA);
    const googleTrackMiddleware = trackMiddleware(GA.trackEvent);

    const sentryBreadcrumbMiddleware = trackMiddleware(
      addBreadcrumb,
      'breadcrumb',
    );

    const rootSaga = addFormSubmitSagaTo
      ? addFormSubmitSagaTo(allSagas)
      : allSagas;

    let middleware = [
      sagaMiddleware,
      googleTrackMiddleware,
      pubSubMiddleware,
      sentryBreadcrumbMiddleware,
    ];

    if (process.env.DEBUG === 'true') {
      const logger = createLogger({
        collapsed: (getState: unknown, action: AnyAction) =>
          action.type.includes('@@redux-form'),
      });
      middleware = [...middleware, logger];
    }

    const composeEnhancers = composeWithDevTools({
      trace: true,
      traceLimit: 100,
    });

    const reducer = combineReducers(reducersMap);

    const store = createStore(
      reducer,
      composeEnhancers(applyMiddleware(...middleware)),
    );

    sagaMiddleware.run(rootSaga);

    setForceLogoutCallback(() => {
      store.dispatch(logoutManual());
    });

    const injectSaga = createInjectSaga(sagaMiddleware.run);
    const injectReducer = createInjectReducer(
      store.replaceReducer,
      reducersMap,
    );

    return { ...store, injectReducer, injectSaga, runSaga: sagaMiddleware.run };
  };
}

export type Store = ReturnType<ReturnType<typeof createConfigureStore>>;
