import * as Yup from 'yup';

import { validateFormattedBrPhone } from 'shared/utils/v2/validations';

const validationSchema = Yup.object().shape({
  contactPhone: Yup.string().test(
    'isValidPhone',
    'Informe um telefone válido',
    (rawPhoneNumber) => {
      if (!rawPhoneNumber) return true;

      return validateFormattedBrPhone(rawPhoneNumber);
    },
  ),
});

export { validationSchema };
