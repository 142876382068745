import axios, { AxiosResponse } from 'axios';

import api from './api';
import { LoginResponseForm } from 'features/new-auth/state/types';

const AUTH_PARAMS = {
  params: {
    token: process.env.ICLINIC_API_AUTH_TOKEN,
  },
};

const legacyApp = axios.create({
  baseURL: process.env.ICLINIC_APP_URL,
  params: {},
});

export const loginWithEmail = (
  email: string,
  password: string,
  nextUrl: string,
): Promise<AxiosResponse<LoginResponseForm>> =>
  legacyApp.post(
    '/usuarios/login/auth-agent/',
    {
      username: email,
      password,
      next_url: nextUrl,
      is_mobile: !!window.mobileWebview,
      ...(window.mobileWebview && {
        platform: window.mobileWebview.Constants.platform,
      }),
      ...(window.mobileWebview && {
        installation_id: window.mobileWebview.Constants.installationId,
      }),
    },
    {
      ...AUTH_PARAMS,
    },
  );

export const loginWithGoogle = (token: string) =>
  api.post(
    '/accounts/user/login/social/google/',
    {
      access_token: token,
    },
    {
      ...AUTH_PARAMS,
    },
  );

export const sendPasswordResetEmail = (email: string, token: string) =>
  api.post(
    '/accounts/user/password/forget/',
    {
      email,
    },
    {
      params: {
        token,
      },
    },
  );

export const getAuthToken = () => process.env.ICLINIC_API_AUTH_TOKEN;

const passwordResetPath = '/accounts/user/password/reset/';

export const sendNewPassword = (
  uidb36: string,
  passToken: string,
  newPassword: string,
  token: string,
) =>
  api.post(
    passwordResetPath,
    {
      new_password1: newPassword,
      new_password2: newPassword,
    },
    {
      params: {
        token,
        uidb36,
        pass_token: passToken,
        auto_login: 'true',
      },
    },
  );

export const checkPasswordLink = (uidb36: string, passToken: string) =>
  api.get<{ validlink: boolean }>(passwordResetPath, {
    params: {
      uidb36,
      pass_token: passToken,
    },
  });

export const validateToken = (token: string) =>
  api.get(`/icapi/ticket/validate/?token=${token}`);
