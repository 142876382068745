/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';

import {
  TextField,
  MaterialUICore,
  MaterialIcons,
  InputAdornment,
} from '@iclinic/design-system';

const { IconButton, MaterialTextFieldProps } = MaterialUICore;
const { Visibility, VisibilityOff } = MaterialIcons;

interface PasswordFieldProps extends MaterialTextFieldProps {
  onVisibilityChange?: (visible: boolean) => void;
}

const PasswordField = ({
  onVisibilityChange,
  ...props
}: PasswordFieldProps) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => setShowPassword(!showPassword);

  const handlePasswordVisibilityClick = () => {
    handleTogglePassword();

    if (onVisibilityChange) onVisibilityChange(!showPassword);
  };

  const PasswordVisibility = showPassword ? <Visibility /> : <VisibilityOff />;

  return (
    <TextField
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="Muda a visibilidade da senha"
              onClick={handlePasswordVisibilityClick}
              edge="end"
              data-testid="password-visibility-button"
            >
              {PasswordVisibility}
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
};

export default PasswordField;
