import React, { useCallback } from 'react';
import { Button, MaterialIcons } from '@iclinic/design-system';
import { useFormikContext } from 'formik';

import { FormValues } from './form.normalizer';

type RemoveScheduleButtonProps = {
  index: number;
};

const { AddCircle, DeleteForever } = MaterialIcons;

export const ScheduleButton = ({ index }: RemoveScheduleButtonProps) => {
  const isFirstItem = index === 0;
  const buttonColor = isFirstItem ? 'secondary' : 'transparent';
  const buttonIcon = isFirstItem ? <AddCircle /> : <DeleteForever />;

  const formik = useFormikContext<FormValues>();
  const handleAddSchedule = useCallback(() => {
    const newSchedule = {
      daysAdvance: 5,
      timeSend: '14:30:00',
    };

    formik.setValues({
      ...formik.values,
      schedules: [...formik.values.schedules, newSchedule],
    });
  }, [formik]);

  const handleRemoveSchedule = useCallback(() => {
    const schedules = [...formik.values.schedules];
    const removed = schedules[index];

    if (removed.id) {
      formik.setFieldValue('schedulesDeleted', [
        ...formik.values.schedulesDeleted,
        removed.id,
      ]);
    }

    schedules.splice(index, 1);
    formik.setFieldValue('schedules', schedules);
  }, [formik, index]);

  const buttonClickHandler = isFirstItem
    ? handleAddSchedule
    : handleRemoveSchedule;

  return (
    <Button
      color={buttonColor}
      size="small"
      variant="contained"
      startIcon={buttonIcon}
      onClick={buttonClickHandler}
    >
      {isFirstItem ? 'Adicionar envio' : 'Remover envio'}
    </Button>
  );
};
