import {
  axiomTokens,
  MaterialIcons,
  MaterialUICore,
} from '@iclinic/design-system';

const { styled } = MaterialUICore;
const { WhatsApp } = MaterialIcons;
const { spacing, color } = axiomTokens;

export const PreviewTitleWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  columnGap: spacing[2],
});

export const WhatsAppIcon = styled(WhatsApp)({
  color: color.icon.feedback.success,
});
