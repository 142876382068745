import React from 'react';
import { Renderer } from 'react-dom';
import { Store } from 'redux';
import { Provider } from 'react-redux';
import { Router } from 'react-router';
import { History } from 'history';

import { MaterialUICore, theme } from '@iclinic/design-system';
import { ErrorBoundary } from 'shared/components';

const { ThemeProvider } = MaterialUICore;

export interface CreateRenderProps {
  Element: React.ComponentType;
  target: HTMLElement;
  boundary?: string;
}
const createRender = <
  RenderProps extends Renderer,
  HistoryProps extends History
>(
  store: Store,
  render: RenderProps,
  history: HistoryProps,
) => (
  Element: CreateRenderProps['Element'],
  target: CreateRenderProps['target'],
  boundary: CreateRenderProps['boundary'] = 'webapp',
) => {
  return render(
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <ErrorBoundary boundary={boundary}>
          <Router history={history}>
            <Element />
          </Router>
        </ErrorBoundary>
      </ThemeProvider>
    </Provider>,
    target,
  );
};

export default createRender;
