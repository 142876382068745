import * as Yup from 'yup';

import {
  required,
  validEmail,
  passwordValidation,
  invalidPassword,
} from '../validationSchema';
import { validateBrPhone } from './utils';

export const emailFormValidation = Yup.object({
  email: Yup.string().trim().required(required).email(validEmail),
  termsOfServiceAndPrivacyPolicy: Yup.boolean().required().oneOf([true]),
});

export const passwordFormValidation = Yup.object({
  password: Yup.string()
    .required(required)
    .test(
      'password-validation',
      invalidPassword,
      (value) => passwordValidation(value).isValidPassword,
    ),
});

export const accountFormValidation = Yup.object({
  name: Yup.string().required(required),
  billing_phone: Yup.string()
    .required(required)
    .test('br-phone', 'Telefone inválido', (value) => validateBrPhone(value)),
  profession: Yup.string().required(required),
  cbo: Yup.string().when('profession', {
    is: 'Médico',
    then: Yup.string().required(required),
  }),
});

export const singleSignupFormValidation = ({
  isTermsRequired,
}: {
  isTermsRequired: boolean;
}) => {
  const initialSchema = {
    email: Yup.string().trim().required(required).email(validEmail),
    name: Yup.string().required(required),
    billing_phone: Yup.string()
      .required(required)
      .test('br-phone', 'Telefone inválido', (value) => validateBrPhone(value)),
    profession: Yup.string().required(required),
    cbo: Yup.string().when('profession', {
      is: 'Médico',
      then: Yup.string().required(required),
    }),
    password: Yup.string()
      .required(required)
      .test(
        'password-validation',
        invalidPassword,
        (value) => passwordValidation(value).isValidPassword,
      ),
  };

  return Yup.object({
    ...initialSchema,
    termsOfServiceAndPrivacyPolicy: Yup.boolean().when([], {
      is: () => isTermsRequired,
      then: Yup.boolean().required().oneOf([true]),
    }),
  });
};
