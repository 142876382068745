import React from 'react';
import PropTypes from 'prop-types';

class ExpireAfter extends React.PureComponent {
  timer = null;

  constructor(props) {
    super(props);
    this.state = {
      visible: true,
    };
  }

  componentDidMount() {
    this.setTimer();
  }

  UNSAFE_componentWillReceiveProps() {
    this.setTimer();
    this.setState({
      visible: true,
    });
  }

  componentWillUnmount() {
    this.onClearTimeout();
  }

  onClearTimeout() {
    clearTimeout(this.timer);
  }

  onClear() {
    const { onExpire } = this.props;
    this.setState({
      visible: false,
    });
    onExpire();
  }

  setTimer() {
    if (this.timer !== null) {
      this.onClearTimeout();
    }
    this.hideTimer();
  }

  hideTimer() {
    const { delay } = this.props;
    this.timer = setTimeout(() => {
      this.onClear();
      this.timer = null;
    }, delay);
  }

  render() {
    const { children } = this.props;
    const { visible } = this.state;
    return visible && children;
  }
}

ExpireAfter.defaultProps = {
  onExpire: () => {},
  delay: 3000,
};

ExpireAfter.propTypes = {
  children: PropTypes.node.isRequired,
  onExpire: PropTypes.func,
  delay: PropTypes.number,
};

export default ExpireAfter;
