import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Alert, MaterialIcons } from '@iclinic/design-system';

import { getLoginWithGoogle } from 'features/new-auth/state/selectors';
import GoogleSignInButton from './GoogleSignInButton';

const { ErrorOutlineOutlined } = MaterialIcons;

export default function LoginWith(): JSX.Element {
  const [loginWithGoogle] = [useSelector(getLoginWithGoogle)];

  return (
    <Box>
      {loginWithGoogle.error && (
        <Box my={2}>
          <Alert
            severity="error"
            elevation={0}
            icon={<ErrorOutlineOutlined />}
            message={loginWithGoogle.errorMessage}
          />
        </Box>
      )}
      <GoogleSignInButton />
    </Box>
  );
}
