// External
import { takeLatest, select, call, put } from 'redux-saga/effects';

// Internal
import { handlerErrors } from 'state/shared/sagas';
import iclinic from 'services/iclinic';
import {
  CLINIC_ID,
  PROFILE_ID,
  GET_PROFILE_CLINIC,
  FETCH_USER_INFO,
  ACCEPT_TERMS,
  SERVICE_TERMS,
} from './constants';
import {
  getUserData,
  getProfileAndClinicFailure,
  setTerms,
  acceptTermsSuccess,
} from './actions';

/**
 * Sets params to deprecated apiEmkt interceptor
 * @deprecated
 */
export function* workerGetProfileAndClinic() {
  try {
    const { clinicId, profileId } = yield select(({ userInfo }) => userInfo);
    localStorage.setItem(CLINIC_ID, clinicId);
    localStorage.setItem(PROFILE_ID, profileId);
  } catch (error) {
    yield call(handlerErrors, error, getProfileAndClinicFailure);
  }
}

export function* workerGetUserInfos() {
  try {
    const { userData, profileId } = yield select(({ userInfo }) => userInfo);
    const page = `/accounts/userprofile/${profileId}`;
    const { getResponseData, errors } = yield call(
      iclinic.campaign.fetchUserProfiles,
      page,
    );

    if (errors) throw errors;

    const { treatment, home_phone, mobile_phone, cpf } = getResponseData();

    yield put(
      getUserData({
        ...userData,
        treatment,
        home_phone,
        mobile_phone,
        cpf,
      }),
    );
  } catch (error) {
    yield call(handlerErrors, error, getProfileAndClinicFailure);
  }
}

export function* workerUpdateTerms() {
  let termsStorage = JSON.parse(localStorage.getItem(SERVICE_TERMS));

  if (termsStorage?.length > 0) {
    termsStorage.splice(0, 1);
  } else {
    termsStorage = [];
  }

  localStorage.setItem(SERVICE_TERMS, JSON.stringify(termsStorage));
  yield put(setTerms(termsStorage));
}

export function* workerAcceptTerms(action) {
  const { termObject } = action.payload;
  try {
    const { profileId } = yield select(({ userInfo }) => userInfo);

    const { errors } = yield call(iclinic.acceptTerms, profileId, {
      service_term_identifier: termObject.identifier,
      service_term_version: termObject.version,
    });

    if (errors) throw errors;

    yield put(acceptTermsSuccess());

    yield call(workerUpdateTerms);
  } catch (error) {
    yield call(handlerErrors, error, getProfileAndClinicFailure);
  }
}

export default function* userInfoSagas() {
  yield takeLatest(GET_PROFILE_CLINIC, workerGetProfileAndClinic);
  yield takeLatest(FETCH_USER_INFO, workerGetUserInfos);
  yield takeLatest(ACCEPT_TERMS, workerAcceptTerms);
}
