import { Body, MaterialUICore, axiomTokens } from '@iclinic/design-system';

const { styled, LinearProgress } = MaterialUICore;
const { color, border, spacing } = axiomTokens;

export const TitleWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: spacing[2],

  '& .MuiSvgIcon-root': {
    color: color.icon.default,
  },
});

export const SectionCard = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  padding: spacing[4],
  gap: spacing[3],

  borderRadius: border.radius.sm,
  border: `1px solid ${color.border.subtle}`,
});

export const Divider = styled('div')({
  height: 1,
  backgroundColor: color.background[3],
});

export const LineSeparatedText = styled(Body)({
  display: 'flex',
  justifyContent: 'space-between',
  color: color.typeface.smooth,
});

export const ProgressBar = styled(LinearProgress)({
  marginBottom: spacing[2],

  '&.MuiLinearProgress-root': {
    height: 8,
    borderRadius: border.radius.sm,
  },

  '&.MuiLinearProgress-colorPrimary': {
    backgroundColor: color.others.skeleton,
  },

  '& .MuiLinearProgress-bar': {
    borderRadius: border.radius.sm,
    backgroundColor: color.highlight.blue[1],
  },

  '&.exceeded': {
    '& .MuiLinearProgress-bar': {
      backgroundColor: color.highlight.red[1],
    },
  },
});
