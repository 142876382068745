import { TemplateKind } from '../services/types';

export const packages = [
  {
    price: 'R$ 56,90',
    name: 'Gestão iniciante',
    sendQuantity: 158,
    slug: 'message-initial',
  },
  {
    price: 'R$ 98,90',
    name: 'Gestão essencial',
    sendQuantity: 275,
    slug: 'message-essential',
  },
  {
    price: 'R$ 149,90',
    name: 'Gestão avançada',
    sendQuantity: 419,
    slug: 'message-advanced',
  },
  {
    price: 'R$ 239,90',
    name: 'Gestão experiente',
    sendQuantity: 668,
    slug: 'message-experienced',
  },
];

export const kindLabelMap = {
  [TemplateKind.CONFIRMATION]: 'Confirmação de agenda',
  [TemplateKind.REMINDER]: 'Lembrete de agenda',
};

export const contractorUpdateMessages = {
  success: 'Nova configuração salva com sucesso!',
  failed: 'Algum erro ocorreu, por favor tente novamente mais tarde!',
};
