const { ASSETS_PATH } = process.env;

const images = {
  messaging: {
    successUpgrade: `${
      ASSETS_PATH as string
    }images/messaging/success_upgrade.gif`,
  },
  appointmentConfirmation: {
    imageSuccess: `${
      ASSETS_PATH as string
    }images/message/appointment-success.png`,
    imageCanceled: `${
      ASSETS_PATH as string
    }images/message/appointment-cancel.png`,
  },
} as const;

export default images;
