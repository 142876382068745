export const CLINIC_ID = 'clinicId';
export const PROFILE_ID = 'profileId';
export const SERVICE_TERMS = 'SERVICE_TERMS';

export const GET_USER_INFO = '@webapp/userInfo/GET_USER_INFO';
export const FETCH_USER_INFO = '@webapp/userInfo/FETCH_USER_INFO';
export const GET_PROFILE_CLINIC = '@webapp/userInfo/GET_PROFILE_CLINIC';
export const GET_PROFILE_CLINIC_FAILURE = '@webapp/userInfo/GET_PROFILE_CLINIC_FAILURE';
export const SET_TERMS = '@webapp/userInfo/SET_TERMS';
export const ACCEPT_TERMS = '@webapp/userInfo/ACCEPT_TERMS';
export const ACCEPT_TERMS_SUCCESS = '@webapp/userInfo/ACCEPT_TERMS_SUCCESS';
