import React from 'react';
import {
  Body,
  Heading,
  Button,
  MaterialIcons,
  SectionTitle,
} from '@iclinic/design-system';
import { Form, FormikProvider, useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import { Rating } from 'features/survey/components';
import { Card } from './Evaluation.styles';
import * as S from './EvaluationCard.styles';
import { submitSurveyAnswer } from 'features/survey/state/patients';
import { isSubmitingSurveyAnswer } from 'features/survey/state/patients/selectors';

const { Star } = MaterialIcons;

interface EvaluationCardProps {
  surveyId: string;
}

type FormData = {
  npsValue?: number;
  comment?: string;
};

export default function EvaluationCard({ surveyId }: EvaluationCardProps) {
  const dispatch = useDispatch();
  const isSubmitting = useSelector(isSubmitingSurveyAnswer);

  const onSubmit = (formValues: FormData) => {
    dispatch(
      submitSurveyAnswer({
        surveyId,
        surveyAnswer: {
          nps_value: formValues.npsValue || 0,
          comment: formValues.comment || '',
        },
      }),
    );
  };

  const formik = useFormik<FormData>({
    initialValues: {},
    onSubmit,
  });

  const handleNPSClick = (npsValue: number) => {
    formik.setFieldValue('npsValue', npsValue);
  };

  return (
    <Card>
      <SectionTitle title="Avaliação" icon={<Star />} mb={0} />

      <S.CardContent>
        <Heading variant="sm">
          Quanto você recomendaria para um amigo ou parente?
        </Heading>
        <Body variant="xs">
          Escolha de 0 (muito improvável) a 10 (muito provável) para
          classificar.
        </Body>
      </S.CardContent>
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit}>
          <Rating
            max={10}
            startInZero
            value={formik.values.npsValue}
            onRatingClick={handleNPSClick}
          />

          <S.TextArea
            id="comment"
            name="comment"
            label="Comentário sobre atendimento"
            placeholder="Descreva como foi o seu atendimento"
            onChange={formik.handleChange}
            value={formik.values.comment}
          />

          <S.ButtonStyling>
            <Button
              size="large"
              type="submit"
              isLoading={isSubmitting}
              data-ga="survey_send_patient_desktop_click"
            >
              Enviar avaliação
            </Button>
          </S.ButtonStyling>
        </Form>
      </FormikProvider>
    </Card>
  );
}
