import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Link } from '@iclinic/design-system';

import { emailErrorSelector, errorSelector } from '../../state/selectors';
import { existingEmailCode } from 'features/new-auth/signup/constants';
import { trackSignupStep } from '../../trackSignupUtils';

export const HelperText = () => {
  const error = useSelector(errorSelector);
  const { message } = useSelector(emailErrorSelector);

  const onClickHandler = useCallback(() => {
    trackSignupStep({
      name: 'next_to_login',
    });
  }, []);

  if (error !== existingEmailCode) {
    return <span>{message}</span>;
  }

  return (
    <span>
      {message}{' '}
      <Link
        onClick={onClickHandler}
        variant="caption"
        bold
        href="/new/usuarios/login"
      >
        Faça seu login para continuar.
      </Link>
    </span>
  );
};
