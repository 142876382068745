import { Body, Button } from '@iclinic/design-system';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as S from './AppointmentOptions.styles';
import { Card } from './ScreenDetailsConfirmation.styles';
import { sendSchedulingAnswer } from 'features/messaging/state/patients';
import { ScheduleStatus } from 'features/messaging/services/types';
import { getScheduleInfoData } from 'features/messaging/state/patients/selectors';

export default function AppointmentOptions() {
  const dispatch = useDispatch();
  const appointmentData = useSelector(getScheduleInfoData);

  const onClickConfirmation = useCallback(() => {
    dispatch(sendSchedulingAnswer(ScheduleStatus.CONFIRMATION));
  }, [dispatch]);

  const onClickCancel = useCallback(() => {
    dispatch(sendSchedulingAnswer(ScheduleStatus.CANCEL));
  }, [dispatch]);

  if (!appointmentData) {
    return null;
  }

  return (
    <Card>
      <S.CardContent>
        <S.Heading variant="md">Confirmação de agenda</S.Heading>
        <Body variant="xs">
          {`Confirme ou cancele sua presença na agenda com o 
          ${appointmentData.physician.name}`}
        </Body>
        <S.ButtonWrapper>
          <Button color="secondary" variant="contained" onClick={onClickCancel}>
            Cancelar agenda
          </Button>
          <Button
            size="medium"
            color="primary"
            variant="contained"
            onClick={onClickConfirmation}
          >
            Confirmar agenda
          </Button>
        </S.ButtonWrapper>
      </S.CardContent>
    </Card>
  );
}
