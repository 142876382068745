import surveyApi from 'services/iclinic/surveyApi';
import { SurveyAnswer, SurveyInfoData } from '../types';

export function getSurveyInfo(surveyId: string) {
  return surveyApi.get<SurveyInfoData>(`survey/${surveyId}/`);
}

export function sendSurveyAnswer(surveyId: string, surveyAnswer: SurveyAnswer) {
  return surveyApi.patch(`/survey/${surveyId}/reply/`, surveyAnswer);
}
