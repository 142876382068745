/* eslint-disable no-underscore-dangle */
export const dispatchHubspotEvent = (
  eventId: string,
  properties: Record<string, unknown>,
) => {
  window._hsq = window._hsq || [];
  if (properties.email) {
    window._hsq.push(['identify', { email: properties.email }]);
  }
  window._hsq.push([
    'trackCustomBehavioralEvent',
    {
      name: eventId,
      properties,
    },
  ]);
};
