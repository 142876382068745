import { createSelector } from '@reduxjs/toolkit';

import { RequestStatus } from 'shared/constants/State';
import { StoreState } from 'state/rootReducer';

export const isFetchingPhysicians = ({ messaging }: StoreState) =>
  messaging.common.fetcherPhysicianStatus === RequestStatus.Pending;

export const getPhysicians = ({ messaging }: StoreState) =>
  messaging.common.physicians;

export const getCommonData = createSelector(
  [isFetchingPhysicians, getPhysicians],
  (isFetching, physicians) => ({
    isFetchingPhysicians: isFetching,
    physicians,
  }),
);

export const getPhysicianId = ({ userInfo }: StoreState) =>
  userInfo.userData.physicianId;

export const getUserKind = ({ userInfo }: StoreState) =>
  userInfo.userData.userKind;

export const isUserPhysician = ({ userInfo }: StoreState) =>
  userInfo.userData.userKind === 'p';

export const isUserAdmin = ({ userInfo }: StoreState) =>
  userInfo.userData.isAdmin;

export const isUserClinicAdmin = ({ userInfo }: StoreState) =>
  userInfo.userData.isClinicAdmin;

export const getPartialUserInfo = createSelector(
  [getPhysicianId, getUserKind, isUserPhysician, isUserAdmin],
  (physicianId, userKind, isPhysician, isAdmin) => ({
    physicianId,
    userKind,
    isPhysician,
    isAdmin,
  }),
);

export const isFetchingPermissionsMenu = ({ messaging }: StoreState) =>
  messaging.common.fetcherMenuPermissions === RequestStatus.Pending;

export const getPermissionsMenu = ({ messaging }: StoreState) =>
  messaging.common.permissionsMenu;

export const getAvailableMenus = ({ messaging }: StoreState) =>
  messaging.common.availableMenus;

export const getFeature = ({ messaging }: StoreState) =>
  messaging.common.feature;
