import React, { useCallback } from 'react';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import { TextField } from '@iclinic/design-system';

import { emailErrorSelector } from '../../state/selectors';
import { EmailStepValues } from '../../state/types';
import { useEmailFormTracking } from '../../hooks/useCustomTrackers';
import { trackSignupStep } from '../../trackSignupUtils';
import { HelperText } from './HelperText';

const EmailInput = () => {
  const { emailError, message } = useSelector(emailErrorSelector);

  const { errors, touched, handleChange, handleBlur, values } =
    useFormikContext<EmailStepValues>();

  const helperText =
    (emailError && <HelperText />) || (touched.email && errors.email);

  const hasEmailError = !!(errors.email && touched.email) || emailError;

  useEmailFormTracking({
    email: values.email,
    emailError: message,
    hasEmailError,
  });

  const onBlurHandler = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      trackSignupStep({
        name: 'user_email',
        stepValue: values.email,
      });
      handleBlur(event);
    },
    [handleBlur, values.email],
  );

  return (
    <TextField
      label="Email"
      id="email"
      name="email"
      placeholder=""
      autoComplete="email"
      margin="normal"
      error={hasEmailError}
      helperText={helperText}
      onBlur={onBlurHandler}
      fullWidth
      onChange={handleChange}
      value={values.email}
    />
  );
};

export default EmailInput;
