import { all, fork } from 'redux-saga/effects';

import surveyPatientsSagas from './patients/sagas';
import surveyDashboardSagas from './dashboard/sagas';
import filtersSagas from './filters/sagas';

export default function* surveySagas() {
  yield all([
    fork(surveyPatientsSagas),
    fork(surveyDashboardSagas),
    fork(filtersSagas),
  ]);
}
