const { ASSETS_PATH } = process.env;

const images = {
  referrals: {
    banner: `${ASSETS_PATH as string}images/referrals/banner.png`,
    bannerBlueFriday: `${
      ASSETS_PATH as string
    }images/referrals/banner_blue_friday.png`,
    bannerReceptionist: `${
      ASSETS_PATH as string
    }images/referrals/banner_receptionist.png`,
    code: `${ASSETS_PATH as string}images/referrals/code.png`,
    indicationGraph: `${
      ASSETS_PATH as string
    }images/referrals/indication_graph.png`,
    share: `${ASSETS_PATH as string}images/referrals/share.png`,
    trophy: `${ASSETS_PATH as string}images/referrals/trophy.png`,
    wantToIndicate: `${
      ASSETS_PATH as string
    }images/referrals/want_to_indicate.gif`,
    rewardRedemption: `${
      ASSETS_PATH as string
    }images/referrals/reward_redemption.gif`,
    rewardContragulations: `${
      ASSETS_PATH as string
    }images/referrals/reward_contragulations.gif`,
  },
};

export default images;
