import { MaterialUICore, axiomTokens } from '@iclinic/design-system';

const { styled } = MaterialUICore;

const { spacing, color, border } = axiomTokens;

export const MainCard = styled('div')(({ theme }) => ({
  minHeight: '100vh',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  [theme.breakpoints.up(600)]: {
    background: color.background['2'],
  },
}));

export const CardContainer = styled('div')({
  display: 'flex',
  margin: '0 auto',
  maxWidth: '80vw',
  padding: spacing['8'],
  flexDirection: 'column',
});

export const Card = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  paddingInline: spacing['8'],
  paddingBottom: spacing['8'],
  background: color.background['1'],

  [theme.breakpoints.up(600)]: {
    border: `${border.width.xs} solid ${color.border.subtle}`,
    borderRadius: border.radius.sm,
  },
}));

export const CardContent = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  letterSpacing: 0.44,
  alignItems: 'center',

  '& > p': {
    color: color.typeface.smooth,
    marginTop: spacing['2'],
    textAlign: 'center',
  },

  [theme.breakpoints.down(400)]: {
    '& > p': {
      maxWidth: '90%',
    },
  },
}));

export const Image = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  marginBottom: spacing['8'],
  marginTop: spacing['8'],

  '& > img': {
    width: '140px',
    height: '140px',
  },
});

export const ButtonWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
});
