// External
import { PatientId, FlowId } from 'state/marketing/emailFlow/patient/types';
import { PhysicianId } from 'state/marketing/emailFlow/professional/types';
import { apiEmkt, apiV2, apiSlackServerless } from './api';

export const savePatientFlows = (
  flows: FlowId[],
  patientId: PatientId,
  physicianId: PhysicianId,
) => {
  const bodyRequest = { flows };

  return apiEmkt.put(`/marketing/flows/patient/${patientId}?physicianId=${physicianId}`, bodyRequest);
};

export const deletePatientFlows = (
  patientIds: PatientId[],
) => apiEmkt.delete(`/marketing/flows/patient/${patientIds}`);

export const fetchPatientSummary = (patientId: PatientId, physicianId: PhysicianId) => (
  apiEmkt.get(`/marketing/flows/patient-summary/${patientId}?physicianId=${physicianId}`)
);

export const fetchProfessionals = (
  subscriptionId: number,
  clinicId: number,
) => (
  apiV2.get(`/subscriptions/subscription/${subscriptionId}/physicians-by-clinic-with-functionality/?clinic=${clinicId}&funcionality=campaign`)
);

export const notifyIntent = (subscriptionId: number) => (
  apiSlackServerless.post('/', {
    subscription_id: subscriptionId,
  })
);
