import React from 'react';
import { useSelector } from 'react-redux';
import {
  Body,
  Chip,
  Link,
  MaterialIcons as Icons,
} from '@iclinic/design-system';

import {
  TitleWrapper,
  SectionCard,
  Divider,
  LineSeparatedText,
} from '../Common.styles';
import * as S from './PaymentEstimate.styles';
import { getPaymentSummary } from 'features/messaging/state/messagePackage/details/selectors';
import { formatCurrency } from 'shared/utils/strings';

const PaymentEstimate = () => {
  const paymentSummary = useSelector(getPaymentSummary);
  const hasSinglePhysician =
    paymentSummary?.summary && paymentSummary.summary.length <= 1;

  return (
    <SectionCard>
      <TitleWrapper>
        <Icons.MonetizationOn />

        <Body variant="xs" bold>
          Estimativa de pagamento
        </Body>
      </TitleWrapper>

      <S.SummaryWrapper>
        {paymentSummary?.summary.map(
          ({
            franchise,
            product,
            profile_name,
            total_unit,
            value,
            unit_price,
          }) => (
            <div key={profile_name}>
              {hasSinglePhysician ? null : (
                <LineSeparatedText variant="sm" bold>
                  {profile_name}
                </LineSeparatedText>
              )}

              <LineSeparatedText variant="xs">
                <span>{product}</span>

                <span>
                  <strong>{franchise}</strong> envios/mês ={' '}
                  <strong>{formatCurrency(value)}</strong>
                </span>
              </LineSeparatedText>

              {total_unit > 0 ? (
                <LineSeparatedText variant="xs">
                  <span>Adicional unitário</span>
                  <strong>
                    {total_unit} x {formatCurrency(unit_price)}
                  </strong>
                </LineSeparatedText>
              ) : null}
            </div>
          ),
        )}

        <Divider />

        <S.TotalWrapper>
          <LineSeparatedText variant="xs" bold>
            Total
          </LineSeparatedText>

          <Chip
            size="small"
            variant="outlined"
            severity="info"
            label={formatCurrency(paymentSummary?.amount)}
          />
        </S.TotalWrapper>
      </S.SummaryWrapper>

      <S.OverlineText variant="overline">
        Para mudança de plano{' '}
        <Link
          variant="overline"
          href="https://suporte.iclinic.com.br/pt-br"
          target="_blank"
        >
          entre em contato
        </Link>{' '}
        com a nossa equipe.
      </S.OverlineText>
    </SectionCard>
  );
};

export { PaymentEstimate };
