import React, { useEffect, useState } from 'react';
import {
  Body,
  Button,
  CircularProgress,
  Checkbox,
  Chip,
  Heading,
} from '@iclinic/design-system';
import { useDispatch, useSelector } from 'react-redux';

import history from 'routes/history';
import { getPhysiciansData } from '../../../state/messagePackage/checkout/selectors';
import { Physician } from '../../../services/types';
import {
  fetchPhysiciansAvailable,
  setSelectedPhysiciansAndKinds,
  setCurrentStep,
} from 'features/messaging/state/messagePackage/checkout';
import * as S from './SelectionOfProfessionals.styles';

const START_STEP = 1;

const SelectionOfProfessionals = () => {
  const dispatch = useDispatch();
  const { isFetchingPhysicians, physicians } = useSelector(getPhysiciansData);
  const [selectedPhysicians, setSelectedPhysicians] = useState<Physician[]>([]);
  const [selectedKinds, setSelectedKinds] = useState<string[]>([]);

  useEffect(() => {
    dispatch(fetchPhysiciansAvailable());
  }, [dispatch]);

  const onPhysicianClick = (physician: Physician) => {
    setSelectedPhysicians((previousState) => {
      const hasPhysician = previousState.find(
        (item) => item.id === physician.id,
      );

      if (hasPhysician) {
        return previousState.filter((item) => item.id !== physician.id);
      }

      return [...previousState, physician];
    });
  };

  const onChangeKind = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = event.target;

    setSelectedKinds((previousState) => {
      if (previousState.includes(name)) {
        return previousState.filter((kind) => kind !== name);
      }

      return [...previousState, name];
    });
  };

  const onSubmitSelections = () => {
    dispatch(
      setSelectedPhysiciansAndKinds({
        physicians: selectedPhysicians,
        kinds: selectedKinds,
      }),
    );
    dispatch(setCurrentStep(START_STEP));
    history.push('/relacionamento/pacote-de-mensagem/escolher-pacote');
  };

  return (
    <S.Root>
      <S.TitleAndSubtitleWrapper>
        <Heading variant="md"> Pacote de mensagem </Heading>

        <Body variant="xs">
          Encontre o plano de mensageria perfeito para você, comparando opções
          que se adequem às suas necessidades e orçamento.
        </Body>
      </S.TitleAndSubtitleWrapper>

      <S.Card>
        <S.TitleAndSubtitleWrapper>
          <Heading variant="sm">Seleção de profissionais</Heading>

          <S.Subtitle variant="xs">
            Selecione os profissionais que terão acesso à funcionalidade
          </S.Subtitle>
        </S.TitleAndSubtitleWrapper>

        <S.ChipContainer>
          {isFetchingPhysicians ? (
            <CircularProgress />
          ) : (
            <>
              {physicians.map((physician) => (
                <Chip
                  key={physician.profile_name}
                  label={physician.profile_name}
                  size="small"
                  clickable
                  variant="outlined"
                  severity={
                    selectedPhysicians.find((item) => item.id === physician.id)
                      ? 'info'
                      : 'default'
                  }
                  onClick={() => onPhysicianClick(physician)}
                />
              ))}
            </>
          )}
        </S.ChipContainer>

        <S.TitleAndSubtitleWrapper>
          <Heading variant="sm">Seleção de envio de comunicações</Heading>

          <S.Subtitle variant="xs">
            Selecione as comunicações que deseja enviar de forma <br />
            automática para seus pacientes:
          </S.Subtitle>
        </S.TitleAndSubtitleWrapper>
        <S.CheckboxContainer>
          <Checkbox
            name="event_reminder"
            size="small"
            checked={selectedKinds.includes('event_reminder')}
            label="Lembrete de agenda"
            onChange={onChangeKind}
          />

          <Checkbox
            name="confirmation_event"
            size="small"
            checked={selectedKinds.includes('confirmation_event')}
            label="Confirmação de agenda "
            onChange={onChangeKind}
          />
        </S.CheckboxContainer>

        <S.ButtonContainer>
          <Button
            disabled={!selectedPhysicians.length || !selectedKinds.length}
            variant="contained"
            onClick={onSubmitSelections}
          >
            Próximo
          </Button>
        </S.ButtonContainer>
      </S.Card>
    </S.Root>
  );
};

export { SelectionOfProfessionals };
