import React from 'react';
import { Body, Heading } from '@iclinic/design-system';

import * as S from './Success.styles';
import { image } from 'shared/utils/urlGetter';

export default function SucessScreen() {
  return (
    <S.CardMain>
      <S.Card>
        <S.CardContainer>
          <S.Image>
            <img src={image('search.imageMessage')} alt="" />
          </S.Image>
          <S.CardContent>
            <Heading variant="lg">Tudo certo!</Heading>
            <Body variant="sm">
              Ficamos felizes em receber sua avaliação e estamos
              <br />
              comprometidos em oferecer sempre a melhor experiência
              <br />
              possível aos nossos pacientes.
            </Body>
          </S.CardContent>
        </S.CardContainer>
      </S.Card>
    </S.CardMain>
  );
}
