const MONTHS_OF_THE_YEAR = [
  'Janeiro',
  'Feveiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
];

export default MONTHS_OF_THE_YEAR;
