import { RequestStatus } from 'shared/constants/State';
import { StoreState } from 'state/rootReducer';

export const isFetchingPhysicians = ({ survey }: StoreState) =>
  survey.surveyDashboard.fetcherPhysicianStatus === RequestStatus.Pending;

export const isFetchingDashboardData = ({ survey }: StoreState) =>
  survey.surveyDashboard.fetcherDashboardDataStatus === RequestStatus.Pending;

export const isFetchingRecentNps = ({ survey }: StoreState) =>
  survey.surveyDashboard.fetcherRecentNpsStatus === RequestStatus.Pending;

export const getPhysicianList = ({ survey }: StoreState) =>
  survey.surveyDashboard.physicians || [];

export const getDashboardData = ({ survey }: StoreState) =>
  survey.surveyDashboard.dashboardData;

export const getDashboardTimeline = ({ survey }: StoreState) =>
  survey.surveyDashboard.dashboardData?.timeline || [];

export const getRecentNpsList = ({ survey }: StoreState) =>
  survey.surveyDashboard.recentNpsData?.results || [];

export const getRecentNpsCount = ({ survey }: StoreState) =>
  survey.surveyDashboard.recentNpsData?.count || 0;

export const getSelectedPhysicianId = ({ survey }: StoreState) =>
survey.surveyDashboard.selectedId;
