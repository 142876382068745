import React from 'react';
import { useFormikContext } from 'formik';
import { useRifm } from 'rifm';

import { phoneFormatter } from 'shared/utils/formatters';
import { SignupFormValues } from '../../types';
import { SignupField } from './Form.styles';

const PhoneInput = () => {
  const {
    errors,
    touched,
    values,
    setFieldValue,
    handleBlur,
  } = useFormikContext<SignupFormValues>();

  const { value: billing_phone, onChange: onChangePhone } = useRifm({
    value: values.billing_phone,
    onChange: (value: string) => setFieldValue('billing_phone', value),
    format: phoneFormatter,
  });

  return (
    <SignupField
      label="Telefone"
      id="billing_phone"
      name="billing_phone"
      type="tel"
      placeholder="Deixe seu melhor telefone"
      onBlur={handleBlur}
      onChange={onChangePhone}
      value={billing_phone}
      autoComplete="billing_phone"
      margin="normal"
      error={!!(errors.billing_phone && touched.billing_phone)}
      helperText={touched.billing_phone && errors.billing_phone}
      fullWidth
      data-ga="setup-billing_phone"
    />
  );
};

export default PhoneInput;
