import {
  Body,
  MaterialUICore,
  axiomTokens,
  Select,
} from '@iclinic/design-system';

const { styled } = MaterialUICore;
const { color, border, spacing } = axiomTokens;

export const CardWrapper = styled('div')({
  display: 'flex',
  padding: spacing[6],
  rowGap: spacing[8],
  flexDirection: 'column',
  backgroundColor: color.background[1],
  border: `1px solid ${color.border.subtle}`,
  borderRadius: border.radius.sm,
});

export const ContentMessageWrapper = styled('div')({
  display: 'flex',
  rowGap: spacing[6],
  flexDirection: 'column',
});

export const TitleAndSubtitleWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  rowGap: spacing[1],
});

export const ContentSubtitle = styled(Body)({
  color: color.typeface.smooth,
});

export const PhoneWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  rowGap: spacing[4],
  alignItems: 'flex-start',
});

export const ConfirmTriggersWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  rowGap: spacing[3],
});

export const FieldsContainer = styled('div')({
  display: 'flex',
  columnGap: spacing[4],
  justifyContent: 'flex-start',

  '& > div': {
    maxWidth: 224,
  },
});

export const ButtonsWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  columnGap: spacing[4],
});

export const PhysicianSelect = styled(Select)({
  maxWidth: 264,
});

export const ButtonsBox = styled('div')({
  display: 'flex',
  whiteSpace: 'nowrap',
  gap: spacing['4'],
  marginTop: spacing['3'],
});

export const PanelWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing[3],
});
