import { combineReducers } from 'redux';

import SchedulePatientsReducer, { SchedulePatientState } from './patients';

export type MessagingState = {
  schedulePatients: SchedulePatientState;
};

const messagingReducer = combineReducers<MessagingState>({
  schedulePatients: SchedulePatientsReducer,
});

export default messagingReducer;
