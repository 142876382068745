import React from 'react';
import { Avatar, Body, Heading, MaterialIcons } from '@iclinic/design-system';
import { useSelector } from 'react-redux';

import { formatTime } from 'shared/utils/date';
import { getSurveyInfoData } from 'features/survey/state/patients/selectors';
import { Card } from './Evaluation.styles';
import { phoneFormatter } from 'shared/utils/formatters';
import { getInitials } from 'shared/utils/v2/strings';
import {
  formatAddress,
  formatCityState,
  formatInFullDate,
  formatPhysicianCredentials,
} from '../../utils';
import * as S from './DoctorDataCard.styles';

const { AccessTime, TodayIcon, Phone, PinDropRounded } = MaterialIcons;

export default function DoctorDataCard() {
  const surveyData = useSelector(getSurveyInfoData);

  const physicianCredentials = formatPhysicianCredentials(
    surveyData?.physician.cbo,
    surveyData?.physician.council_name,
    surveyData?.physician.council_number,
    surveyData?.physician.council_region,
  );
  const cityAndState = formatCityState(
    surveyData?.clinic.city,
    surveyData?.clinic.state,
  );
  const address = formatAddress(
    surveyData?.clinic.address,
    surveyData?.clinic.number,
    surveyData?.clinic.complement,
  );

  return (
    <Card>
      <S.CardHeader>
        <Avatar size="md" src={surveyData?.physician.picture_url || undefined}>
          {getInitials(surveyData?.physician.name || '')}
        </Avatar>
        <div>
          <Heading variant="md">
            {surveyData?.physician.name || 'Não informado'}
          </Heading>
          <Body variant="xxs">{physicianCredentials || '-'}</Body>
        </div>
      </S.CardHeader>

      <S.CardContent>
        <Heading variant="xs">
          {surveyData?.clinic.name || 'Não informado'}
        </Heading>

        <S.BoxText>
          <S.Wrapper>
            <TodayIcon color="inherit" fontSize="small" />
          </S.Wrapper>
          <Body variant="xs">
            {surveyData?.event.date
              ? formatInFullDate(surveyData.event.date)
              : ''}
          </Body>
        </S.BoxText>

        <S.BoxText>
          <S.Wrapper>
            <AccessTime color="inherit" fontSize="small" />
          </S.Wrapper>
          <Body variant="xs">
            {surveyData?.event.start_time
              ? formatTime(surveyData?.event.start_time)
              : ''}
          </Body>
        </S.BoxText>

        <S.BoxText>
          <S.Wrapper>
            <PinDropRounded color="inherit" fontSize="small" />
          </S.Wrapper>
          <Body variant="xs">
            {address ? <span>{address}</span> : null}

            {surveyData?.clinic.neighborhood ? (
              <>
                <br />
                <span>{surveyData?.clinic.neighborhood}</span>
              </>
            ) : null}

            {cityAndState ? (
              <>
                <br />
                <span>{`${surveyData?.clinic.city} - ${surveyData?.clinic.state}`}</span>
              </>
            ) : null}

            {surveyData?.clinic.zip_code ? (
              <>
                <br />
                <span>{surveyData?.clinic.zip_code}</span>
              </>
            ) : null}
          </Body>
        </S.BoxText>

        <S.BoxText>
          <S.Wrapper>
            <Phone color="inherit" fontSize="small" />
          </S.Wrapper>
          <Body variant="xs">
            {surveyData?.clinic.phone
              ? phoneFormatter(surveyData.clinic.phone)
              : ''}
          </Body>
        </S.BoxText>
      </S.CardContent>
    </Card>
  );
}
