import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RequestStatus } from 'shared/constants/State';
import {
  SurveyEvent,
  FilterData,
  Physician,
} from 'features/survey/services/types';

export interface SendSurveyState {
  status: RequestStatus;
  surveyEvents?: SurveyEvent[];
  countSurveyEvents?: number;
  fetchPhysicianStatus: RequestStatus;
  physicians?: Physician[];
}

export const initialState: SendSurveyState = {
  status: RequestStatus.Pending,
  fetchPhysicianStatus: RequestStatus.Pending,
};

export const nameStore = '@webapp/survey';

export const fetchSurveyEvents = createAction<FilterData>(
  `${nameStore}/fetchSurveyEvents`,
);

export const fetchSurveyPhysicians = createAction(
  `${nameStore}/fetchSurveyPhysicians`,
);

export const fetchSurveyAssessmentLink = createAction<number>(
  `${nameStore}/fetchSurveyAssessmentLink`,
);

const SurveyDataSlice = createSlice({
  name: nameStore,
  initialState,
  reducers: {
    fetchSurveyEventsSuccess: (
      state,
      action: PayloadAction<{
        surveyEvents: SurveyEvent[];
        count: number;
      }>,
    ): SendSurveyState => ({
      ...state,
      status: RequestStatus.Success,
      surveyEvents: action.payload.surveyEvents,
      countSurveyEvents: action.payload.count,
    }),
    fetchSurveyEventsFailure: (state): SendSurveyState => ({
      ...state,
      status: RequestStatus.Error,
    }),
    fetchSurveyPhysiciansSuccess: (
      state,
      action: PayloadAction<Physician[]>,
    ): SendSurveyState => ({
      ...state,
      fetchPhysicianStatus: RequestStatus.Success,
      physicians: action.payload,
    }),
    fetchSurveyPhysiciansFailure: (state): SendSurveyState => ({
      ...state,
      fetchPhysicianStatus: RequestStatus.Error,
    }),
    fetchSurveyAssessmentLinkSuccess: (
      state,
      action: PayloadAction<number>,
    ) => ({
      ...state,
      surveyEvents: state.surveyEvents?.map((event) => ({
        ...event,
        status: event.id === action.payload ? 'Enviado' : event.status,
      })),
    }),
    isFetchingEvents: (state) => ({
      ...state,
      status: RequestStatus.Pending,
    }),
  },
});

export const {
  fetchSurveyEventsFailure,
  fetchSurveyEventsSuccess,
  fetchSurveyPhysiciansSuccess,
  fetchSurveyPhysiciansFailure,
  fetchSurveyAssessmentLinkSuccess,
  isFetchingEvents,
} = SurveyDataSlice.actions;

export default SurveyDataSlice.reducer;
