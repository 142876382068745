import React from 'react';
import { useSelector } from 'react-redux';
import { useFormikContext } from 'formik';

import { Box, MaterialUICore, tokens } from '@iclinic/design-system';
import { emailUIStateSelector } from '../../state/selectors';
import { CheckboxText } from '../Signup.styles';
import { SignupFormValues } from '../../types';
import {
  Checkbox,
  FormFeedbackBody,
  FormTermsBody,
  TermsControlLabel,
} from './Form.styles';
import { termsErrorMsg } from '../../constants';

const { FormGroup } = MaterialUICore;

const Terms = () => {
  const { values, handleChange, touched, errors } = useFormikContext<
    SignupFormValues
  >();

  const { disabled } = useSelector(emailUIStateSelector);

  const displayTermsError =
    !!touched.termsOfServiceAndPrivacyPolicy &&
    !!errors.termsOfServiceAndPrivacyPolicy;

  return (
    <>
      <FormGroup row>
        <TermsControlLabel
          control={
            <Checkbox
              checked={values.termsOfServiceAndPrivacyPolicy}
              onChange={handleChange}
              name="termsOfServiceAndPrivacyPolicy"
              color="primary"
              disabled={disabled}
              data-ga="setup-termsOfServiceAndPrivacyPolicy"
              error={displayTermsError ? 'error' : undefined}
            />
          }
          label={
            <>
              <FormTermsBody
                error={displayTermsError ? 'error' : undefined}
                variant="xxs"
              >
                Li e aceito os{' '}
                <CheckboxText
                  href="https://suporte.iclinic.com.br/iclinic-termos-de-uso"
                  bold
                  target="_blank"
                  data-ga="setup-termsOfService-link"
                  error={displayTermsError ? 'error' : undefined}
                >
                  Termos de Uso
                </CheckboxText>{' '}
                e{' '}
                <CheckboxText
                  target="_blank"
                  rel="noreferrer"
                  href="https://suporte.iclinic.com.br/politica-de-privacidade-iclinic"
                  bold
                  data-ga="setup-privacyPolicy-link"
                  error={displayTermsError ? 'error' : undefined}
                >
                  Política de Privacidade
                </CheckboxText>
              </FormTermsBody>
            </>
          }
        />
      </FormGroup>

      <Box mb={tokens.spacing.base}>
        {displayTermsError && (
          <FormFeedbackBody
            variant="xxs"
            error={displayTermsError ? 'error' : undefined}
          >
            {termsErrorMsg}
          </FormFeedbackBody>
        )}
      </Box>
    </>
  );
};

export default Terms;
