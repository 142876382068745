import {
  Box,
  Body,
  MaterialIcons as Icons,
  MaterialUICore,
  axiomTokens,
  Heading,
} from '@iclinic/design-system';

const { styled, Radio } = MaterialUICore;
const { border, color, spacing } = axiomTokens;

export const Card = styled(Box)({
  gridColumn: '2',
  gridRow: '1 / span 2',
  padding: spacing[4],
  display: 'flex',
  flexDirection: 'column',
  rowGap: spacing[3],
  borderRadius: border.radius.sm,
  border: `1px solid ${color.border.subtle}`,
  cursor: 'pointer',

  '&:has(input:checked)': {
    borderColor: color.border.activated,
  },
});

export const HorizontalWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: spacing[1],
});

export const HorizontalWrapperWithSpace = styled(HorizontalWrapper)({
  justifyContent: 'space-between',
});

export const HeadingSmooth = styled(Heading)({
  color: color.typeface.smooth,
});

export const BodySmooth = styled(Body)({
  color: color.typeface.smooth,
});

export const CloseIcon = styled(Icons.Close)({
  color: color.highlight.red[3],
});

export const CheckIcon = styled(Icons.CheckCircleOutline)({
  color: color.highlight.teal[1],
});

export const Divider = styled('div')({
  height: 1,
  paddingTop: 1,
  backgroundColor: color.background[3],
});

export const SimpleRadio = styled(Radio)({
  padding: 0,
  margin: 0,
});
