// External
import { useEffect, useState } from 'react';

const defaultPageWidthMobile = 466;

const useWidthUpdater = (pageWidthMobile = defaultPageWidthMobile): boolean => {
  const [pageWidth, setWidth] = useState(window.innerWidth);
  const isMobile: boolean = pageWidth < pageWidthMobile;

  useEffect(() => {
    const updatePageWidth = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', updatePageWidth);
    return () => window.removeEventListener('resize', updatePageWidth);
  }, [setWidth]);
  return isMobile;
};

export default useWidthUpdater;
