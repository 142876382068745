import { AxiosError } from 'axios';

import { UNKNOWN_ERROR_CODE } from './constants';

export const getErrorCode = (error: AxiosError): string =>
  error?.response?.data?.message?.code || UNKNOWN_ERROR_CODE;

export const replaceCharactersByTags = (content: string) =>
  content
    .replace(/\*([^*]+)\*/g, '<strong>$1</strong>')
    .replace(/_([^_]+)_/g, '<em>$1</em>')
    .replace(/~([^~]+)~/g, '<s>$1</s>')
    .replace(/(\r\n|\r|\n)/g, '<br/>');

export const calculatePercentage = (part: number, total: number) =>
  Math.trunc((part * 100) / total);
