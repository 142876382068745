// External
import { takeLatest, call, put, all, select } from 'redux-saga/effects';
import { Saga } from 'redux-saga';

// Internal
import { captureException } from 'shared/utils/handlerErrors';
import { emitErrors } from 'shared/utils/emitErrors';
import { ApiErrors, ActionCreatorFailure } from '@types';
import iclinic from 'services/iclinic';
import * as types from './types';
import {
  trackEvent,
  downloadUrlOpenIntent,
  generatePdfFailure,
  generatePdfSuccess,
  sendEmailFailure,
  sendEmailSuccess,
  setLoadingPDF,
  openExternalUrlIntent,
} from './actions';
import { errorsHandle } from './errors';

export const lists3Pdf = (state) => state.shared.s3Pdf;
export const patientId = (state) => state.records.root.patient.id;

type TrackEvent = ReturnType<typeof trackEvent>;
export function* workerTrackEvent({ payload, trackFn }: TrackEvent) {
  try {
    yield trackFn(payload);
  } catch (error) {
    captureException(error);
  }
}

type DownloadUrlOpenIntent = ReturnType<typeof downloadUrlOpenIntent>;
export function workerDownloadUrlOpenIntent({
  payload,
}: DownloadUrlOpenIntent) {
  const nativeWebView = window.ReactNativeWebView;
  if (nativeWebView) {
    nativeWebView.postMessage(JSON.stringify(downloadUrlOpenIntent(payload)));
  }
}

type OpenExternalUrlIntent = ReturnType<typeof openExternalUrlIntent>;
export function workerOpenExternalUrlIntent({
  payload,
}: OpenExternalUrlIntent) {
  const nativeWebView = window.ReactNativeWebView;
  if (nativeWebView) {
    nativeWebView.postMessage(JSON.stringify(openExternalUrlIntent(payload)));
  }
}

export function* handlerErrors(
  error: Error | ApiErrors[],
  actionCreatorFailure: ActionCreatorFailure,
) {
  if (error instanceof Error) {
    captureException(error);
    return;
  }
  yield put(actionCreatorFailure(emitErrors(error)));
}

export function* workerFetchUrlPdf(
  id: string,
  processIdPdf: string,
  documentType: string,
) {
  try {
    const { getResponseData, errors } = yield call(
      iclinic.pdfGenerator.getRetrieveUrlPDF,
      processIdPdf,
    );

    if (errors) throw errors;

    const { url } = getResponseData();

    const pdfs = yield select(lists3Pdf);

    yield put(generatePdfSuccess([...pdfs, { id, documentType, url }]));
  } catch (error) {
    yield call(handlerErrors, error, generatePdfFailure);
  }
}

export function* workerGeneratePDF(
  id: string,
  documentType: string,
  url: string,
  queryParams: object = {},
) {
  try {
    yield put(setLoadingPDF());
    const { getResponseData, errors } = yield call(
      iclinic.pdfGenerator.generatorPdf,
      { url, queryParams },
    );
    if (errors) throw errors;

    const { process_id, key } = getResponseData();

    const ticketId = key || process_id;

    yield call(workerFetchUrlPdf, id, ticketId, documentType);
  } catch (error) {
    yield call(handlerErrors, error, generatePdfFailure);
  }
}

export function* workerSendEmail(emailTemplate: types.EmailSendRequest) {
  try {
    const { errors } = yield call(iclinic.email.fetchEmailSend, emailTemplate);

    if (errors) throw errors;
  } catch (error) {
    yield call(handlerErrors, error, sendEmailFailure);
  }
}

export function* workerFetchSendEmail(emailTemplate: types.EmailSendRequest) {
  try {
    const {
      email: { recipients },
    } = emailTemplate;

    const { getResponseData: data, errors: errorsData } = yield call(
      iclinic.email.fetchEmailIsValid,
      recipients[0],
    );

    if (errorsData) {
      yield call(workerSendEmail, emailTemplate);
      return;
    }

    const { is_valid } = data();

    if (!is_valid) {
      yield put(sendEmailFailure([errorsHandle.emailInvalid]));
      return;
    }

    yield put(sendEmailSuccess(is_valid));
    yield call(workerSendEmail, emailTemplate);
  } catch (error) {
    yield call(handlerErrors, error, sendEmailFailure);
  }
}

export function* workeredirect({
  payload: { url, method },
}: {
  payload: types.Redirect;
}) {
  yield call([window.location, method], url);
}

export function* sharedSagas() {
  yield all([
    yield takeLatest(types.TRACK_EVENT, workerTrackEvent),
    yield takeLatest(
      types.DOWNLOAD_URL_OPEN_INTENT,
      workerDownloadUrlOpenIntent,
    ),
    yield takeLatest(
      types.OPEN_EXTERNAL_URL_INTENT,
      workerOpenExternalUrlIntent,
    ),
  ]);
}

export default sharedSagas;

export function* keepAliveSaga(saga: Saga) {
  while (true) {
    try {
      yield call(saga);
      break;
    } catch (e) {
      yield call(captureException, e);
    }
  }
}
