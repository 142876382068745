import {
  axiomTokens,
  Link as DSLink,
  MaterialUICore,
} from '@iclinic/design-system';

const { styled } = MaterialUICore;
const { spacing } = axiomTokens;

export const NotficationWrapper = styled('div')({
  padding: `${spacing[4]} ${spacing[6]} 0`,
});

export const Link = styled(DSLink)({
  fontSize: 'inherit',
});
