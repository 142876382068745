import { MaterialUICore, axiomTokens } from '@iclinic/design-system';

const { styled } = MaterialUICore;
const { color } = axiomTokens;

export const BackgroundWrapper = styled('div')(({ theme }) => ({
  position: 'fixed',
  width: '100%',
  height: '100%',
  zIndex: 0,

  '& *': {
    width: '100%',
    height: '100%',
    position: 'absolute',
  },

  '& img': {
    objectFit: 'cover',
    objectPosition: 'left top',
    zIndex: 1,
  },

  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

export const Backdrop = styled('div')({
  background: color.others.backdrop,
  opacity: '.5',
  zIndex: 2,
});
