import ReactGA from 'react-ga';

const GoogleAnalytics = (ua) => {
  ReactGA.initialize(ua, {
    debug: process.env.DEV,
    testMode: process.env.NODE_ENV === 'test',
  });

  return {
    trackEvent: (payload) => ReactGA.event(payload),
  };
};

export default GoogleAnalytics;
