import React from 'react';
import { useFormikContext } from 'formik';

import { SignupFormValues } from '../../types';
import { SignupField } from './Form.styles';

const NameInput = () => {
  const {
    errors,
    touched,
    handleChange,
    values,
    handleBlur,
  } = useFormikContext<SignupFormValues>();

  return (
    <SignupField
      label="Nome completo"
      id="name"
      name="name"
      type="text"
      placeholder=""
      autoComplete="name"
      margin="normal"
      error={!!(errors.name && touched.name)}
      helperText={touched.name && errors.name}
      fullWidth
      onBlur={handleBlur}
      onChange={handleChange}
      value={values.name}
      data-ga="setup-name"
    />
  );
};

export default NameInput;
