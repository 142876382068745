import React from 'react';
import { Heading } from '@iclinic/design-system';

import * as S from './PageHeader.styles';

interface PageHeaderProps {
  title: React.ReactNode;
  description: React.ReactNode;
}

const PageHeader = ({ description, title }: PageHeaderProps) => (
  <div>
    <Heading variant="md">{title}</Heading>

    <S.DescriptionText variant="xs">{description}</S.DescriptionText>
  </div>
);

export { PageHeader };
