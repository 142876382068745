// Internal
import { ActionType } from '@types';
import * as types from './types';
import * as actions from './actions';

export const initialState = {
  updatePermissionsUrl: '',
  url: '',
  s3Pdf: [],
  queryParams: {},
  errors: [],
  isEmailValid: true,
  isLoading: false,
  isSending: false,
};

type SharedState = typeof initialState;
type SharedActions = ActionType<typeof actions>;

const shared = (state = initialState, action: SharedActions): SharedState => {
  switch (action.type) {
    case types.SET_UPDATE_PERMISSIONS_URL:
      return {
        ...state,
        updatePermissionsUrl: action.payload.updatePermissionsUrl,
      };
    case types.SET_PROCESS_ID:
      return {
        ...state,
        processId: action.payload.processId,
      };
    case types.SET_LOADING_PDF:
      return {
        ...state,
        isLoading: true,
      };
    case types.GENERATE_PDF_SUCCESS:
      return {
        ...state,
        isLoading: action.payload.isLoading,
        s3Pdf: action.payload.s3Pdf,
      };
    case types.SEND_EMAIL_SIGNATURE:
      return {
        ...state,
        isSending: action.payload.isSending,
      };
    case types.SEND_EMAIL_FAILURE:
      return {
        ...state,
        isSending: action.payload.isSending,
        errors: action.payload.errors,
      };
    case types.GENERATE_PDF_FAILURE:
      return {
        ...state,
        isLoading: action.payload.isLoading,
        errors: action.payload.errors,
      };
    case types.SEND_EMAIL_SUCCESS:
      return {
        ...state,
        isSending: action.payload.isSending,
        isEmailValid: action.payload.isEmailValid,
      };
    case types.CLEAR_PDF_LIST:
      return {
        ...state,
        s3Pdf: [],
        errors: [],
      };
    case types.CLEAR_SHARED_ERRORS:
      return {
        ...state,
        errors: [],
      };
    default:
      return state;
  }
};

export default shared;
