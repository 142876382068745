import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Body,
  Box,
  CircularProgress,
  Heading,
  MaterialUICore,
} from '@iclinic/design-system';

import { cleanPhysiciansData, fetchPhysicians } from '../../state/common';
import {
  getPermissionsMenu,
  getUserKind,
  isFetchingPhysicians,
} from '../../state/common/selectors';
import { MessageStatus, TemplateKind } from '../../services/types';
import ShippingHistory from './ShippingHistory/ShippingHistory';
import { PersonalizationTabPanel } from './PersonalizationTabPanel';
import * as S from './ScheduleConfirmation.styles';

const { Tab, Tabs } = MaterialUICore;

enum TabOptions {
  PERSONALIZATION = 'personalization',
  HISTORY = 'history',
}

const ScheduleConfirmation = () => {
  const dispatch = useDispatch();
  const isFetching = useSelector(isFetchingPhysicians);
  const userKind = useSelector(getUserKind);
  const permissionsMenu = useSelector(getPermissionsMenu);
  const [tab, setTab] = useState(() => {
    if (permissionsMenu?.confirmation_event.customization) {
      return TabOptions.PERSONALIZATION;
    }

    return TabOptions.HISTORY;
  });

  const onChangeTabHandler = (
    _: React.ChangeEvent<{}>,
    newValue: TabOptions,
  ) => {
    setTab(newValue);
  };

  useEffect(() => {
    dispatch(cleanPhysiciansData());

    if (userKind === 'r') {
      dispatch(fetchPhysicians());
    }
  }, [dispatch, userKind]);

  return (
    <S.Root>
      <div>
        <Heading variant="md">Confirmação de agenda</Heading>

        <Body variant="xs">
          Reduza cancelamentos e tenha mais controle da sua agenda através do
          envio automático de confirmações de agenda.
        </Body>
      </div>

      {isFetching ? (
        <Box display="flex" justifyContent="center">
          <CircularProgress data-testid="loader-physicians" />
        </Box>
      ) : (
        <S.ContentTabs>
          <Tabs
            textColor="primary"
            indicatorColor="primary"
            value={tab}
            onChange={onChangeTabHandler}
          >
            {permissionsMenu?.confirmation_event.customization ? (
              <Tab label="Personalização" value={TabOptions.PERSONALIZATION} />
            ) : null}

            <Tab label="Histórico de envios" value={TabOptions.HISTORY} />
          </Tabs>

          <div hidden={tab !== TabOptions.PERSONALIZATION}>
            <PersonalizationTabPanel />
          </div>

          <div hidden={tab !== TabOptions.HISTORY}>
            <ShippingHistory
              kind={TemplateKind.CONFIRMATION}
              statusOptions={[
                MessageStatus.SENT,
                MessageStatus.CANCELED,
                MessageStatus.CONFIRMED,
                MessageStatus.ERROR,
                MessageStatus.NUMBER_INVALID,
              ]}
            />
          </div>
        </S.ContentTabs>
      )}
    </S.Root>
  );
};

export { ScheduleConfirmation };
