import React from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';

import AuthRoute from 'routes/AuthRoute';
import NotFound from 'shared/components/NotFound';
import {
  LoginV2,
  NewPassword,
  PasswordResetRequest,
  PasswordExpirationMessageExpired,
} from 'features/new-auth/containers';
import StepSignupRoutes from 'features/new-auth/signup/stepSignup/components/Routes';
import SurveyRoutes from 'features/survey/routes';
import EmbedFormContainer from 'features/new-auth/signup/containers/EmbedFormContainer';
import AppointmentRoutes from 'features/messaging/routes/public.routes';

const SlashNewRoutes = ({ match }: RouteComponentProps) => (
  <Switch>
    <AuthRoute exact path={`${match.path}/`} component={LoginV2} />
    <AuthRoute
      exact
      path={`${match.path}/usuarios/login`}
      component={LoginV2}
    />
    <Route
      exact
      path={`${match.path}/usuarios/recuperar-senha`}
      component={PasswordResetRequest}
    />
    <Route
      exact
      path={`${match.path}/usuarios/reset/:token`}
      component={NewPassword}
    />
    <Route
      path={`${match.path}/usuarios/setup`}
      component={() => {
        window.location.href = `/new/cadastro/${window.location.search}`;
        return null;
      }}
    />
    <Route path={`${match.path}/cadastro`} component={StepSignupRoutes} />
    <Redirect
      from={`${match.path}/signup`}
      to={{
        pathname: `${match.path}/cadastro`,
        search: window.location.search,
      }}
    />
    <Route path={`${match.path}/subscribe`} component={StepSignupRoutes} />
    <Route
      exact
      path={`${match.path}/embed-signup-form`}
      component={EmbedFormContainer}
    />

    <Route
      path={`${match.path}/pesquisa-de-satisfacao`}
      component={SurveyRoutes}
    />

    <Route
      path={`${match.path}/confirmacao-agenda`}
      component={AppointmentRoutes}
    />

    <Route
      path={`${match.path}/usuarios/senha-expirada`}
      component={PasswordExpirationMessageExpired}
    />

    <Route component={NotFound} />
  </Switch>
);

export default SlashNewRoutes;
