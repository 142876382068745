import { Body, Heading } from '@iclinic/design-system';
import React from 'react';
import { useSelector } from 'react-redux';

import { image } from 'shared/utils/urlGetter';
import { getScheduleInfoData } from 'features/messaging/state/patients/selectors';
import { formatInFullDateMessage } from 'features/messaging/formatters';
import { formatTime } from 'shared/utils/date';
import { Card, CardContainer, CardContent, MainCard } from '../Common.styles';
import * as S from './SuccessAppointment.styles';

export function SuccessAppointment() {
  const appointmentData = useSelector(getScheduleInfoData);

  return (
    <MainCard>
      <Card>
        <CardContainer>
          <S.Image>
            <img
              src={image('appointmentConfirmation.imageSuccess')}
              alt="imagem de agenda confirmada"
            />
          </S.Image>
          <CardContent>
            <Heading variant="lg" align="center">
              Agenda confirmada!
            </Heading>
            <Body variant="sm">
              Sua agenda com o {appointmentData?.physician.name} em{' '}
              {appointmentData?.event_date
                ? formatInFullDateMessage(appointmentData.event_date)
                : ''}{' '}
              às <br />
              {appointmentData?.event_time
                ? formatTime(appointmentData.event_time)
                : ''}{' '}
              foi confirmada com sucesso.
            </Body>
          </CardContent>
        </CardContainer>
      </Card>
    </MainCard>
  );
}
