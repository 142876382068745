// Internal
import { Breadcrumb } from 'shared/sentryClient';
import { products } from 'state/upgradeAddon/constants';

export const TRACK_EVENT = '@webapp/shared/TRACK_EVENT';
export const SET_UPDATE_PERMISSIONS_URL =
  '@webapp/shared/SET_UPDATE_PERMISSIONS_URL';
export const FEATURE_USER_ENABLED = '@webapp/shared/FEATURE_USER_ENABLED';
export const DOWNLOAD_URL_OPEN_INTENT =
  '@webapp/shared/DOWNLOAD_URL_OPEN_INTENT';
export const OPEN_EXTERNAL_URL_INTENT =
  '@webapp/shared/OPEN_EXTERNAL_URL_INTENT';
export const CLEAR_PDF_LIST = '@webapp/shared/CLEAR_PDF_LIST';
export const SET_PROCESS_ID = '@webapp/shared/SET_PROCESS_ID';
export const GENERATE_PDF_FAILURE = '@webapp/shared/GENERATE_PDF_FAILURE';
export const GENERATE_PDF_SUCCESS = '@webapp/shared/GENERATE_PDF_SUCCESS';
export const SEND_EMAIL = '@webapp/shared/SEND_EMAIL';
export const SEND_EMAIL_FAILURE = '@webapp/shared/SEND_EMAIL_FAILURE';
export const SEND_EMAIL_SUCCESS = '@webapp/shared/SEND_EMAIL_SUCCESS';
export const SET_LOADING_PDF = '@webapp/shared/SET_LOADING_PDF';
export const SEND_EMAIL_SIGNATURE = '@webapp/shared/SEND_EMAIL_SIGNATURE';
export const CLEAR_SHARED_ERRORS = '@webapp/shared/CLEAR_SHARED_ERRORS';

type TrackBreadcrumb = Breadcrumb;
type TrackAnalytics = {
  label: keyof typeof products;
  action: 'open' | 'close' | 'step';
  category: 'addon';
};

export type DownloadIntentPayload = {
  url: string;
  name: string;
  extension: string;
};

export type OpenExternalUrlIntentPayload = {
  url: string;
};

export type PDFPayload = {
  url: string;
  queryParams?: object;
  documentType: string;
};

export type PDFResponse = {
  id: string;
  documentType: string;
  url: string;
};

export type EmailValidResponse = {
  is_valid: boolean;
};

export type Attachments = {
  file_name: string;
  url: string;
};

export type Email = {
  sender: string;
  reply_to: string;
  subject: string;
  body: string;
  recipients: string[];
  configuration_set: string;
  attachments: Attachments[];
};

export type EmailSendRequest = {
  patient_id: string;
  physician_id: string;
  email: Email;
};

export interface Redirect {
  url: string;
  method: 'assign' | 'replace';
}

export type TrackNodes = 'track' | 'breadcrumb';
export type TracksPayload = TrackBreadcrumb | TrackAnalytics;
export type TrackFunction = (payload: TracksPayload) => void;
