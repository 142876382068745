import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RequestStatus } from 'shared/constants/State';
import {
  DashboardData,
  DashboardFilterData,
  Physician,
  RecentNpsFilterData,
  RecentNpsResponse,
} from '../../services/types';

export interface SurveyDashboardState {
  fetcherPhysicianStatus: RequestStatus;
  physicians?: Physician[];
  fetcherDashboardDataStatus?: RequestStatus;
  dashboardData?: DashboardData;
  fetcherRecentNpsStatus?: RequestStatus;
  recentNpsData?: RecentNpsResponse;
  selectedId?: string;
}

export const initialState: SurveyDashboardState = {
  fetcherPhysicianStatus: RequestStatus.Pending,
};

export const nameStore = '@webapp/survey-dashboard';

export const fetchPhysicians = createAction(`${nameStore}/fetchPhysicians`);

export const fetchSurveyDashboardData = createAction<DashboardFilterData>(
  `${nameStore}/fetchSurveyDashboardData`,
);

export const fetchRecentNps = createAction<RecentNpsFilterData>(
  `${nameStore}/fetchRecentNps`,
);

const SurveyDashboadSlice = createSlice({
  name: nameStore,
  initialState,
  reducers: {
    fetchPhysiciansSuccess: (state, action: PayloadAction<Physician[]>) => ({
      ...state,
      fetcherPhysicianStatus: RequestStatus.Success,
      physicians: action.payload,
    }),
    fetchPhysiciansFailure: (state) => ({
      ...state,
      fetcherPhysicianStatus: RequestStatus.Error,
    }),
    fetchSurveyDashboardDataStart: (state) => ({
      ...state,
      fetcherDashboardDataStatus: RequestStatus.Pending,
    }),
    fetchSurveyDashboardDataSuccess: (
      state,
      action: PayloadAction<DashboardData>,
    ) => ({
      ...state,
      fetcherDashboardDataStatus: RequestStatus.Success,
      dashboardData: action.payload,
    }),
    fetchSurveyDashboardDataFailure: (state) => ({
      ...state,
      fetcherDashboardDataStatus: RequestStatus.Error,
    }),
    fetchRecentNpsStart: (state) => ({
      ...state,
      fetcherRecentNpsStatus: RequestStatus.Pending,
    }),
    fetchRecentNpsSuccess: (
      state,
      action: PayloadAction<RecentNpsResponse>,
    ) => ({
      ...state,
      fetcherRecentNpsStatus: RequestStatus.Success,
      recentNpsData: action.payload,
    }),
    fetchRecentNpsFailure: (state) => ({
      ...state,
      fetcherRecentNpsStatus: RequestStatus.Error,
    }),
    selectPhysicianId: (state, action: PayloadAction<string>) => ({
      ...state,
      selectedId: action.payload,
    }),
  },
});

export const {
  fetchPhysiciansSuccess,
  fetchPhysiciansFailure,
  fetchRecentNpsStart,
  fetchRecentNpsSuccess,
  fetchRecentNpsFailure,
  fetchSurveyDashboardDataStart,
  fetchSurveyDashboardDataSuccess,
  fetchSurveyDashboardDataFailure,
  selectPhysicianId,
} = SurveyDashboadSlice.actions;

export default SurveyDashboadSlice.reducer;
