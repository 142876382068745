import {
  MaterialUICore,
  axiomTokens,
  Body as BodyDS,
  Link as LinkDS,
  Heading as HeadingDS,
} from '@iclinic/design-system';

const { styled } = MaterialUICore;
const { font, spacing, color } = axiomTokens;

export const Link = styled(LinkDS)({
  display: 'inline',
  fontSize: font.size.xxs,
});

export const FormContainer = styled('div')({
  padding: `0 0 ${spacing[16]}`,
});

export const Body = styled(BodyDS)({
  color: color.typeface.smooth,
  marginBottom: spacing[2],
});

export const Heading = styled(HeadingDS)({
  color: color.typeface.pure,
  marginBottom: spacing[5],
  fontSize: font.size.md,
});

export const EmailButtonWrapper = styled('div')({
  marginTop: spacing[10],
});

export const ButtonWrapper = styled('div')({
  padding: `${spacing[3]} ${spacing[8]}`,
});

export const ButtonTerms = styled(BodyDS)({
  marginTop: spacing[4],
  color: color.typeface.smooth,
});
