import React, { FocusEventHandler } from 'react';
import { useFormikContext } from 'formik';
import { TextField, MaterialUICore } from '@iclinic/design-system';
import { useSelector } from 'react-redux';

import { AccountFormValues, PossibleSignupRoles } from '../../constants';
import { getEmbed } from '../../state/selectors';

const { useTheme, useMediaQuery, MenuItem } = MaterialUICore;

const NativeOption = (props: any) => <option {...props} />;

interface ProfessionSelectProps {
  toggleDialogModal: Function;
  onBlur: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
}

const ProfessionSelect = ({
  toggleDialogModal,
  onBlur,
}: ProfessionSelectProps) => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));
  const isEmbed = useSelector(getEmbed);

  const { errors, touched, handleChange, values } =
    useFormikContext<AccountFormValues>();

  const native = !isEmbed && isXs;
  const OptionComponent = native ? NativeOption : MenuItem;
  const selectProps = { native };

  const normalizeOptions = () =>
    Object.values(PossibleSignupRoles).map((value) => ({
      value,
      title: value,
    }));

  const selectOptions = native
    ? [{ value: '', title: '' }, ...normalizeOptions()]
    : normalizeOptions();

  const onChangeHandler = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    handleChange(event);
    const profession = event.target.value;
    if (profession === PossibleSignupRoles.Patient) toggleDialogModal();
  };

  return (
    <TextField
      label="Seu papel na clínica"
      id="profession"
      select
      name="profession"
      fullWidth
      value={values.profession}
      SelectProps={selectProps}
      error={!!(errors.profession && touched.profession)}
      helperText={
        touched.profession && errors.profession ? errors.profession : ''
      }
      onChange={onChangeHandler}
      data-ga="setup-profession"
      onBlur={onBlur}
    >
      {selectOptions.map((item) => (
        <OptionComponent key={item.value} value={item.value}>
          {item.title}
        </OptionComponent>
      ))}
    </TextField>
  );
};

export default ProfessionSelect;
