import { createSlice } from '@reduxjs/toolkit';

import { GoogleState } from './types';

const initialState: GoogleState = {
  auth2Ready: false,
};

const googleSlice = createSlice({
  name: 'google',
  initialState,
  reducers: {
    initSuccess: (state) => {
      state.auth2Ready = true;
    },
    initFailure: (state) => {
      state.auth2Ready = false;
    },
  },
});

export const { initSuccess, initFailure } = googleSlice.actions;

export default googleSlice.reducer;
