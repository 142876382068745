import React, { useEffect } from 'react';
import {
  Body,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogHeader,
  Heading,
} from '@iclinic/design-system';
import { useDispatch, useSelector } from 'react-redux';

import { onlyNumbers } from 'shared/utils/formatters';
import { fetchConfirmationPreviewMessage } from '../../../state/scheduleConfirmation/personalization';
import {
  getConfirmationPreviewMessage,
  isFetchingConfirmationPreviweMessage,
} from '../../../state/scheduleConfirmation/personalization/selectors';
import * as S from './PreviewMessageModal.styles';

interface PreviewMessageModalProps {
  physicianId?: number;
  open: boolean;
  onClose?: () => void;
  content?: string;
  contactPhone?: string;
}

const PreviewMessageModal = ({
  physicianId,
  open,
  onClose,
  content,
  contactPhone,
}: PreviewMessageModalProps) => {
  const dispatch = useDispatch();
  const isFetchingPreview = useSelector(isFetchingConfirmationPreviweMessage);
  const message = useSelector(getConfirmationPreviewMessage);

  useEffect(() => {
    if (open && physicianId) {
      dispatch(
        fetchConfirmationPreviewMessage({
          physicianId,
          template: {
            contactPhone: onlyNumbers(contactPhone || ''),
            content,
          },
        }),
      );
    }
  }, [contactPhone, content, dispatch, open, physicianId]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogHeader title="Preview" onClose={onClose} />

      <DialogContent>
        <S.PreviewTitleWrapper>
          <S.WhatsAppIcon />

          <Heading variant="sm">Preview da mensagem</Heading>
        </S.PreviewTitleWrapper>

        {isFetchingPreview && (
          <Box display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        )}

        <Body
          variant="sm"
          dangerouslySetInnerHTML={{
            __html: message || '',
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button size="large" onClick={onClose}>
          Sair do preview
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { PreviewMessageModal };
