const { ASSETS_PATH } = process.env;

const images = {
  upgrade: {
    pro: `${ASSETS_PATH as string}images/upgrade/upgrade-pro.png`,
    plus: `${ASSETS_PATH as string}images/upgrade/upgrade-plus.png`,
    premium: `${ASSETS_PATH as string}images/upgrade/upgrade-premium.png`,
    blueFriday: `${ASSETS_PATH as string}images/upgrade/selo-blue-friday.png`,
    successPro: `${ASSETS_PATH as string}images/upgrade/success-pro.png`,
    successPlus: `${ASSETS_PATH as string}images/upgrade/success-plus.png`,
    successPremium: `${
      ASSETS_PATH as string
    }images/upgrade/success-premium.png`,
  },
  subscription: {
    changeDateSuccess: `${
      ASSETS_PATH as string
    }images/subscription/change_date_success.gif`,
    forbiddenChangeDate: `${
      ASSETS_PATH as string
    }images/subscription/forbidden_change_date.gif`,
    additionalPlans: `${ASSETS_PATH as string}images/subscription/fakedoor.png`,
    fakedoorBanner: `${
      ASSETS_PATH as string
    }images/subscription/fakedoor-banner.png`,
  },
};

export default images;
