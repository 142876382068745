import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit';

import { RequestStatus } from 'shared/constants/State';
import {
  BestProduct,
  GetBestProductParams,
  Physician,
  TemplateKind,
  SubscriptionSummary,
} from '../../../services/types';
import { PhysiciansAndKinds, ProductByPhysician } from '../../types';

export interface CheckoutState {
  fetcherPhysicianStatus: RequestStatus;
  physicians: Physician[];
  selectedPhysicians?: Physician[];
  selectedKinds?: TemplateKind[];
  currentStep?: number;
  fetcherBestProduct?: RequestStatus;
  bestProduct?: BestProduct;
  selectedProductByPhysician?: ProductByPhysician[];
  fetcherSubscriptionSummary?: RequestStatus;
  subscriptionSummary?: SubscriptionSummary;
  openSummary: boolean;
  submitSubscriptionStatus?: RequestStatus;
  openSuccess: boolean;
}

export const initialState: CheckoutState = {
  fetcherPhysicianStatus: RequestStatus.Pending,
  physicians: [],
  openSummary: false,
  openSuccess: false,
};

export const nameStore = '@webapp/messaging/messagePackage/checkout';

export const fetchPhysiciansAvailable = createAction(
  `${nameStore}/fetchPhysiciansAvailable`,
);

export const fetchBestProductByPhysician = createAction<GetBestProductParams>(
  `${nameStore}/fetchBestProductByPhysician`,
);

export const submitProductSelection = createAction<ProductByPhysician>(
  `${nameStore}/submitProductSelection`,
);

export const fetchSubscriptionSummary = createAction(
  `${nameStore}/fetchSubscriptionSummary`,
);

export const submitSubscription = createAction(
  `${nameStore}/submitSubscription`,
);

const CheckoutSlice = createSlice({
  name: nameStore,
  initialState,
  reducers: {
    fetchPhysiciansAvailableSuccess: (
      state,
      action: PayloadAction<Physician[]>,
    ) => ({
      ...state,
      fetcherPhysicianStatus: RequestStatus.Success,
      physicians: action.payload,
    }),
    fetchPhysiciansAvailableFailure: (state) => ({
      ...state,
      fetcherPhysicianStatus: RequestStatus.Error,
    }),
    setSelectedPhysiciansAndKinds: (
      state,
      action: PayloadAction<PhysiciansAndKinds>,
    ) => ({
      ...state,
      selectedPhysicians: action.payload.physicians,
      selectedKinds: action.payload.kinds,
    }),
    setCurrentStep: (state, action: PayloadAction<number>) => ({
      ...state,
      currentStep: action.payload,
    }),
    fetchBestProductByPhysicianStart: (state) => ({
      ...state,
      fetcherBestProduct: RequestStatus.Pending,
    }),
    fetchBestProductByPhysicianSuccess: (
      state,
      action: PayloadAction<BestProduct>,
    ) => ({
      ...state,
      fetcherBestProduct: RequestStatus.Success,
      bestProduct: action.payload,
    }),
    fetchBestProductByPhysicianFailure: (state) => ({
      ...state,
      fetcherBestProduct: RequestStatus.Error,
    }),
    setSelectedProductByPhysician: (
      state,
      action: PayloadAction<ProductByPhysician[]>,
    ) => ({
      ...state,
      selectedProductByPhysician: action.payload,
    }),
    fetchSubscriptionSummaryStart: (state) => ({
      ...state,
      fetcherSubscriptionSummary: RequestStatus.Pending,
    }),
    fetchSubscriptionSummarySuccess: (
      state,
      action: PayloadAction<SubscriptionSummary>,
    ) => ({
      ...state,
      fetcherSubscriptionSummary: RequestStatus.Success,
      subscriptionSummary: action.payload,
    }),
    fetchSubscriptionSummaryFailure: (state) => ({
      ...state,
      fetcherSubscriptionSummary: RequestStatus.Error,
    }),
    toggleOpenSummary: (state) => ({
      ...state,
      openSummary: !state.openSummary,
    }),
    toggleOpenSuccess: (state) => ({
      ...state,
      openSuccess: !state.openSuccess,
    }),
    submitSubscriptionStart: (state) => ({
      ...state,
      submitSubscriptionStatus: RequestStatus.Pending,
    }),
    submitSubscriptionSuccess: (state) => ({
      ...state,
      submitSubscriptionStatus: RequestStatus.Success,
    }),
    submitSubscriptionFailure: (state) => ({
      ...state,
      submitSubscriptionStatus: RequestStatus.Error,
    }),
  },
});

export const {
  fetchPhysiciansAvailableFailure,
  fetchPhysiciansAvailableSuccess,
  setSelectedPhysiciansAndKinds,
  setCurrentStep,
  fetchBestProductByPhysicianStart,
  fetchBestProductByPhysicianSuccess,
  fetchBestProductByPhysicianFailure,
  setSelectedProductByPhysician,
  fetchSubscriptionSummaryStart,
  fetchSubscriptionSummarySuccess,
  fetchSubscriptionSummaryFailure,
  toggleOpenSummary,
  toggleOpenSuccess,
  submitSubscriptionStart,
  submitSubscriptionSuccess,
  submitSubscriptionFailure,
} = CheckoutSlice.actions;

export default CheckoutSlice.reducer;
