import { MaterialUICore } from '@iclinic/design-system';

const { makeStyles } = MaterialUICore;

const useStyles = makeStyles((theme: MaterialUICore.Theme) => ({
  containerImage: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      height: '100%',
      width: '50%',
      zIndex: 2,
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  image: {
    maxWidth: '80%',
  },
  loader: {
    transform: 'translate(-50%, -50%)',
    zIndex: 5,
  },
}));

export default useStyles;
