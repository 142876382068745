import {
  newTracker,
  enableActivityTracking,
  trackPageView,
  trackSelfDescribingEvent,
  SelfDescribingJson,
} from '@snowplow/browser-tracker';
import {
  ConsentPlugin,
  enableGdprContext,
} from '@snowplow/browser-plugin-consent';
import {
  FormTrackingPlugin,
  enableFormTracking,
} from '@snowplow/browser-plugin-form-tracking';
import {
  LinkClickTrackingPlugin,
  enableLinkClickTracking,
} from '@snowplow/browser-plugin-link-click-tracking';

import history from 'routes/history';
import { captureException } from 'shared/utils/handlerErrors';

export const enableTrackOutOfTheBoxEvents = () => {
  try {
    newTracker('sp1', process.env.SNOWPLOW_COLLECTOR_URL || '', {
      appId: process.env.SNOWPLOW_ICLINIC_APP_ID || '',
      platform: 'web',
      discoverRootDomain: true,
      contexts: {
        webPage: true,
      },
      plugins: [
        ConsentPlugin(),
        FormTrackingPlugin(),
        LinkClickTrackingPlugin(),
      ],
    });

    enableGdprContext({
      basisForProcessing: 'consent',
      documentId: 'https://suporte.iclinic.com.br/pt-br/iclinic-termos-de-uso',
      documentVersion: '1.1.0',
      documentDescription:
        'this document describes consent basis for processing',
    });

    enableFormTracking();

    enableActivityTracking({
      minimumVisitLength: 30,
      heartbeatDelay: 10,
    });

    enableLinkClickTracking();

    trackPageView();

    history.listen(() => {
      trackPageView();
    });
  } catch (e) {
    captureException(e);
  }
};

export const enableNewTrackerOnly = () => {
  newTracker('sp1', process.env.SNOWPLOW_COLLECTOR_URL || '', {
    appId: process.env.SNOWPLOW_ICLINIC_APP_ID || '',
    platform: 'web',
    discoverRootDomain: true,
    contexts: {
      webPage: true,
    },
  });
};

export const trackCustomEvent = (
  schema: string,
  eventData: Record<string, unknown> = {},
  context: SelfDescribingJson<Record<string, unknown>>[] = [],
) => {
  try {
    trackSelfDescribingEvent({
      event: {
        schema,
        data: eventData,
      },
      context,
    });
  } catch (e) {
    captureException(e);
  }
};

export const factoryTrackCustomEvent = (schema: string) => {
  const currentSchema = schema;

  return (eventData: Record<string, unknown>) => {
    try {
      trackSelfDescribingEvent({
        event: {
          schema: currentSchema,
          data: eventData,
        },
      });
    } catch (e) {
      captureException(e);
    }
  };
};
