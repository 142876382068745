import { MaterialUICore, axiomTokens } from '@iclinic/design-system';

const { styled } = MaterialUICore;
const { spacing, color, border } = axiomTokens;

export const Actions = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: spacing['4'],
  position: 'fixed',
  left: 0,
  bottom: 0,
  width: '100%',
  backgroundColor: color.background['1'],
  borderTop: `${border.width.xs} solid ${color.border.subtle}`,
  [theme.breakpoints.up('md')]: {
    position: 'relative',
    padding: `${spacing['4']} 0 ${spacing['4']}`,
    borderTop: 'none',
  },
}));
