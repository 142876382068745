import React from 'react';

import { Box, Link, tokens } from '@iclinic/design-system';
import { RecaptchaBody } from './Form.styles';

const Recaptcha = () => {
  return (
    <Box
      mt={tokens.spacing.md}
      mb={tokens.spacing.md}
      mx={1}
      textAlign="center"
    >
      <RecaptchaBody variant="xxs">
        Este site é protegido com reCAPTCHA e a{' '}
        <Link
          variant="caption"
          href="https://policies.google.com/privacy"
          target="_blank"
        >
          Política de Privacidade
        </Link>{' '}
        e{' '}
        <Link
          variant="caption"
          href="https://policies.google.com/terms"
          target="_blank"
        >
          Termos de Serviço
        </Link>{' '}
        do Google se aplicam.
      </RecaptchaBody>
    </Box>
  );
};

export default Recaptcha;
