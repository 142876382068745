import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress } from '@iclinic/design-system';

import * as S from './ScreenDetailsConfirmation.styles';
import CardAppointmentDetails from './CardAppointmentDetails';
import { isFetchingScheduleInfo } from 'features/messaging/state/patients/selectors';
import { fetchScheduleInfo } from 'features/messaging/state/patients';
import AppointmentOptions from './AppointmentOptions';

export function ScreenDetailsConfirmation() {
  const dispatch = useDispatch();
  const isFetching = useSelector(isFetchingScheduleInfo);

  useEffect(() => {
    dispatch(fetchScheduleInfo());
  }, [dispatch]);

  if (isFetching) {
    return (
      <S.LoaderWrapper>
        <CircularProgress data-testid="circular-appointment-page" />
      </S.LoaderWrapper>
    );
  }

  return (
    <S.CardMain>
      <S.CardContainerDetails>
        <CardAppointmentDetails />
        <AppointmentOptions />
      </S.CardContainerDetails>
    </S.CardMain>
  );
}
