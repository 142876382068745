import { MaterialUICore, axiomTokens } from '@iclinic/design-system';

import { Card } from './Dashboard.styles';

const { styled } = MaterialUICore;
const { spacing } = axiomTokens;

export const ChartCard = styled(Card)({
  display: 'flex',
  flexDirection: 'column',
  rowGap: spacing[2],
  padding: `${spacing[2]} ${spacing[4]}`,
});

export const LegendsWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  columnGap: spacing[4],
});
