import { ServiceTerms } from 'state/userInfo/types';
import { apiV2 } from './api';

type InterceptorResponse<ResponseData> = {
  getResponseData: () => ResponseData;
  errors?: ApiErrors[]
};

type AcceptTerms = {
  status: true,
};

export type AcceptTermsResponse = InterceptorResponse<AcceptTerms>;

const acceptTerms = (
  profile: Number,
  payload: ServiceTerms,
) => apiV2.post<AcceptTermsResponse>(`/accounts/userprofile/${profile}/accept-service-term/`, payload);

export default acceptTerms;
