import React from 'react';
import { useFormikContext } from 'formik';
import { Box, MaterialUICore, tokens } from '@iclinic/design-system';
import { useSelector } from 'react-redux';

import {
  AccountFormValues,
  availableProfessionalsCount,
} from '../../constants';
import { SpecialityMenu, SpecialityTextField } from './SpecialityStyles';
import { getEmbed } from '../../state/selectors';

const { useTheme, useMediaQuery } = MaterialUICore;

const NativeOption = (props: any) => <option {...props} />;

export default () => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));
  const isEmbed = useSelector(getEmbed);
  const isNative = !isEmbed && isXs;
  const selectProps = { native: isNative };
  const { handleChange, values } = useFormikContext<AccountFormValues>();

  const OptionComponent = isNative ? NativeOption : SpecialityMenu;

  return (
    <Box mt={tokens.spacing.base}>
      <SpecialityTextField
        label="Quantidade de profissionais de saúde na clínica"
        id="professionals_count"
        name="professionals_count"
        select
        fullWidth
        onChange={handleChange}
        value={values.professionals_count}
        SelectProps={selectProps}
        autoComplete="professionals_count"
        margin="normal"
        data-ga="setup-professionals_count"
      >
        {availableProfessionalsCount.map((option) => (
          <OptionComponent key={option.value} value={option.value}>
            {option.title}
          </OptionComponent>
        ))}
      </SpecialityTextField>
    </Box>
  );
};
