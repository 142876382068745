export type Physician = {
  id: number;
  profile_name: string;
};

export type Schedule = {
  id?: number;
  days_advance: number;
  time_send: string;
};

export enum ApproveStatus {
  PENDING = 'pending_approve_template',
  APPROVED = 'approved_template',
  DISAPPROVED = 'disapproved_template',
  CANCELED = 'canceled_template',
}

export type TemplateMessage = {
  content?: {
    approve_status?: ApproveStatus;
    template?: string;
  };
  contact_phone?: string;
  is_active: boolean;
  schedules_deleted: number[];
  schedules: Schedule[];
};

export enum TemplateKind {
  CONFIRMATION = 'confirmation_event',
  REMINDER = 'event_reminder',
}

export type PreviewMessage = {
  message: string;
};

export type PreviewTemplateData = {
  content: string;
  contact_phone?: string;
};

export type PreviewMessageParams = {
  kind: TemplateKind;
  physicianId: number;
  template: PreviewTemplateData;
};

export type GetBestProductParams = {
  physicianId: number;
  kinds: TemplateKind[];
};

export type BestProduct = {
  average: number;
  best_product: string;
};

export type SubscriptionData = {
  value_per_user: string;
  total_users: number;
  product: string;
  totalValue: string;
  promotionValue?: string;
  text: string;
};

export type PaymentInfo = {
  paymentSlip: boolean;
  card?: {
    number: string;
    owner: string;
    flag: string;
  };
};

export type SubscriptionSummary = {
  currentSubscription: SubscriptionData[];
  newSubscription: SubscriptionData[];
  total: string;
  paymentInfo: PaymentInfo;
};

export type SubscriptionSummaryResponse = {
  data: SubscriptionSummary;
};

export type SubscribeSummaryData = {
  products: {
    [packageSlug: string]: number;
  };
  typeHiring: string;
};

export type GetSubscriptionSummaryArgs = {
  subscriptionId: string;
  subscribeSummaryData: SubscribeSummaryData;
};

export type SubscribeData = {
  [productSlug: string]: {
    [physicianId: string]: TemplateKind[];
  };
};

export type ClinicInfo = {
  id: number;
  name: string;
  address?: string;
  number?: string;
  complement?: string;
  neighborhood?: string;
  city?: string;
  state?: string;
  zip_code?: string;
  phone?: string;
};

export type PhysicianInfo = {
  id: number;
  name: string;
  cbo?: string;
  council_name?: string;
  council_number?: string;
  council_region?: string;
  picture_url?: string;
  treatment?: string;
};

export type ScheduleInfoData = {
  event_date: string;
  event_time: string;
  clinic: ClinicInfo;
  physician: PhysicianInfo;
};

export enum ScheduleStatus {
  CONFIRMATION = 'om',
  CANCEL = 'cm',
}

export type PaymentSummaryItem = {
  product: string;
  profile_name: string;
  value: string;
  franchise: number;
  unit_price: string;
  total_unit: number;
  value_unit: number;
};

export type PaymentSummary = {
  amount: string;
  summary: PaymentSummaryItem[];
};

export type ConsumptionPackage = {
  total_sent: number;
  physicians?: {
    profile_name: string;
    total_sent: number;
  }[];
  kinds: {
    kind: TemplateKind;
    total: number;
  }[];
  first_consumption_date: string;
  total_franchise: number;
};

export type ConsumptionExceeded = {
  total_exceeded: number;
  kinds: {
    kind: TemplateKind;
    total: number;
  }[];
  first_consumption_date_exceeded?: string;
};

export type Consumptions = {
  consumption_package: ConsumptionPackage;
  consumption_exceeded?: ConsumptionExceeded;
};

export type GetPhysiciansArgs = {
  returnAll?: boolean;
  byTemplateKind?: TemplateKind;
};

export type PermissionsMenu = {
  confirmation_event: {
    customization: boolean;
    history: boolean;
  };
  event_reminder: {
    customization: boolean;
    history: boolean;
  };
  message_package?: boolean;
};

export type Feature = {
  id: number;
  state: boolean;
};

export type FeaturesResponse = {
  objects: Feature[];
};

export enum MessageStatus {
  SENT = 0,
  ERROR = 1,
  NUMBER_INVALID = 2,
  CONFIRMED = 4,
  CANCELED = 5,
}

export enum DateFilterOptions {
  LAST_WEEK = 'week',
  LAST_15_DAYS = '15days',
  THIS_MONTH = 'current_month',
  LAST_90_DAYS = '90days',
}

export type PaginationParams = {
  page: number;
  limit?: number;
};

export type MessageHistoryArgs = {
  kind: TemplateKind;
  physicianId?: number;
  status?: MessageStatus;
  date?: DateFilterOptions;
} & PaginationParams;

export type MessageHistoryRecord = {
  date_sent: string;
  patient_name: string;
  procedures: string[];
  status: MessageStatus;
};

export type Meta = {
  limit: number;
  page: number;
  total_count: number;
};

export type MessageHistoryResponse = {
  meta: Meta;
  objects: MessageHistoryRecord[];
};

export type ContractorTemplate = {
  physician_id: number;
  profile_name: string;
  product: string;
  franchise: number;
  confirmation_event: boolean;
  event_reminder: boolean;
};

export type ContractorsResponse = {
  meta: Meta;
  objects: ContractorTemplate[];
};

export type EventStatus = {
  physicianId: number;
  confirmation_event: boolean;
  event_reminder: boolean;
};

export type ReadAlertArgs = {
  kind: TemplateKind;
  physicianId: number;
};
