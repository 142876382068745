import React from 'react';
import { useSelector } from 'react-redux';

import * as S from './ProgressBar.styles';
import { progressSelector } from '../../state/selectors';
import { SignupSteps } from '../../state/types';

const ProgressBar = () => {
  const step = useSelector(progressSelector);
  return (
    <S.Root>
      <S.ProgressBarContainer visible={step !== SignupSteps.Email}>
        <S.Bar step={step} />
      </S.ProgressBarContainer>
    </S.Root>
  );
};

export default ProgressBar;
