import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, FormikProvider, useFormik } from 'formik';
import { Button } from '@iclinic/design-system';

import {
  getEmbed,
  listCBOState,
  signupFormValuesSelector,
  statusSelector,
} from '../../state/selectors';
import { singleSignupFormValidation } from '../../validations';
import EmailInput from './EmailInput';
import NameInput from './NameInput';
import PhoneInput from './PhoneInput';
import ProfessionFields from './ProfessionFields';
import PasswordInput from './PasswordInput';
import Terms from './Terms';
import { triggerSubmitTrial } from '../../state';
import { SignupFormValues } from '../../types';
import { PossibleSignupRoles } from '../../constants';
import { RequestStatus } from 'shared/constants/State';
import Recaptcha from './Recaptcha';
import { EmbedForm } from './EmbedForm';

const SingleSignupForm = () => {
  const formValues = useSelector(signupFormValuesSelector);
  const status = useSelector(statusSelector);
  const cbosList = useSelector(listCBOState);
  const dispatch = useDispatch();
  const isEmbed = useSelector(getEmbed);

  const handleSubmit = (values: SignupFormValues) => {
    dispatch(triggerSubmitTrial({ ...values, cbosList }));
  };

  const formik = useFormik({
    initialValues: formValues,
    onSubmit: handleSubmit,
    validationSchema: singleSignupFormValidation({ isTermsRequired: !isEmbed }),
  });

  const isDisabled =
    status === RequestStatus.Pending ||
    formik.values.profession === PossibleSignupRoles.Patient;

  if (isEmbed) {
    return (
      <FormikProvider value={formik}>
        <EmbedForm status={status} isDisabled={isDisabled} />
      </FormikProvider>
    );
  }

  return (
    <FormikProvider value={formik}>
      <Form>
        <EmailInput />
        <NameInput />
        <PhoneInput />
        <ProfessionFields />
        <PasswordInput />
        <Terms />
        <Button
          color="primary"
          size="large"
          type="submit"
          fullWidth
          variant="contained"
          data-ga="setup-avancar_1_etapa"
          isLoading={status === RequestStatus.Pending}
          disabled={isDisabled}
        >
          Entrar
        </Button>
        <Recaptcha />
      </Form>
    </FormikProvider>
  );
};

export default SingleSignupForm;
