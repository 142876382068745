import { MaterialUICore, axiomTokens } from '@iclinic/design-system';

const { styled } = MaterialUICore;
const { spacing } = axiomTokens;

export const TitleAndSubtitleWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  rowGap: spacing[1],
});
