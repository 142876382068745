import React from 'react';
import { Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import {
  MaterialUICore,
  Box,
  Typography,
  Link,
  Button,
} from '@iclinic/design-system';

import useStyles from './LoginFormV2.styles';
import LoginFields from './LoginFields';
import SocialLogin from './SocialLogin';
import NotificationErrors from './NotificationErrors';
import validationSchema, {
  initialValues,
  InitialValues,
} from '../validationSchema';
import { loginWithEmailSubmit, clearErrors } from '../state/actions';
import hlink from 'shared/utils/linkHandler';
import { StoreState } from 'state/rootReducer';

const { CircularProgress } = MaterialUICore;

const recoverPasswordPath = '/new/usuarios/recuperar-senha/';
const signupPath = '/new/cadastro/';

const LoginFormV2 = () => {
  const dispatch = useDispatch();
  const errorsAuth = useSelector((state: StoreState) => state.authv2.errors);

  const classes = useStyles();

  const handleSubmit = (values: InitialValues) => {
    dispatch(clearErrors());
    dispatch(loginWithEmailSubmit(values));
  };

  const hasErrors = errorsAuth.length > 0;
  const errorHandler = hasErrors && <NotificationErrors errors={errorsAuth} />;

  const isNotWebview = !window.ReactNativeWebView;

  return (
    <div data-testid="login-form-v2">
      {errorHandler}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <LoginFields />
            <Button
              color="primary"
              fullWidth
              size="large"
              type="submit"
              disabled={isSubmitting && !hasErrors}
              variant="contained"
            >
              {isSubmitting && !hasErrors ? (
                <CircularProgress size={14} />
              ) : (
                'Entrar'
              )}
            </Button>
            <Box mt={3} mb={4} textAlign="center">
              <Button
                href={recoverPasswordPath}
                color="transparent"
                onClick={hlink(recoverPasswordPath)}
              >
                Esqueci minha senha
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
      {isNotWebview && <SocialLogin />}
      <Box mt={3} py={2} textAlign="center" className={classes.footer}>
        <Typography variant="body2">
          Você ainda não tem uma conta iClinic?
        </Typography>
        <Typography variant="body2">
          <Link
            href={signupPath}
            variant="body2"
            bold
            onClick={hlink(signupPath)}
          >
            Teste o iClinic gratuitamente
          </Link>
        </Typography>
      </Box>
    </div>
  );
};

export default LoginFormV2;
