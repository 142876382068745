import { ApiErrors } from '@types';
import * as types from './types';

export const trackEvent = (
  payload: types.TracksPayload,
  trackFn: types.TrackFunction,
) =>
  ({
    type: types.TRACK_EVENT,
    payload,
    trackFn,
  } as const);

export const downloadUrlOpenIntent = (payload: types.DownloadIntentPayload) =>
  ({
    type: types.DOWNLOAD_URL_OPEN_INTENT,
    payload,
  } as const);

export const openExternalUrlIntent = (
  payload: types.OpenExternalUrlIntentPayload,
) =>
  ({
    type: types.OPEN_EXTERNAL_URL_INTENT,
    payload,
  } as const);

export const setUpdatePermissionsUrl = (url: string) =>
  ({
    type: types.SET_UPDATE_PERMISSIONS_URL,
    payload: {
      updatePermissionsUrl: url,
    },
  } as const);

export const clearPdfList = () =>
  ({
    type: types.CLEAR_PDF_LIST,
  } as const);

export const setLoadingPDF = () =>
  ({
    type: types.SET_LOADING_PDF,
  } as const);

export const generatePdfSuccess = (s3Pdf: types.PDFResponse[]) =>
  ({
    type: types.GENERATE_PDF_SUCCESS,
    payload: {
      isLoading: false,
      s3Pdf,
    },
  } as const);

export const generatePdfFailure = (errors: ApiErrors[]) =>
  ({
    type: types.GENERATE_PDF_FAILURE,
    payload: {
      isLoading: false,
      errors,
    },
  } as const);

export const sendEmailSignature = (
  email: string,
  attachments: types.Attachments[],
) =>
  ({
    type: types.SEND_EMAIL_SIGNATURE,
    payload: {
      isSending: true,
      email,
      attachments,
    },
  } as const);

export const sendEmailSuccess = (isEmailValid: boolean) =>
  ({
    type: types.SEND_EMAIL_SUCCESS,
    payload: {
      isSending: false,
      isEmailValid,
    },
  } as const);

export const sendEmailFailure = (errors: ApiErrors[]) =>
  ({
    type: types.SEND_EMAIL_FAILURE,
    payload: {
      isSending: false,
      errors,
    },
  } as const);

export const clearSharedErrors = () =>
  ({
    type: types.CLEAR_SHARED_ERRORS,
  } as const);
