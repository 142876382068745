import React from 'react';
import { Body, MaterialIcons } from '@iclinic/design-system';

import * as S from './SignupBanners.styles';

const { PersonPinRounded, CalendarToday, MonetizationOn, EditRounded } =
  MaterialIcons;

export function BenefitsList() {
  return (
    <S.CardWrapper>
      <S.BannerList>
        <ul>
          <S.ListItem>
            <PersonPinRounded fontSize="inherit" />
            <Body inverseTextColor variant="xxs">
              Prontuário Eletrônico personalizável
            </Body>
          </S.ListItem>
          <S.ListItem>
            <CalendarToday fontSize="inherit" />
            <Body inverseTextColor variant="xxs">
              Agenda médica e agendamento online
            </Body>
          </S.ListItem>
          <S.ListItem>
            <MonetizationOn fontSize="inherit" />
            <Body inverseTextColor variant="xxs">
              Gestão financeira
            </Body>
          </S.ListItem>
          <S.ListItem>
            <EditRounded fontSize="inherit" />
            <Body inverseTextColor variant="xxs">
              Prescrição digital, e muito mais!
            </Body>
          </S.ListItem>
        </ul>
      </S.BannerList>
    </S.CardWrapper>
  );
}
