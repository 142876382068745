const { ASSETS_PATH } = process.env;

const images = {
  videoConference: {
    iclinicAttendant: `${ASSETS_PATH as string}images/video-conference/iclinic_attendant.png`,
    waitingParticipant: `${ASSETS_PATH as string}images/video-conference/waiting_participant.png`,
    tokenProblem: `${ASSETS_PATH as string}images/video-conference/token_problem.png`,
    devices: `${ASSETS_PATH as string}images/video-conference/devices.png`,
    phone: `${ASSETS_PATH as string}images/video-conference/phone.png`,
    powerPlug: `${ASSETS_PATH as string}images/video-conference/power_plug.png`,
    reconnectingParticipant: `${ASSETS_PATH as string}images/video-conference/reconnecting_participant.png`,
    cameraBlockedIcon: `${ASSETS_PATH as string}images/video-conference/camera_block_icon.png`,
    browserSuggestion: `${ASSETS_PATH as string}images/video-conference/browser_suggestion.png`,
    abstractBG: `${ASSETS_PATH as string}images/video-conference/background-images/Abstract.jpg`,
    bohoHomeBG: `${ASSETS_PATH as string}images/video-conference/background-images/BohoHome.jpg`,
    bookshelfBG: `${ASSETS_PATH as string}images/video-conference/background-images/Bookshelf.jpg`,
    coffeeShopBG: `${ASSETS_PATH as string}images/video-conference/background-images/CoffeeShop.jpg`,
    contemporaryBG: `${ASSETS_PATH as string}images/video-conference/background-images/Contemporary.jpg`,
    cozyHomeBG: `${ASSETS_PATH as string}images/video-conference/background-images/CozyHome.jpg`,
    desertBG: `${ASSETS_PATH as string}images/video-conference/background-images/Desert.jpg`,
    fishingBG: `${ASSETS_PATH as string}images/video-conference/background-images/Fishing.jpg`,
    flowerBG: `${ASSETS_PATH as string}images/video-conference/background-images/Flower.jpg`,
    kitchenBG: `${ASSETS_PATH as string}images/video-conference/background-images/Kitchen.jpg`,
    modernHomeBG: `${ASSETS_PATH as string}images/video-conference/background-images/ModernHome.jpg`,
    natureBG: `${ASSETS_PATH as string}images/video-conference/background-images/Nature.jpg`,
    oceanBG: `${ASSETS_PATH as string}images/video-conference/background-images/Ocean.jpg`,
    patioBG: `${ASSETS_PATH as string}images/video-conference/background-images/Patio.jpg`,
    plantBG: `${ASSETS_PATH as string}images/video-conference/background-images/Plant.jpg`,
    sanFranciscoBG: `${ASSETS_PATH as string}images/video-conference/background-images/SanFrancisco.jpg`,
    abstractThumb: `${ASSETS_PATH as string}images/video-conference/background-images/thumb/Abstract.jpg`,
    bohoHomeThumb: `${ASSETS_PATH as string}images/video-conference/background-images/thumb/BohoHome.jpg`,
    bookshelfThumb: `${ASSETS_PATH as string}images/video-conference/background-images/thumb/Bookshelf.jpg`,
    coffeeShopThumb: `${ASSETS_PATH as string}images/video-conference/background-images/thumb/CoffeeShop.jpg`,
    contemporaryThumb: `${ASSETS_PATH as string}images/video-conference/background-images/thumb/Contemporary.jpg`,
    cozyHomeThumb: `${ASSETS_PATH as string}images/video-conference/background-images/thumb/CozyHome.jpg`,
    desertThumb: `${ASSETS_PATH as string}images/video-conference/background-images/thumb/Desert.jpg`,
    fishingThumb: `${ASSETS_PATH as string}images/video-conference/background-images/thumb/Fishing.jpg`,
    flowerThumb: `${ASSETS_PATH as string}images/video-conference/background-images/thumb/Flower.jpg`,
    kitchenThumb: `${ASSETS_PATH as string}images/video-conference/background-images/thumb/Kitchen.jpg`,
    modernHomeThumb: `${ASSETS_PATH as string}images/video-conference/background-images/thumb/ModernHome.jpg`,
    natureThumb: `${ASSETS_PATH as string}images/video-conference/background-images/thumb/Nature.jpg`,
    oceanThumb: `${ASSETS_PATH as string}images/video-conference/background-images/thumb/Ocean.jpg`,
    patioThumb: `${ASSETS_PATH as string}images/video-conference/background-images/thumb/Patio.jpg`,
    plantThumb: `${ASSETS_PATH as string}images/video-conference/background-images/thumb/Plant.jpg`,
    sanFranciscoThumb: `${ASSETS_PATH as string}images/video-conference/background-images/thumb/SanFrancisco.jpg`,
    virtualBackground: `${ASSETS_PATH as string}images/video-conference/background-images/virtual-background`,
  },
};

export default images;
