import { Body, Heading, Loader } from '@iclinic/design-system';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FiltersSurvey } from 'features/survey/components/FiltersSurvey';
import { TablePagination } from 'features/survey/components/TablePagination';
import { TableSurvey } from 'features/survey/components/TableSurvey';
import { getSelectedPhysicianId } from 'features/survey/state/dashboard/selectors';
import {
  fetchSurveyEvents,
  fetchSurveyPhysicians,
} from 'features/survey/state/filters';
import {
  getCountSurveyEvents,
  getEventsSurveyStatus,
} from 'features/survey/state/filters/selectors';
import { RequestStatus } from 'shared/constants/State';
import { FilterData } from '../../services/types';
import * as S from './SendSurvey.styles';

export default function SendSurvey() {
  const dispatch = useDispatch();
  const count = useSelector(getCountSurveyEvents);
  const surveyStatus = useSelector(getEventsSurveyStatus);
  const selectedPhysicianId = useSelector(getSelectedPhysicianId);

  const [filter, setFilter] = useState<FilterData>({
    page: 1,
    physician: selectedPhysicianId || '',
  });

  const onChangeFilter = (name: string, value: string | number) => {
    setFilter((previousState) => ({
      ...previousState,
      [name]: value,
    }));
  };

  useEffect(() => {
    dispatch(fetchSurveyEvents(filter));
  }, [dispatch, filter]);

  useEffect(() => {
    dispatch(fetchSurveyPhysicians());
  }, [dispatch]);

  return (
    <S.Container>
      <S.HeaderWrapper>
        <Heading variant="md">Envio da pesquisa de satisfação</Heading>
        <Body variant="xxs">
          No término do atendimento, a pesquisa de satisfação estará disponível
          para ser enviada ao <br />
          paciente. Para o envio, é necessário que o
          <strong> WhatsApp Web esteja aberto</strong> em seu computador.
        </Body>
      </S.HeaderWrapper>

      <S.Content>
        <div>
          <Body variant="sm" bold>
            Histórico de envio
          </Body>

          <Body variant="xs">
            Acompanhe o status de todos ass pesquisas de satisfação que foram
            enviadas para os seus pacientes.
          </Body>
        </div>

        <FiltersSurvey onChange={onChangeFilter} />

        <S.TableWrapper>
          {surveyStatus === RequestStatus.Pending ? (
            <S.LoaderWrapper>
              <Loader size={60} />
            </S.LoaderWrapper>
          ) : (
            <TableSurvey />
          )}

          <TablePagination
            pageCount={Math.ceil((count || 0) / 10)}
            onChange={onChangeFilter}
            pageNumber={filter.page}
          />
        </S.TableWrapper>
      </S.Content>
    </S.Container>
  );
}
