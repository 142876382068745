import { MaterialUICore } from '@iclinic/design-system';

const { makeStyles } = MaterialUICore;

const useStyles = makeStyles((theme: MaterialUICore.Theme) => ({
  containerImage: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
      height: '100vh',
      position: 'fixed',
      right: 0,
      top: 0,
      width: '100%',
      zIndex: 1,
    },
  },
  link: {
    display: 'block',
    height: '100%',
    left: 0,
    position: 'absolute',
    top: 0,
    width: '100%',
    zIndex: 1,
    textIndent: '-9999px',
  },
  image: {
    display: 'block',
    height: '100vh',
    left: 0,
    objectFit: 'cover',
    position: 'absolute',
    top: 0,
    width: '100%',
  },
  loader: {
    left: '50%',
    top: '50%',
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    zIndex: 5,
  },
}));

export default useStyles;
