import React, { useEffect, useState } from 'react';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Heading,
  MaterialIcons,
  MaterialUICore,
  Select,
  Switch,
  TextField,
} from '@iclinic/design-system';
import { useDispatch, useSelector } from 'react-redux';
import { Form, FormikProvider, useFormik } from 'formik';
import { useRifm } from 'rifm';

import { MessageContent } from '../../../components';
import {
  confirmationDaysAdvanceOptions,
  timeSendOptions,
  tags,
  MessageByApproveStatusData,
  approveMessagesByStatus,
  defaultMessage,
} from '../../../constants';
import {
  fetchConfirmationTemplateMessage,
  submitConfirmationTemplateMessage,
} from '../../../state/scheduleConfirmation/personalization';
import {
  getCommonData,
  getPartialUserInfo,
} from '../../../state/common/selectors';
import { getScheduleConfirmationData } from '../../../state/scheduleConfirmation/personalization/selectors';
import { updateReadAlert } from '../../../state/common';
import { TemplateKind } from '../../../services/types';
import {
  FormValues,
  factoryTemplateMessageInitialValues,
} from './form.normalizer';
import { phoneFormatter } from 'shared/utils/formatters';
import SnackbarMessages from './SnackbarMessages';
import { validationSchema } from './validation.schema';
import * as S from './PersonalizationTabPanel.styles';
import { PreviewMessageModal } from './PreviewMessageModal';

const { WhatsApp } = MaterialIcons;
const { MenuItem } = MaterialUICore;

const PersonalizationTabPanel = () => {
  const dispatch = useDispatch();
  const [addPhone, setAddPhone] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [initialTemplate, setInitialTemplate] = useState(defaultMessage);
  const [message, setMessage] = useState<MessageByApproveStatusData | null>(
    null,
  );
  const { isFetchingTemplate, templateMessage, isSubmittingTemplate } =
    useSelector(getScheduleConfirmationData);
  const { physicians } = useSelector(getCommonData);
  const { physicianId, isAdmin } = useSelector(getPartialUserInfo);

  const onSubmit = (formValues: FormValues) => {
    if (!formValues.physicianId) return;

    dispatch(
      submitConfirmationTemplateMessage({
        physicianId: formValues.physicianId,
        singleScheduleTemplateMessage: formValues,
      }),
    );
  };

  const formik = useFormik<FormValues>({
    initialValues: factoryTemplateMessageInitialValues(),
    onSubmit,
    validationSchema,
  });

  const { values, handleChange, setFieldValue, errors, touched } = formik;

  const onTemplateMessageChangeHandler = (newValue: string) => {
    setFieldValue('content', newValue);
  };

  const onClickAddPhoneHandler = () => {
    setAddPhone(true);
  };

  const { value: phoneValue, onChange: onChangePhone } = useRifm({
    value: values.contactPhone as string,
    onChange: (value) => setFieldValue('contactPhone', value),
    format: phoneFormatter,
  });

  const togglePreview = () => {
    setPreviewOpen((prev) => !prev);
  };

  const onCloseAlert = () => {
    setMessage(null);

    if (values.physicianId) {
      dispatch(
        updateReadAlert({
          kind: TemplateKind.CONFIRMATION,
          physicianId: values.physicianId,
        }),
      );
    }
  };

  useEffect(() => {
    if (values.physicianId)
      dispatch(fetchConfirmationTemplateMessage(values.physicianId));
  }, [dispatch, values.physicianId]);

  useEffect(() => {
    if (templateMessage) {
      const [schedule] = templateMessage.schedules;

      setFieldValue('daysAdvance', schedule.days_advance);
      setFieldValue('isActive', templateMessage.is_active);

      if (templateMessage.content?.template) {
        setFieldValue('content', templateMessage.content.template);
        setInitialTemplate(templateMessage.content.template);
      }

      const messageByStatus = templateMessage.content?.approve_status
        ? approveMessagesByStatus[templateMessage.content.approve_status]
        : null;
      setMessage(messageByStatus);

      if (schedule) {
        setFieldValue('timeSend', schedule.time_send);
        setFieldValue('existTemplateId', schedule.id);
      }

      if (templateMessage.contact_phone) {
        setFieldValue(
          'contactPhone',
          phoneFormatter(templateMessage.contact_phone),
        );
        setAddPhone(true);
      }
    }
  }, [setFieldValue, templateMessage]);

  useEffect(() => {
    if (physicians?.length) {
      const [physician] = physicians;
      setFieldValue('physicianId', physician.id);
    } else if (physicianId) {
      setFieldValue('physicianId', physicianId);
    }
  }, [physicianId, physicians, setFieldValue]);

  if (isFetchingTemplate) {
    return (
      <S.Card>
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      </S.Card>
    );
  }

  return (
    <>
      <S.PanelWrapper>
        {message && (
          <Alert
            message={message.message}
            severity={message.isError ? 'error' : 'info'}
            icon={message.icon}
            action={
              message.isError ? (
                <Button variant="text" size="large" onClick={onCloseAlert}>
                  Fechar
                </Button>
              ) : undefined
            }
          />
        )}

        <FormikProvider value={formik}>
          <Form>
            <S.Card>
              {!isAdmin && physicians?.length ? (
                <S.SelectPhysician
                  name="physicianId"
                  label="Profissionais de saúde"
                  value={values.physicianId}
                  onChange={handleChange}
                >
                  {physicians.map(({ id, profile_name }) => (
                    <MenuItem key={id.toString()} value={id}>
                      {profile_name}
                    </MenuItem>
                  ))}
                </S.SelectPhysician>
              ) : null}

              <S.MessageContentWrapper>
                <S.TitleAndSubtitleWrapper>
                  <Heading variant="sm">Conteúdo da mensagem</Heading>

                  <S.Subtitle variant="xs">
                    Utilize as <em>tags</em> automáticas para personalizar o
                    conteúdo da mensagem como: nome do paciente, data da agenda,
                    procedimento.
                    <br />
                    As <em>tags</em> serão substituídas no momento do envio da
                    mensagem pelos dados reais da agenda do paciente.
                  </S.Subtitle>
                </S.TitleAndSubtitleWrapper>

                <MessageContent
                  key={initialTemplate}
                  tags={tags}
                  initialTemplate={initialTemplate}
                  currentTemplate={values.content}
                  onChange={onTemplateMessageChangeHandler}
                  onPreviewClick={togglePreview}
                />
              </S.MessageContentWrapper>
              <S.PhoneContentWrapper>
                <S.TitleAndSubtitleWrapper>
                  <Heading variant="sm">Telefone para contato</Heading>

                  <S.Subtitle variant="xs">
                    Insira o número que o paciente deve utilizar para conversar
                    com a clínica no Whatsapp, em casos de dúvidas ou
                    reagendamento.
                  </S.Subtitle>
                </S.TitleAndSubtitleWrapper>

                {addPhone ? (
                  <TextField
                    id="contactPhone"
                    name="contactPhone"
                    label="Whatsapp da clínica"
                    value={phoneValue}
                    onChange={onChangePhone}
                    error={!!(errors.contactPhone && touched.contactPhone)}
                    helperText={touched.contactPhone && errors.contactPhone}
                    InputProps={{
                      endAdornment: <WhatsApp />,
                    }}
                  />
                ) : (
                  <Button
                    size="large"
                    variant="outlined"
                    onClick={onClickAddPhoneHandler}
                  >
                    Adicionar Whatsapp da clínica
                  </Button>
                )}
              </S.PhoneContentWrapper>
              <S.ConfirmTriggersWrapper>
                <S.TitleAndSubtitleWrapper>
                  <Heading variant="sm">Configurações de envio</Heading>

                  <S.Subtitle variant="xs">
                    Defina os critérios de antecedência de envios automáticos
                    das mensagens de confirmação de agenda. Para agendamentos às
                    segundas-feiras, as mensagens serão enviadas sempre na
                    sexta-feira anterior.
                  </S.Subtitle>
                </S.TitleAndSubtitleWrapper>

                <S.FieldsWrapper>
                  <Select
                    name="daysAdvance"
                    label="Antecedência do envio"
                    value={values.daysAdvance}
                    onChange={handleChange}
                  >
                    {confirmationDaysAdvanceOptions.map(({ text, value }) => (
                      <MenuItem key={text} value={value}>
                        {text}
                      </MenuItem>
                    ))}
                  </Select>

                  <Select
                    name="timeSend"
                    label="Hora do envio"
                    value={values.timeSend}
                    onChange={handleChange}
                  >
                    {timeSendOptions.map(({ text, value }) => (
                      <MenuItem key={value} value={value}>
                        {text}
                      </MenuItem>
                    ))}
                  </Select>
                </S.FieldsWrapper>

                <S.Subtitle variant="xs">
                  <em>
                    *Confirmações de agendamentos que aconteceriam aos finais de
                    semana e feriados, serão enviadas sempre no dia útil
                    anterior.
                  </em>
                </S.Subtitle>

                <div>
                  <Switch
                    id="isActive"
                    name="isActive"
                    defaultChecked
                    checked={values.isActive}
                    onChange={handleChange}
                    label="Envio automático com base na antecedência programada."
                  />

                  <S.Subtitle variant="xs">
                    <em>
                      *Desabilitando o envio automático as comunicações não
                      serão enviadas e o seu pacote não será consumido.
                    </em>
                  </S.Subtitle>
                </div>

                <S.ButtonsWrapper>
                  <Button disabled={isSubmittingTemplate} color="transparent">
                    Cancelar
                  </Button>

                  <Button isLoading={isSubmittingTemplate} type="submit">
                    Salvar
                  </Button>
                </S.ButtonsWrapper>
              </S.ConfirmTriggersWrapper>
            </S.Card>
          </Form>
        </FormikProvider>
      </S.PanelWrapper>

      <SnackbarMessages />
      <PreviewMessageModal
        physicianId={values.physicianId}
        open={previewOpen}
        onClose={togglePreview}
        content={values.content}
        contactPhone={values.contactPhone}
      />
    </>
  );
};

export { PersonalizationTabPanel };
