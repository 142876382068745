import React from 'react';

import { Typography } from '@iclinic/design-system';
import classes from './NotFound.scss';

function Redirect() {
  // todo: create a nice 404 page.
  window.location.href = 'https://app.iclinic.com.br/404';
  return (
    <div className={classes.container}>
      <Typography variant="h2">Redirecionando..</Typography>
    </div>
  );
}

export default Redirect;
