import {
  axiomTokens,
  MaterialUICore,
  Button as DSButton,
} from '@iclinic/design-system';

const { styled, TableRow: DSTableRow, TableCell } = MaterialUICore;

const { color, spacing, border } = axiomTokens;

export const ContentTable = styled('div')({
  marginTop: spacing['6'],
  display: 'flex',
});

export const TableRow = styled(DSTableRow)({
  '&:nth-child(odd)': {
    backgroundColor: color.background['1'],
  },

  '& .MuiTableCell-root': {
    borderBottom: `${border.width.xs} solid ${color.border.subtle}`,
  },
});

export const SmallTableCell = styled(TableCell)({
  maxWidth: 192,
});

export const Button = styled(DSButton)({
  textDecoration: 'underline',
});
