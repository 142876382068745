import { Heading, MaterialUICore, axiomTokens } from '@iclinic/design-system';

const { styled } = MaterialUICore;
const { spacing } = axiomTokens;

export const Root = styled('div')({
  display: 'flex',
  gap: spacing[3],
  alignItems: 'center',
});

export const StepWrapper = styled('div')({
  display: 'flex',
  position: 'relative',
});

export const StepCountText = styled(Heading)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
});

export const Wrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing[1],
});
