import { apiEmkt, apiV2, apiEmktPublic } from './api';

const defaultAudiences = [{ filter: 'all' }];
const defaultMail = {
  body: '',
  draftJSState: {
    blocks: [],
    entityMap: {},
  },
  subject: '',
};

export const decodeError = ({ timestamp, ...rest }) => ({
  timestamp: new Date(timestamp),
  ...rest,
});

const decodeSender = ({ email = '', name = '' }) => ({
  email,
  name,
});

const decodeMail = ({
  sender = {},
  body = defaultMail.body,
  draftJSState = defaultMail.draftJSState,
  subject = defaultMail.subject,
}) => ({
  sender: decodeSender(sender),
  body,
  draftJSState,
  subject,
});

export const decodeCampaign = ({
  createdAt,
  sentAt,
  updatedAt,
  schedule,
  errors,
  mail = {},
  ...rest
}) => {
  const campaign = {
    createdAt: new Date(createdAt),
    sentAt: (sentAt && new Date(sentAt)) || null,
    updatedAt: (updatedAt && new Date(updatedAt)) || null,
    schedule: (schedule && new Date(schedule)) || null,
    mail: decodeMail(mail),
    ...rest,
  };
  if (errors) {
    campaign.errors = errors.map(decodeError);
  }
  return campaign;
};

export const fetchListCampaigns = () => apiEmkt.get('/marketing/campaigns/');

export const fetchCreateCampaign = ({
  title,
  status = 'draft',
  schedule = null,
  audiences = defaultAudiences,
  mail = defaultMail,
}) =>
  apiEmkt.post('/marketing/campaigns/', {
    title,
    status,
    schedule,
    audiences,
    mail,
  });

export const decodeUserProfile = ({ id, email, name, kind }) => ({
  id,
  email,
  name,
  kind,
});

export const fetchUnsubscribe = (unsubscribe) =>
  apiEmktPublic.put('/marketing/unsubscription', unsubscribe);

export const decodeProcedure = ({ id, name, physician }) => ({
  id,
  name: `${name} (${physician.name})`,
});

export const fetchCampaign = (campaignId) =>
  apiEmkt.get(`/marketing/campaigns/${campaignId}/`);

export const fetchUpdateCampaign = (campaign) =>
  apiEmkt.patch(`/marketing/campaigns/${campaign.id}/`, campaign);

export const fetchDeleteCampaign = (campaignId) =>
  apiEmkt.delete(`/marketing/campaigns/${campaignId}/`);

export const fetchSendTestEmail = ({ campaignId, physicianId, recipients }) =>
  apiEmkt.post(`/marketing/campaigns/${campaignId}/send-test`, {
    params: {
      physicianId,
    },
    recipients,
  });

export const fetchSendTestEmailFlow = ({ params, recipient }) =>
  apiEmkt.post('/marketing/emailFlows/send-test', { params, recipient });

// api
export const fetchProcedures = (page = '/accounts/physicianprocedure/') =>
  apiV2.get(page);
export const fetchPatientTags = (clinicId) =>
  apiV2.get(`/clinics/clinic/tags/${clinicId}/`);
export const fetchUserProfiles = (page = '/accounts/userprofile/') =>
  apiV2.get(page);
export const fetchPatientCount = (clinicId, audiences) =>
  apiV2.post(`/patients/patient/count/${clinicId}/`, { audiences });

export const fetchListEmailFlows = ({
  physician_id: physicianId,
  name,
  email,
}) =>
  apiEmkt.get('/marketing/emailFlows/', {
    params: {
      physicianId,
      name,
      email,
    },
  });
export const fetchUpdateEmailFlow = ({ id, physicianId, ...updated }) =>
  apiEmkt.patch(`/marketing/emailFlows/${id}/`, updated, {
    params: {
      physicianId,
    },
  });

export const fetchEmailFlow = ({ id, physicianId }) =>
  apiEmkt.get(`/marketing/emailFlows/${id}/`, {
    params: {
      physicianId,
    },
  });

export const fetchCreateEmailFlow = ({ physicianId, ...inserted }) =>
  apiEmkt.post('/marketing/emailFlows/', inserted, {
    params: {
      physicianId,
    },
  });

export const fetchDeleteEmailFlow = (physicianId, emailFlowId) =>
  apiEmkt.delete(`/marketing/emailFlows/${emailFlowId}/`, {
    params: {
      physicianId,
    },
  });
export const fetchDeleteEmailFlowComponent = ({ physicianId, componentId }) =>
  apiEmkt.delete(`/marketing/emailFlow/components/${componentId}/`, {
    params: {
      physicianId,
    },
  });

export const fetchUpdateEmailFlowComponent = ({
  id,
  physicianId,
  ...updated
}) =>
  apiEmkt.patch(`/marketing/emailFlow/components/${id}/`, updated, {
    params: {
      physicianId,
    },
  });

export const fetchCreateEmailFlowComponent = ({
  emailFlowId,
  physicianId,
  ...created
}) =>
  apiEmkt.post(`/marketing/emailFlow/${emailFlowId}/components`, created, {
    params: {
      physicianId,
    },
  });
