import { MaterialUICore, axiomTokens } from '@iclinic/design-system';

const { styled, TableRow: DSTableRow, TableCell } = MaterialUICore;

const { color, border } = axiomTokens;

export const ContentTable = styled('div')({
  display: 'flex',
  justifyContent: 'center',
});

export const TableRow = styled(DSTableRow)({
  '&.header-row.MuiTableRow-head': {
    backgroundColor: color.background['2'],
  },

  '&:nth-child(even)': {
    backgroundColor: color.background['2'],
  },

  '& .MuiTableCell-root': {
    borderBottom: `${border.width.xs} solid ${color.border.subtle}`,
  },
});

export const SmallTableCell = styled(TableCell)({
  maxWidth: 192,
});
