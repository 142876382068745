import { PDFPayload } from 'state/shared/types';
import qs from 'qs';
import { apiV2, apiPdfServerless } from './api';

export const generatorPdf = ({ url, queryParams }: PDFPayload) => {
  const queryStringParams = qs.stringify(queryParams, { allowDots: true });
  if (Object.keys(queryParams).length) {
    return apiV2.get(`${url}?${queryStringParams}`);
  }
  return apiV2.get(url);
};

export const getRetrieveUrlPDF = (processId: string) => apiPdfServerless.get(`retrieve?process_id=${processId}&token=${process.env.AUTHORIZER_LAMBDA_TOKEN}`);
export const getRetrieveUrlPDFRecords = (keyId: string, patientId: string) => apiV2.get(`/patients/patient/${patientId}/records/file/?key=${keyId}`);
