import { Body, Box, MaterialUICore, axiomTokens } from '@iclinic/design-system';

const { styled } = MaterialUICore;
const { border, spacing, color, font } = axiomTokens;

export const BannerWrapper = styled(Box)({
  display: 'flex',
  padding: `${spacing['2']} 0 0 ${spacing['4']}`,
});

export const CardWrapper = styled(Box)({
  paddingRight: spacing['4'],
  width: '98%',
  flexShrink: 0,
  display: 'grid',
});

export const BannerCard = styled(Box)({
  position: 'relative',
  background: 'linear-gradient(248.1deg, #0063CF 0%, #003F72 100%)',
  borderRadius: border.radius.sm,
  padding: `${spacing['8']} ${spacing['4']}`,

  '& > *': {
    position: 'relative',
  },

  '& img': {
    position: 'absolute',
    right: 0,
    height: '100%',
    top: 0,
  },
});

export const BannerList = styled(BannerCard)({
  background: 'linear-gradient(248.1deg, #003F72 0%, #228BF0 100%)',
  padding: `${spacing['1']} ${spacing['4']} ${spacing['3']}`,
});

export const DemonstrationCard = styled(BannerCard)({
  background: 'linear-gradient(248.1deg, #228BF0 0%, #0063CF 100%)',
});

export const BannerHeading = styled(Body)({
  maxWidth: 170,
});

export const ListItem = styled('li')({
  display: 'flex',
  gap: spacing['2'],
  padding: `${spacing['2']} 0 ${spacing['1']}`,
  borderBottom: `${border.width.xs} solid ${color.brand.border.subtle}`,
  color: color.brand.icon.default,
  alignItems: 'center',
  fontSize: font.size.xxs,
});
