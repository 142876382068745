import React, { useCallback, useEffect, useState } from 'react';
import {
  Body,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogHeader,
  Heading,
  MaterialIcons as Icons,
} from '@iclinic/design-system';

import history from 'routes/history';
import { getUrlParams } from 'shared/utils/url';
import * as S from './ModalWarningPersonalization.styles';

const ModalWarningPersonalization = () => {
  const subscription = getUrlParams('subscription');
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (subscription === 'on') {
      setOpen(true);
    }
  }, [subscription]);

  const onCloseHandler = useCallback(() => {
    setOpen(false);
    history.replace('/relacionamento/pacote-de-mensagem/detalhes');
  }, []);

  const onRedirectToPersonalization = useCallback(() => {
    history.replace('/relacionamento/confirmacao-de-agenda');
  }, []);

  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <DialogHeader
        title="Personalize as mensagens para envio"
        onClose={onCloseHandler}
      />

      <DialogContent>
        <Body variant="sm">
          Escolha os horários e as mensagens a serem enviadas na página de
          personalização ou mantenha o envio de configuração padrão sugerida
          pelo sistema.
        </Body>

        <div>
          <S.TitleStandardsWrapper>
            <Icons.DateRange fontSize="small" />

            <Body variant="xs" bold>
              Configuração padrão
            </Body>
          </S.TitleStandardsWrapper>

          <S.StandardsWrapper>
            <S.InfoWrapper>
              <Heading variant="overline">Confirmação de agenda</Heading>
              <Heading variant="overline">Antecedência de envio: 1 dia</Heading>
              <Heading variant="overline">Horário: 08h</Heading>
            </S.InfoWrapper>

            <S.Divider />

            <S.InfoWrapper>
              <Heading variant="overline">Lembrete de agenda</Heading>
              <Heading variant="overline">Antecedência de envio: 5 dia</Heading>
              <Heading variant="overline">Horário: 10h</Heading>
            </S.InfoWrapper>
          </S.StandardsWrapper>
        </div>
      </DialogContent>

      <DialogActions>
        <Button color="transparent" onClick={onRedirectToPersonalization}>
          Personalizar
        </Button>
        <Button onClick={onCloseHandler}>Manter padrão</Button>
      </DialogActions>
    </Dialog>
  );
};

export { ModalWarningPersonalization };
