import { Pagination } from '@iclinic/design-system';
import React from 'react';

import * as S from './PaginationConfigurationTable.styles';

interface PaginationHistoryProps {
  page: number;
  count: number;
  onChange?: (newPage: number) => void;
  hidePrev?: boolean;
  hideNext?: boolean;
}

export const PaginationConfiguration = ({
  page,
  count,
  onChange,
  hidePrev,
  hideNext,
}: PaginationHistoryProps) => {
  const onChangePageHandler = (
    _: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    if (!onChange) return;

    onChange(value);
  };

  return (
    <S.PaginationWrapper>
      <Pagination
        page={page}
        count={count}
        onChange={onChangePageHandler}
        hidePrevButton={hidePrev}
        hideNextButton={hideNext}
      />
    </S.PaginationWrapper>
  );
};
