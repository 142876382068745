import {
  EmailValidResponse,
  EmailSendRequest,
} from 'state/shared/types';
import { apiEmail } from './api';

export const fetchEmailIsValid = (email: string) => apiEmail.get<EmailValidResponse>(`/v1/email/is-valid?email=${email}&token=${process.env.ICLINIC_EMAIL_TOKEN}`);

export const fetchEmailSend = ({
  patient_id,
  physician_id,
  email,
}: EmailSendRequest) => apiEmail.post(`/v1/email/dispatch?token=${process.env.ICLINIC_EMAIL_TOKEN}`, {
  patient_id,
  physician_id,
  email,
});
