import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Body, Box, Button } from '@iclinic/design-system';

import { professionSelector, statusSelector } from '../state/selectors';
import * as S from './ChipSelectorContainers.styles';
import { RequestStatus } from 'shared/constants/State';
import { backStep, submitProfession, setProfession } from '../state/reducer';
import { Profession, SignupSteps } from '../state/types';
import { ButtonSubmit } from '../components/Signup.styles';
import PatientModal from '../../components/PatientModal';
import { Actions } from '../components/Actions/Actions';
import { useCheckStep } from '../hooks/useCheckStep';
import { trackSignupStep } from '../trackSignupUtils';
import { trackingStepNames } from '../constants';

const JobStepContainer = () => {
  const dispatch = useDispatch();
  const status = useSelector(statusSelector);
  const profession = useSelector(professionSelector);
  const [openPatientModal, setOpenopenPatientModal] = useState(false);
  useCheckStep({ navigationStep: SignupSteps.Job });

  const handleSubmit = () => {
    if (profession) {
      dispatch(submitProfession(profession));
    }
  };

  const handleBackStep = () => {
    trackSignupStep({
      name: trackingStepNames[SignupSteps.Personal].back,
    });
    dispatch(backStep(SignupSteps.Personal));
  };

  const toggleDialogModal = () =>
    setOpenopenPatientModal((currentValue) => !currentValue);

  const onClickPatientModalHandler = () => {
    trackSignupStep({
      name: 'next_to_agendarconsulta',
    });
    window.location.replace('https://agendarconsulta.com/');
  };

  const isPatient = profession === Profession.Patient;
  const isDisabled = !profession || isPatient;

  const handleChipSelection = (selectedProfession: Profession) => {
    trackSignupStep({
      name: 'user_profession',
      stepValue: selectedProfession,
    });

    dispatch(setProfession(selectedProfession));
    if (selectedProfession === Profession.Patient) {
      toggleDialogModal();
    }
  };

  return (
    <Box>
      <S.StepHeading variant="md">
        Qual papel você exerce na clínica/consultório?
      </S.StepHeading>
      <Body variant="xs">
        Temos diferentes soluções direcionadas a cada perfil.
      </Body>
      <S.ChipsContainer>
        {Object.values(Profession).map((currentProfession) => (
          <S.Chip
            key={currentProfession}
            label={currentProfession}
            active={profession === currentProfession}
            variant="outlined"
            onClick={() => handleChipSelection(currentProfession)}
          />
        ))}
      </S.ChipsContainer>
      <Actions>
        <Button
          variant="contained"
          color="transparent"
          size="medium"
          onClick={handleBackStep}
        >
          Voltar
        </Button>
        <ButtonSubmit
          color="primary"
          disabled={isDisabled}
          variant="contained"
          onClick={handleSubmit}
          isLoading={status === RequestStatus.Pending}
        >
          Avançar
        </ButtonSubmit>
      </Actions>
      <PatientModal
        open={openPatientModal}
        onCancel={toggleDialogModal}
        onConfirm={onClickPatientModalHandler}
      />
    </Box>
  );
};

export default JobStepContainer;
