import Axios, { AxiosRequestConfig } from 'axios';

import { AUTH_TOKEN_KEY } from 'shared/constants/auth';

const surveyApi = Axios.create({
  baseURL: process.env.ICLINIC_SURVEY_API_URL,
});

const interceptorRequestAuthentication = (request: AxiosRequestConfig) => {
  const tokenValue = global.localStorage.getItem(AUTH_TOKEN_KEY);

  if (!tokenValue) return request;

  const tokenParam = {
    headers: { Authorization: `Token ${tokenValue}`, ...request.headers },
  };
  return Object.assign(request, tokenParam);
};

surveyApi.interceptors.request.use(interceptorRequestAuthentication);

export default surveyApi;
