import { Body, MaterialUICore, axiomTokens } from '@iclinic/design-system';

const { styled } = MaterialUICore;
const { spacing, color, border } = axiomTokens;

export const Root = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  padding: spacing[8],
  rowGap: spacing[6],
});

export const Card = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: color.background[1],
  border: `1px solid ${color.border.subtle}`,
  borderRadius: border.radius.sm,
  padding: spacing[6],
});

export const TitleAndSubtitleWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  rowGap: spacing[1],
});

export const Subtitle = styled(Body)({
  color: color.typeface.smooth,
});

export const CheckboxContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  marginTop: spacing['3'],
  marginBottom: spacing['6'],
});

export const ChipContainer = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  gap: spacing['2'],
  marginTop: spacing['3'],
  marginBottom: spacing['6'],
});

export const ButtonContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row-reverse',
});
