import {
  MATCH_DOTS,
  REMOVE_WHITE_SPACE,
  MATCH_THOUSANDS_SEPARATOR,
  REGEX_GET_ONLY_NUMBERS,
} from '../constants/strings';

export const removeWhitespaces = (stringValue) =>
  stringValue?.replace(REMOVE_WHITE_SPACE, '') || '';

export const formatNumberToDecimal = (value) => {
  const valueFixed = parseFloat(value).toFixed(2);
  if (!Number.isNaN(valueFixed)) {
    const valueWithCommaDecimal = valueFixed.replace(MATCH_DOTS, ',');
    return valueWithCommaDecimal.replace(MATCH_THOUSANDS_SEPARATOR, '.');
  }
  return '';
};

export const formatToInteger = (stringValue) => Number(stringValue).toFixed(0);

export const getPlural = (value, plural, singular) =>
  value > 1 ? plural : singular;

export const splitPriceDiference = (price) => {
  const [total, cents] = price.split('.');

  return {
    total,
    cents,
  };
};

export const removeQueryString = (url) => url.split('?')[0];

export const removeQueryStringFromUrl = () => {
  global.history.pushState(
    null,
    document.title,
    removeQueryString(window.location.href),
  );
};

export const routePath = (
  reactRouter = '',
  featureRoute = '',
  pageRoute = '',
) => {
  const isMigration = process.env.MIGRATION;

  if (isMigration === 'true') {
    const path = '/';
    return path.concat(featureRoute, '/', pageRoute);
  }

  return reactRouter.concat('/', pageRoute);
};

export const totalUsersMessage = (totalValueFormatted, totalUsers) =>
  `R$ ${totalValueFormatted} x ${totalUsers} profissiona${
    totalUsers > 1 ? 'is' : 'l'
  } de saúde`;

export const pluralize = (quantity, word, plural = `${word}s`) =>
  quantity > 1 ? plural : word;

export const searchLocaleStartsWith = (reference, searchValue) => {
  const searchCompareOptions = {
    usage: 'search',
    sensitivity: 'base',
    ignorePunctuation: true,
  };
  const searchCompareLanguage = 'pt-br';
  return (
    reference
      .substring(0, searchValue.length)
      .localeCompare(
        searchValue,
        searchCompareLanguage,
        searchCompareOptions,
      ) === 0
  );
};

export const getOnlyNumbers = (string) =>
  string.replace(REGEX_GET_ONLY_NUMBERS, '');

export const escapeString = (text) =>
  text
    .replace(/&amp;/g, '&')
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/&quot/g, '"')
    .replace(/&#39;/g, "'")
    .replace(/&#x2F;/g, '/')
    .replace(/&#x60;/g, '`')
    .replace(/&#x3D;/g, '=');

export const capitalizeFirstLetter = (text) =>
  text.charAt(0).toUpperCase() + text.slice(1);

export const formatCurrency = (value) =>
  new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(value);
