import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RequestStatus } from 'shared/constants/State';
import { SurveyInfoData } from '../../services/types';
import { SubmitAnswerActionParams } from './types';

export interface SurveyPatientState {
  fetcherStatus: RequestStatus;
  surveyInfoData?: SurveyInfoData;
  submitStatus?: RequestStatus;
}

export const initialState: SurveyPatientState = {
  fetcherStatus: RequestStatus.Pending,
};

export const nameStore = '@webapp/survey-patients';

export const fetchSurveyInfo = createAction<string>(
  `${nameStore}/fetchSurveyInfo`,
);

export const submitSurveyAnswer = createAction<SubmitAnswerActionParams>(
  `${nameStore}/submitSurveyAnswer`,
);

const SurveyPatientSlice = createSlice({
  name: nameStore,
  initialState,
  reducers: {
    fetchSurveyInfoSuccess: (state, action: PayloadAction<SurveyInfoData>) => ({
      ...state,
      fetcherStatus: RequestStatus.Success,
      surveyInfoData: action.payload,
    }),
    fetchSurveyInfoFailure: (state) => ({
      ...state,
      fetcherStatus: RequestStatus.Error,
    }),
    submitSurveyAnswerStart: (state) => ({
      ...state,
      submitStatus: RequestStatus.Pending,
    }),
    submitSurveyAnswerSuccess: (state) => ({
      ...state,
      submitStatus: RequestStatus.Success,
    }),
    submitSurveyAnswerFailure: (state) => ({
      ...state,
      submitStatus: RequestStatus.Error,
    }),
  },
});

export const {
  fetchSurveyInfoFailure,
  fetchSurveyInfoSuccess,
  submitSurveyAnswerFailure,
  submitSurveyAnswerStart,
  submitSurveyAnswerSuccess,
} = SurveyPatientSlice.actions;

export default SurveyPatientSlice.reducer;
