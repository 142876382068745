import { trackCustomEvent } from 'shared/utils/trackEvents';

type SignupStepData = {
  name: string;
  stepNum?: number | null;
  stepValue?: string | null;
  failureReason?: string | null;
};

export const SETUP_SCHEMA = 'iglu:br.com.iclinic/signup/jsonschema/1-0-1';

export function trackSignupStep({
  name,
  stepNum = null,
  stepValue = null,
  failureReason = null,
}: SignupStepData) {
  trackCustomEvent(SETUP_SCHEMA, {
    type: 'creation',
    step_name: name,
    step_num: stepNum,
    step_value: stepValue,
    failure_reason: failureReason,
    automatic: false,
  });
}
