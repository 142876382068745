import React from 'react';
import { useSelector } from 'react-redux';
import { Alert, MaterialIcons } from '@iclinic/design-system';

import * as S from './Signup.styles';
import PromotionBar from './PromotionBar/PromotionBar';
import ProgressBar from './ProgressBar/ProgressBar';
import { SignupBackground } from './SignupBackground/SignupBackground';
import { emailErrorSelector, errorSelector } from '../state/selectors';
import { useCustomFormErrorTracking } from '../hooks/useCustomTrackers';

const StepSignup = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const error = useSelector(errorSelector);
  const { emailError, message } = useSelector(emailErrorSelector);

  const hasUnknownError = !emailError && error;

  useCustomFormErrorTracking({
    name: 'error',
    hasError: !!error,
    errorMessage: error,
  });

  return (
    <>
      <SignupBackground />
      <S.StepSignupGrid>
        <S.SignupFormContainer>
          <S.SignupFormHeader>
            <S.SignupFormLogo width={158} />
          </S.SignupFormHeader>
          <PromotionBar />
          <S.ContentWrapper>
            {hasUnknownError && message && (
              <S.AlertWrapper>
                <Alert
                  severity="error"
                  icon={<MaterialIcons.ErrorOutlineOutlined />}
                  message={message}
                />
              </S.AlertWrapper>
            )}
            {children}
          </S.ContentWrapper>
          <ProgressBar />
        </S.SignupFormContainer>
      </S.StepSignupGrid>
    </>
  );
};

export default StepSignup;
