import isEmpty from 'lodash.isempty';

export const extractPrescriptionUserToken = (data) => {
  let token = '';
  data.forEach((item) => {
    const memedIntegration = item.integrations.find(
      (integration) => integration.active,
    );
    const metaToken = memedIntegration.meta.token;
    if (!isEmpty(metaToken)) {
      token = metaToken;
    }
  });

  return token;
};

export const checkDrugKind = (kind) => (kind === 'custom' ? 't' : 'm');
