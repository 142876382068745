import React from 'react';
import { Body, Heading, MaterialIcons as Icons } from '@iclinic/design-system';

import { ConsumptionPackage } from '../../../../services/types';
import { kindLabelMap } from '../../../../constants';
import * as S from './Consumption.styles';
import {
  Divider,
  LineSeparatedText,
  ProgressBar,
  TitleWrapper,
} from '../Common.styles';
import { formatDate } from 'shared/utils/date';
import { calculatePercentage } from 'features/messaging/utils';

interface ConsumptionProps {
  consumptionPackage: ConsumptionPackage;
}

const HUNDRED_PERCENT = 100;

const Consumption = ({ consumptionPackage }: ConsumptionProps) => {
  const percentageSend = calculatePercentage(
    consumptionPackage.total_sent,
    consumptionPackage.total_franchise,
  );

  return (
    <S.Card>
      <TitleWrapper>
        <Icons.DateUsage />

        <Body variant="xs" bold>
          Consumo do pacote
        </Body>
      </TitleWrapper>

      <Heading variant="xs">{consumptionPackage.total_sent} envios</Heading>

      <div>
        <ProgressBar
          className={percentageSend === HUNDRED_PERCENT ? 'exceeded' : ''}
          variant="determinate"
          value={percentageSend}
        />

        <LineSeparatedText variant="xxs">
          <span>
            Início do consumo:{' '}
            {consumptionPackage.first_consumption_date
              ? formatDate(
                  consumptionPackage.first_consumption_date,
                  'DD/MM/YYYY',
                )
              : 'Não enviado'}
          </span>
          <span>Total: {consumptionPackage.total_franchise}</span>
        </LineSeparatedText>
      </div>

      {consumptionPackage.kinds.length ? (
        <>
          <Divider />

          <div>
            {consumptionPackage.kinds.map(({ kind, total }) => (
              <LineSeparatedText variant="xxs" key={kind}>
                <span>{kindLabelMap[kind]}</span>

                <strong>{total}</strong>
              </LineSeparatedText>
            ))}
          </div>
        </>
      ) : null}

      {consumptionPackage.physicians?.length ? (
        <>
          <Divider />

          <div>
            {consumptionPackage.physicians.map(
              ({ profile_name, total_sent }) => (
                <LineSeparatedText variant="xxs" key={profile_name}>
                  <span>{profile_name}</span>
                  <strong>{total_sent}</strong>
                </LineSeparatedText>
              ),
            )}
          </div>
        </>
      ) : null}
    </S.Card>
  );
};

export { Consumption };
