import { axiomTokens, MaterialUICore } from '@iclinic/design-system';

const { styled } = MaterialUICore;
const { border, color, font, spacing } = axiomTokens;

export const TitleStandardsWrapper = styled('div')({
  display: 'flex',
  columnGap: spacing[2],
  marginBottom: spacing[2],

  '& > svg': {
    color: color.icon.default,
  },
});

export const StandardsWrapper = styled('div')({
  display: 'flex',
  padding: spacing[4],
  justifyContent: 'space-between',
  borderRadius: border.radius.sm,
  border: `1px solid ${color.border.subtle}`,
});

export const InfoWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',

  '& > span:first-child': {
    fontWeight: font.weight.bold,
  },
});

export const Divider = styled('div')({
  width: 1,
  backgroundColor: color.background[3],
});
