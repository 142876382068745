import React from 'react';

import * as S from './PageWrapper.styles';

interface PageWrapperProps {
  children: React.ReactNode;
}

const PageWrapper = ({ children }: PageWrapperProps) => (
  <S.Div>{children}</S.Div>
);

export { PageWrapper };
