import React, { useState, useEffect, useMemo } from 'react';
import { MaterialUICore, Logo, Button } from '@iclinic/design-system';
import { useSelector, shallowEqual } from 'react-redux';

import { PASSWORD_EXPIRATION_FLOW_KEY } from 'shared/constants/auth';
import { image } from 'shared/utils/urlGetter';
import { formatDate } from 'shared/utils/date';
import { readLocalStorage } from 'shared/utils/readFromLocalStorage';
import * as S from './PasswordExpirationMessage.styles';
import { getPhysicianName } from '../state/selectors';
import { PasswordExpirationFlowStateStringify } from '../state/types';

const { Fade } = MaterialUICore;

function BoxReset({ daysToReset }: { daysToReset?: number }) {
  if (!daysToReset || daysToReset <= 0) {
    return null;
  }
  return (
    <S.BoxExpireTime>
      <S.HeadingExpireTime variant="lg" align="center" inverseTextColor>
        Você tem {daysToReset} {daysToReset > 1 ? 'dias' : 'dia'} para realizar
        a alteração
      </S.HeadingExpireTime>
    </S.BoxExpireTime>
  );
}

function PasswordExpirationMessage() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { physicianName } = useSelector(getPhysicianName, shallowEqual);

  const { passwordExpired, expirationDate, daysToReset } =
    useMemo<PasswordExpirationFlowStateStringify>(
      () =>
        readLocalStorage(PASSWORD_EXPIRATION_FLOW_KEY, {
          passwordExpired: false,
          daysToReset: 0,
          expirationDate: null,
        }),
      [],
    );

  const removePasswordExpirationState = () => {
    window.localStorage.removeItem(PASSWORD_EXPIRATION_FLOW_KEY);
  };

  const onClickNotNow = () => {
    setModalIsOpen(false);
    removePasswordExpirationState();
  };

  const onClickChangePassword = () => {
    removePasswordExpirationState();
    window.location.href = '/new/usuarios/recuperar-senha/';
  };

  useEffect(() => {
    if (passwordExpired) {
      setModalIsOpen(true);
    }
  }, [passwordExpired]);

  if (!passwordExpired || !modalIsOpen || !expirationDate) {
    return null;
  }

  return (
    <S.Modal open>
      <Fade in>
        <S.ModalContainer>
          <S.FlexContainer>
            <S.Container>
              <S.MessageContainer>
                <Logo width={145} />

                <S.HeadingTitle variant="xl" inverseTextColor>
                  Altere sua senha para seguir a prática de segurança de
                  atualizações de senha
                </S.HeadingTitle>

                <S.HeadingSubtitle variant="sm" inverseTextColor>
                  Olá, {physicianName}, tudo bem?
                </S.HeadingSubtitle>

                <S.BodyContent>
                  <S.BodyParagraph variant="xs" inverseTextColor>
                    Nós da iClinic priorizamos a segurança dos seus dados.
                    Estamos promovendo uma campanha de substituição de senhas
                    por versões mais seguras e recomendamos que você faça a
                    troca o quanto antes, mas não se preocupe, você tem tempo
                    até o dia {formatDate(expirationDate)} para realizar a
                    alteração.
                  </S.BodyParagraph>

                  <S.BodyParagraph variant="xs" inverseTextColor>
                    Se tiver qualquer dúvida, fique à vontade para nos chamar
                    aqui no chat.
                  </S.BodyParagraph>

                  <S.BodyParagraph variant="xs" inverseTextColor>
                    Caso não queira ou não possa realizar esta ação neste
                    momento, basta clicar em “não posso agora”.
                  </S.BodyParagraph>

                  <S.BodyParagraph variant="xs" inverseTextColor bold>
                    Obrigado e um ótimo trabalho.
                  </S.BodyParagraph>
                </S.BodyContent>

                <S.FooterContainer>
                  <S.ButtonNotNow color="transparent" onClick={onClickNotNow}>
                    Não posso agora
                  </S.ButtonNotNow>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={onClickChangePassword}
                  >
                    Quero realizar a troca
                  </Button>
                </S.FooterContainer>
              </S.MessageContainer>

              <S.ImageContainer>
                <S.ImageDoctor
                  src={image('signup.doctorAlertMessage')}
                  alt="Médica"
                  width={581}
                />
                <BoxReset daysToReset={daysToReset} />
              </S.ImageContainer>
            </S.Container>
          </S.FlexContainer>
        </S.ModalContainer>
      </Fade>
    </S.Modal>
  );
}

export default PasswordExpirationMessage;
