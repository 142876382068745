import { apiV2 } from './api';

const fetchFeatureUser = (payload) => (
  new Promise((resolve, reject) => (
    apiV2.post('/features/featureuser/', payload).then((response) => {
      const validateStatus = apiV2.defaults.validateStatus(response.status);
      if (validateStatus) {
        resolve(response);
      }
      reject(response);
    }).catch(reject)
  ))
);

export { fetchFeatureUser };
export default fetchFeatureUser;
