import React from 'react';

import { ApiErrors } from '@types';
import {
  MaterialUICore,
  MaterialIcons,
  Box,
  Alert,
} from '@iclinic/design-system';

const { makeStyles } = MaterialUICore;
const { ErrorOutlineOutlined } = MaterialIcons;

const useStyles = makeStyles((theme) => ({
  containerAlert: {
    margin: '16px 0 8px',
    [theme.breakpoints.up('md')]: {
      marginTop: '52px',
    },
  },
}));

type NotificationErrosProps = {
  errors: ApiErrors[];
};

export default function NotificationErros({
  errors,
}: NotificationErrosProps): JSX.Element {
  const classes = useStyles();

  return (
    <>
      {errors.map((error: ApiErrors) => (
        <Box className={classes.containerAlert} key={error.code}>
          <Alert
            severity="error"
            icon={<ErrorOutlineOutlined />}
            message={error.message}
          />
        </Box>
      ))}
    </>
  );
}
