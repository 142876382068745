import { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';

import { AUTH_TOKEN_KEY } from 'shared/constants/auth';
import { captureException, captureMessage } from 'shared/utils/handlerErrors';
import { GENERIC_ERROR_MESSAGE } from 'shared/constants/errorMessages';
import { CLINIC_ID, PROFILE_ID } from '../../shared/constants/services';

const isProd = process.env.NODE_ENV === 'production';
let forceLogoutCallback: () => void;

export const setForceLogoutCallback = (callback: () => void) => {
  forceLogoutCallback = callback;
};

export const interceptorRequestAuthentication = (
  request: AxiosRequestConfig,
) => {
  const tokenValue = global.localStorage.getItem(AUTH_TOKEN_KEY);

  if (!tokenValue) return request;

  const tokenParam = { params: { token: tokenValue, ...request.params } };
  return Object.assign(request, tokenParam);
};

export const interceptorRequestAuthenticationHeaders = (
  request: AxiosRequestConfig,
) => {
  const tokenValue = global.localStorage.getItem(AUTH_TOKEN_KEY);

  if (!tokenValue) return request;

  const tokenParam = { headers: { token: tokenValue, ...request.headers } };
  return Object.assign(request, tokenParam);
};

const hasEnvelopedData = (response: AxiosResponse) =>
  response.data && response.data.data;

/**
 * Solves the request envelope data object from api
 * @example
 * // returns response.data.data
 * request.then(response => response.getResponseData())
 * @returns {Object} Returns the value of response.data.data for the request.
 */

export const interceptorResponseTransformData = (response: AxiosResponse) => {
  try {
    const responseData = hasEnvelopedData(response)
      ? response.data.data
      : response.data;
    const getResponseData = {
      getResponseData: () => responseData,
    };
    return Object.assign(response, getResponseData);
  } catch (e) {
    captureException(e);
    return response;
  }
};

export function unauthorizedInterceptor(error: AxiosError) {
  const { request, response } = error;

  if (isProd && response?.status === 401) {
    captureMessage(`Request: ${request?.responseURL} - forced logout`);
    forceLogoutCallback();
    return Promise.reject(response);
  }
  return null;
}

/**
 * Retrieve errors from api
 * @example
 * // returns response.errors
 * request.then(response => response.errors)
 * @returns {Array} Returns the errors from the request.
 */

export const interceptorResponseErrors = async (error: AxiosError) => {
  if (!error.response) return error;
  try {
    await unauthorizedInterceptor(error);
  } catch (e) {
    captureException(e);
  }

  const responseErrors = error.response.data.errors;
  const getResponseErrors = {
    errors: responseErrors || [{ message: GENERIC_ERROR_MESSAGE }],
  };
  return Object.assign(error.response, getResponseErrors);
};

/**
 * It`s like *interceptorResponseErrors*, but it returns a rejected Promise
 */
export const interceptorResponseErrorsPromise = async (error: AxiosError) => {
  if (!error.response) return Promise.reject(error);
  await unauthorizedInterceptor(error);

  const { response } = error;
  let { errors } = response.data;
  errors = errors || [{ message: GENERIC_ERROR_MESSAGE }];

  // eslint-disable-next-line prefer-promise-reject-errors
  return Promise.reject({ ...response, errors });
};

/**
 * Request authentication with qs params to email-mkt-api
 * @deprecated
 */
export const interceptorRequestAuthenticationEmkt = (
  request: AxiosRequestConfig,
) => {
  const tokenValue = global.localStorage.getItem(AUTH_TOKEN_KEY);
  const clinicId = global.localStorage.getItem(CLINIC_ID);
  const profileId = global.localStorage.getItem(PROFILE_ID);

  if (!tokenValue) {
    return request;
  }

  const requestParams = {
    params: {
      token: tokenValue,
      clinicId,
      userId: profileId,
      ...request.params,
    },
  };
  return {
    ...request,
    ...requestParams,
  };
};

export const exportDataApiTokenInterceptor = (
  request: AxiosRequestConfig,
): AxiosRequestConfig => {
  const token = global.localStorage.getItem(AUTH_TOKEN_KEY);

  if (!token) {
    throw new Error(`missing token in header Authorization`);
  }
  return {
    ...request,
    headers: {
      Authorization: `Token ${token}`,
    },
  };
};
