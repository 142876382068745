// Internal
import { ActionType } from '@types';
import * as types from './constants';
import * as actions from './actions';
import { CampaignManagerState } from './types';

export const initialState: CampaignManagerState = {
  isFetching: false,
  errors: [],
  campaigns: {
    name: '',
    components: [],
  },
};

type CampaignManageActions = ActionType<typeof actions>;

const campaignManager = (
  state = initialState,
  action: CampaignManageActions,
) => {
  switch (action.type) {
    case types.FETCH_ACTIVE_CAMPAIGN:
      return {
        ...state,
        isFetching: true,
      };
    case types.FETCH_ACTIVE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        campaigns: action.payload.campaigns,
      };
    case types.FETCH_ACTIVE_CAMPAIGN_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: action.payload.errors,
      };
    default:
      return state;
  }
};

export default campaignManager;
