export const createCookie = (
  name: string,
  value: string,
  expire: number,
  secure: boolean = true,
) => {
  const date = new Date();
  date.setTime(date.getTime() + expire * 1000);
  const expires = `; expires=${date.toUTCString()}`;
  const secureFlag = secure ? '; secure' : '';
  document.cookie = `${name}=${value}${expires}${secureFlag}; path=/`;
};

export const getCookie = (name: string) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop()?.split(';').shift();
  return '';
};
