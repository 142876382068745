import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import GoogleIcon from './GoogleIcon';
import StyledGoogleSignInButton from './GoogleSignInButton.styles';
import { loginWithGoogleAuth2Request } from '../state/actions';
import { CredentialResponse } from 'shared/utils/googleAuth2';
import { getLoginWithGoogleReady } from 'features/new-auth/state/selectors';

export default function GoogleSignInButton(): JSX.Element {
  const dispatch = useDispatch();
  const isGoogleScriptLoaded = useSelector(getLoginWithGoogleReady);

  const handleCredentialResponse = (response: CredentialResponse) => {
    dispatch(loginWithGoogleAuth2Request(response.credential));
  };

  const initializeGoogleLogin = () => {
    const googleLoginWrapper = document.createElement('div');
    googleLoginWrapper.style.display = 'none';
    googleLoginWrapper.classList.add('custom-google-button');
    document.body.appendChild(googleLoginWrapper);

    window.google.accounts.id.initialize({
      client_id: process.env.GOOGLE_AUTH2_CLIENT_ID,
      callback: handleCredentialResponse,
    });

    window.google.accounts.id.renderButton(googleLoginWrapper, {
      type: 'icon',
      width: '200',
    });

    return googleLoginWrapper;
  };

  const handleClick = () => {
    if (isGoogleScriptLoaded) {
      const googleLoginWrapper = initializeGoogleLogin();
      const googleLoginWrapperButton = googleLoginWrapper.querySelector(
        '.custom-google-button div[role=button]',
      ) as HTMLElement;
      if (googleLoginWrapperButton) {
        googleLoginWrapperButton.click();
      }
    }
  };

  return (
    <StyledGoogleSignInButton
      fullWidth
      variant="outlined"
      startIcon={<GoogleIcon />}
      disabled={!isGoogleScriptLoaded}
      onClick={handleClick}
    >
      Entrar com Google
    </StyledGoogleSignInButton>
  );
}
