import { createSelector } from '@reduxjs/toolkit';

import { SignupState } from '../types';
import {
  emailErrorMessages,
  defaultEmailError,
  defaultErrorMessage,
  defaultErrorCode,
} from '../constants';
import { RequestStatus } from 'shared/constants/State';

interface State {
  signup: SignupState;
}

export const progressStepSelector = (state: State) => state.signup.progressStep;
export const statusSelector = (state: State) => state.signup.status;
export const errorSelector = (state: State) => state.signup.error;
export const emailSelector = (state: State) => state.signup.email;
export const signupSelector = (state: State) => state.signup;
export const listCBOState = (state: State) => state.signup.listCBO;
export const socialTokenSelector = (state: State) => state.signup.socialToken;
export const autoLoginSelector = (state: State) => state.signup.autoLogin;
export const getEmbed = (state: State) => state.signup.embed;

export const errorMessageSelector = createSelector(errorSelector, (error) => {
  if (!error) return { emailError: false, message: '', error };

  let errorRow = emailErrorMessages.find((row) => row[0] === error);

  if (!errorRow) errorRow = defaultEmailError;
  const [, message, emailError] = errorRow;

  return { emailError, message, error };
});

export const pendingDisabledSelector = createSelector(
  statusSelector,
  (status) => ({ status, disabled: status === RequestStatus.Pending }),
);

export const emailUIStateSelector = createSelector(
  pendingDisabledSelector,
  errorMessageSelector,
  (pending, errorMessage) => ({
    ...pending,
    ...errorMessage,
  }),
);

export const emailFormValuesSelector = (state: State) => ({
  email: state.signup.email,
  termsOfServiceAndPrivacyPolicy: false,
});

export const signupFormValuesSelector = (state: State) => ({
  email: state.signup.email,
  name: state.signup.name,
  billing_phone: state.signup.billing_phone,
  profession: state.signup.profession,
  cbo: state.signup.cbo,
  professionals_count: state.signup.professionals_count,
  password: '',
  termsOfServiceAndPrivacyPolicy: false,
});

export const subscriptionDataSelector = createSelector(
  signupSelector,
  ({
    email,
    name,
    billing_phone,
    profession,
    cbo,
    socialToken,
    professionals_count,
  }) => ({
    email,
    name,
    billing_phone,
    profession,
    professionals_count,
    cbo,
    socialToken,
  }),
);

export const defaultErrorMsgSelector = createSelector(
  statusSelector,
  errorSelector,
  (status, error) => ({
    status,
    error,
    errorMessage:
      (status === RequestStatus.Error &&
        (error === defaultErrorCode ? defaultErrorMessage : error)) ||
      '',
  }),
);

export const passwordUIStateSelector = createSelector(
  defaultErrorMsgSelector,
  ({ status, error, errorMessage }) => ({
    status,
    error,
    disabled:
      status === RequestStatus.Pending || status === RequestStatus.Success,
    errorMessage,
  }),
);

export const formValuesAccountSelector = createSelector(
  (state: State) => state.signup.name,
  (state: State) => state.signup.billing_phone,
  (state: State) => state.signup.profession,
  (state: State) => state.signup.cbo,
  (state: State) => state.signup.professionals_count,
  (name, billing_phone, profession, cbo, professionals_count) => ({
    name,
    billing_phone,
    profession,
    cbo,
    professionals_count,
  }),
);

export const accountFormSelector = createSelector(
  pendingDisabledSelector,
  defaultErrorMsgSelector,
  ({ disabled }, { status, error, errorMessage }) => ({
    status,
    error,
    disabled,
    errorMessage,
  }),
);

export const listCBOSelector = createSelector(listCBOState, (cboData) => ({
  ...cboData,
  objects: [{ active: false, term: '', id: 0, cbo: 0 }, ...cboData.objects],
}));
