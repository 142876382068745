import React, { createContext, useState, useContext, useCallback } from 'react';

import { AUTH_TOKEN_KEY } from 'shared/constants/auth';
import iclinic from 'services/iclinic';

interface AuthState {
  token: string;
}

interface AuthContextData {
  token: string;
  validateAuthToken: () => Promise<void>;
  isLoaded: boolean;
}

interface AuthProviderProps {
  children: React.ReactNode;
}

export const AuthContext = createContext<AuthContextData>(
  {} as AuthContextData,
);

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [data, setData] = useState<AuthState>(() => {
    return { token: '' } as AuthState;
  });

  const [isLoaded, setIsLoaded] = useState(() => false);

  const validateAuthToken = useCallback(async () => {
    try {
      const tokenAuth = global.localStorage.getItem(AUTH_TOKEN_KEY);

      // do not overfetch if token is null
      if (tokenAuth === null) {
        setData({ token: '' });
      } else {
        const { errors } = await iclinic.auth.validateToken(tokenAuth);

        if (errors) throw errors;

        setData({ token: tokenAuth });
      }
    } catch (error) {
      setData({ token: '' });
    } finally {
      setIsLoaded(true);
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{ token: data.token, isLoaded, validateAuthToken }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}
