import { Store, AnyAction } from 'redux';
import { TrackNodes, TrackFunction } from 'state/shared/types';
import { trackEvent } from '../shared/actions';

export default (
  trackFn: TrackFunction,
  trackNode: TrackNodes = 'track',
) => (store: Store) => (next: (action: AnyAction) => void) => (action: AnyAction) => {
  if (action[trackNode]) {
    store.dispatch(trackEvent(action[trackNode], trackFn));
  }
  return next(action);
};
