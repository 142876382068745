// External
import { Reducer } from 'redux';

// Internal
import { ActionType } from '@types';
import * as types from './constants';
import * as actions from './actions';
import { UserInfoState } from './types';

export const initialState: UserInfoState = {
  userData: {},
  profileId: null,
  clinicId: null,
  errors: [],
  listTerms: [],
  isFetching: false,
};

type UserInfoActions = ActionType<typeof actions>;

const userInfoReducer: Reducer<UserInfoState, UserInfoActions> = (
  state = initialState,
  action: UserInfoActions,
): UserInfoState => {
  switch (action.type) {
    case types.GET_PROFILE_CLINIC:
      return {
        ...state,
        clinicId: action.payload.clinicId,
        profileId: action.payload.profileId,
      };
    case types.GET_USER_INFO:
      return {
        ...state,
        userData: {
          ...state.userData,
          ...action.payload.userData,
        },
      };
    case types.GET_PROFILE_CLINIC_FAILURE:
      return {
        ...state,
        errors: action.payload.errors,
        isFetching: false,
      };
    case types.SET_TERMS:
      return {
        ...state,
        listTerms: action.payload.listTerms,
      };
    case types.ACCEPT_TERMS:
      return {
        ...state,
        isFetching: action.payload.isFetching,
        termObject: action.payload.termObject,
      };
    case types.ACCEPT_TERMS_SUCCESS:
      return {
        ...state,
        isFetching: action.payload.isFetching,
      };
    default:
      return state;
  }
};

export default userInfoReducer;
