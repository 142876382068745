import { SingleScheduleTemplateMessage } from 'features/messaging/state/types';
import {
  defaultMessage,
  confirmationDaysAdvanceOptions,
  timeSendOptions,
} from '../../../constants';

export type FormValues = {
  physicianId?: number;
} & SingleScheduleTemplateMessage;

const factoryTemplateMessageInitialValues = (): FormValues => ({
  content: defaultMessage,
  daysAdvance: confirmationDaysAdvanceOptions[0].value,
  timeSend: timeSendOptions[0].value,
  isActive: true,
  contactPhone: '',
  physicianId: 0,
});

export { factoryTemplateMessageInitialValues };
