import React from 'react';
import {
  Body,
  Chip,
  Heading,
  MaterialIcons as Icons,
} from '@iclinic/design-system';

import * as S from './SuggestionPackageCard.styles';

interface SuggestionPackageCardProps {
  packageName: string;
  sendQuantity: number;
  price: string;
  slug: string;
  selected?: boolean;
  onClick?: (slug: string) => void;
  hasReminder?: boolean;
  hasConfirmation?: boolean;
}

const SuggestionPackageCard = ({
  packageName,
  price,
  sendQuantity,
  slug,
  onClick,
  selected,
  hasConfirmation,
  hasReminder,
}: SuggestionPackageCardProps) => {
  const onClickHandler = () => {
    if (!onClick) return;

    onClick(slug);
  };

  return (
    <S.Card onClick={onClickHandler}>
      <div>
        <S.HorizontalWrapperWithSpace>
          <S.HeadingSmooth variant="overline">
            Sugerido pra você
          </S.HeadingSmooth>

          <S.SimpleRadio
            name={slug}
            checkedIcon={<Icons.CheckCircle color="primary" />}
            checked={selected}
            inputProps={{
              'aria-label': packageName,
            }}
          />
        </S.HorizontalWrapperWithSpace>

        <Heading variant="md">{packageName}</Heading>
      </div>

      <S.HorizontalWrapper>
        {hasConfirmation ? (
          <S.CheckIcon fontSize="small" />
        ) : (
          <S.CloseIcon fontSize="small" />
        )}
        <S.BodySmooth variant="xs">Confirmação de agenda</S.BodySmooth>
      </S.HorizontalWrapper>

      <S.HorizontalWrapper>
        {hasReminder ? (
          <S.CheckIcon fontSize="small" />
        ) : (
          <S.CloseIcon fontSize="small" />
        )}
        <S.BodySmooth variant="xs">Lembrete de agenda</S.BodySmooth>
      </S.HorizontalWrapper>

      <S.Divider />

      <S.HorizontalWrapperWithSpace>
        <Body variant="xs">
          até <strong>{sendQuantity} envios</strong> ao mês
        </Body>

        <Chip variant="outlined" severity="info" label={price} size="small" />
      </S.HorizontalWrapperWithSpace>
    </S.Card>
  );
};

export { SuggestionPackageCard };
