export default {
  ignoreErrors: [
    // Random plugins/extensions
    'top.GLOBALS',
    'NotAllowedError: The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission.',
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    'originalCreateNotification',
    'canvas.contentDocument',
    'MyApp_RemoveAllHighlights',
    'https://tt.epicplay.com',
    "Can't find variable: ZiteReader",
    'jigsaw is not defined',
    'ComboSearch is not defined',
    'https://loading.retry.widdit.com/',
    'atomicFindClose',
    // Facebook borked
    'fb_xd_fragment',
    // ISP "optimizing" proxy - `Cache-Control: no-transform`
    // seems to reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
    'bmi_SafeAddOnload',
    'EBCallBackMessageReceived',
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    'conduitPage',
    /i\.calculateCaretPosition/,
    'window.Canvas.findParentAutocomplete(modulos/plataforma.sinapse/js/canvas)',
    'The play() request was interrupted by a call to pause().',
  ],
  blacklistUrls: [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    // iClinic specifics
    /file:\/\//,
    /pstatic\.davebestdeals\.com/,
    /fast\.conpass\.io/,
    // Third party track
    /js\.hsleadflows\.net/,
    /js\.userpilot\.io/,
  ],
};
