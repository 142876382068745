import React from 'react';
import { MaterialUICore, Chip, Alert, Box } from '@iclinic/design-system';
import { useDispatch, useSelector } from 'react-redux';

import { formatDate } from 'shared/utils/date';
import { fetchSurveyAssessmentLink } from 'features/survey/state/filters';
import { WhatsappIconSurvey } from 'features/survey/components/WhatsappIcon';
import { getSurveyEvents } from '../state/filters/selectors';
import * as S from './TableSurvey.styles';

const { Table, TableCell, TableHead, TableRow, TableBody } = MaterialUICore;

export const TableSurvey = () => {
  const dispatch = useDispatch();
  const surveyEvents = useSelector(getSurveyEvents);

  if (!surveyEvents?.length) {
    return (
      <Box mt={2}>
        <Alert message="Nenhum registro encontrado" severity="warning" />
      </Box>
    );
  }

  return (
    <S.ContentTable>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="left">Data</TableCell>
            <TableCell align="left">Paciente</TableCell>
            <S.SmallTableCell align="left">Link da avaliação</S.SmallTableCell>
            <S.SmallTableCell align="left">Status</S.SmallTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {surveyEvents.map(({ id, date, patient, status }) => (
            <S.TableRow key={id}>
              <TableCell align="left">{formatDate(date)}</TableCell>
              <TableCell align="left">{patient}</TableCell>
              <S.SmallTableCell align="left">
                <S.Button
                  disableRipple
                  onClick={() => dispatch(fetchSurveyAssessmentLink(id))}
                  variant="text"
                  startIcon={<WhatsappIconSurvey />}
                >
                  Enviar pesquisa
                </S.Button>
              </S.SmallTableCell>
              <S.SmallTableCell align="left">
                <Chip
                  label={status}
                  size="small"
                  severity={status === 'Enviado' ? 'success' : 'default'}
                />
              </S.SmallTableCell>
            </S.TableRow>
          ))}
        </TableBody>
      </Table>
    </S.ContentTable>
  );
};
