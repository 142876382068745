import { Body, Button, Heading } from '@iclinic/design-system';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { image } from 'shared/utils/urlGetter';
import { getScheduleInfoData } from 'features/messaging/state/patients/selectors';
import { formatInFullDateMessage } from 'features/messaging/formatters';
import { formatTime } from 'shared/utils/date';
import { onlyNumbers } from 'shared/utils/formatters';
import {
  ButtonWrapper,
  Card,
  CardContainer,
  CardContent,
  MainCard,
  Image,
} from '../Common.styles';

export function CanceledAppointment() {
  const appointmentData = useSelector(getScheduleInfoData);

  const handleRescheduleClick = useCallback(() => {
    const whatsappURL = `https://api.whatsapp.com/send?phone=55${onlyNumbers(
      appointmentData?.clinic.phone || '',
    )}`;
    window.open(whatsappURL, '_blank');
  }, [appointmentData]);

  return (
    <MainCard>
      <Card>
        <CardContainer>
          <Image>
            <img
              src={image('appointmentConfirmation.imageCanceled')}
              alt="imagem de agenda cancelada"
            />
          </Image>
          <CardContent>
            <Heading variant="lg" align="center">
              Agenda cancelada!
            </Heading>
            <Body variant="sm">
              Sua agenda com o {appointmentData?.physician.name} em{' '}
              {appointmentData?.event_date
                ? formatInFullDateMessage(appointmentData.event_date)
                : ''}{' '}
              às <br />
              {appointmentData?.event_time
                ? formatTime(appointmentData.event_time)
                : ''}{' '}
              foi cancelada com sucesso.
            </Body>
          </CardContent>
        </CardContainer>
        <ButtonWrapper>
          {appointmentData?.clinic.phone ? (
            <Button
              size="large"
              variant="contained"
              onClick={handleRescheduleClick}
            >
              Reagendar
            </Button>
          ) : null}
        </ButtonWrapper>
      </Card>
    </MainCard>
  );
}
