/* eslint-disable no-useless-escape */
import isURL from 'validator/lib/isURL';
import moment from 'moment';
/**
 * Make a validation function
 * @param {function} fn
 * @param {string} message
 */
export const makeValidation = (fn, message) => (value, allValues) =>
  fn(value, allValues) ? undefined : message;

/**
 * Check if has value
 * @param {string} value
 */
export const hasValue = (value) =>
  value !== '' && value !== null && value !== undefined;

/**
 * Check if value is a valid e-mail address
 * @param {string} value
 */
export const isEmail = (value) =>
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    value,
  );

/**
 * Returns a new function to check value length
 * @param {number} number
 */
export const haveMinLength = (number) => (value) => value.length >= number;

/**
 * Check if the provided string is a valid url path
 * @param {number} number
 */
export const isValidUrlPath = (value) => /^[\/][^\/].*/gi.test(value);

/**
 * Check if the provided string contains http://
 * @param {value} string with the URL
 */
export const isValidUrlHttp = (value) =>
  isURL(value, {
    require_valid_protocol: true,
    require_protocol: true,
    require_host: true,
  });

/**
 * Check if the provided content contains a number
 * @param {value} number
 */

export const isNumber = (number = null) => !global.window.isNaN(number);

/**
 * Check if provided content is valid date
 * @param date
 * @returns {boolean}
 */
export const isValidFullDate = (date) =>
  moment(date, 'DD/MM/YYYY', true).isValid();

/**
 * Check if the user is of legal age
 * @param date
 * @returns {boolean}
 */
export const isMinorOfAge = (date) => {
  const birthDate = moment(date, 'DD/MM/YYYY', true);
  const maxDate = moment().subtract(18, 'years');

  return birthDate.isValid() && maxDate.isSameOrAfter(birthDate);
};

/**
 * Check if provided content is valid date
 * @param hourMinute
 * @returns {boolean}
 */
export const isValidHour = (hourMinute) =>
  moment(hourMinute, 'HH:mm', true).isValid();

/**
 * Check if the provided content is a negative value
 * @param {value} number
 */
export const isPositive = (number) => number > 0;

const mod11 = (num) => num % 11;
const isEqual = (a) => (b) => b === a;
const getDigit = (num) => (num > 1 ? 11 - num : 0);
const mergeDigits = (num1, num2) => `${num1}${num2}`;
const getSumByReduce = (list, multiplier, fn) => list.reduce(fn(multiplier), 0);
const getValidationDigit = (multiplier) => (cpf) => (fn) =>
  getDigit(mod11(getSumByReduce(cpf, multiplier, fn)));

/**
 * Check if the provided content is a valid CPF
 * @param {value} cpf
 */
export const isValidCPF = (cpfInput) => {
  const getTwoLastDigits = (cpf) => `${cpf[9]}${cpf[10]}`;
  const getCpfNumeral = (cpf) => cpf.substr(0, 9).split('');

  const isRepeatingChars = (str) =>
    str.split('').every((elem) => elem === str[0]);

  const toSumOfProducts = (multiplier) => (result, num, index) => {
    const modifier = multiplier - index;
    return result + num * modifier;
  };

  const isRepeatingNumbersCpf = isRepeatingChars;

  const isValid = (cpf) => {
    const CPF = getCpfNumeral(cpf);
    const firstDigit = getValidationDigit(10)(CPF)(toSumOfProducts);
    const secondDigit = getValidationDigit(11)(CPF.concat(firstDigit))(
      toSumOfProducts,
    );

    return isEqual(getTwoLastDigits(cpf))(mergeDigits(firstDigit, secondDigit));
  };

  if (!cpfInput) {
    return false;
  }

  return !isRepeatingNumbersCpf(cpfInput) && isValid(cpfInput);
};

/**
 * Check if the provided content is a valid CNPJ
 * @param {value} cnpjInput
 */
export const isValidCNPJ = (cnpjInput) => {
  const getTwoLastDigits = (cnpj) => `${cnpj[12]}${cnpj[13]}`;
  const getCnpjNumeral = (cnpj) => cnpj.substr(0, 12).split('');

  const isRepeatingChars = (str) =>
    str.split('').every((elem) => elem === str[0]);

  const toSumOfProducts = (multiplier) => (result, num, index) => {
    const modifier = multiplier - index;
    const weight = modifier < 9 ? modifier + 1 : modifier - 7;
    return result + num * weight;
  };

  const isRepeatingNumbersCnpj = isRepeatingChars;

  const isValid = (cnpj) => {
    const CNPJ = getCnpjNumeral(cnpj);
    const firstDigit = getValidationDigit(12)(CNPJ)(toSumOfProducts);
    const secondDigit = getValidationDigit(13)(CNPJ.concat(firstDigit))(
      toSumOfProducts,
    );

    return isEqual(getTwoLastDigits(cnpj))(
      mergeDigits(firstDigit, secondDigit),
    );
  };

  if (!cnpjInput) {
    return false;
  }

  return !isRepeatingNumbersCnpj(cnpjInput) && isValid(cnpjInput);
};

/**
 * Check if the provided content is a valid Document
 * @param {value} input
 */
export const isValidDocument = (input) => {
  if (!input) {
    return false;
  }

  if (input.length !== 11 && input.length !== 14) {
    return false;
  }

  if (input.length === 11) {
    return isValidCPF(input);
  }

  return isValidCNPJ(input);
};

export const isValidName = (event) => {
  const { type } = event;
  let value = event.key;

  if (type === 'paste') {
    value = (event.clipboardData || window.clipboardData).getData('text');
  }

  if (/[0-9]|[^\w\s]/g.test(value)) {
    event.preventDefault();
  }
};

/**
 * Check if the provided date is in the promotional period iClinic Marketing
 * @param {date} date
 */
export const isMarketingDaysLaunchInterval = (date) =>
  date.getFullYear() === 2019 &&
  date.getMonth() === 10 &&
  date.getDate() >= 11 &&
  date.getDate() < 22;

export const isBannerBlackFridayInterval = (date) =>
  date.getFullYear() === 2019 &&
  date.getMonth() === 10 &&
  date.getDate() >= 22 &&
  date.getDate() <= 29;

export const isThemeBlackFridayInterval = (date) =>
  date.getFullYear() === 2019 &&
  date.getMonth() === 10 &&
  date.getDate() >= 27 &&
  date.getDate() <= 29;
