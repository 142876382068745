import React from 'react';

import * as S from './PromotionBar.styles';

const PromotionBar = () => (
  <S.Root id="promotion-bar">
    <S.Title variant="xs">Esquenta Black Friday</S.Title>
    <S.Subtitle variant="xs">
      Aproveite até 40% OFF por tempo limitado.
    </S.Subtitle>
  </S.Root>
);

export default PromotionBar;
