import { MaterialUICore } from '@iclinic/design-system';

const { makeStyles } = MaterialUICore;

const useStyles = makeStyles((theme: MaterialUICore.Theme) => ({
  footer: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: theme.shape.borderRadius,
    padding: '16px 18px',
  },
}));

export default useStyles;
