import {
  Body,
  Heading,
  MaterialUICore,
  axiomTokens,
} from '@iclinic/design-system';

import { Card as RootCard } from './Dashboard.styles';

const { styled } = MaterialUICore;
const { color, spacing } = axiomTokens;

export const Card = styled(RootCard)({
  minHeight: 96,
  display: 'grid',
  gridTemplateColumns: '1fr auto',
  gridTemplateRows: 'min-content',
  padding: `${spacing[2]} ${spacing[4]}`,
  alignItems: 'center',
  gap: spacing[1],
});

export const NpsPatientNameWrapper = styled('div')({
  gridColumn: '1',
  gridRow: '1',
  display: 'flex',
  columnGap: spacing[2],
  alignItems: 'center',

  '& svg': {
    color: color.branding[100],
  },
});

export const NpsValueText = styled(Heading)({
  gridColumn: '2',
  gridRow: '1',
});

export const CommentText = styled(Body)({
  alignSelf: 'start',
  gridColumn: '1 / span 2',
  gridRow: '2',
});
