import { axiomTokens, MaterialUICore, Box } from '@iclinic/design-system';

const { styled } = MaterialUICore;

const { spacing, color, font } = axiomTokens;

export const CardHeader = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: spacing['4'],
  letterSpacing: 0.44,
});

export const CardContent = styled('div')({
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  alignItems: 'flex-start',
  marginTop: spacing['4'],
  gap: spacing['2'],
});

export const BoxText = styled(Box)(({ theme }) => ({
  display: 'flex',
  letterSpacing: 0.44,
  alignItems: 'flex-start',
  gap: spacing['2'],

  [theme.breakpoints.down(600)]: {
    '& > p': {
      letterSpacing: 0.44,
      fontSize: font.size.xs,
      lineHeight: font.lineHeight.xl,
    },
  },
}));

export const Wrapper = styled('div')({
  color: color.icon.default,
  marginTop: 1,
});
