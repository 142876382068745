import React from 'react';
import { Body, MaterialIcons } from '@iclinic/design-system';

import * as S from './AnswerItem.styles';

const { Person } = MaterialIcons;

interface AnswerItemProps {
  patientName: string;
  npsValue: number;
  comment?: string;
}

export default function AnswerItem({
  npsValue,
  patientName,
  comment,
}: AnswerItemProps) {
  return (
    <S.Card>
      <S.NpsPatientNameWrapper>
        <Person />

        <Body variant="sm" bold>
          {patientName}
        </Body>
      </S.NpsPatientNameWrapper>

      <S.NpsValueText variant="overline">
        NPS {npsValue.toString()}
      </S.NpsValueText>

      <S.CommentText variant="xxs">{comment || '-'}</S.CommentText>
    </S.Card>
  );
}
