import { MaterialUICore } from '@iclinic/design-system';

const { makeStyles } = MaterialUICore;

const useStyles = makeStyles((theme) => ({
  grid: {
    height: '100vh',
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      alignItems: 'left',
    },
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'left',
    },
  },
  paper: {
    width: '100%',
    maxWidth: 440,
    '& .MuiPaper-root': {
      [theme.breakpoints.down('xs')]: {
        boxShadow: 'none',
      },
    },
    [theme.breakpoints.up('md')]: {
      position: 'relative',
      zIndex: 1,
      minWidth: 440,
      maxWidth: 440,
    },
  },
  paperBorder: {
    borderRadius: theme.spacing(1),
  },
}));

export default useStyles;
