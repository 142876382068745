import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { captureException } from '@sentry/browser';

import * as S from '../components/form/EmbedForm.styles';
import SingleSignupForm from '../components/form/Form';
import { setEmbed } from '../state';
import { loadRecaptcha } from '../services';

const EmbedFormContainer = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    loadRecaptcha({ autoHideBadge: true }).catch((e) => captureException(e));
  }, []);

  useEffect(() => {
    dispatch(setEmbed(true));
  }, [dispatch]);

  return (
    <S.FormWrapper>
      <SingleSignupForm />
    </S.FormWrapper>
  );
};

export default EmbedFormContainer;
