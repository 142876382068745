import {
  Body,
  Box,
  MaterialUICore,
  tokens,
  axiomTokens,
} from '@iclinic/design-system';

const { spacing, color, border, shadow } = axiomTokens;

const { styled } = MaterialUICore;

type FeedbackBodyProps = {
  error?: string;
};

export const FeedbackWrapper = styled(Box)({
  position: 'absolute',
  width: 'fit-content',
  height: 'fit-content',
  right: 0,
  backgroundColor: color.background[1],
  bottom: `calc(100% + ${spacing['1']})`,
  padding: spacing['3'],
  zIndex: 1,
  borderRadius: border.radius.sm,
  border: `${border.width.xs} solid ${color.border.activated}`,
  boxShadow: shadow.level['1'],
  marginTop: spacing[4],

  '&:after': {
    content: '""',
    position: 'absolute',
    width: spacing['3'],
    height: spacing['3'],
    left: '50%',
    top: `calc(100% - 6px)`,
    marginLeft: `-6px`,
    border: `${border.width.xs} solid transparent`,
    borderBottomColor: color.border.activated,
    borderRightColor: color.border.activated,
    background: color.background['1'],
    borderTopLeftRadius: 2,
    transform: 'rotate(45deg)',
  },
});

export const PasswordFeedbackContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing['2'],
  marginTop: spacing['2'],
}));

export const FeedbackBody = styled(Body)(({ error }: FeedbackBodyProps) => ({
  color: error ? tokens.color.feedback.error.main : tokens.color.neutral[300],
  letterSpacing: '0.44px',
}));
