import { axiomTokens, MaterialUICore } from '@iclinic/design-system';

const { styled } = MaterialUICore;

const { spacing } = axiomTokens;

export const ContentList = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: spacing['6'],
  alignItems: 'center',
  flexWrap: 'wrap',

  [theme.breakpoints.up('sm')]: {
    flexWrap: 'nowrap',
  },
}));

export const SelectWrapper = styled('div')(({ theme }) => ({
  width: '100%',

  [theme.breakpoints.up('sm')]: {
    maxWidth: 282,
  },
}));
