import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import { Box, tokens } from '@iclinic/design-system';

import PasswordCreationFields from 'shared/components/PasswordCreationFields';
import { passwordValidation } from '../../../validationSchema';
import { passwordUIStateSelector } from '../../state/selectors';
import { SignupFormValues } from '../../types';
import * as S from './Form.styles';

const PasswordInput = () => {
  const { values } = useFormikContext<SignupFormValues>();
  const { disabled } = useSelector(passwordUIStateSelector);
  const requirements = passwordValidation(values.password);
  const [isFeedbackActive, toggleFeedback] = useState(false);

  const toggleFeedbackHandler = () => {
    toggleFeedback((currentState) => !currentState);
  };

  const feedBackComponent = (
    eightCharIcon: React.ReactNode,
    atLeastOneCharIcon: React.ReactNode,
    atLeastOneNumberIcon: React.ReactNode,
  ) => {
    if (!isFeedbackActive) {
      return null;
    }
    return (
      <S.PasswordFeedback mt={tokens.spacing.sm}>
        <S.FormFeedbackBody bold variant="xxs">
          A senha precisa ter:
        </S.FormFeedbackBody>
        <S.PasswordFeedbackContainer>
          <Box display="flex" alignItems="center">
            {eightCharIcon}
            <S.FormFeedbackBody variant="xxs">
              Ao menos 8 caracteres
            </S.FormFeedbackBody>
          </Box>
          <Box display="flex" alignItems="center">
            {atLeastOneCharIcon}
            <S.FormFeedbackBody variant="xxs">1 letra</S.FormFeedbackBody>
          </Box>
          <Box display="flex" alignItems="center">
            {atLeastOneNumberIcon}
            <S.FormFeedbackBody variant="xxs">1 número</S.FormFeedbackBody>
          </Box>
        </S.PasswordFeedbackContainer>
      </S.PasswordFeedback>
    );
  };

  return (
    <Box mt={tokens.spacing.sm} position="relative">
      <PasswordCreationFields
        requirements={requirements}
        disabled={disabled}
        customFeedback
        renderFeedback={feedBackComponent}
        onFocus={toggleFeedbackHandler}
        onBlur={toggleFeedbackHandler}
      />
    </Box>
  );
};

export default PasswordInput;
