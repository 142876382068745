import React from 'react';
import { useFormikContext } from 'formik';
import { MaterialUICore, Select } from '@iclinic/design-system';

import * as S from './PersonalizationTabPanel.styles';
import {
  timeSendOptions,
  reminderDaysAdvanceOptions,
} from '../../../constants';
import { FormValues } from './form.normalizer';
import { ScheduleButton } from './ScheduleButton';

const { MenuItem } = MaterialUICore;

export const ShippingConfiguration = () => {
  const formik = useFormikContext<FormValues>();

  const { handleChange } = formik;

  return (
    <>
      {formik.values.schedules.map((schedule, index) => (
        <S.FieldsContainer key={`${index.toString()}-${schedule.daysAdvance}`}>
          <Select
            id={`schedules[${index}].daysAdvance`}
            label="Antecedência do envio"
            name={`schedules[${index}].daysAdvance`}
            value={schedule.daysAdvance}
            onChange={handleChange}
          >
            {reminderDaysAdvanceOptions.map(({ text, value }) => (
              <MenuItem key={text} value={value}>
                {text}
              </MenuItem>
            ))}
          </Select>
          <Select
            id={`schedules[${index}].timeSend`}
            label="Hora do envio"
            name={`schedules[${index}].timeSend`}
            value={schedule.timeSend}
            onChange={handleChange}
          >
            {timeSendOptions.map(({ text, value }) => (
              <MenuItem key={value} value={value}>
                {text}
              </MenuItem>
            ))}
          </Select>

          <S.ButtonsBox>
            <ScheduleButton index={index} />
          </S.ButtonsBox>
        </S.FieldsContainer>
      ))}

      <S.ContentSubtitle variant="xs">
        <em>
          *Lembretes com cinco dias de antecedência para agendamentos de quintas
          e sextas-feiras serão enviados na sexta-feira anterior.
        </em>
        <br />
        <em>
          *Lembretes que aconteceriam aos finais de semana e feriados, serão
          enviadas sempre no dia útil anterior.
        </em>
      </S.ContentSubtitle>
    </>
  );
};
