export const UNKNOWN_ERROR_CODE = 'unknown';

const messagesByCode = {
  phone_null_tag_exist:
    'Telefone para contato é obrigatório quando usado a tag #linktelefoneparacontato na mensagem para o paciente.',
  tag_link_required:
    'Tag #linkdeconfirmação é obrigatória quando o tipo de mensagem é de confirmação de agenda.',
  template_already_exists:
    'Já existe uma configuração criada para esse médico.',
  unique_together_schedule:
    'Já existe uma configuração de horário com esta configuração.',
  time_schedule_exceeded:
    'Para a confirmação de agenda é possível a criação de apenas uma configuração de horário.',
  days_advanced_invalid: 'Valores inválidos para este tipo de template',
  hour_invalid: 'Os minutos devem ser múltiplos de 30.',
  hour_out_of_range: 'O horário deve estar entre 8:00 e 18:00.',
  [UNKNOWN_ERROR_CODE as string]:
    'Algum erro ocorreu, por favor tente novamente mais tarde!',
} as const;

export { messagesByCode };
