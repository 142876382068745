import { useEffect, useState } from 'react';

import { trackSignupStep } from '../trackSignupUtils';

export const useEmailFormTracking = ({
  email,
  emailError,
  hasEmailError,
}: {
  hasEmailError: boolean;
  emailError: string;
  email: string;
}) => {
  const [lastEmailError, setEmailLastError] = useState('');

  useEffect(() => {
    if (!hasEmailError) {
      setEmailLastError('');
      return;
    }

    if (hasEmailError && lastEmailError !== emailError) {
      trackSignupStep({
        name: 'user_email',
        stepNum: 1,
        stepValue: email || null,
        failureReason: emailError,
      });

      setEmailLastError(emailError);
    }
  }, [hasEmailError, emailError, email, lastEmailError]);
};

export const useCustomFormErrorTracking = ({
  name,
  stepNum,
  value,
  errorMessage,
  hasError,
}: {
  name: string;
  stepNum?: number;
  value?: string;
  errorMessage: string;
  hasError: boolean;
}) => {
  const [lastError, setLastError] = useState('');

  useEffect(() => {
    if (!hasError) {
      setLastError('');
      return;
    }

    if (hasError && lastError !== errorMessage) {
      trackSignupStep({
        name,
        stepNum,
        stepValue: value,
        failureReason: errorMessage,
      });

      setLastError(errorMessage);
    }
  }, [errorMessage, hasError, lastError, name, stepNum, value]);
};
