import React from 'react';
import { useFormikContext } from 'formik';
import { MaterialIcons, Typography, Box } from '@iclinic/design-system';

import PasswordField from '../PasswordField';
import { useStyles } from './styles';

const { ErrorRounded, CheckCircleRounded } = MaterialIcons;

interface FeedbackProps {
  customFeedback: boolean;
  renderFeedback?: (
    eightCharsIcon: React.ReactNode,
    atLeastOneCharIcon: React.ReactNode,
    atLeastOneNumberIcon: React.ReactNode,
  ) => React.ReactNode;
  requirements: {
    eightChars: boolean;
    atLeastOneChar: boolean;
    atLeastOneNumber: boolean;
  };
}

const PasswordFeedback = ({
  customFeedback,
  renderFeedback,
  requirements,
}: FeedbackProps) => {
  const classes = useStyles();

  const { eightChars, atLeastOneChar, atLeastOneNumber } = requirements;

  const successIcon = (testId: string) => (
    <CheckCircleRounded className={classes.success} data-testid={testId} />
  );
  const errorIcon = (testId: string) => (
    <ErrorRounded className={classes.error} data-testid={testId} />
  );

  const eightCharsIcon = eightChars
    ? successIcon('eightChars-check')
    : errorIcon('eightChars-fail');
  const atLeastOneCharIcon = atLeastOneChar
    ? successIcon('atLeastOneChar-check')
    : errorIcon('atLeastOneChar-fail');
  const atLeastOneNumberIcon = atLeastOneNumber
    ? successIcon('atLeastOneNumber-check')
    : errorIcon('atLeastOneNumber-fail');

  const customFeedbackComponent = () =>
    renderFeedback &&
    renderFeedback(eightCharsIcon, atLeastOneCharIcon, atLeastOneNumberIcon);

  if (customFeedback) {
    return customFeedbackComponent();
  }

  return (
    <Box mt={2} mb={1}>
      <Box className={classes.passwordCheckLine}>
        {eightCharsIcon}
        <Typography variant="body2" className={classes.passwordCheckText}>
          8 caracteres no mínimo
        </Typography>
      </Box>

      <Box className={classes.passwordCheckLine}>
        {atLeastOneCharIcon}
        <Typography variant="body2" className={classes.passwordCheckText}>
          Pelo menos uma letra
        </Typography>
      </Box>

      <Box className={classes.passwordCheckLine}>
        {atLeastOneNumberIcon}
        <Typography variant="body2" className={classes.passwordCheckText}>
          Pelo menos 1 número
        </Typography>
      </Box>
    </Box>
  );
};

interface Props {
  passwordLabel?: string;
  passwordPlaceHolder?: string;
  passwordConfirmation?: boolean;
  requirements: {
    eightChars: boolean;
    atLeastOneChar: boolean;
    atLeastOneNumber: boolean;
  };
  disabled?: boolean;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onVisibilityChange?: (visible: boolean) => void;
  customFeedback?: boolean;
  renderFeedback?: (
    eightCharsIcon: React.ReactNode,
    atLeastOneCharIcon: React.ReactNode,
    atLeastOneNumberIcon: React.ReactNode,
  ) => React.ReactNode;
}

export default ({
  passwordLabel,
  passwordPlaceHolder,
  passwordConfirmation = false,
  requirements,
  disabled = false,
  onBlur,
  onFocus,
  onVisibilityChange,
  customFeedback = false,
  renderFeedback,
}: Props) => {
  const { errors, touched, handleBlur, handleChange, values } =
    useFormikContext<any>();

  const passHelperText =
    touched.password && errors.password ? errors.password : null;
  const confimationHelperText =
    touched.password && errors.confirmPassword ? errors.confirmPassword : null;

  const handlePasswordBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    handleBlur(event);

    if (onBlur) onBlur(event);
  };

  const handlePasswordFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    if (onFocus) {
      onFocus(event);
    }
  };

  return (
    <>
      <Box mb={1}>
        <PasswordField
          autoComplete="off"
          fullWidth
          label={passwordLabel || 'Cadastre sua senha'}
          placeholder={passwordPlaceHolder || ''}
          id="password"
          name="password"
          error={!!(errors.password && touched.password)}
          helperText={passHelperText}
          onChange={handleChange}
          onBlur={handlePasswordBlur}
          value={values.password}
          data-ga="setup-password"
          disabled={disabled}
          onVisibilityChange={onVisibilityChange}
          onFocus={handlePasswordFocus}
        />
      </Box>

      {passwordConfirmation && (
        <PasswordField
          autoComplete="off"
          fullWidth
          label="Confirme sua senha"
          id="confirmPassword"
          name="confirmPassword"
          placeholder="Repita sua senha"
          error={!!(errors.confirmPassword && touched.confirmPassword)}
          helperText={confimationHelperText}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.confirmPassword}
          data-ga="setup-confirmPassword"
          disabled={disabled}
        />
      )}
      <PasswordFeedback
        customFeedback={customFeedback}
        renderFeedback={renderFeedback}
        requirements={requirements}
      />
    </>
  );
};
