import React from 'react';

import AuthContainer, {
  SingleColContainer,
} from '../../containers/AuthContainer';
import {
  lazyFeature,
  lazyReducer,
  lazySaga,
  injectAll,
} from 'shared/lazyFeature';
import SuspenseFallback from 'features/new-auth/components/SuspenseFallback';

const PasswordResetRequestForm = lazyFeature(
  () =>
    import(
      /* webpackChunkName: "password" */ '../components/PasswordResetRequestForm'
    ),
  injectAll(
    lazyReducer(
      'resetPasswordRequest',
      () => import(/* webpackChunkName: "password" */ '../state/index'),
    ),
    lazySaga(
      'resetPasswordRequestSaga',
      () => import(/* webpackChunkName: "password" */ '../state/sagas'),
    ),
  ),
);

export default () => (
  <AuthContainer>
    <SingleColContainer backTo="/new/usuarios/login">
      <React.Suspense fallback={<SuspenseFallback />}>
        <PasswordResetRequestForm />
      </React.Suspense>
    </SingleColContainer>
  </AuthContainer>
);
