import {
  Heading as DSHeading,
  MaterialUICore,
  axiomTokens,
} from '@iclinic/design-system';

const { styled } = MaterialUICore;

const { spacing, font } = axiomTokens;

export const CardContent = styled('div')({
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  letterSpacing: 0.44,
});

export const ButtonWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  marginTop: spacing['4'],
  position: 'relative',
  justifyContent: 'space-between',

  [theme.breakpoints.down(400)]: {
    marginTop: spacing['4'],
    flexDirection: 'column-reverse',
    gap: spacing['2'],

    '& button:nth-child(1)': {
      width: '100%',
    },
  },
}));

export const Heading = styled(DSHeading)(({ theme }) => ({
  fontSize: font.size.md,
  marginBottom: spacing['4'],

  [theme.breakpoints.down(400)]: {
    marginBottom: spacing['6'],
  },
}));
