import React, { useEffect, useState } from 'react';
import {
  Body,
  Box,
  Button,
  Checkbox,
  CircularProgress,
} from '@iclinic/design-system';
import { useDispatch, useSelector } from 'react-redux';

import history from 'routes/history';
import {
  CardWrapper,
  PageHeader,
  PageWrapper,
  SectionHeader,
} from '../../../components';
import { packages } from '../../../constants';
import {
  getBestProduct,
  getCurrentStep,
  getSelectedKinds,
  getSelectedPhysicians,
  isFetchingBestProduct,
} from '../../../state/messagePackage/checkout/selectors';
import { ModalSubscriptionSummary } from './ModalSubscriptionSummary';
import {
  fetchBestProductByPhysician,
  setCurrentStep,
  submitProductSelection,
} from '../../../state/messagePackage/checkout';
import { TemplateKind } from '../../../services/types';
import { ModalSuccessSubscription } from './ModalSuccessSubscription';
import { SuggestionPackageCard } from './SuggestionPackageCard';
import { PackageCard } from './PackageCard';
import { StepPhysician } from './StepPhysician';
import * as S from './PackageSelector.styles';

const PackageSelector = () => {
  const dispatch = useDispatch();
  const selectedPhysicians = useSelector(getSelectedPhysicians);
  const selectedKinds = useSelector(getSelectedKinds);
  const currentStep = useSelector(getCurrentStep);
  const isFetchingSuggestion = useSelector(isFetchingBestProduct);
  const bestProduct = useSelector(getBestProduct);
  const currentPhysician = selectedPhysicians[currentStep - 1];

  const [sendSelected, setSendSelected] =
    useState<TemplateKind[]>(selectedKinds);
  const suggestedPackage = packages.find(
    (pack) => pack.slug === bestProduct?.best_product,
  );
  const [selectedPackage, setSelectedPackage] = useState('');

  const onChangeSendHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = event.target;
    setSendSelected((prev) => {
      if (prev.includes(name as TemplateKind)) {
        return prev.filter((kind) => kind !== name);
      }

      return [...prev, name as TemplateKind];
    });
  };

  const onClickPackageHandler = (slug: string) => {
    setSelectedPackage(slug);
  };

  const onSubmit = () => {
    dispatch(
      submitProductSelection({
        id: currentPhysician.id,
        kinds: sendSelected,
        productSlug: selectedPackage,
      }),
    );
  };

  const onBackClickHandler = () => {
    if (currentStep > 1) {
      dispatch(setCurrentStep(currentStep - 1));
      return;
    }

    history.goBack();
  };

  useEffect(() => {
    if (currentPhysician) {
      dispatch(
        fetchBestProductByPhysician({
          kinds: sendSelected,
          physicianId: currentPhysician.id,
        }),
      );
    }
  }, [currentPhysician, dispatch, sendSelected]);

  useEffect(() => {
    if (suggestedPackage) {
      setSelectedPackage(suggestedPackage.slug);
    }
  }, [suggestedPackage]);

  const hasMultiplePhysicians = selectedPhysicians.length >= 2;
  const isLastPhysician = currentStep === selectedPhysicians.length;

  return (
    <>
      <PageWrapper>
        <PageHeader
          title="Pacote de mensagem"
          description="Encontre o plano de mensageria perfeito para você, comparando opções que se adequem às suas necessidades e orçamento."
        />

        <CardWrapper>
          {hasMultiplePhysicians ? (
            <StepPhysician
              physicianName={currentPhysician.profile_name}
              stepCount={selectedPhysicians.length}
              currentStep={currentStep}
            />
          ) : null}

          {isFetchingSuggestion ? (
            <CircularProgress />
          ) : (
            <>
              <S.ContentWithSuggestionWrapper>
                <S.SendWrapper>
                  <SectionHeader
                    title="Seleção de envio de comunicações"
                    description="Selecione as comunicações que deseja enviar de forma automática
              para seus pacientes:"
                  />

                  <S.CheckboxesWrapper>
                    <Checkbox
                      label="Lembrete de agenda"
                      name={TemplateKind.REMINDER}
                      checked={sendSelected.includes(TemplateKind.REMINDER)}
                      onChange={onChangeSendHandler}
                    />
                    <Checkbox
                      label="Confirmação de agenda"
                      name={TemplateKind.CONFIRMATION}
                      checked={sendSelected.includes(TemplateKind.CONFIRMATION)}
                      onChange={onChangeSendHandler}
                    />
                  </S.CheckboxesWrapper>
                </S.SendWrapper>
                <Box gridColumn="1" gridRow="2">
                  <SectionHeader
                    title="Volume de envios"
                    description={
                      !bestProduct?.average ? (
                        'Não há histórico de agendamentos. A sugestão de pacote é o iniciante.'
                      ) : (
                        <>
                          Tendo em conta os últimos 3 meses de agendamentos do
                          profissional selecionado e a comunicação escolhida,
                          você irá enviar, em média,{' '}
                          <strong>
                            {bestProduct.average} mensagens por mês.
                          </strong>
                        </>
                      )
                    }
                  />
                </Box>

                {suggestedPackage ? (
                  <SuggestionPackageCard
                    packageName={suggestedPackage.name}
                    sendQuantity={suggestedPackage.sendQuantity}
                    price={suggestedPackage.price}
                    slug={suggestedPackage.slug}
                    selected={selectedPackage === suggestedPackage.slug}
                    onClick={onClickPackageHandler}
                    hasConfirmation={sendSelected.includes(
                      TemplateKind.CONFIRMATION,
                    )}
                    hasReminder={sendSelected.includes(TemplateKind.REMINDER)}
                  />
                ) : null}
              </S.ContentWithSuggestionWrapper>

              <S.PackagesSectionWrapper>
                <Body variant="sm" bold>
                  Outros pacotes
                </Body>

                <S.PackageCardsWrapper>
                  {packages
                    .filter((pack) => pack.slug !== suggestedPackage?.slug)
                    .map(({ name, price, sendQuantity, slug }) => (
                      <PackageCard
                        key={slug}
                        packageName={name}
                        sendQuantity={sendQuantity}
                        price={price}
                        slug={slug}
                        selected={selectedPackage === slug}
                        onClick={onClickPackageHandler}
                      />
                    ))}
                </S.PackageCardsWrapper>
              </S.PackagesSectionWrapper>

              <S.FooterSectionWrapper>
                <Body variant="xxs">
                  <em>
                    *Ao fim do pacote contratado, será cobrado o envio excedente
                    unitário por <strong>R$0,31</strong>
                  </em>
                </Body>

                <S.ActionsWrapper>
                  <Button color="transparent" onClick={onBackClickHandler}>
                    Voltar
                  </Button>
                  <Button onClick={onSubmit}>
                    {isLastPhysician ? 'Contratar pacote' : 'Próximo'}
                  </Button>
                </S.ActionsWrapper>
              </S.FooterSectionWrapper>
            </>
          )}
        </CardWrapper>
      </PageWrapper>

      <ModalSubscriptionSummary />
      <ModalSuccessSubscription />
    </>
  );
};

export { PackageSelector };
