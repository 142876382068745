import React, { useEffect, useState } from 'react';
import {
  MaterialUICore,
  Switch,
  CircularProgress,
  Box,
} from '@iclinic/design-system';
import { useDispatch, useSelector } from 'react-redux';

import { getContractorsData } from '../../../../state/messagePackage/details/selectors';
import {
  fetchContractorTemplateDetails,
  setContractorTemplateEvents,
} from '../../../../state/messagePackage/details';
import { ContractorTemplate } from '../../../../services/types';
import { PaginationConfiguration } from './PaginationConfigurationTable';
import * as S from './ConfigurationTable.styles';
import SnackbarMessages from './SnackbarMessages';

const { Table, TableBody, TableCell, TableHead } = MaterialUICore;
const FIRST_PAGE = 1;

const ConfigurationTable = () => {
  const dispatch = useDispatch();
  const { isFetchingContractors, meta, contractorTemplates } =
    useSelector(getContractorsData);
  const [contractors, setContractors] = useState<ContractorTemplate[]>([]);
  const [page, setPage] = useState(FIRST_PAGE);
  const limit = 10;
  const pagesCount = meta?.total_count
    ? Math.ceil(meta.total_count / limit)
    : 0;

  useEffect(() => {
    dispatch(fetchContractorTemplateDetails({ page, limit }));
  }, [dispatch, page]);

  useEffect(() => {
    if (contractorTemplates?.length) {
      setContractors(contractorTemplates);
    }
  }, [contractorTemplates]);

  const onToggleFunctionality = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
    currentContractor: ContractorTemplate,
  ) => {
    const { name } = event.target;
    setContractors((prev) =>
      prev.map((contractor) => {
        if (contractor.physician_id === currentContractor.physician_id) {
          return {
            ...contractor,
            [name]: checked,
          };
        }

        return contractor;
      }),
    );
    dispatch(
      setContractorTemplateEvents({
        physicianId: currentContractor.physician_id,
        confirmation_event: currentContractor.confirmation_event,
        event_reminder: currentContractor.event_reminder,
        [name]: checked,
      }),
    );
  };

  const onChangePageHandler = (newPage: number) => {
    setPage(newPage);
  };

  if (isFetchingContractors) {
    return (
      <Box justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <S.ContainerTable>
        <Table>
          <TableHead>
            <S.TableRow className="header-row">
              <TableCell align="left">Profissional de saúde</TableCell>
              <TableCell align="left">Pacote | Envios</TableCell>
              <S.SmallTableCell align="left">
                Confirmação agenda
              </S.SmallTableCell>
              <S.SmallTableCell align="left">Lembrete agenda</S.SmallTableCell>
            </S.TableRow>
          </TableHead>
          <TableBody>
            {contractors.map((contractor) => (
              <S.TableRow key={contractor.physician_id.toString()}>
                <TableCell>{contractor.profile_name}</TableCell>
                <TableCell>
                  {contractor.product} - {contractor.franchise} envios
                </TableCell>
                <S.SmallTableCell>
                  <Switch
                    id="confirmation_event"
                    name="confirmation_event"
                    label={contractor.confirmation_event ? 'Ativo' : 'Inativo'}
                    checked={contractor.confirmation_event}
                    onChange={(event, checked) =>
                      onToggleFunctionality(event, checked, contractor)
                    }
                  />
                </S.SmallTableCell>
                <S.SmallTableCell>
                  <Switch
                    id="event_reminder"
                    name="event_reminder"
                    label={contractor.event_reminder ? 'Ativo' : 'Inativo'}
                    checked={contractor.event_reminder}
                    onChange={(event, checked) =>
                      onToggleFunctionality(event, checked, contractor)
                    }
                  />
                </S.SmallTableCell>
              </S.TableRow>
            ))}
          </TableBody>
        </Table>
      </S.ContainerTable>

      {meta?.total_count ? (
        <PaginationConfiguration
          count={pagesCount}
          page={page}
          onChange={onChangePageHandler}
          hidePrev={page === FIRST_PAGE}
          hideNext={page === pagesCount}
        />
      ) : null}

      <SnackbarMessages />
    </>
  );
};

export { ConfigurationTable };
