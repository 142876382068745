import EventEmitter from 'eventemitter3';

export const emmiter = new EventEmitter();

export const pubSubMiddleware = (store) => (next) => (action) => {
  const result = next(action);
  emmiter.emit(action.type, action, store);
  return result;
};

export const on = (...args) => emmiter.on(...args);

export const off = (...args) => emmiter.off(...args);
