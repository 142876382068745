import { Box, MaterialUICore, axiomTokens } from '@iclinic/design-system';

const { styled } = MaterialUICore;
const { border, spacing } = axiomTokens;

export const Root = styled('div')({
  display: 'flex',
  alignItems: 'center',
  columnGap: spacing[2],
});

export const Bullet = styled(Box)({
  borderRadius: border.radius.circular,
  width: 12,
  height: 12,
});
