import React from 'react';
import { MaterialUICore, axiomTokens } from '@iclinic/design-system';

import { STEPS_NUMBER } from '../../constants';

const { styled } = MaterialUICore;
const { color, spacing } = axiomTokens;

type HTMLElementProps<T> = React.DetailedHTMLProps<React.HTMLAttributes<T>, T>;
type ContainerProps = {
  visible: boolean;
} & HTMLElementProps<HTMLDivElement>;

export const Root = styled('div')(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  left: 0,
  width: '100%',
  zIndex: 1,
  [theme.breakpoints.up('md')]: {
    position: 'static',
  },
}));

export const ProgressBarContainer = styled(
  ({ visible, ...rest }: ContainerProps) => <div {...rest} />,
)({
  height: spacing[1],
  position: 'relative',
  background: color.surface.accent.default,
  width: '100%',
  overflow: 'hidden',
  opacity: ({ visible }: ContainerProps) => (visible ? '1' : '0'),
  transition: 'opacity 1s',
  marginTop: spacing[2],
});

const getStepPercent = (step: number) => (step * 100) / STEPS_NUMBER;

type BarProps = {
  step: number;
} & HTMLElementProps<HTMLDivElement>;

export const Bar = styled(({ step, ...rest }: BarProps) => <div {...rest} />)({
  width: ({ step }: BarProps) => `${getStepPercent(step)}%`,
  height: '100%',
  background: '#D40054',
  transition: 'width 1s',
});
