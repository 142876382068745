import { Profession, SignupSteps } from './state/types';

export const getNextStepFromJobByProfession = (profession: Profession) => {
  if (profession === Profession.Doctor) {
    return SignupSteps.Specialty;
  }

  return SignupSteps.ProfessionCount;
};

export const getPreviousStepFromSpecialtyByProfession = (
  profession: Profession = Profession.Doctor,
) => {
  if (profession === Profession.AnotherHealthProfessional) {
    return SignupSteps.ProfessionCount;
  }

  return SignupSteps.Job;
};
