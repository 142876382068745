import { axiomTokens, Button, MaterialUICore } from '@iclinic/design-system';

const { styled } = MaterialUICore;
const { color } = axiomTokens;

const StyledGoogleSignInButton = styled(Button)({
  border: `1px solid ${color.border.subtle}`,
  width: '100%',
  height: '48px',
  '&.Mui-disabled': {
    opacity: 0.6,
    pointerEvents: 'none',
  },
});
export default StyledGoogleSignInButton;
