import qs from 'qs';
import isEmpty from 'lodash.isempty';

import { captureException } from 'shared/utils/handlerErrors';
import { apiV3 } from './api';
import { extractPrescriptionUserToken, checkDrugKind } from './utils';

const basePath = '/prescriptions/';

export const createPrescription = (
  eventId,
  physicianId,
  patientId,
  clinicId,
) => {
  const bodyRequest = {
    event: {
      id: eventId,
    },
    physician: {
      id: physicianId,
    },
    patient: {
      id: patientId,
    },
    clinic: {
      id: clinicId,
    },
  };

  return apiV3.post(basePath, bodyRequest);
};

export const fetchPrescriptions = (params = null) => {
  if (!params) {
    return apiV3.get(basePath);
  }

  const queryStringParams = qs.stringify(params, { allowDots: true });
  return apiV3.get(`${basePath}?${queryStringParams}`);
};

export const updatePrescription = (
  prescriptionId,
  allDrugs,
  prescriptionMemedId,
) => {
  const drugs = allDrugs.map((drug) => {
    const {
      nome: name,
      descricao: description,
      id,
      posologia: posology,
      quantidade: quantity,
      composicao: composition,
      tipo,
    } = drug;
    return {
      integration: {
        id,
        name: 'memed',
      },
      name: name || null,
      description: description || null,
      posology: posology || null,
      quantity: quantity || null,
      composition: composition || null,
      drug_kind: checkDrugKind(tipo),
    };
  });

  let bodyRequest = {
    drugs,
  };

  if (prescriptionMemedId) {
    bodyRequest = {
      ...bodyRequest,
      integration: {
        id: prescriptionMemedId,
        name: 'memed',
      },
    };
  }

  return apiV3.patch(`${basePath}${prescriptionId}/`, bodyRequest);
};

export const fetchSettings = (physicianId, clinicId) =>
  new Promise((resolve, reject) => {
    const params = qs.stringify(
      {
        physician: {
          id: physicianId,
        },
        clinic: {
          id: clinicId,
        },
      },
      { allowDots: true },
    );

    apiV3
      .get(`${basePath}settings/?${params}`)
      .then(({ getResponseData }) => {
        const payload = getResponseData();
        if (isEmpty(payload)) {
          reject();
        }
        resolve(extractPrescriptionUserToken(payload));
      })
      .catch((error) => {
        captureException(error);
        reject(error);
      });
  });

export const markAsRemoved = (prescriptionMemedId) =>
  apiV3.delete(basePath, {
    params: { 'integration.id': prescriptionMemedId },
  });
