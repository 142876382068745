import React from 'react';
import { Body, Heading } from '@iclinic/design-system';

import { CardWrapper } from '../../../../components/CardWrapper';
import * as S from './SettingsTabContent.styles';
import { ConfigurationTable } from './ConfigurationTable';

const SettingsTabContent = () => (
  <CardWrapper>
    <S.TitleAndSubtitleWrapper>
      <Heading variant="sm">Acesso à funcionalidades</Heading>

      <Body variant="xs">
        Gerencie os profissionais e acesso às funcionalidades contratadas.
      </Body>
    </S.TitleAndSubtitleWrapper>

    <ConfigurationTable />
  </CardWrapper>
);

export { SettingsTabContent };
