import { IStoreState } from 'state/rootReducer';

export const getSurveyEvents = ({ survey }: IStoreState) =>
  survey.surveyFilters.surveyEvents;

export const getCountSurveyEvents = ({ survey }: IStoreState) =>
  survey.surveyFilters.countSurveyEvents;

export const getSurveyPhysicians = ({ survey }: IStoreState) =>
  survey.surveyFilters.physicians;

export const getEventsSurveyStatus = ({ survey }: IStoreState) =>
  survey.surveyFilters.status;
