import React from 'react';
import { Switch, Route, RouteComponentProps } from 'react-router-dom';

import SignupSuccess from '../../components/Success';
import FormRoutes from './FormRoutes';

const StepSignupRoutes = ({ match }: RouteComponentProps) => (
  <Switch>
    <Route
      exact
      path={`${match.path}/iniciar-teste`}
      component={SignupSuccess}
    />
    <Route component={FormRoutes} />
  </Switch>
);

export default StepSignupRoutes;
