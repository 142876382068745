const HEX_RADIX = 16;

export const addOpacityToHexColor = (color: string, opacity: number) => {
  const hexRegex = /^#[0-9A-Fa-f]{6}$/;
  if (!hexRegex.test(color)) {
    throw new Error(
      "Invalid HEX value. Use a valid 6 digit HEX value, for example '#FF0000' for red.",
    );
  }

  const hexOpacityValue = Math.round(
    Math.min(Math.max(opacity || 1, 0), 1) * 255,
  ).toString(HEX_RADIX);

  const hexOpacity =
    hexOpacityValue.length === 1
      ? hexOpacityValue.padStart(2, '0')
      : hexOpacityValue;

  return color + hexOpacity.toUpperCase();
};

const MIN_NPS_VALUE = -100;
const MAX_NPS_VALUE = 100;

function checkItsBetween(value: number, min: number, max: number) {
  return value >= min && value <= max;
}

export const getScorePercentage = (value?: number) => {
  if (
    value === null ||
    value === undefined ||
    !checkItsBetween(value, MIN_NPS_VALUE, MAX_NPS_VALUE)
  )
    return null;

  return ((value - MIN_NPS_VALUE) / (MAX_NPS_VALUE - MIN_NPS_VALUE)) * 100;
};
