import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getActiveCampaign } from 'state/campaignManager/actions';
import {
  getCampaigns,
  getResource,
  getIsFetching,
} from 'state/campaignManager/selectors';
import { Filters } from 'state/campaignManager/types';
import useWidthUpdater from 'shared/hooks/useWidthUpdater';
import { CampaignImageBackground } from '../components';
import { MaterialUICore } from '@iclinic/design-system';

export default function LoginCampaignImageBackground(): JSX.Element {
  const mobileWidth = MaterialUICore.useTheme().breakpoints.width('md');
  const dispatch = useDispatch();
  const isMobile = useWidthUpdater(mobileWidth);
  const campaignsImage = useSelector((state) =>
    getCampaigns('login-img', Filters.images)(state),
  );

  const campaignsImageFallback = useSelector((state) =>
    getCampaigns('login-img-fallback', Filters.images)(state),
  );

  const campaignsText = useSelector((state) =>
    getCampaigns('login-text', Filters.texts)(state),
  );
  const campaignsLink = useSelector((state) =>
    getCampaigns('login-link', Filters.texts)(state),
  );
  const isFetching = useSelector((state) => getIsFetching(state));

  useEffect((): void => {
    if (!isMobile) dispatch(getActiveCampaign());
  }, [dispatch, isMobile]);

  const checkCampaigns = campaignsImage?.length > 0;

  const checkCampaignsFallback = campaignsImageFallback?.length > 0;

  if (checkCampaigns && checkCampaignsFallback) {
    const resourceValueText = getResource(campaignsText);
    const resourceValueLink = getResource(campaignsLink);

    return (
      <CampaignImageBackground
        image={campaignsImage[0].image}
        fallback={campaignsImageFallback[0].image}
        text={resourceValueText}
        link={resourceValueLink}
        loading={isFetching}
      />
    );
  }

  return <CampaignImageBackground loading={isFetching} />;
}
