import {
  Box,
  MaterialUICore,
  tokens,
  MaterialIcons,
  Grid,
  Body,
} from '@iclinic/design-system';

const { styled } = MaterialUICore;
const { Check, ChatBubbleOutlineRounded } = MaterialIcons;

export const SignupInfoContainer = styled(Box)(() => ({
  display: 'block',
  position: 'relative',
  maxWidth: 564,
}));

export const SignupInfoList = styled('ul')(() => ({
  paddingBottom: tokens.spacing.md,
  marginBottom: tokens.spacing.md,
  borderBottom: `2px solid ${tokens.color.neutral['100']}`,
}));

export const SignupInfoItem = styled('li')(() => ({
  display: 'flex',
  gap: tokens.spacing.md,

  '& ~ *': {
    marginTop: tokens.spacing.sm,
  },
}));

export const SignupBody = styled(Body)(() => ({
  color: tokens.color.neutral['900'],
  letterSpacing: '0.44px',
}));

export const CheckIcon = styled(Check)(() => ({
  color: tokens.color.primary.light,
}));

export const ChatBubble = styled(ChatBubbleOutlineRounded)(() => ({
  color: tokens.color.primary.light,
}));

export const FooterGrid = styled(Grid)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: tokens.spacing.sm,
  maxWidth: 536,
}));
