/* eslint no-underscore-dangle: ["error", { "allow": ["", "_error"] }] */
import { ActionType } from '@types';

import * as types from './constants';
import { AuthState } from './types';
import * as actions from './actions';

export const initialState: AuthState = {
  authorized: false,
  token: null,
  loginWithGoogle: {
    start: false,
    error: false,
    errorMessage: '',
  },
  errors: [],
};

type AuthActions = ActionType<typeof actions>;

// eslint-disable-next-line @typescript-eslint/default-param-last
const Auth = (state = initialState, action: AuthActions) => {
  switch (action.type) {
    case types.AUTHORIZATION_AUTHORIZE:
      return {
        ...state,
        authorized: true,
      };
    case types.AUTHORIZATION_UNAUTHORIZE:
      return {
        ...state,
        authorized: false,
        token: null,
      };
    case types.AUTHORIZATION_SET_TOKEN:
      return {
        ...state,
        token: action.payload.token,
      };
    case types.AUTHORIZATION_REMOVE_TOKEN:
      return {
        ...state,
        token: null,
      };
    case types.LOGIN_WITH_GOOGLE_AUTH2_REQUEST:
      return {
        ...state,
        loginWithGoogle: {
          start: true,
          error: false,
        },
        token: action.payload.token,
      };
    case types.LOGIN_WITH_GOOGLE_AUTH2_SUCCESS:
      return {
        ...state,
        loginWithGoogle: {
          start: false,
          error: false,
        },
      };
    case types.LOGIN_WITH_GOOGLE_AUTH2_FAILURE:
      return {
        ...state,
        loginWithGoogle: {
          start: false,
          error: true,
          errorMessage: action.payload._error,
        },
      };
    case types.LOGIN_WITH_EMAIL_FAILURE:
      return {
        ...state,
        errors: action.payload,
      };
    case types.CLEAR_ERRORS:
      return {
        ...state,
        errors: [],
      };
    default:
      return state;
  }
};

export default Auth;
