import { RequestStatus } from 'shared/constants/State';
import { StoreState } from 'state/rootReducerLogin';

export const isFetchingSurveyInfo = ({ survey }: StoreState) =>
  survey.surveyPatients.fetcherStatus === RequestStatus.Pending;

export const getSurveyInfoData = ({ survey }: StoreState) =>
  survey.surveyPatients.surveyInfoData;

export const isSubmitingSurveyAnswer = ({ survey }: StoreState) =>
  survey.surveyPatients.submitStatus === RequestStatus.Pending;
