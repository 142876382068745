import { init as apmRumInit, apm } from '@elastic/apm-rum';

export const getOptions = () => ({
  serverUrl: process.env.ELASTIC_SERVER_URL,
  environment: process.env.ELASTIC_ENVIRONMENT!,
  serviceVersion: process.env.APP_VERSION!,
  serviceName: process.env.ELASTIC_SERVICE_NAME!,
  flushInterval: 1000,
  ignoreTransactions: [
    /com\.snowplowanalytics\.snowplow*/,
    /collect*/,
    /unip*/,
    /1559359\/log*/,
    /ads\/ga-audiences*/,
  ],
});

export const initRumTracker = () => {
  const elasticRumConfig = getOptions();
  if (!elasticRumConfig.serverUrl) {
    return;
  }
  apmRumInit(elasticRumConfig);
};

export const setUserContext = (userContext: UserObject) => {
  apm.setUserContext(userContext);
};
