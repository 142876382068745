export const splitPath = (name) => {
  if (name.includes('/')) {
    return name.split('/');
  }

  if (name.includes('.')) {
    return name.split('.');
  }

  return [name];
};

export const resolvePath = (list, name) => {
  const keys = splitPath(name);

  return keys.reduce((acc, key) => {
    if (!acc[key]) {
      throw new Error(`Cannot resolve path "${name}" from path list`);
    }
    return acc[key];
  }, list);
};

export const replaceVariable = (path, name, value) => {
  const regex = new RegExp(`/(:${name})/?`, 'gi');

  return path.replace(regex, `/${value}/`);
};

export const resolveVariables = (path, data) => (
  Object.keys(data).reduce(
    (resolvedPath, name) => replaceVariable(resolvedPath, name, data[name]),
    path,
  ));

export const resolve = (list, name, data = {}) => {
  const path = resolvePath(list, name);
  return resolveVariables(path, data);
};
