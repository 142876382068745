import ReactDOM from 'react-dom';

import Index from './routes/Root';
import createRender from './migration/render';
import {
  sentryEnvironment,
  sentryDsn,
  sentryRelease,
} from './getSentryOptions';
import history from 'routes/history';
import configureStore from './state/configureStoreWebapp';
import { enableTrackOutOfTheBoxEvents } from 'shared/utils/trackEvents';
import { init, setTag } from 'shared/sentryClient';
import { initRumTracker } from 'shared/rumTracker';

enableTrackOutOfTheBoxEvents();

init(sentryEnvironment, sentryDsn, sentryRelease!);
setTag('origem_chamada', 'apartado');

initRumTracker();

const store = configureStore();

const webappRenderRoot = document.getElementById('webapp-root');

if (webappRenderRoot) {
  // renders in new webapp
  createRender(store, ReactDOM.render, history)(Index, webappRenderRoot);
}
