// External
import { createSelector } from 'reselect';

// Internal
import { StoreState } from 'state/rootReducer';
import { Filters, Component, Text } from './types';

export const stateCampaigns = (state: StoreState) =>
  state.campaignManager.campaigns;
export const isFetching = (state: StoreState) =>
  state.campaignManager.isFetching;

export const getCampaigns = (slug: string, filter: Filters) =>
  createSelector([stateCampaigns], (campaigns) => {
    const components: Component[] = campaigns.components.reduce(
      (acc, component: Component) => acc.concat(component[filter]),
      [],
    );

    return components.filter((component) => component.slug === slug);
  });

export const getAllCampaigns = ({ campaignManager }: StoreState) =>
  campaignManager.campaigns;

export const getResource = (array: Text[]) => {
  const resource = array?.length > 0 ? array[0] : { resource_value: '' };
  return resource.resource_value;
};

export const getIsFetching = createSelector(
  [isFetching],
  (isLoading) => isLoading,
);
