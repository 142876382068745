import React from 'react';
import { Body } from '@iclinic/design-system';

import * as S from './Rating.styles';
import { generateArrayByLength } from 'shared/utils/arrays';

interface RatingProps {
  value?: number;
  max: number;
  startInZero?: boolean;
  onRatingClick?: (ratingValue: number) => void;
}

export default function Rating({
  value,
  max,
  startInZero = false,
  onRatingClick,
}: RatingProps) {
  const length = startInZero ? max + 1 : max;
  const ratingCount = generateArrayByLength(length).map((count: number) =>
    startInZero ? count : count + 1,
  );

  const onClickHandler = (ratingValue: number) => {
    if (!onRatingClick) return;

    onRatingClick(ratingValue);
  };

  return (
    <S.RatingContainer>
      {ratingCount.map((ratingValue) => (
        <S.RatingButton
          key={ratingValue.toString()}
          onClick={() => onClickHandler(ratingValue)}
          className={value === ratingValue ? 'selected' : ''}
        >
          <Body variant="xs">{ratingValue.toString()}</Body>
        </S.RatingButton>
      ))}
    </S.RatingContainer>
  );
}
