// wrapper from JSON.parse to support "undefined" value
function parseJSON<Value>(
  value: string | null,
  initialValue: Value,
): Value | undefined {
  try {
    return value === 'undefined' ? undefined : JSON.parse(value ?? '');
  } catch {
    return initialValue;
  }
}

export function readLocalStorage<Value>(
  key: string,
  initialValue: Value,
): Value {
  if (typeof window === 'undefined') {
    return initialValue;
  }
  try {
    const item = window.localStorage.getItem(key);

    return item ? (parseJSON(item, initialValue) as Value) : initialValue;
  } catch (error) {
    return initialValue;
  }
}
