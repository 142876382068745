import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Body,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogHeader,
  Heading,
} from '@iclinic/design-system';

import {
  submitSubscription,
  toggleOpenSummary,
} from '../../../state/messagePackage/checkout';
import {
  getOpenSummary,
  getSubscriptionSummary,
  isFetchingSubscriptionSummary,
  isSubmittingSubscription,
} from '../../../state/messagePackage/checkout/selectors';
import * as S from './ModalSubscriptionSummary.styles';
import { formatCurrency } from 'shared/utils/strings';

interface PaymentDescriptionProps {
  description: string;
  value: string;
  promotionValue?: string;
}

const PaymentDescription = ({
  description,
  value,
  promotionValue,
}: PaymentDescriptionProps) => (
  <Box display="flex" alignItems="center" justifyContent="space-between">
    <Body variant="sm">{description}</Body>

    <Body variant="sm">
      {promotionValue ? (
        <>
          <S.ScratchedText>{formatCurrency(value)}</S.ScratchedText>

          {formatCurrency(promotionValue)}
        </>
      ) : (
        formatCurrency(value)
      )}
    </Body>
  </Box>
);

const ModalSubscriptionSummary = () => {
  const dispatch = useDispatch();
  const openSummary = useSelector(getOpenSummary);
  const isFetchingSummary = useSelector(isFetchingSubscriptionSummary);
  const subscriptionSummary = useSelector(getSubscriptionSummary);
  const isSubmittingSubscriptionUpgrade = useSelector(isSubmittingSubscription);

  const onClose = useCallback(() => {
    dispatch(toggleOpenSummary());
  }, [dispatch]);

  const onSubscriptionUpgradeClickHandler = useCallback(() => {
    dispatch(submitSubscription());
  }, [dispatch]);

  return (
    <Dialog open={openSummary} onClose={onClose} fullWidth maxWidth="md">
      <DialogHeader title="Resumo da assinatura" onClose={onClose} />

      <DialogContent>
        {isFetchingSummary ? (
          <Box display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Heading variant="xs">Antiga assinatura iClinic</Heading>

            <S.ListSubscriptionsWrapper>
              {subscriptionSummary?.currentSubscription?.length
                ? subscriptionSummary.currentSubscription.map(
                    ({ text, totalValue, promotionValue }) => (
                      <PaymentDescription
                        key={text}
                        description={text}
                        value={totalValue}
                        promotionValue={promotionValue}
                      />
                    ),
                  )
                : null}
            </S.ListSubscriptionsWrapper>

            <Heading variant="xs">Nova assinatura iClinic</Heading>

            <S.ListSubscriptionsWrapper>
              {subscriptionSummary?.newSubscription?.length
                ? subscriptionSummary.newSubscription.map(
                    ({ text, totalValue, promotionValue }) => (
                      <PaymentDescription
                        key={text}
                        description={text}
                        value={totalValue}
                        promotionValue={promotionValue}
                      />
                    ),
                  )
                : null}
            </S.ListSubscriptionsWrapper>

            <S.Divider />

            <S.SubtotalWrapper>
              <Body variant="sm">Subtotal</Body>
              <Body variant="sm" bold>
                {formatCurrency(subscriptionSummary?.total)}
              </Body>
            </S.SubtotalWrapper>

            <Heading variant="xs">Dados de pagamento</Heading>

            {subscriptionSummary?.paymentInfo.paymentSlip ? (
              <Body variant="sm">Boleto</Body>
            ) : (
              <Body variant="sm">
                {subscriptionSummary?.paymentInfo.card?.flag || '-'} ****{' '}
                {subscriptionSummary?.paymentInfo.card?.number || '-'}{' '}
              </Body>
            )}
          </>
        )}
      </DialogContent>

      <DialogActions>
        <Button
          isLoading={isSubmittingSubscriptionUpgrade}
          onClick={onSubscriptionUpgradeClickHandler}
        >
          Contratar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { ModalSubscriptionSummary };
