import React from 'react';
import { Pagination } from '@iclinic/design-system';

import * as S from './TablePagination.styles';

type Props = {
  pageNumber: number;
  pageCount: number;
  onChange: (name: string, value: number) => void;
};

export const TablePagination = ({ pageCount, onChange, pageNumber }: Props) => {
  const onPageChangeHandler = (
    _e: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    onChange('page', value);
  };

  if (pageCount <= 1) {
    return null;
  }

  return (
    <S.PaginationWrapper>
      <Pagination
        page={pageNumber}
        count={pageCount}
        onChange={onPageChangeHandler}
      />
    </S.PaginationWrapper>
  );
};
