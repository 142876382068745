import { axiomTokens, MaterialUICore } from '@iclinic/design-system';

const { styled } = MaterialUICore;

const { border, spacing, color } = axiomTokens;

export const CardMain = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  minHeight: '100vh',
  background: color.background[1],

  [theme.breakpoints.up(700)]: {
    background: color.background[2],
  },
}));

export const CardContainerDetails = styled('div')(({ theme }) => ({
  display: 'flex',
  padding: spacing['8'],
  maxWidth: 958,
  margin: '0 auto',

  [theme.breakpoints.down(500)]: {
    padding: 0,
    width: '100%',
    alignItems: 'center',
    flexDirection: 'column',
  },

  [theme.breakpoints.down(900)]: {
    flexWrap: 'wrap',
  },

  [theme.breakpoints.up(700)]: {
    alignItems: 'flex-start',
    gap: spacing['8'],
  },
}));

export const Card = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  padding: spacing['8'],
  background: color.background['1'],

  [theme.breakpoints.up(700)]: {
    borderRadius: border.radius.sm,
    border: `${border.width.xs} solid ${color.border.subtle}`,
  },
}));

export const LoaderWrapper = styled('div')({
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});
