import moment from 'moment';

import daysOfTheWeek from 'shared/constants/daysOfWeeks';
import monthsYear from 'shared/constants/monthsYear';

export const BRAZIL = 'DD/MM/YYYY';
export const USA = 'YYYY-MM-DD';

export const formatDate = (
  date: string,
  fromDateFormat = USA,
  toDateFormat = BRAZIL,
): string => {
  if (!date) throw new Error('Date param is required');

  return moment(date, fromDateFormat).format(toDateFormat);
};

export const formatDateWithDayOfTheWeek = (
  fullDateTimeString: string,
): string => {
  const date = new Date(fullDateTimeString);

  const dayOfWeek = daysOfTheWeek[date?.getDay()];
  const stringDate = `${date?.getDate()} de ${
    monthsYear[date?.getUTCMonth()]
  } de ${date?.getFullYear()}`;
  return `${dayOfWeek}, ${stringDate}`;
};

export const extractTime = (fullDateTimeString: string): string => {
  const time = new Date(fullDateTimeString);
  return `${time.getHours() < 10 ? '0' : ''}${time.getHours()}:${
    time.getMinutes() < 10 ? '0' : ''
  }${time.getMinutes()}`;
};

export const formatTime = (time: string): string => {
  return time
    .split(':')
    .slice(0, 2)
    .join(':');
};

const DAYS_PER_YEAR = 365;
const DAYS_PER_MONTH = 30;
const MINIMUM = 1;

export const getFormatedStringFromDays = (numberOfDays: number): string => {
  const years = Math.floor(numberOfDays / DAYS_PER_YEAR);
  const months = Math.floor((numberOfDays % DAYS_PER_YEAR) / DAYS_PER_MONTH);
  const days = Math.floor((numberOfDays % DAYS_PER_YEAR) % DAYS_PER_MONTH);

  let yearsDisplay = '';
  if (years > 0) {
    yearsDisplay = years === MINIMUM ? `${years} ano` : `${years} anos`;
  }

  let monthsDisplay = '';
  if (months > 0) {
    monthsDisplay = months === MINIMUM ? `${months} mês ` : `${months} meses`;
  }

  let daysDisplay = '';
  if (days > 0) {
    daysDisplay = days === MINIMUM ? `${days} dia` : `${days} dias`;
  }

  return (
    yearsDisplay +
    (yearsDisplay ? ` ${monthsDisplay}` : monthsDisplay) +
    ((yearsDisplay || monthsDisplay) && daysDisplay
      ? ` e ${daysDisplay}`
      : daysDisplay)
  );
};

export const isValidDate = (d: Date) => {
  return d instanceof Date && !Number.isNaN(Number(d));
};

/**
 * @returns date string in YYYY-MM-DDTHH:MM:SS.ML-HH:00
 */
export const formatDateToIsoStandard = (date: Date): string => {
  const currentTimeZoneOffsetInHours = date.getTimezoneOffset() / 60;
  let isoDate = `${date.getFullYear().toString()}-`;
  isoDate += `${(date.getMonth() + 1).toString().padStart(2, '0')}-`;
  isoDate += `${date
    .getDate()
    .toString()
    .padStart(2, '0')}T`;
  isoDate += `${date
    .getHours()
    .toString()
    .padStart(2, '0')}:`;
  isoDate += `${date
    .getMinutes()
    .toString()
    .padStart(2, '0')}:`;
  isoDate += `${date
    .getSeconds()
    .toString()
    .padStart(2, '0')}.`;
  isoDate += `${date
    .getMilliseconds()
    .toString()
    .padStart(2, '0')}-`;
  isoDate += `${currentTimeZoneOffsetInHours.toString().padStart(2, '0')}:00`;

  return isoDate;
};
