import { ApiErrors } from '@types';
import { errorMessages, GENERIC_ERROR_MESSAGE } from '../constants/errorMessages';

export const getErrorMessage = (errorCode: string) => {
  const errorMessageMapped = errorMessages[errorCode];
  return errorMessageMapped || null;
};

export const emitErrors = (
  errorsList: ApiErrors[],
  customMessage: string = '',
): ApiErrors[] => errorsList.map((error: ApiErrors) => ({
  ...error,
  message: customMessage || getErrorMessage(error.code) || error.message || GENERIC_ERROR_MESSAGE,
}));
