import {
  Grid,
  MaterialUICore,
  axiomTokens,
  TextField as TextFieldDs,
} from '@iclinic/design-system';

const { styled } = MaterialUICore;
const { spacing } = axiomTokens;

export const SingleSignupGrid = styled(Grid)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  margin: '0 auto',
  minHeight: '100vh',
  padding: `${spacing[2]} 0`,
  flexDirection: 'column',
  [theme.breakpoints.up('md')]: {
    gap: '10%',
    maxWidth: '80%',
    padding: `80px ${spacing[4]}`,
    flexDirection: 'row',
  },
}));

export const CboComponent = styled('div')({
  position: 'relative',
});

export const TextField = styled(TextFieldDs)({
  '& .MuiInputBase-root': {
    padding: 0,
  },
  '& .MuiInputBase-input.MuiAutocomplete-input': {
    padding: `${spacing[4]} ${spacing[2]}`,
  },
});
