import { axiomTokens, MaterialUICore } from '@iclinic/design-system';

const { ButtonBase, styled } = MaterialUICore;
const { border, color, spacing } = axiomTokens;

export const RatingContainer = styled('div')({
  display: 'flex',
  gap: spacing['2'],
  marginTop: spacing['2'],
  flexWrap: 'wrap',
});

export const RatingButton = styled(ButtonBase)({
  display: 'flex',
  height: spacing['10'],
  width: spacing['10'],
  alignItems: 'center',
  justifyContent: 'center',
  background: color.surface.subtle.default,
  borderColor: color.border.accent,
  borderStyle: 'solid',
  border: border.width.xs,
  borderRadius: border.radius.circular,
  cursor: 'pointer',

  '& > p': {
    color: color.typeface.smooth,
  },

  '&.selected': {
    backgroundColor: color.surface.selected.default,
    borderColor: color.border.activated,

    '& > p': {
      color: color.interactive.accent.default,
    },
  },
});
