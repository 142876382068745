import { Box, MaterialUICore, axiomTokens } from '@iclinic/design-system';

const { styled } = MaterialUICore;

const { spacing, color } = axiomTokens;

export const CardHeader = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: spacing['4'],
  letterSpacing: 0.44,
});

export const CardContent = styled('div')({
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  alignItems: 'flex-start',
  marginTop: spacing['4'],
  gap: spacing['2'],
});

export const BoxText = styled(Box)(() => ({
  display: 'flex',
  gap: spacing['2'],

  '& svg': {
    color: color.icon.default,
  },
}));
