import { ApiErrors } from '@types';

export interface ICampaignManagerState {
  isFetching: boolean;
  campaigns: Campaign;
  errors: ApiErrors[];
}

export interface Campaign {
  name: string;
  components: Component[];
}

export interface Component {
  name: string;
  slug: string;
  texts?: Text[];
  images?: Image[];
}

export interface Text {
  name: string;
  slug: string;
  resource_value?: string;
  resource_type?: string;
}

export interface Image {
  name: string;
  slug: string;
  image?: string;
}

export enum Filters {
  texts = 'texts',
  images = 'images',
}

export type CampaignManagerState = Readonly<ICampaignManagerState>;
