// External
import React from 'react';
import { Switch, Route, RouteComponentProps } from 'react-router-dom';

// Internal
import { LoginV2 } from 'features/new-auth/containers';
import NotFound from 'shared/components/NotFound';
import { AuthProvider } from 'shared/hooks/useAuth';
import AuthRoute from './AuthRoute';
import SlashNewRoutes from './SlashNewRoutes';

// webapp SPA Routes (routes served in production using /new prefix)
export default () => (
  <AuthProvider>
    <Switch>
      <AuthRoute exact path="/" component={LoginV2} />

      <Route
        exact
        path="/dashboard"
        component={({ match }: RouteComponentProps) => {
          window.location.href = `${match.path}/`;
          return null;
        }}
      />

      <Route path="/new" component={SlashNewRoutes} />

      <Route component={NotFound} />
    </Switch>
  </AuthProvider>
);
