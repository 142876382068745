import { createSelector } from '@reduxjs/toolkit';

import { RequestStatus } from 'shared/constants/State';
import { StoreState } from 'state/rootReducer';

export const isFetchingDetails = ({ messaging }: StoreState) =>
  messaging.messagePackage.details.fetcherDetailsStatus ===
  RequestStatus.Pending;

export const getPaymentSummary = ({ messaging }: StoreState) =>
  messaging.messagePackage.details.paymentSummary;

export const getConsumptions = ({ messaging }: StoreState) =>
  messaging.messagePackage.details.consumptions;

export const isFetchingContractorTemplates = ({ messaging }: StoreState) =>
  messaging.messagePackage.details.fetcherContractorsStatus ===
  RequestStatus.Pending;

export const getContractorTemplatesMeta = ({ messaging }: StoreState) =>
  messaging.messagePackage.details.meta;

export const getContractorTemplates = ({ messaging }: StoreState) =>
  messaging.messagePackage.details.contractorTemplates;

export const getContractorsData = createSelector(
  [
    isFetchingContractorTemplates,
    getContractorTemplatesMeta,
    getContractorTemplates,
  ],
  (isFetchingContractors, meta, contractorTemplates) => ({
    isFetchingContractors,
    meta,
    contractorTemplates,
  }),
);

export const getContractorUpdateMessages = ({ messaging }: StoreState) =>
  messaging.messagePackage.details.contractorUpdateMessages;
