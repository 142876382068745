export const GOOGLE_API_SCRIPT_URL = 'https://accounts.google.com/gsi/client';
export const GOOGLE_RECAPTCHA_SCRIPT_URL =
  'https://www.google.com/recaptcha/api.js';

export const MEMED_COLOR = '#4294CE';
export const MEMED_TARGET_ID = 'memed-prescription-container';
export const MEMED_MILLISECONDS_TO_GET_DRUGS = 500;
export const MEMED_MAX_TRIES_TO_GET_DRUGS = 5;

export const CLINIC_ID = 'clinicId';
export const PROFILE_ID = 'profileId';
