import { Breadcrumb } from '@sentry/browser';
import {
  init as sentryInit,
  addBreadcrumb,
  configureScope,
  withScope,
  captureException,
  captureMessage,
  setExtra,
  setContext,
  setTag,
  Integrations,
} from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import inboundFilterConfig from './inboundFilterConfig';

export const init = (
  environment: string,
  dsn?: string,
  release?: string,
  tracesSampleRate = 0.01,
  sampleRate = 0.1,
) => {
  sentryInit({
    dsn,
    release,
    environment,
    integrations: [
      new Integrations.InboundFilters(inboundFilterConfig),
      new BrowserTracing(),
    ],
    tracesSampleRate,
    sampleRate,
  });
};

export type { Breadcrumb };
export {
  addBreadcrumb,
  configureScope,
  withScope,
  captureException,
  captureMessage,
  setExtra,
  setContext,
  setTag,
};
