import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { MaterialUICore } from '@iclinic/design-system';

import history from 'routes/history';
import SingleSignupForm from '../components/form/FormWrapper';
import SingleSignupInfo from '../components/SignupInfo';
import { autoLoginSelector } from '../state/selectors';
import { SignupBanners } from '../components/SignupBanner/SignupBanners';

const { useTheme, useMediaQuery } = MaterialUICore;
const SignupContainer = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'));
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));

  const hasAutoLogin = useSelector(autoLoginSelector);

  useEffect(() => {
    if (hasAutoLogin) history.push('/new/cadastro/iniciar-teste');
  }, [hasAutoLogin]);

  return (
    <>
      {isXs && <SignupBanners />}
      <SingleSignupForm />
      {isMd && <SingleSignupInfo />}
    </>
  );
};

export default SignupContainer;
