import surveyApi from 'services/iclinic/surveyApi';
import {
  DashboardData,
  DashboardFilterData,
  Physician,
  RecentNpsFilterData,
  RecentNpsResponse,
} from '../types';

export function getPhysicians() {
  return surveyApi.get<Physician[]>(`/external/physicians/`);
}

export function getSurveyDashboardData({
  date,
  physicianId,
}: DashboardFilterData) {
  const params = [`date=${date}`];

  if (physicianId) {
    params.push(`physician=${physicianId}`);
  }

  return surveyApi.get<DashboardData>(`/dashboard/?${params.join('&')}`);
}

export function getSurveyRecentNps({
  date,
  physicianId,
  limit,
  offset,
}: RecentNpsFilterData) {
  const params = [`date=${date}`, `limit=${limit}`, `offset=${offset}`];

  if (physicianId) {
    params.push(`physician=${physicianId}`);
  }

  return surveyApi.get<RecentNpsResponse>(
    `/survey/get_recent_nps/?${params.join('&')}`,
  );
}
