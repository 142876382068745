import {
  Body,
  Button,
  Chip,
  Heading,
  Loader,
  MaterialUICore,
  Select,
} from '@iclinic/design-system';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  fetchPhysicians,
  fetchRecentNps,
  fetchSurveyDashboardData,
  selectPhysicianId,
} from 'features/survey/state/dashboard';
import { dispatchGaClick } from 'shared/utils/googleAnalytics';
import { RecentNpsFilterData } from '../../services/types';
import {
  getPhysicianList,
  getRecentNpsCount,
  getRecentNpsList,
  getSelectedPhysicianId,
  isFetchingRecentNps,
} from '../../state/dashboard/selectors';
import AnswerItem from './AnswerItem';
import * as S from './Dashboard.styles';
import NpsChart from './NpsChart';
import TimelineChart from './TimelineChart';

const { MenuItem } = MaterialUICore;

const MIN_LENGHT_TO_SHOW_SELECT_PHYSICIAN = 2;
const limit: number = 6;

export default function Dashboard() {
  const dispatch = useDispatch();
  const physicians = useSelector(getPhysicianList);
  const fetchingRecentNps = useSelector(isFetchingRecentNps);
  const recentNps = useSelector(getRecentNpsList);
  const recentNpsCount = useSelector(getRecentNpsCount);
  const isAllRecentNpsLoaded = recentNps.length === recentNpsCount;
  const selectedPhysicianId = useSelector(getSelectedPhysicianId);

  const [filters, setFilters] = useState<RecentNpsFilterData>({
    date: 'week',
    limit,
    offset: 0,
    physicianId: selectedPhysicianId || '',
  });

  const onChipClickHandler = (value: string) => {
    dispatchGaClick({
      eventName: `survey_dashboard_filter_${value || 'all'}_click`,
    });
    setFilters((previous) => ({
      ...previous,
      date: value,
      offset: 0,
    }));
  };

  const onChangePhysicianHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { value } = event.target;

    setFilters((previous) => ({
      ...previous,
      physicianId: value,
      offset: 0,
    }));

    dispatch(selectPhysicianId(value));
  };

  const onLoadMoreClickHandler = () => {
    if (!isAllRecentNpsLoaded) {
      setFilters((previous) => ({
        ...previous,
        offset: previous.offset + limit,
      }));
    }
  };

  useEffect(() => {
    if (physicians.length && !filters.physicianId) {
      setFilters((previous) => ({
        ...previous,
        physicianId: physicians[0].id.toString(),
      }));
    }
  }, [dispatch, filters.physicianId, physicians]);

  useEffect(() => {
    dispatch(fetchPhysicians());
  }, [dispatch]);

  useEffect(() => {
    if (filters.physicianId) {
      dispatch(
        fetchSurveyDashboardData({
          date: filters.date,
          physicianId: filters.physicianId,
        }),
      );
    }
  }, [dispatch, filters.date, filters.physicianId]);

  useEffect(() => {
    if (filters.physicianId) {
      dispatch(fetchRecentNps(filters));
    }
  }, [dispatch, filters]);

  return (
    <S.Root>
      <S.SectionHeaderWrapper>
        <S.HeaderWrapper>
          <Heading variant="md">Dashboard Pesquisa de Satisfação</Heading>

          <Body variant="xxs">
            Monitore constantemente o nível de satisfação dos seus pacientes,
            identifique e aprimore seus serviços para atender melhor às
            expectativas.
          </Body>
        </S.HeaderWrapper>

        {physicians.length >= MIN_LENGHT_TO_SHOW_SELECT_PHYSICIAN ? (
          <S.SelectWrapper>
            <Select
              label="Profissional de saúde"
              fullWidth
              value={filters.physicianId?.toString()}
              onChange={onChangePhysicianHandler}
            >
              {physicians.map(({ id, name }) => (
                <MenuItem key={name} value={id.toString()}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </S.SelectWrapper>
        ) : null}
      </S.SectionHeaderWrapper>

      <S.Container>
        <S.ChipsWrapper>
          <Chip
            variant="outlined"
            label="Esta semana"
            clickable
            active={filters.date === 'week'}
            onClick={() => onChipClickHandler('week')}
          />
          <Chip
            variant="outlined"
            label="Últimos 15 dias"
            clickable
            active={filters.date === '15days'}
            onClick={() => onChipClickHandler('15days')}
          />
          <Chip
            variant="outlined"
            label="Este mês"
            clickable
            active={filters.date === 'current_month'}
            onClick={() => onChipClickHandler('current_month')}
          />
          <Chip
            variant="outlined"
            label="90 dias atrás"
            clickable
            active={filters.date === '90days'}
            onClick={() => onChipClickHandler('90days')}
          />
          <Chip
            variant="outlined"
            label="Todos"
            clickable
            active={filters.date === ''}
            onClick={() => onChipClickHandler('')}
          />
        </S.ChipsWrapper>

        <S.ChartsWrapper>
          <NpsChart />

          <TimelineChart />
        </S.ChartsWrapper>

        <S.AnswersWrapper>
          <S.SubtitleWrapper>
            <Heading variant="sm">Respostas recentes</Heading>

            <div>
              <Body variant="sm">O que você achou do atendimento?</Body>
              {recentNps.length ? (
                <Body variant="xxs">
                  Encontramos <strong>{recentNpsCount} respostas</strong> para
                  essa pergunta.
                </Body>
              ) : null}

              {!recentNps.length && !fetchingRecentNps ? (
                <Body variant="xxs">
                  Não encontramos nenhuma resposta para esta pergunta.
                </Body>
              ) : null}
            </div>
          </S.SubtitleWrapper>

          {recentNps.length ? (
            <S.AnswersItemsWrapper>
              {recentNps.map(({ nps_value, patient_name, comment }) => (
                <AnswerItem
                  key={patient_name}
                  patientName={patient_name}
                  npsValue={nps_value}
                  comment={comment}
                />
              ))}
            </S.AnswersItemsWrapper>
          ) : null}

          <S.LoadMoreWrapper>
            {fetchingRecentNps ? <Loader /> : null}
            {recentNps.length && !fetchingRecentNps && !isAllRecentNpsLoaded ? (
              <Button size="small" onClick={onLoadMoreClickHandler}>
                Exibir mais
              </Button>
            ) : null}
          </S.LoadMoreWrapper>
        </S.AnswersWrapper>
      </S.Container>
    </S.Root>
  );
}
