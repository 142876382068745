const { ASSETS_PATH } = process.env;

const images = {
  signup: {
    background: `${ASSETS_PATH as string}images/signup/dash_bg.png`,
    decorator: `${ASSETS_PATH as string}images/signup/decorator.png`,
    doctor: `${ASSETS_PATH as string}images/signup/doctor.png`,
    app: `${ASSETS_PATH as string}images/signup/app-sample.png`,
    doctorAlertMessage: `${
      ASSETS_PATH as string
    }images/signup/doctor-alert-message.webp`,
    logoShadowRight: `${
      ASSETS_PATH as string
    }images/signup/logo-shadow-right.webp`,
    scheduleBackground: `${
      ASSETS_PATH as string
    }images/signup/schedule-background.webp`,
  },
} as const;

export default images;
