import React, { useEffect, ChangeEvent, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import { Autocomplete } from '@iclinic/design-system';

import { professionSelector, listCBOSelector } from '../../state/selectors';
import { filterListCBO } from '../../state/reducer';
import {
  AutocompleteCboList,
  ProfessionFilters,
  SpecialtyStepValues,
} from '../../state/types';
import { TextField, CboComponent } from './SpecialtyStep.styles';
import { trackSignupStep } from '../../trackSignupUtils';

const CboInput = () => {
  const cboList = useSelector(listCBOSelector);
  const dispatch = useDispatch();
  const profession = useSelector(professionSelector);

  useEffect(() => {
    const professionFilter = profession
      ? ProfessionFilters.get(profession)
      : null;
    if (professionFilter) dispatch(filterListCBO(professionFilter));
  }, [dispatch, profession]);

  const nameField = 'cbo';
  const { errors, touched, values, handleBlur, setFieldValue } =
    useFormikContext<SpecialtyStepValues>();

  const autoCompleteRef = useRef<HTMLElement>();

  const checksetFieldValue = (
    _: ChangeEvent<{}>,
    newValue: AutocompleteCboList,
  ) => {
    const trackingValue = typeof newValue === 'string' ? newValue : '';
    trackSignupStep({
      name: 'user_specialty',
      stepValue: trackingValue,
    });

    if (newValue) {
      return setFieldValue(nameField, newValue);
    }

    return setFieldValue(nameField, '');
  };
  const helperText = touched.cbo && errors.cbo ? errors.cbo : '';
  const hasError = !!(errors.cbo && touched.cbo);

  return (
    <CboComponent id="cbo_select">
      <Autocomplete
        id={nameField}
        options={cboList}
        value={values.cbo}
        onChange={checksetFieldValue}
        getOptionSelected={(option) => option === values.cbo}
        noOptionsText="Sem opções"
        renderInput={(params) => (
          <TextField
            {...params}
            inputRef={autoCompleteRef}
            name={nameField}
            placeholder="Especialização"
            error={hasError}
            onBlur={handleBlur}
            helperText={helperText}
          />
        )}
      />
    </CboComponent>
  );
};

export default CboInput;
