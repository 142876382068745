import { Physician, TemplateKind } from '../services/types';

export type SingleScheduleTemplateMessage = {
  existTemplateId?: number;
  content: string;
  contactPhone?: string;
  isActive: boolean;
  daysAdvance?: number;
  timeSend?: string;
};

export type SubmitSingleScheduleTemplateData = {
  physicianId: number;
  singleScheduleTemplateMessage: SingleScheduleTemplateMessage;
};

export type PreviewTemplateActionData = {
  contactPhone?: string;
  content?: string;
};

export type PreviewActionParams = {
  physicianId: number;
  template: PreviewTemplateActionData;
};

export type PhysiciansAndKinds = {
  physicians: Physician[];
  kinds: TemplateKind[];
};

export type ProductByPhysician = {
  id: number;
  productSlug: string;
  kinds: TemplateKind[];
};

export enum MenuSlug {
  MESSAGES = 'messages',
  CONFIRMATION = 'confirmation_event',
  REMINDER = 'event_reminder',
  METRICS = 'metrics',
  PACKAGE = 'package_message',
}

export type MultiplesScheduleTemplateMessage = {
  content: string;
  contactPhone?: string;
  isActive: boolean;
  schedules: {
    id?: number;
    daysAdvance: number;
    timeSend: string;
  }[];
  schedulesDeleted: number[];
};

export type SubmitMultiplesScheduleTemplateData = {
  physicianId: number;
  multiplesScheduleTemplateMessage: MultiplesScheduleTemplateMessage;
};

export type Message = {
  id: string;
  message: string;
  isSuccess: boolean;
};
