import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box, CircularProgress } from '@iclinic/design-system';

import { fetchMenuPermissions } from 'features/messaging/state/common';

const Home = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchMenuPermissions());
  }, [dispatch]);

  return (
    <Box display="flex" justifyContent="center">
      <CircularProgress data-testid="loader-home" />
    </Box>
  );
};

export { Home };
