export const dispatchGaEvent = (
  event: string,
  properties: Record<string, unknown> = {},
) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event,
    ...properties,
  });
};

export const dispatchGaClick = ({ eventName }: { eventName: string }) =>
  dispatchGaEvent('data_ga_click', { event_name: eventName });

type FindEventProps = {
  eventName: string;
  componentParam: string;
};

export const findEventWithComponent = ({
  eventName,
  componentParam,
}: FindEventProps): boolean =>
  window.dataLayer &&
  window.dataLayer.some(
    ({ event, component }) =>
      event === eventName && component === componentParam,
  );
