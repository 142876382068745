import {
  FileSignatureRequest,
  FileSignatureResponse,
  FileSignatureResponseCompleted,
  FileSignatureCompletedRequest,
  ListPSCRequest,
  ListPSCResponse,
  AccessTokenRequest,
  AccessTokenResponse,
  FileSignatureCloudRequest,
} from 'state/records/signature/types';
import { apiSignature } from './api';

export const fetchStartSignature = ({
  s3_file_url,
  encoded_cert,
  patient_id,
  physician_id,
  user_id,
  document_type,
  document_id,
}: FileSignatureRequest) =>
  apiSignature.post<FileSignatureResponse>(
    `/v1/signature/start/?token=${process.env.ICLINIC_SIGNATURE_TOKEN}`,
    {
      s3_file_url,
      encoded_cert,
      patient_id,
      physician_id,
      user_id,
      document_type,
      document_id,
    },
  );

export const getCompletedSignature = ({
  signed_hash,
  transfer_id,
  document_type,
  document_id,
}: FileSignatureCompletedRequest) =>
  apiSignature.post<FileSignatureResponseCompleted>(
    `/v1/signature/complete/?token=${process.env.ICLINIC_SIGNATURE_TOKEN}`,
    {
      signed_hash,
      transfer_id,
      document_type,
      document_id,
    },
  );

export const getListPsc = ({ user_document, redirect_uri }: ListPSCRequest) =>
  apiSignature.post<ListPSCResponse>(
    `/v1/signature/discover-providers/?token=${process.env.ICLINIC_SIGNATURE_TOKEN}`,
    {
      user_document,
      redirect_uri,
    },
  );

export const getAccessTokenSignature = ({
  auth_code,
  state,
}: AccessTokenRequest) =>
  apiSignature.post<AccessTokenResponse>(
    `/v1/signature/token/?token=${process.env.ICLINIC_SIGNATURE_TOKEN}`,
    {
      auth_code,
      state,
    },
  );

export const fetchSignatureCloud = ({
  s3_file_url,
  access_token,
  patient_id,
  physician_id,
  document_type,
  document_id,
  user_id,
}: FileSignatureCloudRequest) =>
  apiSignature.post<FileSignatureResponseCompleted>(
    `/v1/signature/sign-pades-provider/?token=${process.env.ICLINIC_SIGNATURE_TOKEN}`,
    {
      s3_file_url,
      access_token,
      patient_id,
      physician_id,
      document_type,
      document_id,
      user_id,
    },
  );
