import { MaterialUICore, TextField } from '@iclinic/design-system';

const { styled, MenuItem } = MaterialUICore;

export const SpecialityMenu = styled(MenuItem)({
  whiteSpace: 'break-spaces',
  maxWidth: '346px',
});

export const SpecialityTextField = styled(TextField)({
  '& .MuiSelect-root': {
    paddingRight: '74px',
  },
});
