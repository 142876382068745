import { createSelector } from '@reduxjs/toolkit';

import { RequestStatus } from 'shared/constants/State';
import { StoreState } from 'state/rootReducer';

export const isFetchingMessageHistory = ({ messaging }: StoreState) =>
  messaging.messageHistory.fetcherHistoryStatus === RequestStatus.Pending;

export const getMessageHistoryRecords = ({ messaging }: StoreState) =>
  messaging.messageHistory.historyRecords;

export const getMessageHistoryMeta = ({ messaging }: StoreState) =>
  messaging.messageHistory.meta;

export const getMessageHistoryData = createSelector(
  [isFetchingMessageHistory, getMessageHistoryRecords, getMessageHistoryMeta],
  (isFetchingHistory, historyRecords, meta) => ({
    isFetchingHistory,
    historyRecords,
    meta,
  }),
);
