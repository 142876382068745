import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, CircularProgress, MaterialUICore } from '@iclinic/design-system';

import { PageHeader, PageWrapper } from '../../../components';
import { fetchPhysicians, fetchMenuPermissions } from '../../../state/common';
import {
  isFetchingPhysicians,
  getAvailableMenus,
} from '../../../state/common/selectors';
import { fetchPhysiciansAvailable } from '../../../state/messagePackage/checkout';
import { ModalWarningPersonalization } from './ModalWarningPersonalization';
import { OverviewTabContent } from './OverviewTabContent';
import { SettingsTabContent } from './SettingsTabContent/SettingsTabContent';

const { Tab, Tabs } = MaterialUICore;

enum TabOptions {
  OVERVIEW = 'overview',
  SETTINGS = 'settings',
}

const Details = () => {
  const dispatch = useDispatch();
  const isFetchingAllPhysicians = useSelector(isFetchingPhysicians);
  const availableMenus = useSelector(getAvailableMenus);
  const [tab, setTab] = useState(TabOptions.OVERVIEW);

  useEffect(() => {
    dispatch(fetchPhysicians({ returnAll: true }));
    dispatch(fetchPhysiciansAvailable());
  }, [dispatch]);

  useEffect(() => {
    if (!availableMenus) {
      dispatch(fetchMenuPermissions(true));
    }
  }, [availableMenus, dispatch]);

  const onChangeTabHandler = useCallback(
    (_: React.ChangeEvent<{}>, newValue: TabOptions) => {
      setTab(newValue);
    },
    [],
  );

  return (
    <>
      <PageWrapper>
        <PageHeader
          title="Pacote de mensagem"
          description="Acesse os detalhes seu consumo, escolha planos e controle seus gastos."
        />

        {isFetchingAllPhysicians ? (
          <Box display="flex" justifyContent="center">
            <CircularProgress data-testid="loader-all-physicians" />
          </Box>
        ) : (
          <>
            <Tabs
              textColor="primary"
              indicatorColor="primary"
              value={tab}
              onChange={onChangeTabHandler}
            >
              <Tab label="Visão geral" value={TabOptions.OVERVIEW} />
              <Tab label="Configurações" value={TabOptions.SETTINGS} />
            </Tabs>

            <div hidden={tab !== TabOptions.OVERVIEW}>
              <OverviewTabContent />
            </div>

            <div hidden={tab !== TabOptions.SETTINGS}>
              <SettingsTabContent />
            </div>
          </>
        )}
      </PageWrapper>

      <ModalWarningPersonalization />
    </>
  );
};

export { Details };
