import {
  Body,
  Box,
  Heading,
  MaterialUICore,
  axiomTokens,
} from '@iclinic/design-system';

import { Card } from './Dashboard.styles';

const { styled } = MaterialUICore;
const { border, spacing } = axiomTokens;
const gridColumnSpan = '1 / span 2';

export const ChartCard = styled(Card)(() => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: spacing[2],
  padding: `${spacing[2]} ${spacing[4]} ${spacing[6]}`,
}));

export const ChartTitle = styled(Heading)({
  gridColumn: gridColumnSpan,
  gridRow: '1',
});

export const ChartWrapper = styled('div')({
  gridColumn: gridColumnSpan,
  gridRow: '2',
  maxWidth: 228,
  width: '100%',
  margin: '0 auto',
  marginBottom: spacing[2],
  position: 'relative',
});

export const ChartNpsValueWrapper = styled('div')({
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -25%)',

  '& > p': {
    marginTop: `-${spacing[1]}`,
  },
});

export const LegendWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  columnGap: spacing[2],
});

export const Bullet = styled(Box)({
  borderRadius: border.radius.circular,
  width: 12,
  height: 12,
});

export const NpsExplanationText = styled(Body)({
  marginTop: spacing[2],
  gridColumn: gridColumnSpan,
});

export const NpsDescriptionZone = styled('div')({
  gridColumn: 'span 2',
  display: 'flex',
  justifyContent: 'center',
  columnGap: spacing['6'],
});

export const NpsDescription = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  rowGap: spacing['2'],
});
