import { axiomTokens, MaterialUICore, Box } from '@iclinic/design-system';

const { styled } = MaterialUICore;

const { border, color, spacing } = axiomTokens;

export const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  padding: `0 ${spacing['8']} ${spacing[6]}`,
  rowGap: spacing[6],
});

export const HeaderWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  letterSpacing: 0.44,
  gap: spacing['2'],
});

export const Content = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: spacing[4],
  background: color.background[1],
  borderRadius: border.radius.sm,
  border: `1px solid ${color.border.subtle}`,
  padding: spacing[4],

  [theme.breakpoints.up('sm')]: {
    padding: spacing[6],
    rowGap: spacing[6],
  },
}));

export const TableWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  rowGap: spacing[2],
});

export const LoaderWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 150,
});
