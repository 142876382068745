import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit';

import { RequestStatus } from 'shared/constants/State';
import {
  Feature,
  GetPhysiciansArgs,
  PermissionsMenu,
  Physician,
  ReadAlertArgs,
} from '../../services/types';
import { MenuSlug } from '../types';

export interface MessagingCommonState {
  fetcherPhysicianStatus?: RequestStatus;
  physicians?: Physician[];
  fetcherMenuPermissions?: RequestStatus;
  permissionsMenu?: PermissionsMenu;
  availableMenus?: MenuSlug[];
  feature?: Feature;
  fetcherReadAlertStatus?: RequestStatus;
}

export const initialState: MessagingCommonState = {};

export const nameStore = '@webapp/messaging/common';

export const fetchPhysicians = createAction<GetPhysiciansArgs | undefined>(
  `${nameStore}/fetchPhysicians`,
);

export const fetchMenuPermissions = createAction<boolean | undefined>(
  `${nameStore}/fetchMenuPermissions`,
);

export const fetchVisitedMessagingFeature = createAction(
  `${nameStore}/fetchVisitedMessagingFeature`,
);

export const submitVisitedMessagingFeature = createAction(
  `${nameStore}/submitVisitedMessagingFeature`,
);

export const updateReadAlert = createAction<ReadAlertArgs>(
  `${nameStore}/updateReadAlert`,
);

const MessagingCommonSlice = createSlice({
  name: nameStore,
  initialState,
  reducers: {
    cleanPhysiciansData: (state) => ({
      ...state,
      fetcherPhysicianStatus: undefined,
      physicians: undefined,
    }),
    fetchPhysiciansStart: (state) => ({
      ...state,
      fetcherPhysicianStatus: RequestStatus.Pending,
    }),
    fetchPhysiciansSuccess: (state, action: PayloadAction<Physician[]>) => ({
      ...state,
      fetcherPhysicianStatus: RequestStatus.Success,
      physicians: action.payload,
    }),
    fetchPhysiciansFailure: (state) => ({
      ...state,
      fetcherPhysicianStatus: RequestStatus.Error,
      physicians: [],
    }),
    fetchMenuPermissionsStart: (state) => ({
      ...state,
      fetcherMenuPermissions: RequestStatus.Pending,
    }),
    fetchMenuPermissionsSuccess: (
      state,
      action: PayloadAction<{
        permissionsMenu: PermissionsMenu;
        availableMenus: MenuSlug[];
      }>,
    ) => ({
      ...state,
      fetcherMenuPermissions: RequestStatus.Success,
      permissionsMenu: action.payload.permissionsMenu,
      availableMenus: action.payload.availableMenus,
    }),
    fetchMenuPermissionsFailure: (state) => ({
      ...state,
      fetcherMenuPermissions: RequestStatus.Error,
    }),
    updateVisitedMessagingFeature: (
      state,
      action: PayloadAction<Feature | undefined>,
    ) => ({
      ...state,
      feature: action.payload,
    }),
    updateReadAlertFetcherStatus: (
      state,
      action: PayloadAction<RequestStatus>,
    ) => ({
      ...state,
      fetcherReadAlertStatus: action.payload,
    }),
  },
});

export const {
  cleanPhysiciansData,
  fetchPhysiciansStart,
  fetchPhysiciansFailure,
  fetchPhysiciansSuccess,
  fetchMenuPermissionsStart,
  fetchMenuPermissionsSuccess,
  fetchMenuPermissionsFailure,
  updateVisitedMessagingFeature,
  updateReadAlertFetcherStatus,
} = MessagingCommonSlice.actions;

export default MessagingCommonSlice.reducer;
