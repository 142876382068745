const { ASSETS_PATH } = process.env;

const images = {
  tissInvoicing: {
    editNote: `${ASSETS_PATH as string}images/tiss-invoicing/edit-note.svg`,
    createLotArt: `${
      ASSETS_PATH as string
    }images/tiss-invoicing/create-lot.svg`,
    listLotArt: `${ASSETS_PATH as string}images/tiss-invoicing/list-lot.svg`,
    emptyListArt: `${
      ASSETS_PATH as string
    }images/tiss-invoicing/empty-list.svg`,
  },
};

export default images;
