import { MaterialUICore, axiomTokens } from '@iclinic/design-system';

const { styled } = MaterialUICore;
const { spacing } = axiomTokens;

export const Root = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  padding: spacing[8],
  rowGap: spacing[6],
});

export const ContentTabs = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  rowGap: spacing[3],
});
