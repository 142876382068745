import React from 'react';
import { Logo, Button, MaterialUICore } from '@iclinic/design-system';

import { image } from 'shared/utils/urlGetter';
import * as S from './PasswordExpirationMessage.styles';

const { Fade } = MaterialUICore;

function PasswordExpirationMessageExpired() {
  const onClickChangePassword = () => {
    window.location.href = '/new/usuarios/recuperar-senha/';
  };

  return (
    <S.Modal open>
      <Fade in>
        <S.ModalContainer>
          <S.FlexContainer>
            <S.Container>
              <S.MessageContainer>
                <Logo width={145} />

                <S.HeadingTitle variant="xl" inverseTextColor>
                  Altere sua senha para seguir a prática de segurança de
                  atualizações de senha
                </S.HeadingTitle>

                <S.HeadingSubtitle variant="sm" inverseTextColor>
                  Olá, tudo bem?
                </S.HeadingSubtitle>

                <S.BodyContent>
                  <S.BodyParagraph variant="xs" inverseTextColor>
                    Nós da iClinic priorizamos a segurança dos seus dados.
                    Estamos promovendo uma campanha de substituição de senhas
                    por versões mais seguras e recomendamos que você faça a
                    troca imediatamente.
                  </S.BodyParagraph>

                  <S.BodyParagraph variant="xs" inverseTextColor>
                    Se tiver qualquer dúvida, fique à vontade para nos chamar
                    aqui no chat.
                  </S.BodyParagraph>

                  <S.BodyParagraph variant="xs" inverseTextColor bold>
                    Obrigado e um ótimo trabalho.
                  </S.BodyParagraph>
                </S.BodyContent>

                <S.FooterContainer>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={onClickChangePassword}
                  >
                    Quero realizar a troca
                  </Button>
                </S.FooterContainer>
              </S.MessageContainer>

              <S.ImageContainer>
                <S.ImageDoctor
                  src={image('signup.doctorAlertMessage')}
                  alt="Médica"
                  width={429}
                />
                <S.BoxExpireTime>
                  <S.HeadingExpireTime
                    variant="lg"
                    align="center"
                    inverseTextColor
                  >
                    Sua segurança em primeiro lugar!
                  </S.HeadingExpireTime>
                </S.BoxExpireTime>
              </S.ImageContainer>
            </S.Container>
          </S.FlexContainer>
        </S.ModalContainer>
      </Fade>
    </S.Modal>
  );
}

export default PasswordExpirationMessageExpired;
