/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import {
  Route as ReactDOMRoute,
  RouteProps as ReactRouterProps,
  Redirect,
} from 'react-router-dom';

import { MaterialUICore } from '@iclinic/design-system';
import { useAuth } from 'shared/hooks/useAuth';
import { getNextURLParam } from 'shared/utils/url';
import useStyles from './AuthRoute.styles';

interface RouteProps extends ReactRouterProps {
  isPrivate?: boolean;
  component: React.ComponentType;
}

const { Backdrop, CircularProgress } = MaterialUICore;

const Route = ({
  isPrivate = false,
  component: Component,
  ...rest
}: RouteProps) => {
  const { token, validateAuthToken, isLoaded } = useAuth();
  const classes = useStyles();

  const nextUrl = getNextURLParam(window.location.search);

  useEffect(() => {
    (async () => {
      await validateAuthToken();
    })();
  }, [validateAuthToken]);

  const doLogin = isPrivate === !!token || nextUrl.length > 0;

  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) => {
        if (!isLoaded) {
          return (
            <Backdrop className={classes.backdrop} open>
              <CircularProgress className={classes.spinner} />
            </Backdrop>
          );
        }

        if (doLogin) {
          return <Component />;
        }
        return (
          <Redirect
            to={{
              pathname: isPrivate ? '/' : nextUrl || '/dashboard',
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
};

export default Route;
