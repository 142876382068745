import React, { useCallback } from 'react';
import { useFormikContext } from 'formik';
import { useRifm } from 'rifm';

import { phoneFormatter } from 'shared/utils/formatters';
import { PersonalStepValues } from '../../state/types';
import { SignupField } from './PersonalStep.styles';
import { trackSignupStep } from '../../trackSignupUtils';

const PhoneInput = () => {
  const { errors, touched, values, setFieldValue, handleBlur } =
    useFormikContext<PersonalStepValues>();

  const helperText: React.ReactNode =
    touched.billingPhone && errors.billingPhone;
  const hasPhoneError = !!(errors.billingPhone && touched.billingPhone);

  const { value: billingPhone, onChange: onChangePhone } = useRifm({
    value: values.billingPhone,
    onChange: (value: string) => setFieldValue('billingPhone', value),
    format: phoneFormatter,
  });

  const onBlurHandler = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      trackSignupStep({
        name: 'user_phone',
        stepValue: values.billingPhone,
      });
      handleBlur(event);
    },
    [handleBlur, values.billingPhone],
  );

  return (
    <SignupField
      label="Telefone"
      id="billingPhone"
      name="billingPhone"
      type="tel"
      placeholder="(__)_________"
      onBlur={onBlurHandler}
      onChange={onChangePhone}
      value={billingPhone}
      autoComplete="billingPhone"
      margin="normal"
      error={hasPhoneError}
      helperText={helperText}
      fullWidth
    />
  );
};

export default PhoneInput;
