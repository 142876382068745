import { MaterialIcons as Icons } from '@iclinic/design-system';

import { MenuSlug } from '../state/types';

export const menuOptions = [
  {
    id: 'message',
    text: 'Mensagens',
    icon: Icons.Message,
    permission: MenuSlug.MESSAGES,
    submenu: [
      {
        text: 'Confirmação de agenda',
        path: '/relacionamento/confirmacao-de-agenda',
        permission: MenuSlug.CONFIRMATION,
      },
      {
        text: 'Lembrete de agenda',
        path: '/relacionamento/lembrete-de-agenda',
        permission: MenuSlug.REMINDER,
      },
    ],
  },
  {
    text: 'Pacote de mensagem',
    icon: Icons.DateUsage,
    path: '/relacionamento/pacote-de-mensagem/detalhes',
    permission: MenuSlug.PACKAGE,
  },
];

export const packageUrl = '/relacionamento/pacote-de-mensagem';
