const { ASSETS_PATH } = process.env;

const images = {
  freeSamples: {
    samplesBox: `${ASSETS_PATH as string}images/free-samples/samples-box.svg`,
    boxDetail: `${ASSETS_PATH as string}images/free-samples/box-detail.svg`,
  },
};

export default images;
