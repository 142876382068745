import axios from 'axios';

import {
  requestSaveImage,
  requestCreateEventAdditional,
} from 'state/records/attachments/imageComparison/types';
import {
  TextTemplate as TextTemplateType,
  getTextTemplateResponse as getTextTemplateResponseType,
} from '../../state/records/textTemplate/types';
import {
  interceptorRequestAuthentication,
  interceptorResponseErrors,
  interceptorResponseTransformData,
} from './interceptors';

export const api = axios.create({
  baseURL: process.env.ICLINIC_API_URL,
});

api.interceptors.request.use(interceptorRequestAuthentication);
api.interceptors.response.use(
  interceptorResponseTransformData,
  interceptorResponseErrors,
);

export const getImagesByPatient = (patientId: number) =>
  api.get(
    `/patients/patient/${patientId}/events/images/?event-status=st,cp,ca`,
  );

export const saveImageCompare = (payload: requestSaveImage) =>
  api.post('/records/eventimage/', payload, {
    headers: {
      'Content-Type': 'application/octet-stream',
    },
  });

export const createAdditionalEvent = (payload: requestCreateEventAdditional) =>
  api.post('/records/eventaditional/', payload);

export const getResumeRecords = (patientId: number, eventId: number) =>
  api.get(`/patients/patient/${patientId}/events/${eventId}/summary/`);

export const getTextTemplates = (page: number, limit: number = 10) =>
  api.get<getTextTemplateResponseType>(
    `/textfield_template/?limit=${limit}&page=${page}`,
  );

export const saveTextTemplate = (template: TextTemplateType) =>
  api.post('/textfield_template/', {
    ...template,
  });

export const deleteTextTemplate = (textTemplateId: number) =>
  api.delete(`/textfield_template/${textTemplateId}/`);

export default getImagesByPatient;
