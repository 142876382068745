import loadScript from 'simple-load-script';

import { captureException } from 'shared/utils/handlerErrors';
import { GOOGLE_API_SCRIPT_URL } from 'shared/constants/services';

const loadAPI = () =>
  new Promise((resolve, reject) => {
    loadScript(GOOGLE_API_SCRIPT_URL)
      .then(resolve)
      .catch((error) => {
        captureException('Error loading GOOGLE_API_SCRIPT_URL script', error);
        reject(error);
      });
  });

export { loadAPI };
export default loadAPI;
