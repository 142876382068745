import { MaterialUICore } from '@iclinic/design-system';

const { makeStyles } = MaterialUICore;

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 5,
    background: '#fff',
  },
  spinner: {
    color: theme.palette.primary.main,
  },
}));

export default useStyles;
