import { MaterialUICore } from '@iclinic/design-system';

const { makeStyles } = MaterialUICore;

const iconSize = 13.33;
const icon = { width: iconSize, height: iconSize, marginRight: 5.33 };

export const useStyles = makeStyles((theme) => ({
  passwordCheckText: {
    color: theme.palette.grey[300],
  },
  success: {
    color: theme.palette.success.main,
    ...icon,
  },
  error: {
    color: theme.palette.error.main,
    ...icon,
  },
  passwordCheckLine: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  newPasswordSuccessIcon: {
    color: theme.palette.success.main,
  },
}));
