import React from 'react';
import { Body } from '@iclinic/design-system';

import * as S from './ChartLegend.styles';

interface ChartLegendProps {
  bulletColor: string;
  label: string;
}

export default function ChartLegend({ bulletColor, label }: ChartLegendProps) {
  return (
    <S.Root>
      <S.Bullet bgcolor={bulletColor}>
        <span />
      </S.Bullet>

      <Body variant="xxs">{label}</Body>
    </S.Root>
  );
}
