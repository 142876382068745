import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit';

import { RequestStatus } from 'shared/constants/State';
import {
  MessageHistoryArgs,
  MessageHistoryRecord,
  MessageHistoryResponse,
  Meta,
} from '../../services/types';

export interface MessageHistoryState {
  fetcherHistoryStatus?: RequestStatus;
  meta?: Meta;
  historyRecords?: MessageHistoryRecord[];
}

export const initialState: MessageHistoryState = {};

export const nameStore = '@webapp/messaging/messageHistory';

export const fetchMessageHistory = createAction<MessageHistoryArgs>(
  `${nameStore}/fetchMessageHistory`,
);

const MessageHistorySlice = createSlice({
  name: nameStore,
  initialState,
  reducers: {
    fetchMessageHistoryStart: (state) => ({
      ...state,
      fetcherHistoryStatus: RequestStatus.Pending,
    }),
    fetchMessageHistorySuccess: (
      state,
      action: PayloadAction<MessageHistoryResponse>,
    ) => ({
      ...state,
      fetcherHistoryStatus: RequestStatus.Success,
      historyRecords: action.payload.objects,
      meta: action.payload.meta,
    }),
    fetchMessageHistoryFailure: (state) => ({
      ...state,
      fetcherHistoryStatus: RequestStatus.Error,
    }),
  },
});

export const {
  fetchMessageHistoryStart,
  fetchMessageHistoryFailure,
  fetchMessageHistorySuccess,
} = MessageHistorySlice.actions;

export default MessageHistorySlice.reducer;
