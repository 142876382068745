import React from 'react';
import { Box, axiomTokens } from '@iclinic/design-system';

import * as S from './PasswordInput.styles';

const { spacing } = axiomTokens;

export const passwordFeedback =
  (isFeedbackActive: boolean) =>
  (
    eightCharIcon: React.ReactNode,
    atLeastOneCharIcon: React.ReactNode,
    atLeastOneNumberIcon: React.ReactNode,
  ) => {
    if (!isFeedbackActive) {
      return null;
    }
    return (
      <S.FeedbackWrapper mt={spacing[4]}>
        <S.FeedbackBody bold variant="xxs">
          A senha precisa ter:
        </S.FeedbackBody>
        <S.PasswordFeedbackContainer>
          <Box display="flex" alignItems="center">
            {eightCharIcon}
            <S.FeedbackBody variant="xxs">Ao menos 8 caracteres</S.FeedbackBody>
          </Box>
          <Box display="flex" alignItems="center">
            {atLeastOneCharIcon}
            <S.FeedbackBody variant="xxs">1 letra</S.FeedbackBody>
          </Box>
          <Box display="flex" alignItems="center">
            {atLeastOneNumberIcon}
            <S.FeedbackBody variant="xxs">1 número</S.FeedbackBody>
          </Box>
        </S.PasswordFeedbackContainer>
      </S.FeedbackWrapper>
    );
  };
