import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from '@iclinic/design-system';
import { RouteComponentProps } from 'react-router-dom';

import DoctorDataCard from './DoctorDataCard';
import EvaluationCard from './EvaluationCard';
import * as S from './Evaluation.styles';
import { fetchSurveyInfo } from 'features/survey/state/patients';
import { isFetchingSurveyInfo } from 'features/survey/state/patients/selectors';

interface EvaluationRouteProps {
  surveyId: string;
}

function Evaluation({ match }: RouteComponentProps<EvaluationRouteProps>) {
  const dispatch = useDispatch();
  const isFetching = useSelector(isFetchingSurveyInfo);
  const { surveyId } = match.params;

  useEffect(() => {
    dispatch(fetchSurveyInfo(surveyId));
  }, [dispatch, surveyId]);

  if (isFetching) {
    return (
      <S.LoaderWrapper>
        <Loader data-testid="loader-evaluation-page" />
      </S.LoaderWrapper>
    );
  }

  return (
    <S.CardMain>
      <S.CardContainer>
        <DoctorDataCard />
        <EvaluationCard surveyId={surveyId} />
      </S.CardContainer>
    </S.CardMain>
  );
}

export default Evaluation;
