import React, { useEffect } from 'react';
import {
  Body,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogHeader,
  Heading,
} from '@iclinic/design-system';
import { useDispatch, useSelector } from 'react-redux';

import { onlyNumbers } from 'shared/utils/formatters';
import { fetchReminderPreviewMessage } from '../../../state/scheduleReminder/personalization';
import {
  getReminderPreviewMessage,
  isFetchingReminderPreviweMessage,
} from '../../../state/scheduleReminder/personalization/selectors';
import * as S from './PreviewMessageModal.styles';

interface PreviewMessageModalProps {
  physicianId?: number;
  open: boolean;
  onClose?: () => void;
  content?: string;
  contactPhone?: string;
}

const PreviewMessageModalReminder = ({
  physicianId,
  open,
  onClose,
  content,
  contactPhone,
}: PreviewMessageModalProps) => {
  const dispatch = useDispatch();
  const isFetchingReminderPreviewMessage = useSelector(
    isFetchingReminderPreviweMessage,
  );
  const messagePreview = useSelector(getReminderPreviewMessage);

  useEffect(() => {
    if (open && physicianId) {
      dispatch(
        fetchReminderPreviewMessage({
          physicianId,
          template: {
            contactPhone: onlyNumbers(contactPhone || ''),
            content,
          },
        }),
      );
    }
  }, [contactPhone, content, dispatch, open, physicianId]);

  return (
    <Dialog maxWidth="sm" open={open} onClose={onClose} fullWidth>
      <DialogHeader onClose={onClose} title="Preview" />

      <DialogContent>
        <S.PreviewWrapper>
          <S.IconWhatsApp />
          <Heading variant="sm">Preview da mensagem</Heading>
        </S.PreviewWrapper>

        {isFetchingReminderPreviewMessage && (
          <Box display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        )}

        <Body
          variant="sm"
          dangerouslySetInnerHTML={{
            __html: messagePreview || '',
          }}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} size="large">
          Sair do preview
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { PreviewMessageModalReminder };
