// External
import { connect } from 'react-redux';
import React from 'react';

// Internal
import {
  configureScope,
  withScope,
  captureException,
} from 'shared/sentryClient';
import { StoreState } from 'state/rootReducer';

type Props = {
  boundary: string;
  children: React.ReactNode;
};

const mapStateToProps = (state: StoreState) => ({
  userData: state.userInfo.userData,
});

type MapStateToProps = ReturnType<typeof mapStateToProps>;
type ErrorBoundaryProps = Props & MapStateToProps;

class ErrorBoundary extends React.Component<ErrorBoundaryProps> {
  componentDidMount() {
    configureScope((scope) => {
      const { userData } = this.props;
      if (userData) {
        const { subscriptionId, userEmail, userName, physicianId, isAdmin } =
          userData;
        scope.setUser({
          id: subscriptionId,
          name: userName,
          email: userEmail,
          physicianId,
          isAdmin,
        });
      }
    });
  }

  componentDidCatch(error: any) {
    const { boundary } = this.props;
    withScope((scope) => {
      scope.setExtra('boundary', boundary);
      captureException(error);
    });
  }

  render() {
    const { children } = this.props;
    return children;
  }
}

export default connect(mapStateToProps)(ErrorBoundary);
