import React from 'react';
import { Alert, MaterialIcons, Snackbar } from '@iclinic/design-system';
import { useDispatch, useSelector } from 'react-redux';

import { getContractorUpdateMessages } from 'features/messaging/state/messagePackage/details/selectors';
import { removeContractorUpdateMessageById } from 'features/messaging/state/messagePackage/details';

const { Warning, CheckCircleOutline } = MaterialIcons;

const SnackbarMessages = () => {
  const dispatch = useDispatch();
  const messages = useSelector(getContractorUpdateMessages);

  const handleCloseMessage = (messageId: string) => {
    dispatch(removeContractorUpdateMessageById(messageId));
  };

  if (!messages.length) return null;

  return (
    <>
      {messages.map(({ id, message, isSuccess }) => (
        <Snackbar
          key={id}
          open
          onClose={() => handleCloseMessage(id)}
          severity={isSuccess ? 'success' : 'error'}
          autoHideDuration={6000}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
        >
          <Alert
            severity={isSuccess ? 'success' : 'error'}
            icon={isSuccess ? <CheckCircleOutline /> : <Warning />}
            message={message}
          />
        </Snackbar>
      ))}
    </>
  );
};

export default SnackbarMessages;
