import React from 'react';
import { useSelector } from 'react-redux';
import {
  Body,
  Box,
  Chip,
  CircularProgress,
  MaterialIcons,
  MaterialUICore,
} from '@iclinic/design-system';

import { formatDate } from 'shared/utils/date';
import { getMessageHistoryData } from '../../../state/messageHistory/selectors';
import { MessageStatus } from '../../../services/types';
import * as S from './TableHistory.styles';

const { Table, TableCell, TableHead, TableBody } = MaterialUICore;
const { Cancel, Check, ErrorOutlineOutlined, DoneAllIcon, Warning } =
  MaterialIcons;

const statusMapping = {
  [MessageStatus.CANCELED]: {
    text: 'Cancelado',
    severity: 'error',
    icon: <Cancel />,
  },
  [MessageStatus.CONFIRMED]: {
    text: 'Confirmado',
    severity: 'success',
    icon: <DoneAllIcon />,
  },
  [MessageStatus.SENT]: {
    text: 'Enviado',
    severity: 'info',
    icon: <Check />,
  },
  [MessageStatus.ERROR]: {
    text: 'Falha no envio',
    severity: 'error',
    icon: <ErrorOutlineOutlined />,
  },
  [MessageStatus.NUMBER_INVALID]: {
    text: 'Telefone inválido',
    severity: 'error',
    icon: <Warning />,
  },
} as const;

export function TableHistory() {
  const { isFetchingHistory, historyRecords } = useSelector(
    getMessageHistoryData,
  );

  if (isFetchingHistory) {
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress data-testid="table-history-loader" />
      </Box>
    );
  }

  return (
    <S.ContentTable>
      {historyRecords?.length ? (
        <Table>
          <TableHead>
            <S.TableRow className="header-row">
              <TableCell align="left">Data</TableCell>
              <TableCell align="left">Paciente</TableCell>
              <S.SmallTableCell align="left">Categoria</S.SmallTableCell>
              <S.SmallTableCell align="left">Status envio</S.SmallTableCell>
            </S.TableRow>
          </TableHead>
          <TableBody>
            {historyRecords.map(
              ({ date_sent, patient_name, procedures, status }) => (
                <S.TableRow key={`${date_sent}-${patient_name}`}>
                  <TableCell align="left">
                    {formatDate(date_sent) || '-'}
                  </TableCell>
                  <TableCell align="left">{patient_name}</TableCell>
                  <S.SmallTableCell align="left">
                    {procedures.join(', ')}
                  </S.SmallTableCell>
                  <S.SmallTableCell align="left">
                    <Chip
                      label={statusMapping[status].text}
                      size="small"
                      severity={statusMapping[status].severity}
                      icon={statusMapping[status].icon}
                    />
                  </S.SmallTableCell>
                </S.TableRow>
              ),
            )}
          </TableBody>
        </Table>
      ) : (
        <Body variant="xs" align="center">
          Ainda não há histórico de mensagens enviadas!
        </Body>
      )}
    </S.ContentTable>
  );
}
