import { ApiErrors } from '@types';
import * as types from './constants';
import { Campaign } from './types';

export const getActiveCampaign = () => ({ type: types.FETCH_ACTIVE_CAMPAIGN } as const);

export const getActiveCampaignSuccess = (campaigns: Campaign) => ({
  type: types.FETCH_ACTIVE_CAMPAIGN_SUCCESS,
  payload: {
    campaigns,
  },
} as const);

export const getActiveCampaignFailure = (errors: ApiErrors[]) => ({
  type: types.FETCH_ACTIVE_CAMPAIGN_FAILURE,
  payload: {
    errors,
  },
} as const);
