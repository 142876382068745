import {
  Body,
  Box,
  Grid,
  Heading,
  MaterialIcons,
  MaterialUICore,
  axiomTokens,
  tokens,
} from '@iclinic/design-system';

const { styled } = MaterialUICore;
const { opacity } = axiomTokens;

export const SuccessList = styled('ul')(() => ({
  padding: tokens.spacing.lg,
  backgroundColor: tokens.color.primary.light,
  borderRadius: tokens.border.radius.sm,
  listStyle: 'none',
  textAlign: 'left',
  maxWidth: 512,
  margin: `0 auto ${tokens.spacing.md}`,
}));

export const SuccessItem = styled('li')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: tokens.spacing.md,
  '& ~ *': {
    marginTop: tokens.spacing.sm,
  },
}));

export const SucessTextContainer = styled(Box)(() => ({
  maxWidth: 700,
}));

export const SuccessHeading = styled(Heading)(() => ({
  color: tokens.color.neutral[900],
}));

export const SuccessBody = styled(Body)(() => ({
  color: tokens.color.neutral[900],
  letterSpacing: '0.44px',
}));

export const ListIconContainer = styled(Box)(() => ({
  borderRadius: tokens.border.radius.circular,
  backgroundColor: tokens.color.primary.main,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 16,
  height: 16,
}));

export const ListCheck = styled(MaterialIcons.Check)(() => ({
  color: tokens.color.neutral[0],
}));

export const SuccessBackground = styled('div')(() => ({
  position: 'fixed',
  width: '100%',
  height: '100%',
  backgroundColor: tokens.color.primary.light,
  opacity: opacity.level['4'],
  top: 0,
  left: 0,
}));

export const SuccessContainer = styled(Grid)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  margin: '0 auto',
  minHeight: '100vh',
  padding: `${tokens.spacing.base} 0`,
  flexDirection: 'column',
  [theme.breakpoints.up('md')]: {
    gap: '10%',
    maxWidth: '80%',
    padding: `80px ${tokens.spacing.sm}`,
    flexDirection: 'row',
  },
}));
