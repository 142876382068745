import React from 'react';
import { Body, Heading, MaterialUICore } from '@iclinic/design-system';

import * as S from './StepPhysician.styles';

const { CircularProgress } = MaterialUICore;

interface StepPhysicianProps {
  physicianName: string;
  stepCount: number;
  currentStep: number;
}

const A_HUNDRED_PERCENT = 100;

function calculatePercentage(currentStep: number, totalSteps: number) {
  return (currentStep * A_HUNDRED_PERCENT) / totalSteps;
}

const StepPhysician = ({
  currentStep,
  physicianName,
  stepCount,
}: StepPhysicianProps) => (
  <S.Root>
    <S.StepWrapper>
      <CircularProgress
        size={48}
        variant="determinate"
        value={calculatePercentage(currentStep, stepCount)}
      />

      <S.StepCountText variant="sm">
        {currentStep}/{stepCount}
      </S.StepCountText>
    </S.StepWrapper>

    <S.Wrapper>
      <Heading variant="lg">{physicianName}</Heading>

      <Body variant="xs">Selecione o pacote desejado</Body>
    </S.Wrapper>
  </S.Root>
);

export { StepPhysician };
