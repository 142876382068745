import Axios from 'axios';

import {
  interceptorRequestAuthentication,
  interceptorResponseTransformData,
} from './interceptors';

const iClinicApi = Axios.create({
  baseURL: process.env.ICLINIC_API_URL,
});

iClinicApi.interceptors.request.use(interceptorRequestAuthentication);
iClinicApi.interceptors.response.use(interceptorResponseTransformData);

export default iClinicApi;
