import { ApiErrors } from '@types';

import * as types from './constants';
import { LoginForm } from './types';

export const authorize = () =>
  ({
    type: types.AUTHORIZATION_AUTHORIZE,
  } as const);

export const unauthorize = () =>
  ({
    type: types.AUTHORIZATION_UNAUTHORIZE,
  } as const);

export const setToken = (token: string) =>
  ({
    type: types.AUTHORIZATION_SET_TOKEN,
    payload: { token },
  } as const);

export const setTokenManual = (token: string) =>
  ({
    type: types.AUTHORIZATION_SET_TOKEN_MANUAL,
    payload: { token },
  } as const);

export const removeToken = () =>
  ({
    type: types.AUTHORIZATION_REMOVE_TOKEN,
  } as const);

export const loginWithEmailSubmit = ({ email, password }: LoginForm) =>
  ({
    type: types.LOGIN_WITH_EMAIL_SUBMIT,
    payload: {
      email,
      password,
    },
  } as const);

export const loginWithEmailSuccess = (response: object) =>
  ({
    type: types.LOGIN_WITH_EMAIL_SUCCESS,
    payload: response,
  } as const);

export const loginWithEmailFailure = (errors: ApiErrors[]) =>
  ({
    type: types.LOGIN_WITH_EMAIL_FAILURE,
    payload: errors,
  } as const);

export const loginWithGoogleAuth2Request = (token: string | undefined) =>
  ({
    type: types.LOGIN_WITH_GOOGLE_AUTH2_REQUEST,
    payload: { token },
  } as const);

export const loginWithGoogleAuth2Success = () =>
  ({
    type: types.LOGIN_WITH_GOOGLE_AUTH2_SUCCESS,
  } as const);

export const loginWithGoogleAuth2Failure = (error: object) =>
  ({
    type: types.LOGIN_WITH_GOOGLE_AUTH2_FAILURE,
    payload: error,
  } as const);

export const logoutManual = () =>
  ({
    type: types.LOGOUT_MANUAL,
  } as const);

export const logoutAutomatic = () =>
  ({
    type: types.LOGOUT_AUTOMATIC,
  } as const);

export const authenticateFailure = (errors: ApiErrors[]) =>
  ({
    type: types.AUTHENTICATION_FAILURE,
    payload: errors,
  } as const);

export const clearErrors = () =>
  ({
    type: types.CLEAR_ERRORS,
  } as const);
