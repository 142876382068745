import {
  Body as BodyDS,
  Box,
  Button,
  DialogActions,
  MaterialUICore,
  axiomTokens,
  Heading,
} from '@iclinic/design-system';

import { image } from 'shared/utils/urlGetter';

const { styled, Modal: ModalDS } = MaterialUICore;

export const Modal = styled(ModalDS)({
  display: 'flex',
});

export const ModalContainer = styled(Box)({
  display: 'flex',
  overflow: 'auto',
  flexGrow: 1,
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  background: 'linear-gradient(248.1deg, #0063CF 0%, #003F72 100%)',
});

const logoShadowStyle = {
  content: '""',
  width: '306px',
  height: '310px',
  background: `url(${image('signup.logoShadowRight')})`,
  backgroundRepeat: 'no-repeat',
};

export const FlexContainer = styled('div')(({ theme }) => ({
  '&:before': {
    ...logoShadowStyle,
    left: 0,
    top: 0,
    position: 'absolute',
  },
  '&:after': {
    ...logoShadowStyle,
    right: 0,
    bottom: 0,
    position: 'absolute',
    transform: 'rotate(180deg)',
  },
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '90%',
  maxHeight: '90%',

  [theme.breakpoints.down('xs')]: {
    padding: axiomTokens.spacing['4'],
  },
}));

export const Body = styled(BodyDS)({
  letterSpacing: '0.44px',
  marginBottom: axiomTokens.spacing['6'],
});

export const ButtonNotNow = styled(Button)(() => ({
  color: axiomTokens.color.inverse.typeface.strong,
  '&:hover': {
    background: 'none',
  },
}));

export const HeadingTitle = styled(Heading)(() => ({
  marginTop: axiomTokens.spacing['12'],
}));

export const HeadingSubtitle = styled(Heading)(() => ({
  marginTop: axiomTokens.spacing['12'],
}));

export const BodyContent = styled(Box)(() => ({
  marginTop: axiomTokens.spacing['3'],
}));

export const BodyParagraph = styled(Body)(() => ({}));

export const ImageDoctor = styled('img')(() => ({}));

export const BoxExpireTime = styled(Box)(() => ({
  background: axiomTokens.color.branding['40'],
  borderRadius: axiomTokens.border.radius.md,
  padding: '44px 0',
  border: `solid 1px ${axiomTokens.color.branding['80']}`,
}));

export const HeadingExpireTime = styled(Heading)(() => ({
  margin: `0 ${axiomTokens.spacing['2']}`,
}));

export const Container = styled(Box)(() => ({
  display: 'flex',
  gap: axiomTokens.spacing['14'],
}));

export const FooterContainer = styled(DialogActions)(() => ({
  display: 'flex',
  borderTop: `solid 1px rgba(255, 255, 255, 0.27)`,
  paddingRight: 0,
}));

export const MessageContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '565px',
}));

export const ImageContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));
