import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, FormikProvider, useFormik } from 'formik';

import {
  emailSelector,
  passwordSelector,
  statusSelector,
} from '../state/selectors';
import EmailInput from '../components/EmailStep/EmailInput';
import PasswordInput from '../components/EmailStep/PasswordInput';
import * as S from './InputStep.styles';
import { RequestStatus } from 'shared/constants/State';
import { emailFormValidation } from '../validations';
import { submitEmail } from '../state/reducer';
import { EmailStepValues, SignupSteps } from '../state/types';
import { ButtonSubmit } from '../components/Signup.styles';
import { trackSignupStep } from '../trackSignupUtils';
import { trackingStepNames } from '../constants';

const EmailStepContainer = () => {
  const status = useSelector(statusSelector);
  const dispatch = useDispatch();
  const email = useSelector(emailSelector);
  const password = useSelector(passwordSelector);

  const handleSubmit = (values: EmailStepValues) => {
    trackSignupStep({
      name: trackingStepNames[SignupSteps.Personal].next,
    });
    dispatch(submitEmail(values));
  };

  const onLoginClickHandler = useCallback(() => {
    trackSignupStep({
      name: 'next_to_login',
    });
  }, []);

  const formik = useFormik({
    initialValues: {
      email: email || '',
      password: password || '',
    },
    onSubmit: handleSubmit,
    validationSchema: emailFormValidation,
  });

  return (
    <>
      <S.FormContainer>
        <FormikProvider value={formik}>
          <Form>
            <S.Body variant="xs">
              Teste grátis, não pedimos seu cartão de crédito.
            </S.Body>
            <EmailInput />
            <PasswordInput />
            <S.EmailButtonWrapper>
              <ButtonSubmit
                color="primary"
                size="large"
                type="submit"
                fullWidth
                variant="contained"
                isLoading={status === RequestStatus.Pending}
              >
                Avançar
              </ButtonSubmit>
            </S.EmailButtonWrapper>
            <S.ButtonTerms variant="xxs">
              Ao clicar em &quot;Avançar&quot;, você aceita nossos{' '}
              <S.Link
                variant="caption"
                href="https://suporte.iclinic.com.br/iclinic-termos-de-uso"
                target="_blank"
              >
                Termos de Uso
              </S.Link>{' '}
              e{' '}
              <S.Link
                variant="caption"
                href="https://suporte.iclinic.com.br/politica-de-privacidade-iclinic"
                target="_blank"
              >
                Política de Privacidade.
              </S.Link>
            </S.ButtonTerms>
          </Form>
        </FormikProvider>
      </S.FormContainer>
      <S.Body variant="xxs">
        Já possui uma conta?{' '}
        <S.Link onClick={onLoginClickHandler} href="/new/usuarios/login">
          Acesse aqui
        </S.Link>
        .
      </S.Body>
    </>
  );
};

export default EmailStepContainer;
