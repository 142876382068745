import { call, put, takeLatest } from 'redux-saga/effects';
import { Response } from '@types';
import { AxiosError } from 'axios';

import history from 'routes/history';
import * as api from '../../services/patients';
import * as actions from '.';
import { SurveyInfoData } from '../../services/types';

type PayloadFetchSurveyInfo = ReturnType<typeof actions.fetchSurveyInfo>;
type PayloadSubmitSurveyAnswer = ReturnType<typeof actions.submitSurveyAnswer>;

const successRoute = '/new/pesquisa-de-satisfacao/sucesso';

export function* fetchSurveyInfoWorker({ payload }: PayloadFetchSurveyInfo) {
  try {
    const { data }: Response<SurveyInfoData> = yield call(
      api.getSurveyInfo,
      payload,
    );

    yield put(actions.fetchSurveyInfoSuccess(data));
  } catch (error) {
    yield put(actions.fetchSurveyInfoFailure());

    const { response } = error as AxiosError;
    const statusCode = response?.status;
    const data = response?.data;

    if (statusCode === 401 && data.status === 'Answered') {
      history.push(successRoute);
    } else if (statusCode === 404) {
      history.push('/404');
    }
  }
}

export function* submitSurveyAnswerWorker({
  payload,
}: PayloadSubmitSurveyAnswer) {
  try {
    yield put(actions.submitSurveyAnswerStart());

    const { surveyId, surveyAnswer } = payload;

    yield call(api.sendSurveyAnswer, surveyId, surveyAnswer);

    yield put(actions.submitSurveyAnswerSuccess());
    history.push(successRoute);
  } catch {
    yield put(actions.submitSurveyAnswerFailure());
  }
}

export default function* surveyPatientsSagas() {
  yield takeLatest(actions.fetchSurveyInfo, fetchSurveyInfoWorker);
  yield takeLatest(actions.submitSurveyAnswer, submitSurveyAnswerWorker);
}
