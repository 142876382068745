import React from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';

import {
  ScreenDetailsConfirmation,
  CanceledAppointment,
  SuccessAppointment,
  NotConfirmationAppointment,
} from '../pages';

const AppointmentRoutes = ({ match }: RouteComponentProps) => (
  <Switch>
    <Route
      exact
      path={`${match.path}/confirmado`}
      component={SuccessAppointment}
    />

    <Route
      exact
      path={`${match.path}/cancelado`}
      component={CanceledAppointment}
    />

    <Route
      exact
      path={`${match.path}/nao-confirmado`}
      component={NotConfirmationAppointment}
    />

    <Route
      exact
      path={`${match.path}/`}
      component={ScreenDetailsConfirmation}
    />

    <Redirect to="/404" />
  </Switch>
);

export default AppointmentRoutes;
