import { axiomTokens, MaterialUICore } from '@iclinic/design-system';

const { styled } = MaterialUICore;

const { spacing, color, border } = axiomTokens;

export const CardMain = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  minHeight: '100vh',
  width: '100%',
  justifyContent: 'center',

  [theme.breakpoints.up(600)]: {
    background: color.background['2'],
  },
}));

export const CardContainer = styled('div')({
  padding: spacing['8'],
  maxWidth: '80vw',
  margin: '0 auto',
  display: 'flex',
  flexDirection: 'column',
});

export const Card = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  paddingInline: spacing['8'],
  background: color.background['1'],

  [theme.breakpoints.up(600)]: {
    borderColor: color.border.subtle,
    borderStyle: 'solid',
    border: border.width.xs,
    borderRadius: border.radius.sm,
  },
}));

export const CardContent = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  letterSpacing: 0.44,
  alignItems: 'center',

  '& > p': {
    color: color.typeface.smooth,
    marginTop: spacing['2'],
    textAlign: 'center',
    margin: '0 auto',
    lineHeight: '28px',
  },

  [theme.breakpoints.down(500)]: {
    '& > p': {
      maxWidth: '90%',
    },
  },
}));

export const Image = styled('div')({
  display: 'flex',
  justifyContent: 'center',
});
