import { StoreState } from 'state/rootReducer';
import { LoginWithGoogle } from './types';

export const getAuthToken = ({ authv2 }: StoreState): string | null =>
  authv2.token;

export const getLoginWithGoogleReady = ({ google }: StoreState) =>
  google.auth2Ready;

export const getLoginWithGoogle = ({ authv2 }: StoreState): LoginWithGoogle =>
  authv2.loginWithGoogle;

export const getPhysicianName = ({ userInfo }: StoreState) => ({
  physicianName: userInfo.userData.userName,
});
