import {
  Box,
  Grid,
  Link,
  MaterialUICore,
  tokens,
} from '@iclinic/design-system';

const { styled, Backdrop } = MaterialUICore;
type Theme = MaterialUICore.Theme;

export const SingleSignupGrid = styled(Grid)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  margin: '0 auto',
  minHeight: '100vh',
  padding: `${tokens.spacing.base} 0`,
  flexDirection: 'column',
  [theme.breakpoints.up('md')]: {
    gap: '10%',
    maxWidth: '80%',
    padding: `80px ${tokens.spacing.sm}`,
    flexDirection: 'row',
  },
}));

export const SearchBackdrop = styled(Backdrop)(() => ({
  zIndex: 1,
}));

export const CboAutocomplete = styled(Box)(() => ({
  position: 'relative',
  zIndex: 1,
}));

export const CboActive = styled(Box)(() => ({
  position: 'fixed',
  margin: 0,
  width: `calc(100% - ${tokens.spacing.lg})`,
  maxWidth: '408px',
  top: tokens.spacing.base,
  zIndex: 1,
}));

type CheckboxProps = {
  theme: Theme;
  error?: string;
};

export const CheckboxText = styled(Link)(({ theme, error }: CheckboxProps) => ({
  fontSize: '14px',
  lineHeight: '16px',
  '&:focus': {
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: '4px',
  },
  color: error ? theme.palette.error.main : '',
}));
