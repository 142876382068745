import { axiomTokens, MaterialUICore } from '@iclinic/design-system';

const { styled } = MaterialUICore;

const { border, spacing, color } = axiomTokens;

export const CardMain = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  minHeight: '100vh',
  width: '100%',

  [theme.breakpoints.up(700)]: {
    background: color.background['2'],
  },
}));

export const CardContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  padding: spacing['8'],
  maxWidth: 958,
  margin: '0 auto',

  [theme.breakpoints.down(900)]: {
    flexWrap: 'wrap',
  },

  [theme.breakpoints.down(500)]: {
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 0,
  },

  [theme.breakpoints.up(700)]: {
    gap: spacing['8'],
    alignItems: 'flex-start',
  },
}));

export const Card = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: spacing['8'],
  background: color.background['1'],

  [theme.breakpoints.up(700)]: {
    borderColor: color.border.subtle,
    borderStyle: 'solid',
    border: border.width.xs,
    borderRadius: border.radius.sm,
  },
}));

export const LoaderWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
});
