import React from 'react';

import AuthContainer, {
  SingleColContainer,
} from '../../containers/AuthContainer';
import { MatchToken } from '../types';
import {
  lazyFeature,
  injectAll,
  lazyReducer,
  lazySaga,
} from 'shared/lazyFeature';
import SuspenseFallback from 'features/new-auth/components/SuspenseFallback';

const CheckPasswordLink = lazyFeature(
  () =>
    import(
      /* webpackChunkName: "password" */ '../components/CheckPasswordLink'
    ),
  injectAll(
    lazyReducer(
      'checkPasswordLink',
      () =>
        import(/* webpackChunkName: "password" */ '../state/checkPasswordLink'),
    ),
    lazySaga(
      'newPasswordSaga',
      () => import(/* webpackChunkName: "password" */ '../state/sagas'),
    ),
  ),
);

const NewPasswordForm = lazyFeature(
  () =>
    import(/* webpackChunkName: "password" */ '../components/NewPasswordForm'),
  injectAll(
    lazyReducer(
      'newPassword',
      () => import(/* webpackChunkName: "password" */ '../state/index'),
    ),
    lazySaga(
      'newPasswordSaga',
      () => import(/* webpackChunkName: "password" */ '../state/sagas'),
    ),
  ),
);

export default ({ match }: MatchToken) => (
  <AuthContainer>
    <SingleColContainer backTo="/new/usuarios/login">
      <React.Suspense fallback={<SuspenseFallback />}>
        <CheckPasswordLink match={match}>
          <NewPasswordForm match={match} />
        </CheckPasswordLink>
      </React.Suspense>
    </SingleColContainer>
  </AuthContainer>
);
