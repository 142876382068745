import React, { useRef } from 'react';
import { Box, Button, Chip, MaterialIcons } from '@iclinic/design-system';

import * as S from './MessageContent.styles';

const { Visibility } = MaterialIcons;

interface MessageContentProps {
  initialTemplate: string;
  currentTemplate: string;
  onChange?: (newValue: string) => void;
  tags: string[];
  onPreviewClick?: () => void;
}

const MessageContent = ({
  initialTemplate,
  currentTemplate,
  onChange,
  tags,
  onPreviewClick,
}: MessageContentProps) => {
  const contentRef = useRef<HTMLInputElement>();

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!onChange) return;

    const { value: newValue } = event.target;

    onChange(newValue);
  };

  const onClickTagHandler = (tag: string) => {
    if (!contentRef.current) return;

    const { selectionStart } = contentRef.current;

    let newValue = '';
    const currentValue = contentRef.current.value;

    if (selectionStart) {
      const leftTextPart = currentValue.substring(0, selectionStart);
      const rightTextPart = currentValue.substring(
        selectionStart,
        currentValue.length,
      );

      newValue = `${leftTextPart + tag} ${rightTextPart}`;
      contentRef.current.value = newValue;

      const finalSelectionPosition = selectionStart + tag.length;
      contentRef.current.focus();
      contentRef.current.setSelectionRange(
        finalSelectionPosition,
        finalSelectionPosition,
      );
    } else {
      newValue = `${currentValue + tag} `;
      contentRef.current.value = newValue;
    }

    if (onChange) onChange(newValue);
  };

  return (
    <S.Root>
      <S.TagsWrapper>
        {tags.map((tag) => (
          <Chip
            key={tag}
            id={`tag-${tag}`}
            label={tag}
            size="small"
            clickable
            variant="outlined"
            severity={currentTemplate.includes(tag) ? 'info' : 'default'}
            onClick={() => onClickTagHandler(tag)}
          />
        ))}
      </S.TagsWrapper>

      <S.TextArea
        id="content"
        name="content"
        label="Mensagem"
        defaultValue={initialTemplate}
        onChange={onChangeHandler}
        rows={8}
        inputProps={{
          ref: contentRef,
        }}
        helperText={
          <Box display="flex" justifyContent="space-between">
            <span>
              Utilize o * (asterísco) antes e depois das palavras que você
              deseja que esteja em negrito. Ex.: *#nomedoprofissional*
            </span>
            <span>{currentTemplate.length} de 1020 caracteres</span>
          </Box>
        }
      />

      <Button
        startIcon={<Visibility />}
        color="secondary"
        size="small"
        onClick={onPreviewClick}
      >
        Pré-visualização da mensagem
      </Button>
    </S.Root>
  );
};

export { MessageContent };
