import React, { useCallback } from 'react';
import { useFormikContext } from 'formik';

import { PersonalStepValues } from '../../state/types';
import { SignupField } from './PersonalStep.styles';
import { trackSignupStep } from '../../trackSignupUtils';

const NameInput = () => {
  const { errors, touched, handleChange, handleBlur, values } =
    useFormikContext<PersonalStepValues>();

  const helperText: React.ReactNode = touched.name && errors.name;
  const hasNameError = !!(errors.name && touched.name);

  const onBlurHandler = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      trackSignupStep({
        name: 'user_full_name',
        stepValue: values.name,
      });
      handleBlur(event);
    },
    [handleBlur, values.name],
  );

  return (
    <SignupField
      label="Nome completo"
      id="name"
      name="name"
      type="text"
      placeholder=""
      autoComplete="name"
      margin="normal"
      error={hasNameError}
      helperText={helperText}
      fullWidth
      onBlur={onBlurHandler}
      onChange={handleChange}
      value={values.name}
    />
  );
};

export default NameInput;
