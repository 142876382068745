import {
  Box,
  MaterialUICore,
  tokens,
  axiomTokens,
} from '@iclinic/design-system';

import { image } from 'shared/utils/urlGetter';

const { styled } = MaterialUICore;
const { opacity } = axiomTokens;

export const BackgroundContainer = styled(Box)(() => ({
  display: 'block',
  position: 'fixed',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  backgroundColor: tokens.color.primary.light,
  opacity: opacity.level['4'],
}));

export const ImageContainer = styled(Box)(() => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
}));

export const BackgroundImage = styled('div')(() => ({
  position: 'absolute',
  top: 0,
  right: 0,
  width: 1364,
  height: 972,
  opacity: opacity.level['6'],
  backgroundImage: `linear-gradient(47deg, ${tokens.color.primary.light} 0%, ${
    tokens.color.primary.light
  } 49%, rgba(0, 0, 0, 0) 100%), url('${image('signup.background')}')`,
}));

export const BackgroundDecorator = styled('img')(() => ({
  position: 'absolute',
  bottom: 0,
  right: 0,
  maxWidth: '30%',
  maxHeight: '40%',
}));
