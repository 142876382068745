import iClinicApi from 'services/iclinic/api';
import { getUtmData } from '../../services';
import { SubscriptionData, UpdateHubspotData } from './types';

export const verifyEmail = (email: string, captcha: string) =>
  iClinicApi.post<{ email: string }>('/accounts/email/validate/', {
    email,
    captcha,
    ...getUtmData(),
  });

export const updateContact = (hubspotInfo: UpdateHubspotData) =>
  iClinicApi.post('accounts/hubspot/update-by-steps/', {
    ...hubspotInfo,
    ...getUtmData(),
  });

export const submitSignup = (subscriptionData: SubscriptionData) =>
  iClinicApi.post<{ auto_login: string }>('/subscriptions/subscription/', {
    ...subscriptionData,
    create_auto_login: true,
    setup_step: 3,
    ...getUtmData(),
  });

export const fetchFilteredListCBO = (category: string) =>
  iClinicApi.get(`/tuss/cbocompare/?all&category=${category}`);
