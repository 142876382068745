import * as Yup from 'yup';

import {
  required,
  validEmail,
  passwordValidation,
  invalidPassword,
} from 'features/new-auth/validationSchema';
import { validateBrPhone } from '../utils';

export const emailFormValidation = Yup.object({
  email: Yup.string().trim().required(required).email(validEmail),
  password: Yup.string()
    .required(required)
    .test(
      'password-validation',
      invalidPassword,
      (value) =>
        typeof value === 'string' && passwordValidation(value).isValidPassword,
    ),
});

export const personalFormValidation = Yup.object({
  name: Yup.string().trim().required(required),
  billingPhone: Yup.string()
    .required(required)
    .test(
      'br-phone',
      'Telefone inválido',
      (value) => typeof value === 'string' && validateBrPhone(value),
    ),
});

export const specialtyFormValidation = Yup.object({
  cbo: Yup.string().trim().required(required),
});
