import { ApiErrors } from '@types';
import * as types from './constants';
import filterTerms from './utils';
import { UserData, ServiceTerms } from './types';


export const getUserData = (userData: UserData) => ({
  type: types.GET_USER_INFO,
  payload: {
    userData,
  },
} as const);


export const getProfileAndClinic = (
  clinicId: string,
  profileId: string,
) => ({
  type: types.GET_PROFILE_CLINIC,
  payload: {
    clinicId,
    profileId,
  },
} as const);

export const getProfileAndClinicFailure = (errors: ApiErrors[]) => ({
  type: types.GET_PROFILE_CLINIC_FAILURE,
  payload: {
    errors,
  },
} as const);

export const fetchUserInfo = () => ({
  type: types.FETCH_USER_INFO,
} as const);

export const acceptTerms = (termObject: ServiceTerms) => ({
  type: types.ACCEPT_TERMS,
  payload: {
    termObject,
    isFetching: true,
  },
} as const);

export const acceptTermsSuccess = () => ({
  type: types.ACCEPT_TERMS_SUCCESS,
  payload: { isFetching: false },
} as const);

export const setTerms = (listTerms: ServiceTerms[]) => ({
  type: types.SET_TERMS,
  payload: { listTerms: filterTerms(listTerms) },
} as const);
