import { Avatar, Body, Heading, MaterialIcons } from '@iclinic/design-system';
import React from 'react';
import { useSelector } from 'react-redux';

import * as S from './CardAppointmentDetails.styles';
import { Card } from './ScreenDetailsConfirmation.styles';
import { getScheduleInfoData } from 'features/messaging/state/patients/selectors';
import {
  formatAddressMessage,
  formatCityStateMessage,
  formatInFullDateMessage,
  formatPhysicianMessage,
} from '../../../formatters';
import { phoneFormatter } from 'shared/utils/formatters';
import { getInitials } from 'shared/utils/v2/strings';
import { formatTime } from 'shared/utils/date';

const { AccessTime, TodayIcon, Phone, PinDropRounded } = MaterialIcons;

export default function CardAppointmentDetails() {
  const appointmentData = useSelector(getScheduleInfoData);

  if (!appointmentData) {
    return null;
  }

  const { physician, clinic, event_date, event_time } = appointmentData;

  const detailsPhysicianCredentials = formatPhysicianMessage(
    physician.cbo,
    physician.council_name,
    physician.council_number,
    physician.council_region,
  );
  const cityAndState = formatCityStateMessage(clinic.city, clinic.state);
  const address = formatAddressMessage(
    clinic.address,
    clinic.number,
    clinic.complement,
  );

  return (
    <Card>
      <S.CardHeader>
        <Avatar size="md" src={physician.picture_url || undefined}>
          {getInitials(physician.name || '')}
        </Avatar>
        <div>
          <Heading variant="md">{physician.name || 'Não informado'}</Heading>
          <Body variant="xxs"> {detailsPhysicianCredentials || '-'}</Body>
        </div>
      </S.CardHeader>

      <S.CardContent>
        <Heading variant="xs"> {clinic.name || 'Não informado'}</Heading>

        <S.BoxText>
          <TodayIcon color="inherit" fontSize="medium" />

          <Body variant="xs">
            {' '}
            {event_date ? formatInFullDateMessage(event_date) : ''}
          </Body>
        </S.BoxText>

        <S.BoxText>
          <AccessTime color="inherit" fontSize="medium" />

          <Body variant="xs"> {event_time ? formatTime(event_time) : ''}</Body>
        </S.BoxText>

        <S.BoxText>
          <PinDropRounded color="inherit" fontSize="medium" />

          <Body variant="xs">
            {address && <span>{address}</span>}

            {clinic.neighborhood && (
              <>
                <br />
                <span>{clinic.neighborhood}</span>
              </>
            )}

            {cityAndState && (
              <>
                <br />
                <span>{cityAndState}</span>
              </>
            )}

            {clinic.zip_code && (
              <>
                <br />
                <span>{clinic.zip_code}</span>
              </>
            )}
          </Body>
        </S.BoxText>

        <S.BoxText>
          <Phone color="inherit" fontSize="medium" />

          <Body variant="xs">
            {' '}
            {clinic.phone ? phoneFormatter(clinic.phone) : ''}
          </Body>
        </S.BoxText>
      </S.CardContent>
    </Card>
  );
}
