import surveyApi from 'services/iclinic/surveyApi';
import { FilterData } from '../types';

export const getSurveyEvents = ({
  page,
  physician,
  dateRange = 'last_30_days',
  status,
}: FilterData) => {
  const params: string[] = [`page=${page}`, `date_range=${dateRange}`];

  if (physician) {
    params.push(`physician=${physician}`);
  }

  if (status) {
    params.push(`status=${status}`);
  }

  return surveyApi.get(`/external/events/?${params.join('&')}`);
};

export const getPhysicians = () => surveyApi.get('/external/physicians');

export const getSurveyAssessmentLink = (eventId: number) =>
  surveyApi.get(`/external/events/${eventId}/`);
