import React from 'react';
import { Body } from '@iclinic/design-system';

interface SectionHeaderProps {
  title: React.ReactNode;
  description: React.ReactNode;
}

const SectionHeader = ({ description, title }: SectionHeaderProps) => (
  <div>
    <Body variant="sm" bold>
      {title}
    </Body>
    <Body variant="xs">{description}</Body>
  </div>
);

export { SectionHeader };
