const { ASSETS_PATH } = process.env;

const images = {
  onBoarding: {
    schedule: `${ASSETS_PATH as string}images/onBoarding/onboarding-agenda.svg`,
    records: `${ASSETS_PATH as string}images/onBoarding/onboarding-prontuario.svg`,
    finances: `${ASSETS_PATH as string}images/onBoarding/onboarding-financeiro.svg`,
    logo: `${ASSETS_PATH as string}images/onBoarding/onboarding-logo.svg`,
    shapeUp: `${ASSETS_PATH as string}images/onBoarding/onboarding-shape-up.svg`,
    shapeDown: `${ASSETS_PATH as string}images/onBoarding/onboarding-shape-down.svg`,
  },
};

export default images;
