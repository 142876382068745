import { SignupSteps } from './state/types';

export const emailMessageByErrorCode = {
  ['409802' as string]: 'O e-mail já possui uma conta iClinic.',
  ['400801' as string]: 'O e-mail informado é inválido',
} as const;

export const STEPS_NUMBER = 6;

export const HUBSPOT_LOGIN_EVENT = 'pe5057975_login_test';

export const trackingStepNames = {
  [SignupSteps.Email]: {
    back: 'back_to_email',
  },
  [SignupSteps.Job]: {
    back: 'back_to_profession',
    next: 'next_to_profession',
  },
  [SignupSteps.Personal]: {
    back: 'back_to_personal_data',
    next: 'next_to_personal_data',
  },
  [SignupSteps.ProfessionCount]: {
    back: 'back_to_professionals_quantity',
    next: 'next_to_professionals_quantity',
  },
  [SignupSteps.Specialty]: {
    next: 'next_to_specialty',
  },
} as const;

export const urlSignupCampaign = 'subscribe';
