import { AxiosError } from 'axios';
import { ApiErrors } from '@types';

import { defaultErrorCode } from './constants';
import { CBOList, FetchListCBORequest, SubscriptionSubmit } from './types';
import { getMultipleUrlParams, getUrlParams } from 'shared/utils/url';
import { getCookie } from 'shared/utils/cookie';

interface EmailError {
  errors: ApiErrors[];
}

interface SubmitSubscriptionError {
  message: {
    [key: string]: string[];
  };
}

interface NormalizeHubspotPayload {
  email: string;
  name: string;
  billing_phone: string;
  profession: string;
  cbo: string;
  cbosList?: FetchListCBORequest;
  professionals_count: string;
  coupon?: string;
}

export const getErrorCode = (e: AxiosError<EmailError>): string =>
  e?.response?.data?.errors?.[0]?.code || e.message || defaultErrorCode;
export default getErrorCode;

export const getResponseErrors = (
  e: AxiosError<SubmitSubscriptionError>,
): string => {
  const message = e.response?.data?.message;
  if (message) {
    return Object.keys(message)
      .map((key) => message[key].join(' '))
      .join(' ');
  }
  return defaultErrorCode;
};

export const normalizeHubspot = ({
  email,
  name,
  billing_phone,
  profession,
  cbo,
  cbosList,
  professionals_count,
  coupon,
}: NormalizeHubspotPayload) => {
  const selectedCBO =
    cbosList && cbosList.objects.find((cbos: CBOList) => cbos.term === cbo);

  return {
    email,
    name,
    phone: billing_phone,
    job_title: profession,
    profession: cbo,
    cbo: selectedCBO?.cbo,
    professionals_count,
    coupon,
  };
};

export function validateBrPhone(value: string) {
  let isValid = false;
  isValid = /^\([1-9]{2}\) [2-9][0-9]{3,4}-[0-9]{4}$/.test(value);

  if (value !== undefined && isValid) {
    const validators = [
      (phoneNumber: string) => !/^(\d)\1+$/.test(phoneNumber),
      (phoneNumber: string) => !/^(.)\1*$/.test(phoneNumber),
    ];

    const phoneNumber = value.replace(/\W/g, '');
    isValid = validators.every((validator) => validator(phoneNumber));
  }

  return isValid;
}

export const parseSubmitData = (data: SubscriptionSubmit) => {
  const result = data;
  delete result.cbosList;
  return result;
};

export const getCoupon = () => getUrlParams('promo');

export const getReferralCookieValue = (name: string) => {
  const referralsCookie = getCookie('iclinic_referrals');
  return referralsCookie?.split(`${name}=`).pop()?.split('|').shift() || '';
};

export const getReferralCookieParams = (...names: string[]) =>
  names.map((name) => getReferralCookieValue(name));

export const getReferralParams = () => {
  const referralParamNames = ['promo', 'referralCode', 'refSource'];
  const urlParams = getMultipleUrlParams(...referralParamNames);
  const cookieParams = getReferralCookieParams(...referralParamNames);
  const allValuesAreEmpty = urlParams.every((param) => param === '');
  return allValuesAreEmpty ? cookieParams : urlParams;
};

export const getUtmsData = () => ({
  mkt__utms: getCookie('__utms'),
});
