import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';

import { EvaluationPage, SuccessPage } from './pages';

const SurveyRoutes = ({ match }: RouteComponentProps) => (
  <Switch>
    <Route exact path={`${match.path}/sucesso`} component={SuccessPage} />

    <Route exact path={`${match.path}/:surveyId`} component={EvaluationPage} />
  </Switch>
);

export default SurveyRoutes;
