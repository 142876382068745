import React from 'react';
import { MaterialUICore } from '@iclinic/design-system';

const { SvgIcon } = MaterialUICore;

export default function GoogleIcon(): JSX.Element {
  return (
    <SvgIcon>
      <g clipPath="url(#clip0)">
        <path
          d="M18.5998 12.1608C18.5998 11.6849 18.5616 11.2065 18.48 10.7384H11.9362V13.434H15.6835C15.528 14.3033 15.0284 15.0724 14.2968 15.5611V17.3101H16.5324C17.8453 16.091 18.5998 14.2905 18.5998 12.1608Z"
          fill="#4285F4"
        />
        <path
          d="M11.9363 19C13.8074 19 15.3854 18.3801 16.5351 17.3101L14.2994 15.5611C13.6774 15.9881 12.8744 16.2298 11.9388 16.2298C10.1289 16.2298 8.59429 14.9978 8.04366 13.3414H5.73663V15.1444C6.91437 17.5082 9.31317 19 11.9363 19V19Z"
          fill="#34A853"
        />
        <path
          d="M8.04098 13.3414C7.75037 12.472 7.75037 11.5306 8.04098 10.6612V8.85817H5.7365C4.75251 10.8361 4.75251 13.1665 5.7365 15.1444L8.04098 13.3414V13.3414Z"
          fill="#FBBC04"
        />
        <path
          d="M11.9363 7.77018C12.9254 7.75474 13.8813 8.13027 14.5977 8.8196L16.5784 6.82107C15.3242 5.63275 13.6595 4.97944 11.9363 5.00001C9.31316 5.00001 6.91436 6.49184 5.73663 8.85818L8.04111 10.6612C8.58919 9.00222 10.1264 7.77018 11.9363 7.77018V7.77018Z"
          fill="#EA4335"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="13.6"
            height="14"
            fill="white"
            transform="translate(4.99982 5)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
