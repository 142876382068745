import { Response } from '@types';
import { call, put, takeLatest } from 'redux-saga/effects';
import { captureException } from '@sentry/browser';

import * as actions from '.';
import * as services from '../../services';
import {
  SurveyEventsResponse,
  Physician,
  SurveyAssessmentLink,
} from 'features/survey/services/types';

export type PayloadFetchSurveyEvents = ReturnType<
  typeof actions.fetchSurveyEvents
>;
export type PayloadFetchEvent = ReturnType<
  typeof actions.fetchSurveyAssessmentLink
>;

export function* fetchSurveyEventsWorker({
  payload,
}: PayloadFetchSurveyEvents) {
  try {
    yield put(actions.isFetchingEvents());
    const { data }: Response<SurveyEventsResponse> = yield call(
      services.getSurveyEvents,
      payload,
    );

    const { count, results } = data;

    yield put(
      actions.fetchSurveyEventsSuccess({ surveyEvents: results, count }),
    );
  } catch (erro) {
    yield put(actions.fetchSurveyEventsFailure());
  }
}

export function* fetchSurveyPhysiciansWorker() {
  try {
    const { data }: Response<Physician[]> = yield call(services.getPhysicians);

    yield put(actions.fetchSurveyPhysiciansSuccess(data));
  } catch (erro) {
    yield put(actions.fetchSurveyPhysiciansFailure());
  }
}

export function* fetchSurveyAssessmentLinkWorker({
  payload,
}: PayloadFetchEvent) {
  try {
    const { data }: Response<SurveyAssessmentLink> = yield call(
      services.getSurveyAssessmentLink,
      payload,
    );
    yield call(window.open, data.whatsapp_link, '_blank');
    yield put(actions.fetchSurveyAssessmentLinkSuccess(payload));
  } catch (error) {
    yield call(captureException, error);
  }
}

export default function* sendSurveySagas() {
  yield takeLatest(actions.fetchSurveyEvents, fetchSurveyEventsWorker);
  yield takeLatest(actions.fetchSurveyPhysicians, fetchSurveyPhysiciansWorker);
  yield takeLatest(
    actions.fetchSurveyAssessmentLink,
    fetchSurveyAssessmentLinkWorker,
  );
}
