import { createSelector } from '@reduxjs/toolkit';

import { RequestStatus } from 'shared/constants/State';
import { StoreState } from 'state/rootReducer';

export const isFetchingConfirmationTemplateMessage = ({
  messaging,
}: StoreState) =>
  messaging.scheduleConfirmation.personalization.fetcherTemplateStatus ===
  RequestStatus.Pending;

export const getConfirmationTemplateMessage = ({ messaging }: StoreState) =>
  messaging.scheduleConfirmation.personalization.templateMessage;

export const isSubmittingConfirmationTemplateMessage = ({
  messaging,
}: StoreState) =>
  messaging.scheduleConfirmation.personalization.submitTemplateStatus ===
  RequestStatus.Pending;

export const getSubmitConfirmationTemplateMessageStatus = ({
  messaging,
}: StoreState) =>
  messaging.scheduleConfirmation.personalization.submitTemplateStatus;

export const getSubmitConfirmationErrorMessage = ({ messaging }: StoreState) =>
  messaging.scheduleConfirmation.personalization.submitMessage;

export const isFetchingConfirmationPreviweMessage = ({
  messaging,
}: StoreState) =>
  messaging.scheduleConfirmation.personalization.fetcherPreviewStatus ===
  RequestStatus.Pending;

export const getConfirmationPreviewMessage = ({ messaging }: StoreState) =>
  messaging.scheduleConfirmation.personalization.previewMessage;

export const getScheduleConfirmationData = createSelector(
  [
    isFetchingConfirmationTemplateMessage,
    getConfirmationTemplateMessage,
    isSubmittingConfirmationTemplateMessage,
  ],
  (isFetchingTemplate, templateMessage, isSubmittingTemplate) => ({
    isFetchingTemplate,
    templateMessage,
    isSubmittingTemplate,
  }),
);
