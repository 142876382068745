export const getInitials = (name: string) => {
  if (!name) return '';
  const initials = name.split(' ');
  if (initials.length > 1) {
    return (
      (initials.shift() || '').charAt(0) + (initials.pop() || '').charAt(0)
    );
  }
  return name.substring(0, 2);
};
