import { Body, Heading, axiomTokens } from '@iclinic/design-system';
import { ChartData, ChartOptions, Plugin } from 'chart.js';
import React from 'react';
import { useSelector } from 'react-redux';

import { getScorePercentage } from 'features/survey/utils';
import { DoughnutChart } from '../../components';
import {
  getDashboardData,
  getDashboardTimeline,
} from '../../state/dashboard/selectors';
import * as S from './NpsChart.styles';

const { color } = axiomTokens;

function NpsChart() {
  const dashboardData = useSelector(getDashboardData);
  const timeline = useSelector(getDashboardTimeline);
  const percentageAngleArrow = getScorePercentage(dashboardData?.score);

  const data: ChartData<'doughnut'> = {
    datasets: [
      {
        backgroundColor: [
          color.highlight.red[3],
          color.highlight.red[2],
          color.highlight.orange[1],
          color.highlight.blue[1],
        ],
        borderColor: color.inverse.border.accent,
        data: [-100, 50, 25, 25],
      },
    ],
  };

  const options: ChartOptions<'doughnut'> = {
    responsive: true,
    cutout: '80%',
    circumference: 180,
    rotation: 270,
    aspectRatio: 2,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    animation: false,
  };

  const plugin: Plugin<'doughnut'> = {
    id: 'nps-pointer',
    afterDraw:
      percentageAngleArrow !== null && timeline.length
        ? (chart) => {
            const dataTotal = 100;
            const angle =
              Math.PI + (1 / dataTotal) * percentageAngleArrow * Math.PI;
            const { ctx } = chart;
            const { offsetWidth, offsetHeight } = chart.canvas;
            const coordinateX = offsetWidth / 2;
            const coordinateY = offsetHeight - 6;

            ctx.translate(coordinateX, coordinateY);
            ctx.rotate(angle);
            ctx.beginPath();
            ctx.moveTo(0, -60);
            ctx.lineTo(offsetHeight * 0.85, 0);
            ctx.lineTo(0, 60);
            ctx.fillStyle = '#FFF';
            ctx.fill();
            ctx.rotate(-angle);
            ctx.translate(-coordinateX, -coordinateY);
          }
        : undefined,
  };

  return (
    <S.ChartCard>
      <S.ChartTitle variant="overline">
        Zonas de Classificação - NPS
      </S.ChartTitle>

      <S.ChartWrapper>
        <DoughnutChart data={data} options={options} plugins={[plugin]} />

        <S.ChartNpsValueWrapper>
          <Heading variant="xl" align="center">
            {dashboardData?.score ? dashboardData.score : '-'}
          </Heading>
          <Body variant="xxs" align="center">
            sNPS
          </Body>
        </S.ChartNpsValueWrapper>
      </S.ChartWrapper>
      <S.NpsDescriptionZone>
        <S.NpsDescription>
          <S.LegendWrapper>
            <S.Bullet bgcolor={color.highlight.red[3]}>
              <span />
            </S.Bullet>

            <Body variant="xxs">Zona critíca</Body>
          </S.LegendWrapper>

          <S.LegendWrapper>
            <S.Bullet bgcolor={color.highlight.red[2]}>
              <span />
            </S.Bullet>

            <Body variant="xxs">Zona de melhorias</Body>
          </S.LegendWrapper>
        </S.NpsDescription>
        <S.NpsDescription>
          <S.LegendWrapper>
            <S.Bullet bgcolor={color.highlight.orange[1]}>
              <span />
            </S.Bullet>

            <Body variant="xxs">Zona de qualidade</Body>
          </S.LegendWrapper>

          <S.LegendWrapper>
            <S.Bullet bgcolor={color.highlight.blue[1]}>
              <span />
            </S.Bullet>

            <Body variant="xxs">Zona de excelência</Body>
          </S.LegendWrapper>
        </S.NpsDescription>
      </S.NpsDescriptionZone>

      <S.NpsExplanationText variant="xxs" align="center">
        % de promotores - % de detratores = NPS
      </S.NpsExplanationText>
    </S.ChartCard>
  );
}

export default React.memo(NpsChart);
