import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import { Box, tokens } from '@iclinic/design-system';
import { listFilteredCBO, redirectModal, storeProfession } from '../../state';
import { listCBOSelector } from '../../state/selectors';
import ProfessionalCount from './ProfessionalCount';
import ProfessionSelect from './ProfessionSelect';
import { SignupFormValues } from '../../types';
import SearchBox from './SearchBox';
import PatientModal from '../PatientModal';
import { PossibleSignupRoles, professionFilters } from '../../constants';

export const getDisplayCboField = (value: string) =>
  value === PossibleSignupRoles.Doctor ||
  value === PossibleSignupRoles.AnotherHealthProfessional;

export const showProfessionalCount = (profession: string) =>
  profession === PossibleSignupRoles.AnotherHealthProfessional ||
  profession === PossibleSignupRoles.BusinessITConsultant ||
  profession === PossibleSignupRoles.Receptionist ||
  profession === PossibleSignupRoles.ClinicAdmin;

const ProfessionFields = () => {
  const { values, setFieldValue, setFieldError } = useFormikContext<
    SignupFormValues
  >();

  const [openModalRedirect, setOpenModalRedirect] = useState(false);
  const { objects: cboList } = useSelector(listCBOSelector);
  const dispatch = useDispatch();

  const toggleDialogModal = () =>
    setOpenModalRedirect((currentValue) => !currentValue);

  const displayCboField = getDisplayCboField(values.profession);
  const displayProfessionalCount = showProfessionalCount(values.profession);

  useEffect(() => {
    const filterValue = professionFilters.get(values.profession);
    if (filterValue) dispatch(listFilteredCBO(filterValue));
  }, [dispatch, values.profession]);

  useEffect(() => {
    if (!displayCboField) {
      setFieldValue('cbo', '');
      setFieldError('cbo', '');
    }
  }, [displayCboField, setFieldError, setFieldValue]);

  useEffect(() => {
    if (!displayProfessionalCount) {
      setFieldValue('professionals_count', '');
    }
  }, [displayProfessionalCount, setFieldValue]);

  const onClickRedirectModal = () => {
    dispatch(
      redirectModal({
        url: 'https://agendarconsulta.com/',
        method: 'replace',
      }),
    );
  };

  return (
    <>
      <Box mt={tokens.spacing.sm}>
        <ProfessionSelect
          toggleDialogModal={toggleDialogModal}
          onBlur={() => dispatch(storeProfession(values.profession))}
        />
      </Box>
      {displayCboField && (
        <SearchBox
          mt={tokens.spacing.sm}
          nameField="cbo"
          optionsList={cboList}
        />
      )}
      {displayProfessionalCount && <ProfessionalCount />}

      <PatientModal
        open={openModalRedirect}
        onCancel={toggleDialogModal}
        onConfirm={onClickRedirectModal}
      />
    </>
  );
};

export default ProfessionFields;
