import { takeLatest, all, call, put, select } from 'redux-saga/effects';
import { ReCaptchaInstance } from 'recaptcha-v3';
import { AxiosError } from 'axios';

import * as actions from './index';
import { SIGNUP_STORAGE_KEY, RecaptchaEvents } from '../constants';
import {
  validateEmail,
  submitSignup,
  fetchListCBO,
  fetchCreateTermCBO,
  fetchAccountHubspot,
  loadRecaptcha,
  fetchFilteredListCBO,
} from '../services';
import {
  getErrorCode,
  getReferralParams,
  getUtmsData,
  normalizeHubspot,
  parseSubmitData,
} from '../utils';
import { captureException } from 'shared/utils/handlerErrors';
import { workeredirect } from 'state/shared/sagas';
import { SignupState, SubscriptionSubmit } from '../types';
import { autoLoginSelector, getEmbed, signupSelector } from './selectors';
import { dispatchGaEvent } from 'shared/utils/googleAnalytics';
import { dispatchHubspotEvent } from 'shared/utils/hubspot';

export function* storeState() {
  const signupState: SignupState = yield select(signupSelector);
  const {
    progressStep,
    email,
    name,
    billing_phone,
    cbo,
    profession,
    professionals_count,
    autoLogin,
  } = signupState;
  const saveState = {
    progressStep,
    email,
    name,
    billing_phone,
    cbo,
    profession,
    professionals_count,
    autoLogin,
  };

  yield call(
    [localStorage, 'setItem'],
    SIGNUP_STORAGE_KEY,
    JSON.stringify(saveState),
  );
}

export function* getRecaptchaToken(action: string) {
  try {
    const recaptcha: ReCaptchaInstance = yield call(loadRecaptcha);
    return (yield call([recaptcha, 'execute'], action)) as string;
  } catch (e) {
    // it throws a null when domain is not set correctly for the key
    if (e === null) {
      throw new Error('RecaptchaNullError');
    }

    throw e as Error;
  }
}

export function* workerListCBO() {
  try {
    const { errors, getResponseData } = yield call(fetchListCBO);

    if (errors) throw errors;

    const listCBO = getResponseData();

    yield put(actions.submitListCBO(listCBO));
  } catch (e) {
    yield call(captureException, e);
  }
}

export function* workerFilteredListCBO({ payload }: { payload: string }) {
  try {
    const { data } = yield call(fetchFilteredListCBO, payload);
    yield put(actions.submitListCBO(data));
  } catch (e) {
    yield call(captureException, e);
  }
}

export function* workerCreateTermCBO({ payload: term }: { payload: string }) {
  try {
    const { errors, getResponseData } = yield call(fetchCreateTermCBO, term);

    if (errors) throw errors;

    const newCBO = getResponseData();
    yield put(actions.submitNewCBOTerm(newCBO));
  } catch (e) {
    yield call(captureException, e);
  }
}

export function* submitTrialWorker({
  payload: data,
}: {
  payload: SubscriptionSubmit;
}) {
  const { email, termsOfServiceAndPrivacyPolicy } = data;
  const isEmbed: boolean = yield select(getEmbed);

  try {
    yield put(actions.submitEmail(email));
    const captcha: string = yield call(
      getRecaptchaToken,
      RecaptchaEvents.Email,
    );
    yield call(validateEmail, email, captcha);

    yield put(actions.submitAccount(data));

    const [coupon, referralCode, refSource] = getReferralParams();
    const hubpostPayloadState = normalizeHubspot({ ...data, coupon });
    yield call(fetchAccountHubspot, hubpostPayloadState);

    const submitData = {
      ...parseSubmitData(data),
      termsOfServiceAndPrivacyPolicy: isEmbed || termsOfServiceAndPrivacyPolicy,
      ...(coupon && { coupon }),
      ...(referralCode && { referral: referralCode }),
      ...(refSource && { ref_source: refSource }),
    };

    const {
      data: { auto_login },
    } = yield call(submitSignup, submitData);

    yield call(dispatchGaEvent, 'on_signup');
    yield call(dispatchGaEvent, 'on_login', getUtmsData());
    yield call(dispatchHubspotEvent, 'pe5057975_login_test', {
      email,
      ...getUtmsData(),
    });

    if (isEmbed) {
      yield call([localStorage, 'removeItem'], SIGNUP_STORAGE_KEY);
      window.parent.postMessage(
        { autoLogin: auto_login },
        process.env.ICLINIC_LPS_URL || '',
      );
    }
    yield put(actions.setAutoLogin(auto_login));
    yield call(storeState);
  } catch (e) {
    yield put(actions.requestError(getErrorCode(e as AxiosError)));
    yield call(captureException, e);
  }
}

export function* submitAutoLoginWorker() {
  const auto_login: string = yield select(autoLoginSelector);

  yield call([localStorage, 'removeItem'], SIGNUP_STORAGE_KEY);
  yield call([window.location, 'replace'], `${auto_login}&next=/agenda/`);
}

export function* storeProfessionWorker({ payload }: { payload: string }) {
  yield put(actions.setProfession(payload));
  yield call(storeState);
}

export default function* signupSagas() {
  yield all([
    takeLatest(actions.listCBO, workerListCBO),
    takeLatest(actions.listFilteredCBO, workerFilteredListCBO),
    takeLatest(actions.redirectModal, workeredirect),
    takeLatest(actions.createCBO, workerCreateTermCBO),
    takeLatest(actions.triggerSubmitTrial, submitTrialWorker),
    takeLatest(actions.triggerSubmitAutoLogin, submitAutoLoginWorker),
    takeLatest(actions.storeProfession, storeProfessionWorker),
  ]);
}
