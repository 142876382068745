import { axiomTokens, MaterialUICore } from '@iclinic/design-system';

const { styled } = MaterialUICore;

const { spacing } = axiomTokens;

export const PaginationWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  padding: `${spacing['3']} 0`,
});
