import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import {
  Chart as ChartJS,
  ArcElement,
  DoughnutController,
  CategoryScale,
  ChartData,
  ChartOptions,
  ChartConfiguration,
  Plugin,
} from 'chart.js';

ChartJS.register(ArcElement, DoughnutController, CategoryScale);

interface DoughnutChartProps {
  data: ChartData<'doughnut'>;
  options?: ChartOptions<'doughnut'>;
  plugins?: Plugin<'doughnut'>[];
}

function DoughnutChart({ data, options, plugins }: DoughnutChartProps) {
  const config = useMemo<ChartConfiguration<'doughnut'>>(
    () => ({
      type: 'doughnut',
      data,
      options,
      plugins,
    }),
    [data, options, plugins],
  );

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const chartRef = useRef<ChartJS<'doughnut'> | null>();

  const renderChart = useCallback(() => {
    if (!canvasRef.current) return;

    chartRef.current = new ChartJS(canvasRef.current, config);
  }, [config]);

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.data = data;
      chartRef.current.update();
    }

    return () => {
      if (chartRef.current) {
        chartRef.current.destroy();
        chartRef.current = null;
      }
    };
  }, [data]);

  useEffect(() => {
    renderChart();
  }, [renderChart]);

  return <canvas ref={canvasRef} />;
}

export default React.memo(DoughnutChart);
