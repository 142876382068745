export function validateFormattedBrPhone(value: string) {
  let isValid = false;
  isValid = /^\([1-9]{2}\) [2-9]\d{3,4}-\d{4}$/.test(value);

  if (value !== undefined && isValid) {
    const validators = [
      (phone: string) => !/^(\d)\1+$/.test(phone),
      (phone: string) => !/^(.)\1*$/.test(phone),
    ];

    const phoneNumber = value.replace(/\W/g, '');
    isValid = validators.every((validator) => validator(phoneNumber));
  }

  return isValid;
}
