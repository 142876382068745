import React from 'react';
import { useSelector } from 'react-redux';

import AuthContainer, {
  DoubleColLayout,
  AuthContentContainer,
  SingleColLayout,
} from './AuthContainer';
import {
  getCampaigns,
} from 'state/campaignManager/selectors';
import { LoginFormV2 } from '../components';
import CampaignImageContainer from './CampaignImageContainer';
import { Filters } from 'state/campaignManager/types';

export default () => {
  const campaignsImage = useSelector((state) =>
    getCampaigns('login-img-description', Filters.images)(state),
  );
  const campaignsImageFallback = useSelector((state) =>
    getCampaigns('login-img-fallback-description', Filters.images)(state),
  );
  const checkCampaigns = campaignsImage?.length > 0;

  const checkCampaignsFallback = campaignsImageFallback?.length > 0;

  const leftCol = (
    <AuthContentContainer>
      <LoginFormV2 />
    </AuthContentContainer>
  );

  const rightCol = () => <CampaignImageContainer />;

  return (
    <AuthContainer>
      {checkCampaigns && checkCampaignsFallback ? <DoubleColLayout leftCol={leftCol} rightCol={rightCol} /> :
      <SingleColLayout>
        {leftCol}
      </SingleColLayout>}
    </AuthContainer>
  );
};
