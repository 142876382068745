import { Body, MaterialUICore, axiomTokens } from '@iclinic/design-system';

const { styled } = MaterialUICore;
const { spacing, color, font } = axiomTokens;

const colorBackground = '#300B41';

export const Root = styled('div')(({ theme }) => ({
  display: 'none',
  background: colorBackground,
  padding: `${spacing[3]} ${spacing[4]}`,
  '& + .MuiBox-root': {
    paddingTop: spacing[8],
  },
  [theme.breakpoints.up('md')]: {
    padding: `${spacing[3]} ${spacing[8]}`,
  },
}));

export const Title = styled(Body)({
  color: color.inverse.typeface.strong,
  fontWeight: font.weight.bold,
  fontSize: font.size.xs,
});

export const Subtitle = styled(Title)({
  fontWeight: font.weight.regular,
});
