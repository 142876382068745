import React from 'react';

import {
  Body,
  Box,
  Button,
  Dialog,
  Heading,
  tokens,
} from '@iclinic/design-system';

interface ModalProps {
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

const PatientModal = ({ open, onCancel, onConfirm }: ModalProps) => {
  return (
    <Dialog data-testid="signup-patient-modal" open={open} keepMounted>
      <Box
        px={tokens.spacing.md}
        py={tokens.spacing.sm}
        display="flex"
        flexDirection="column"
      >
        <Box mb={tokens.spacing.md}>
          <Heading variant="md">Conheça o AgendarConsulta</Heading>
        </Box>
        <Box width={372} mb={tokens.spacing.sm}>
          <Body variant="xs">
            Você está se cadastrando em um sistema exclusivo para clínicas e
            consultórios. Para agendar sua consulta, conheça a nossa solução que
            une médicos e pacientes.
          </Body>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Button
            color="transparent"
            onClick={onCancel}
            data-ga="setup-cancel-patient-modal"
          >
            Cancelar
          </Button>
          <Button
            onClick={onConfirm}
            variant="contained"
            color="primary"
            data-ga="setup-know-about-patient-modal"
          >
            Saiba mais
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default PatientModal;
