import {
  Body,
  MaterialUICore,
  axiomTokens,
  Select,
} from '@iclinic/design-system';

const { styled } = MaterialUICore;
const { color, border, spacing } = axiomTokens;

export const Card = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  rowGap: spacing[8],
  backgroundColor: color.background[1],
  border: `1px solid ${color.border.subtle}`,
  borderRadius: border.radius.sm,
  padding: spacing[6],
});

export const MessageContentWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  rowGap: spacing[6],
});

export const TitleAndSubtitleWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  rowGap: spacing[1],
});

export const Subtitle = styled(Body)({
  color: color.typeface.smooth,
});

export const PhoneContentWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  rowGap: spacing[4],
  alignItems: 'flex-start',
});

export const ConfirmTriggersWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  rowGap: spacing[3],
});

export const FieldsWrapper = styled('div')({
  display: 'flex',
  columnGap: spacing[4],
  justifyContent: 'flex-start',

  '& > div': {
    maxWidth: 224,
  },
});

export const ButtonsWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  columnGap: spacing[4],
});

export const SelectPhysician = styled(Select)({
  maxWidth: 264,
});

export const PanelWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing[3],
});
