import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import {
  Chart as ChartJS,
  ChartConfiguration,
  LineElement,
  PointElement,
  LineController,
  LinearScale,
  CategoryScale,
  ChartOptions,
  ChartData,
  Tooltip,
  Filler,
} from 'chart.js';

ChartJS.register(
  LineElement,
  PointElement,
  LineController,
  LinearScale,
  CategoryScale,
  Tooltip,
  Filler,
);

interface LineChartProps {
  data: ChartData<'line'>;
  options: ChartOptions<'line'>;
}

function LineChart({ data, options }: LineChartProps) {
  const config = useMemo<ChartConfiguration<'line'>>(
    () => ({ type: 'line', data, options }),
    [data, options],
  );

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const chartRef = useRef<ChartJS<'line'> | null>(null);

  const renderChart = useCallback(() => {
    if (!canvasRef.current) return;

    chartRef.current = new ChartJS(canvasRef.current, config);
  }, [config]);

  const destroyChart = useCallback(() => {
    if (chartRef.current) {
      chartRef.current.destroy();
      chartRef.current = null;
    }
  }, []);

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.data = config.data;
      chartRef.current.update();
    }

    return () => destroyChart();
  }, [config.data, destroyChart]);

  useEffect(() => {
    renderChart();

    return () => destroyChart();
  }, [destroyChart, renderChart]);

  return <canvas ref={canvasRef} />;
}

export default React.memo(LineChart);
