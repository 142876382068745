import {
  Heading,
  MaterialUICore,
  Chip as DSChip,
  ChipProps,
  axiomTokens,
} from '@iclinic/design-system';

const { styled } = MaterialUICore;

const { color, spacing, font, border } = axiomTokens;

export const StepHeading = styled(Heading)({
  color: color.typeface.pure,
  marginBottom: spacing['2'],
});

export const ChipsContainer = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  gap: spacing['3'],
  margin: `${spacing['6']} 0 ${spacing['16']}`,
});

type ChipType = {
  theme: MaterialUICore.Theme;
} & ChipProps;

export const Chip = styled(DSChip)(({ theme, active }: ChipType) => ({
  padding: spacing['3'],
  fontSize: font.size.xs,
  lineHeight: font.lineHeight.xl,
  height: 'unset',
  borderRadius: border.radius.xxl,
  width: '100%',

  '&.MuiChip-outlined.MuiChip-clickable': {
    backgroundColor: active ? color.interactive.accent.default : 'transparent',
    color: active
      ? color.inverse.interactive.accent.default
      : color.typeface.pure,
  },

  '&.MuiChip-clickable:hover': {
    color: color.interactive.accent.default,
    borderColor: color.interactive.accent.default,
  },

  [theme.breakpoints.up('sm')]: {
    width: 'unset',
  },
}));
