import { Response } from '@types';
import { call, put, takeLatest } from 'redux-saga/effects';
import { AxiosError } from 'axios';

import * as api from '../../services/patients';
import {
  ScheduleInfoData,
  ScheduleStatus,
} from 'features/messaging/services/types';
import * as actions from '.';
import history from 'routes/history';
import { getUrlParams } from 'shared/utils/url';

type PayloadSendSchedulingResponse = ReturnType<
  typeof actions.sendSchedulingAnswer
>;

export function* fetchScheduleInfoWorker() {
  try {
    const token = getUrlParams('token');
    const { data }: Response<ScheduleInfoData> = yield call(
      api.getScheduleInfo,
      token,
    );
    yield put(actions.fetchScheduleInfoSuccess(data));
  } catch (error) {
    yield put(actions.fetchScheduleInfoFailure());
    history.push('/404');
  }
}

export function* sendSchedulingResponseWorker({
  payload: scheduleStatus,
}: PayloadSendSchedulingResponse) {
  try {
    const token = getUrlParams('token');

    yield put(actions.sendSchedulingAnswerStart());
    yield call(api.sendSchedulingResponse, scheduleStatus, token);
    yield put(actions.sendSchedulingAnswerSuccess());

    const routeMapping = {
      [ScheduleStatus.CONFIRMATION]: '/new/confirmacao-agenda/confirmado',
      [ScheduleStatus.CANCEL]: '/new/confirmacao-agenda/cancelado',
    };

    const targetRoute = routeMapping[scheduleStatus];

    if (targetRoute) {
      history.push(targetRoute);
    }
  } catch (error) {
    yield put(actions.sendSchedulingAnswerFailure());
    const { response } = error as AxiosError;
    const statusCode = response?.status;
    if (statusCode === 400) {
      history.push('/new/confirmacao-agenda/nao-confirmado');
    }
  }
}

export default function* schedulePatientsSagas() {
  yield takeLatest(actions.fetchScheduleInfo, fetchScheduleInfoWorker);
  yield takeLatest(actions.sendSchedulingAnswer, sendSchedulingResponseWorker);
}
