import React from 'react';

import { Box, MaterialUICore } from '@iclinic/design-system';

const { CircularProgress } = MaterialUICore;

const SuspenseFallback = () => {
  return (
    <Box my={3} display="flex" justifyContent="center">
      <CircularProgress />
    </Box>
  );
};

export default SuspenseFallback;
