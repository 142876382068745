import { all, fork, call, put } from 'redux-saga/effects';

import { loadAPI } from 'services/google';
import { googleApiInitSuccess, googleApiInitFailure } from './actions';

export function* loadGoogleAPI() {
  try {
    yield call(loadAPI);
    yield put(googleApiInitSuccess());
  } catch (error) {
    yield put(googleApiInitFailure());
  }
}

export default function* googleSagas() {
  yield all([fork(loadGoogleAPI)]);
}
