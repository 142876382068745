import moment from 'moment';

type MaskMap = {
  [index: number]: { prefix?: string; suffix?: string };
};

export const onlyNumbers = (string: string) =>
  (string?.match(/\d+/g) || []).join('');

export const limitNumbers = (str: string, minLength: number) => {
  const number = onlyNumbers(str);
  if (number.length >= minLength) return number.substr(0, minLength);

  return number;
};

export const monthYear = (string: string) => {
  const digits = onlyNumbers(string);

  const TOTAL_CHARS = 5;

  const chars = digits.split('');
  return chars
    .reduce(
      (acc: string, val: string, index: number) =>
        index === 2 ? `${acc}/${val}` : `${acc}${val}`,
      '',
    )
    .substr(0, TOTAL_CHARS);
};

export const creditCardNumber = (string: string) => {
  const digits = onlyNumbers(string);

  const chars = digits.split('');
  const spacesEvery = [4, 8, 12];
  const TOTAL_CHARS = 19;

  return chars
    .reduce(
      (acc: string, val: string, index: number) =>
        spacesEvery.includes(index) ? `${acc} ${val}` : `${acc}${val}`,
      '',
    )
    .substr(0, TOTAL_CHARS);
};

function reduceMask(chars: string[], mask: MaskMap, length: number) {
  return chars
    .reduce((acc: string, val: string, index: number) => {
      const { prefix = '', suffix = '' } = mask[index] || {};
      return `${acc}${prefix}${val}${suffix}`;
    }, '')
    .substr(0, length);
}

export const phoneFormatter = (string: string) => {
  const digits = onlyNumbers(string);
  const chars = digits.split('');

  const PHONE_LENGTH = 11;
  const TOTAL_CHARS = 15;
  const DIVIDER = digits.length < PHONE_LENGTH ? 6 : 7;

  const mask: MaskMap = {
    0: { prefix: '(' },
    1: { suffix: ')' },
    2: { prefix: ' ' },
    [DIVIDER]: { prefix: '-' },
  };
  return reduceMask(chars, mask, TOTAL_CHARS);
};

export const dateFormatter = (string: string) => {
  const digits = onlyNumbers(string);

  return moment(digits, 'YYYYMMDD').format('DD/MM/YYYY');
};

export const cnpjFormatter = (string: string) => {
  const digits = onlyNumbers(string);
  const chars = digits.split('');

  const TOTAL_CHARS = 18;
  const mask: MaskMap = {
    1: { suffix: '.' },
    4: { suffix: '.' },
    7: { suffix: '/' },
    11: { suffix: '-' },
  };
  return reduceMask(chars, mask, TOTAL_CHARS);
};

export const cpfFormatter = (string: string) => {
  const digits = onlyNumbers(string);
  const chars = digits.split('');

  const TOTAL_CHARS = 14;
  const mask: MaskMap = {
    2: { suffix: '.' },
    5: { suffix: '.' },
    8: { suffix: '-' },
  };
  return reduceMask(chars, mask, TOTAL_CHARS);
};

export const agencyFormatter = (string: string) => {
  const digits = onlyNumbers(string);
  const chars = digits.split('');

  const TOTAL_CHARS = 4;
  return reduceMask(chars, {}, TOTAL_CHARS);
};

export const bankAccountFormatter = (string: string) => {
  const digits = onlyNumbers(string);
  const chars = digits.split('');

  const TOTAL_CHARS = 15;
  const DIVIDER = digits.length - 1;

  if (DIVIDER < 3) {
    return reduceMask(chars, {}, TOTAL_CHARS);
  }

  const mask: MaskMap = {
    [DIVIDER]: { prefix: '-' },
  };

  return reduceMask(chars, mask, TOTAL_CHARS);
};

export const cepFormatter = (string: string) => {
  const digits = onlyNumbers(string);
  const chars = digits.split('');

  const TOTAL_CHARS = 9;
  const mask: MaskMap = {
    4: { suffix: '-' },
  };
  return reduceMask(chars, mask, TOTAL_CHARS);
};

export const decimalFormatter = (string: string) => {
  const digits = onlyNumbers(string);
  const chars = digits.split('');

  const TOTAL_CHARS = 4;
  const DIVIDER = digits.length - 2;
  const mask: MaskMap = {
    [DIVIDER]: { prefix: ',' },
  };
  return reduceMask(chars, mask, TOTAL_CHARS);
};

export const timeFormatter = (string: string) => {
  const digits = onlyNumbers(string);
  const chars = digits.split('');

  if (
    digits.length >= 4 &&
    !digits.match(/^([0-9]|0[0-9]|1[0-9]|2[0-3])[0-5][0-9]$/)
  ) {
    return '';
  }

  const DIVIDER = digits.length - 2;
  const TOTAL_CHARS = 5;
  const mask: MaskMap = {
    [DIVIDER]: { prefix: ':' },
  };
  return reduceMask(chars, mask, TOTAL_CHARS);
};

export const monetaryFormatter = (string: string) => {
  const digits = onlyNumbers(string);
  const chars = digits.split('');

  const TOTAL_CHARS = digits.length + 1;
  const DIVIDER = digits.length - 2;
  const mask: MaskMap = {
    [DIVIDER]: { prefix: ',' },
  };
  return reduceMask(chars, mask, TOTAL_CHARS);
};

export const currencyFormatter = (value: number | string = 0) =>
  new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(typeof value === 'number' ? value : parseFloat(value));
