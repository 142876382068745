/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import { Element, scroller } from 'react-scroll';

import { Box, MaterialIcons, Alert } from '@iclinic/design-system';
import { BoxProps } from '@iclinic/design-system/dist/components/Box';

const { ErrorOutline } = MaterialIcons;

type Props = {
  message: React.ReactNode;
  name?: string;
  scrollToElement?: boolean;
} & Omit<BoxProps, 'children'>;

const ErrorAlert = ({
  message,
  name,
  scrollToElement = false,
  ...boxProps
}: Props) => {
  useEffect(() => {
    if (name && scrollToElement) {
      scroller.scrollTo(name, {
        duration: 500,
        smooth: true,
        offset: -100,
      });
    }
  }, [name, scrollToElement]);

  const alertBox = (
    <Box {...boxProps}>
      <Alert severity="error" icon={<ErrorOutline />} message={message} />
    </Box>
  );

  if (name && scrollToElement) {
    return <Element name={name}>{alertBox}</Element>;
  }

  return alertBox;
};

export default ErrorAlert;
