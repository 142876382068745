// External
import { all, spawn } from 'redux-saga/effects';

// Internal
import auth2Sagas from 'features/new-auth/state/sagas';
import campaignManagerSagas from 'state/campaignManager/sagas';
import googleSagas from 'state/google/sagas';
import { sharedSagas, keepAliveSaga } from 'state/shared/sagas';
import userInfoSagas from 'state/userInfo/sagas';
import signupSagas from 'features/new-auth/signup/state/sagas';
import surveySagas from 'features/survey/state/sagas';
import stepSignupSagas from 'features/new-auth/signup/stepSignup/state/sagas';
import messagingSagas from 'features/messaging/state/unauthenticated.sagas';

export default function* rootSaga() {
  const sagas = [
    auth2Sagas,
    campaignManagerSagas,
    googleSagas,
    sharedSagas,
    userInfoSagas,
    signupSagas,
    surveySagas,
    stepSignupSagas,
    messagingSagas,
  ];

  yield all(sagas.map((saga) => spawn(keepAliveSaga, saga)));
}
