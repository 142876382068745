import moment from 'moment';

import { capitalizeFirstLetter } from 'shared/utils/strings';

export function formatPhysicianMessage(
  cbo?: string,
  councilName?: string,
  councilNumber?: string,
  councilRegion?: string,
) {
  let credentials = '';

  if (cbo) credentials += cbo;
  if (councilName) credentials += credentials ? ` ${councilName}` : councilName;
  if (councilNumber)
    credentials += credentials ? ` ${councilNumber}` : councilNumber;
  if (councilRegion)
    credentials += credentials ? ` ${councilRegion}` : councilRegion;

  return credentials;
}

export function formatCityStateMessage(city?: string, state?: string) {
  let str = '';

  if (city) str += city;
  if (state) str += str ? ` - ${state}` : state;

  return str;
}

export function formatAddressMessage(
  street?: string,
  number?: string,
  complement?: string,
) {
  let address = '';

  if (street) address += street;
  if (number) address += address ? `, ${number}` : number;
  if (complement) address += address ? ` - ${complement}` : complement;

  return address;
}

export function formatInFullDateMessage(date: string) {
  moment.locale('pt-br');

  const formatted = moment(date, 'DD/MM/YYYY').format(
    `dddd, DD [de] MMMM [de] YYYY`,
  );
  const [first, second, third] = formatted.split(' de ');

  return [
    capitalizeFirstLetter(first),
    capitalizeFirstLetter(second),
    third,
  ].join(' de ');
}
