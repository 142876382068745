import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Body,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogHeader,
  Heading,
} from '@iclinic/design-system';

import { image } from 'shared/utils/urlGetter';
import { gotoAppRoute } from 'shared/utils/appRoutes';
import { toggleOpenSuccess } from '../../../state/messagePackage/checkout';
import {
  getOpenSuccess,
  getUpdatePermissionUrl,
} from '../../../state/messagePackage/checkout/selectors';

const ModalSuccessSubscription = () => {
  const dispatch = useDispatch();
  const openSuccess = useSelector(getOpenSuccess);
  const updatePermissionUrl = useSelector(getUpdatePermissionUrl);

  const onActionClickHandler = useCallback(() => {
    dispatch(toggleOpenSuccess());

    gotoAppRoute(
      `${updatePermissionUrl}/relacionamento/pacote-de-mensagem/detalhes?subscription=on`,
    );
  }, [dispatch, updatePermissionUrl]);

  return (
    <Dialog open={openSuccess} fullWidth maxWidth="sm">
      <DialogHeader
        title="Contratação realizada"
        onClose={onActionClickHandler}
      />

      <DialogContent>
        <Box display="flex" justifyContent="center">
          <img src={image('messaging.successUpgrade')} alt="Sucesso" />
        </Box>

        <Heading variant="xxl" align="center">
          Parabéns, você fez uma excelente escolha
        </Heading>

        <Body variant="xs" align="center">
          Você já pode fazer o uso das novas funcionalidades contratadas pelo
          menu de Relacionamento.
        </Body>
      </DialogContent>

      <DialogActions>
        <Button onClick={onActionClickHandler}>Voltar para Afya iClinic</Button>
      </DialogActions>
    </Dialog>
  );
};

export { ModalSuccessSubscription };
