import React from 'react';
import {
  Container,
  Box,
  Paper,
  Grid,
  Logo,
  Link,
  Button,
  MaterialIcons,
} from '@iclinic/design-system';

import LoginCampaignImageBackground from './LoginCampaignImageBackground';
import useStyles from './AuthContainer.styles';
import hlink from 'shared/utils/linkHandler';

const { ArrowBack } = MaterialIcons;

type AuthContainerProps = {
  children: React.ReactNode;
  backTo?: string;
};

export const SingleColLayout = ({
  children,
}: {
  children: NonNullable<React.ReactNode>;
}) => {
  const classes = useStyles();
  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      className={classes.grid}
    >
      <Container className={classes.container} disableGutters maxWidth="lg">
        {children}
      </Container>
    </Grid>
  );
};

export const AuthContentContainer = ({
  children,
  backTo,
}: AuthContainerProps) => {
  const classes = useStyles();

  return (
    <Box className={classes.paper}>
      <Paper elevation={16} className={classes.paperBorder}>
        <Grid container justify="space-between">
          {backTo && (
            <Grid item xs={3} alignItems="center">
              <Link
                href={backTo}
                variant="button"
                underline="none"
                onClick={hlink(backTo)}
              >
                <Button
                  color="transparent"
                  startIcon={<ArrowBack color="action" />}
                >
                  Voltar
                </Button>
              </Link>
            </Grid>
          )}

          <Grid item xs={backTo ? 6 : 12}>
            <Box mb={6} textAlign="center">
              <Logo width={166} />
            </Box>
          </Grid>

          {backTo && (
            <Grid item xs={3}>
              {null}
            </Grid>
          )}
        </Grid>
        {children}
      </Paper>
    </Box>
  );
};

export const SingleColContainer = ({
  children,
  backTo,
}: AuthContainerProps) => (
  <SingleColLayout>
    <AuthContentContainer backTo={backTo}>{children}</AuthContentContainer>
  </SingleColLayout>
);

export const DoubleColLayout = ({
  leftCol,
  rightCol,
}: {
  leftCol: React.ReactNode;
  rightCol: React.ReactNode;
}) => {
  const classes = useStyles();

  return (
    <Grid
      container
      direction="row"
      justify="space-around"
      alignItems="center"
      className={classes.grid}
    >
      <Box>{leftCol}</Box>
      <Box>{rightCol}</Box>
    </Grid>
  );
};

function AuthContainer({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  return (
    <>
      <LoginCampaignImageBackground />
      {children}
    </>
  );
}

export default AuthContainer;
