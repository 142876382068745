import {
  Body,
  Box,
  Logo,
  MaterialUICore,
  TextField,
  tokens,
  axiomTokens,
} from '@iclinic/design-system';

type Theme = MaterialUICore.Theme;

const { spacing, color, border, shadow, font } = axiomTokens;

const {
  styled,
  FormControlLabel,
  Checkbox: PrimitiveCheckbox,
} = MaterialUICore;

export const SignupFormContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: 480,
  maxWidth: '100%',
  background: color.background[1],
  flexShrink: 0,
  margin: '0 auto',
  [theme.breakpoints.up('md')]: {
    margin: '0',
  },
}));

export const SignupFormLogo = styled(Logo)(() => ({
  margin: '0 auto',
}));

export const SignupField = styled(TextField)(() => ({
  marginBottom: 0,
}));

export const PasswordFeedbackContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing['2'],
  marginTop: spacing['2'],
}));

type FeedbackBodyProps = {
  error?: string;
};

export const FormFeedbackBody = styled(Body)(
  ({ error }: FeedbackBodyProps) => ({
    color: error ? tokens.color.feedback.error.main : tokens.color.neutral[300],
    letterSpacing: '0.44px',
  }),
);

type TermsBodyProps = {
  theme: Theme;
  error?: string;
};

export const FormTermsBody = styled(Body)(
  ({ theme, error }: TermsBodyProps) => ({
    fontSize: font.size.xxs,
    letterSpacing: '0.44px',
    color: error ? theme.palette.error.main : tokens.color.neutral[300],
  }),
);

export const TermsControlLabel = styled(FormControlLabel)(() => ({
  marginRight: 0,
  marginTop: spacing[4],
}));

export const RecaptchaBody = styled(Body)(() => ({
  fontSize: font.size.xxxs,
  color: tokens.color.neutral[300],
  fontWeight: font.weight.medium,
  letterSpacing: '0.44px',
}));

type CheckboxProps = {
  theme: Theme;
  error?: string;
};

export const Checkbox = styled(PrimitiveCheckbox)(
  ({ theme, error }: CheckboxProps) => ({
    borderColor: error ? theme.palette.error.main : '',
    color: error ? theme.palette.error.main : tokens.color.neutral[300],
  }),
);

export const PasswordFeedback = styled(Box)({
  position: 'absolute',
  width: 'fit-content',
  height: 'fit-content',
  right: 0,
  backgroundColor: color.background[1],
  bottom: `calc(100% + ${spacing['1']})`,
  padding: spacing['3'],
  zIndex: 1,
  borderRadius: border.radius.sm,
  border: `${border.width.xs} solid ${color.border.activated}`,
  boxShadow: shadow.level['1'],

  '&:after': {
    content: '""',
    position: 'absolute',
    width: spacing['3'],
    height: spacing['3'],
    left: '50%',
    top: `calc(100% - 6px)`,
    marginLeft: `-6px`,
    border: `${border.width.xs} solid transparent`,
    borderBottomColor: color.border.activated,
    borderRightColor: color.border.activated,
    background: color.background['1'],
    borderTopLeftRadius: 2,
    transform: 'rotate(45deg)',
  },
});

export const SignupFormHeader = styled(Box)(({ theme }) => ({
  padding: `${spacing[6]} ${spacing[4]}`,
  display: 'flex',
  justifyContent: 'center',
  marginBottom: spacing[4],
  [theme.breakpoints.up('md')]: {
    margin: '0',
    padding: `${spacing[6]} ${spacing[10]}`,
  },
}));

export const ContentWrapper = styled(Box)(({ theme }) => ({
  padding: `0 ${spacing[4]} ${spacing[16]}`,
  [theme.breakpoints.up('md')]: {
    margin: 0,
    padding: `0 ${spacing[8]} ${spacing[4]}`,
  },
}));
