import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit';

import {
  CheckStepPayload,
  EmailStepValues,
  PersonalStepValues,
  Profession,
  ProfessionalCount,
  SignupState,
  SignupSteps,
  CBOList,
} from './types';
import { RequestStatus } from 'shared/constants/State';
import { SIGNUP_STORAGE_KEY } from '../../constants';

export const initialStateValues: SignupState = {
  progressStep: SignupSteps.Email,
  email: '',
  error: '',
  status: RequestStatus.Ready,
  listCBO: [],
};

const storedState = localStorage.getItem(SIGNUP_STORAGE_KEY) || '';

export const initialState: SignupState = storedState
  ? { ...initialStateValues, ...JSON.parse(storedState) }
  : initialStateValues;

const nameStore = '@webapp/signup';

export const checkStep = createAction<CheckStepPayload>(
  `${nameStore}/checkCurrentStep`,
);

export const submitEmail = createAction<EmailStepValues>(
  `${nameStore}/submitEmail`,
);
export const submitPersonal = createAction<PersonalStepValues>(
  `${nameStore}/submitPersonal`,
);
export const submitSpecialty = createAction<string>(
  `${nameStore}/submitSpecialty`,
);
export const backStep = createAction<SignupSteps>(`${nameStore}/backStep`);
export const specialtyBackStep = createAction(`${nameStore}/specialtyBackStep`);

export const submitProfession = createAction<Profession>(
  `${nameStore}/submitProfession`,
);

export const submitProfessionalCount = createAction<ProfessionalCount | null>(
  `${nameStore}/submitProfessionalCount`,
);
export const filterListCBO = createAction<string>(`${nameStore}/filterListCBO`);

const signup = createSlice({
  name: nameStore,
  initialState,
  reducers: {
    setEmailAndPassword: (state, action: PayloadAction<EmailStepValues>) => ({
      ...state,
      email: action.payload.email,
      password: action.payload.password,
      status: RequestStatus.Pending,
      error: '',
    }),
    setPersonal: (state, action: PayloadAction<PersonalStepValues>) => ({
      ...state,
      name: action.payload.name,
      billingPhone: action.payload.billingPhone,
      status: RequestStatus.Pending,
      error: '',
    }),
    setProfession: (state, action: PayloadAction<Profession>) => ({
      ...state,
      profession: action.payload,
    }),
    initRequest: (state) => ({
      ...state,
      status: RequestStatus.Pending,
      error: '',
    }),
    setProfessionalCount: (
      state,
      action: PayloadAction<ProfessionalCount>,
    ) => ({
      ...state,
      professionalCount: action.payload,
    }),
    setSpecialty: (state, action: PayloadAction<string>) => ({
      ...state,
      cbo: action.payload,
      status: RequestStatus.Pending,
      error: '',
    }),
    submitListCBO: (state, action: PayloadAction<CBOList[]>) => ({
      ...state,
      listCBO: action.payload,
    }),
    setStep: (state, action: PayloadAction<SignupSteps>) => ({
      ...state,
      progressStep: action.payload,
    }),
    resetStatus: (state) => ({
      ...state,
      status: RequestStatus.Ready,
    }),
    requestError: (state, action: PayloadAction<string>) => ({
      ...state,
      status: RequestStatus.Error,
      error: action.payload,
    }),
  },
});

export default signup.reducer;

export const {
  setEmailAndPassword,
  setPersonal,
  setSpecialty,
  setStep,
  resetStatus,
  setProfession,
  setProfessionalCount,
  requestError,
  initRequest,
  submitListCBO,
} = signup.actions;
