import qs from 'qs';

import { isValidUrlPath } from 'shared/utils/validation';

/**
 * Returns a valid next path
 * @param {string} str
 * @returns {string}
 */
export const getNextURLParam = (str: string) => {
  const { next } = qs.parse(str.replace(/^\?/, ''));

  if (typeof next === 'string' && isValidUrlPath(next)) {
    return next;
  }

  return '';
};

/**
 * Returns a valid next url
 * @param {object} location
 * @returns {string}
 */
export const getNextUrl = (location: Location) => {
  let nextUrl = location.pathname;

  if (location.query) {
    nextUrl += `?${location.query}`;
  }

  return nextUrl;
};

export const getUrlParams = (
  name: string,
  url: string = window.location.search,
) => {
  const parameterName = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');

  const regex = new RegExp(`[?&]${parameterName}=([^&#]*)`);
  const results = regex.exec(url);
  return results === null ? '' : decodeURIComponent(results[1]);
};

export const getMultipleUrlParams = (...names: string[]) => {
  return names.map((name) => getUrlParams(name));
};

export const updateURLParameter = (
  url: string,
  paramName: string,
  paramValue: string = '',
) => {
  const pattern = new RegExp(`\\b(${paramName}=).*?(&|#|$)`);
  if (url.search(pattern) >= 0) {
    return url.replace(pattern, `$1${paramValue}$2`);
  }
  const newurl = url.replace(/[?#]$/, '');
  return `${
    newurl + (newurl.indexOf('?') > 0 ? '&' : '?') + paramName
  }=${paramValue}`;
};
