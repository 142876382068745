import React from 'react';

import { MaterialUICore, Box } from '@iclinic/design-system';
import useStyles from './CampaignImageBackground.styles';

type CampaignImageProps = {
  image?: string;
  fallback?: string;
  text?: string;
  link?: string;
  loading?: boolean;
  type?: string;
};

const { CircularProgress } = MaterialUICore;

const CampaignImageBackground = ({
  image,
  fallback,
  text,
  link,
  loading,
  type = 'image/webp',
}: CampaignImageProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Box className={classes.containerImage}>
      {loading ? (
        <Box className={classes.loader}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          {link && (
            <a className={classes.link} href={link}>
              {text}
            </a>
          )}
          <picture>
            <source srcSet={image} type={type} />
            <img
              className={classes.image}
              src={fallback}
              alt={text}
              width="2050"
              height="1200"
            />
          </picture>
        </>
      )}
    </Box>
  );
};

export default CampaignImageBackground;
