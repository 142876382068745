import React from 'react';
import { MaterialIcons as Icons } from '@iclinic/design-system';

import { ApproveStatus } from '../services/types';

const tags = [
  '#nomedaclínica',
  '#pronomedetratamento',
  '#nomedopaciente',
  '#nomedoprofissional',
  '#datadaagenda',
  '#procedimento',
  '#horário',
  '#linkdeconfirmação',
  '#linktelefoneparacontato',
];

const confirmationDaysAdvanceOptions = [
  { value: 1, text: '1 dia' },
  { value: 2, text: '2 dias' },
];

const reminderDaysAdvanceOptions = [
  { value: 5, text: '5 dias' },
  { value: 10, text: '10 dias' },
  { value: 15, text: '15 dias' },
];

const timeSendOptions = [
  { value: '08:00:00', text: '08:00' },
  { value: '08:30:00', text: '08:30' },
  { value: '09:00:00', text: '09:00' },
  { value: '09:30:00', text: '09:30' },
  { value: '10:00:00', text: '10:00' },
  { value: '10:30:00', text: '10:30' },
  { value: '11:00:00', text: '11:00' },
  { value: '11:30:00', text: '11:30' },
  { value: '12:00:00', text: '12:00' },
  { value: '12:30:00', text: '12:30' },
  { value: '13:00:00', text: '13:00' },
  { value: '13:30:00', text: '13:30' },
  { value: '14:00:00', text: '14:00' },
  { value: '14:30:00', text: '14:30' },
  { value: '15:00:00', text: '15:00' },
  { value: '15:30:00', text: '15:30' },
  { value: '16:00:00', text: '16:00' },
  { value: '16:30:00', text: '16:30' },
  { value: '17:00:00', text: '17:00' },
  { value: '17:30:00', text: '17:30' },
  { value: '18:00:00', text: '18:00' },
];

const defaultMessage = `Oi #nomedopaciente, este é uma solicitação de confirmação do seu horário agendado com #nomedoprofissional para #datadaconsulta às #horário em #nomedaclínica.

Clique neste link para confirmar ou cancelar:
#linkdeconfirmação`;

const defaultMessageReminder =
  'Oi #nomedopaciente, este é um lembrete que você tem uma agenda com #nomedoprofissional para #datadaagenda às #horario em #nomedaclinica';

const successMessage = 'Configurações salvas com sucesso!';

type MessageByApproveStatusData = {
  message: React.ReactNode;
  icon: React.ReactNode;
  isError: boolean;
};

const approveMessagesByStatus: Record<string, MessageByApproveStatusData> = {
  [ApproveStatus.DISAPPROVED]: {
    message: (
      <>
        As mudanças realizadas na mensagem para o paciente não foram aprovadas.
        <br />
        Ao modificar a mensagem, siga as{' '}
        <a
          href="https://developers.facebook.com/docs/whatsapp/message-templates/guidelines/"
          target="_blank"
          rel="noreferrer"
        >
          regras de aprovação.
        </a>
      </>
    ),
    icon: <Icons.ErrorOutlineOutlined />,
    isError: true,
  },
  [ApproveStatus.PENDING]: {
    message:
      'As mudanças realizadas na mensagem para o paciente podem levar até 24hs para serem aplicadas.',
    icon: <Icons.InfoOutlined />,
    isError: false,
  },
};

export {
  tags,
  confirmationDaysAdvanceOptions,
  reminderDaysAdvanceOptions,
  timeSendOptions,
  defaultMessage,
  defaultMessageReminder,
  successMessage,
  MessageByApproveStatusData,
  approveMessagesByStatus,
};
