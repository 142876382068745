import { MaterialUICore, axiomTokens } from '@iclinic/design-system';

const { styled, Radio } = MaterialUICore;
const { border, color, spacing } = axiomTokens;

export const Card = styled('div')({
  padding: spacing[4],
  display: 'flex',
  flexDirection: 'column',
  rowGap: spacing[3],
  borderRadius: border.radius.sm,
  border: `1px solid ${color.border.subtle}`,
  cursor: 'pointer',

  '&:has(input:checked)': {
    borderColor: color.border.activated,
  },
});

export const HorizontalWrapperWithSpace = styled('div')({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',

  '& label': {
    margin: 0,
  },
});

export const SimpleRadio = styled(Radio)({
  padding: 0,
  margin: 0,
});

export const Divider = styled('div')({
  height: 1,
  paddingTop: 1,
  backgroundColor: color.background[3],
});
