import { load as loadRecaptchaScript } from 'recaptcha-v3';
import { IReCaptchaLoaderOptions } from 'recaptcha-v3/dist/ReCaptchaLoader';
import { AxiosResponse } from 'axios';
import { PromiseEnvelope } from '@types';

import api, { apiV2 } from 'services/iclinic/api';
import { getCookie } from 'shared/utils/cookie';
import {
  SubscriptionData,
  SubscriptionResponse,
  FetchListCBORequest,
} from './types';

export interface AccountHubspot {
  email: string;
  name: string;
  phone: string;
  job_title: string;
  profession: string;
  cbo: number | undefined;
  professionals_count: string;
}

export const getUtmData = () => ({
  __utmzz: getCookie('__utmzz'),
  __utms: getCookie('__utms'),
});

export const validateEmail = (email: string, captcha: string) =>
  api.post<{ email: string }>('/accounts/email/validate/', {
    email,
    captcha,
    ...getUtmData(),
  });

export const fetchListCBO = (): PromiseEnvelope<FetchListCBORequest> =>
  apiV2.get('/tuss/cbocompare/?all');

export const fetchFilteredListCBO = (
  category: string,
): Promise<AxiosResponse<FetchListCBORequest>> =>
  api.get(`/tuss/cbocompare/?all&category=${category}`);

export const submitSignup = (subscriptionData: SubscriptionData) =>
  api.post<SubscriptionResponse>('/subscriptions/subscription/', {
    ...subscriptionData,
    create_auto_login: true,
    setup_step: 3,
    ...getUtmData(),
  });

export const fetchCreateTermCBO = (term: string) =>
  apiV2.post('tuss/cbocompare/', { term });

export const fetchAccountHubspot = (hubspotPayload: AccountHubspot) =>
  api.post('accounts/hubspot/update/', {
    ...hubspotPayload,
    ...getUtmData(),
  });

export const loadRecaptcha = (options?: IReCaptchaLoaderOptions | undefined) =>
  loadRecaptchaScript(process.env.GOOGLE_RECAPTCHA_V3_SITE_KEY || '', options);
