import React from 'react';
import { useFormikContext } from 'formik';
import {
  MaterialUICore,
  MaterialIcons,
  TextField,
  InputAdornment,
  Box,
} from '@iclinic/design-system';

import { InitialValues } from '../validationSchema';
import { usePasswordToggle } from '../hooks';

const { IconButton } = MaterialUICore;

export default function LoginFields(): JSX.Element {
  const { showPassword, handleTogglePassword } = usePasswordToggle();
  const { values, errors, touched, handleChange, handleBlur } =
    useFormikContext<InitialValues>();

  return (
    <>
      <Box mb={3}>
        <TextField
          label="E-mail"
          id="email"
          name="email"
          type="email"
          autoComplete="email"
          error={!!(errors.email && touched.email)}
          helperText={touched.email && errors.email ? errors.email : null}
          fullWidth
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.email}
        />
      </Box>
      <Box mb={3}>
        <TextField
          label="Senha"
          id="password"
          name="password"
          type={showPassword ? 'text' : 'password'}
          autoComplete="current-password"
          error={!!(errors.password && touched.password)}
          helperText={
            touched.password && errors.password ? errors.password : null
          }
          fullWidth
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.password}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="Muda a visibilidade da senha"
                  onClick={handleTogglePassword}
                  edge="end"
                >
                  {showPassword ? (
                    <MaterialIcons.Visibility />
                  ) : (
                    <MaterialIcons.VisibilityOff />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
    </>
  );
}
