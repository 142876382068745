export const AUTHORIZATION_AUTHORIZE = '@webapp/auth2/AUTHORIZATION_AUTHORIZE';
export const AUTHORIZATION_UNAUTHORIZE =
  '@webapp/auth2/AUTHORIZATION_UNAUTHORIZE';
export const AUTHORIZATION_SET_TOKEN = '@webapp/auth2/AUTHORIZATION_SET_TOKEN';
export const AUTHORIZATION_REMOVE_TOKEN =
  '@webapp/auth2/AUTHORIZATION_REMOVE_TOKEN';
export const AUTHORIZATION_SET_TOKEN_MANUAL =
  '@webapp/auth2/AUTHORIZATION_SET_TOKEN_MANUAL';
export const LOGIN_WITH_EMAIL_SUBMIT = '@webapp/auth2/LOGIN_WITH_EMAIL_SUBMIT';
export const LOGIN_WITH_EMAIL_SUCCESS =
  '@webapp/auth2/LOGIN_WITH_EMAIL_SUCCESS';
export const LOGIN_WITH_EMAIL_FAILURE =
  '@webapp/auth2/LOGIN_WITH_EMAIL_FAILURE';
export const LOGIN_WITH_GOOGLE_AUTH2_REQUEST =
  '@webapp/auth2/LOGIN_WITH_GOOGLE_AUTH2_REQUEST';
export const LOGIN_WITH_GOOGLE_AUTH2_SUCCESS =
  '@webapp/auth2/LOGIN_WITH_GOOGLE_AUTH2_SUCCESS';
export const LOGIN_WITH_GOOGLE_AUTH2_FAILURE =
  '@webapp/auth2/LOGIN_WITH_GOOGLE_AUTH2_FAILURE';
export const LOGOUT_MANUAL = '@webapp/auth2/LOGOUT_MANUAL';
export const LOGOUT_AUTOMATIC = '@webapp/auth2/LOGOUT_AUTOMATIC';
export const AUTHENTICATION_FAILURE = '@webapp/auth2/AUTHENTICATION_FAILURE';
export const CLEAR_ERRORS = '@webapp/auth2/CLEAR_ERRORS';
