import { MaterialUICore, axiomTokens } from '@iclinic/design-system';

const { styled } = MaterialUICore;

const { color, spacing, border } = axiomTokens;

export const BodyContent = styled('div')({
  gap: spacing['1'],
  display: 'flex',
  flexDirection: 'column',
});

export const ContentHistory = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: spacing[4],
  background: color.background[1],
  borderRadius: border.radius.sm,
  border: `1px solid ${color.border.subtle}`,
  padding: spacing[4],

  [theme.breakpoints.up('sm')]: {
    rowGap: spacing[6],
    padding: spacing[6],
  },
}));

export const TableContent = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  rowGap: spacing[2],
});
