/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef, useState } from 'react';
import { useFormikContext } from 'formik';
import {
  Autocomplete,
  autocompleteFilter,
  TextField,
  MaterialUICore,
  tokens,
} from '@iclinic/design-system';
import { useSelector } from 'react-redux';

import { AccountFormValues } from '../../constants';
import { CBOList } from '../../types';
import SearchBoxContainer from './SearchBoxWrapper';
import { getEmbed } from '../../state/selectors';

interface SearchBoxProps {
  nameField: string;
  optionsList: SearchCBOList[];
  mt?: string;
}

export interface SearchCBOList extends CBOList {
  inputValue?: string;
}

export const getOption = (option: SearchCBOList | string) => {
  if (typeof option === 'string') return option;

  if (option && option.inputValue) {
    return option.inputValue;
  }

  return option?.term || '';
};

const SearchBox = ({
  nameField,
  optionsList,
  mt = tokens.spacing.md,
}: SearchBoxProps) => {
  const {
    errors,
    touched,
    handleChange,
    handleBlur,
    values,
    setFieldValue,
    setTouched,
  } = useFormikContext<AccountFormValues>();

  const { useTheme, useMediaQuery } = MaterialUICore;
  const autoCompleteRef = useRef<HTMLElement>();

  const [inputValue, setInputValue] = useState('');
  const [backdropEnabled, toggleBackdrop] = useState(false);
  const [isAutocompleteOpened, openAutocomplete] = useState(false);

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));
  const isEmbed = useSelector(getEmbed);

  const blurAutocompleteText = () => {
    if (autoCompleteRef.current && isXs) autoCompleteRef.current.blur();
  };

  const checksetFieldValue = (newValue: SearchCBOList) => {
    if (newValue && newValue.term) {
      blurAutocompleteText();
      toggleBackdrop(false);
      return setFieldValue(nameField, newValue.term);
    }

    return setFieldValue(nameField, '');
  };

  const filterOptionSearchBox = (options: SearchCBOList[], params: any) => {
    const customParams = { ...params, inputValue };
    return autocompleteFilter(options, customParams);
  };

  const resetInvalidField = () => {
    const optionExists = optionsList.some((item) => item.term === inputValue);
    if (!optionExists) setFieldValue(nameField, '');
  };

  const handleBlurCBO = (event: React.FocusEvent<any>) => {
    resetInvalidField();
    toggleBackdrop(false);

    handleBlur(event);
  };

  const scrollToTop = () => {
    if (isXs) setTimeout(() => window.scrollTo({ top: 0 }), 200);
  };

  const handleFocus = () => {
    scrollToTop();
    toggleBackdrop(true);
    openAutocomplete(true);
  };

  const handleTextChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    toggleBackdrop(true);
    handleChange(event);
  };

  return (
    <SearchBoxContainer
      mt={mt}
      backdropEnabled={!isEmbed && backdropEnabled}
      isXs={isXs}
    >
      <Autocomplete
        id={nameField}
        value={values.cbo}
        onFocus={handleFocus}
        open={isAutocompleteOpened}
        onChange={(_, newValue: SearchCBOList) => checksetFieldValue(newValue)}
        onBlur={() => setTouched({ cbo: true })}
        options={optionsList}
        filterOptions={filterOptionSearchBox}
        getOptionLabel={getOption}
        getOptionSelected={(option) => option.term === values.cbo}
        renderOption={(option) => option.term}
        inputValue={inputValue}
        noOptionsText="Sem opções"
        onOpen={() => openAutocomplete(true)}
        onClose={() => openAutocomplete(false)}
        onInputChange={(_, newInputValue: string) =>
          setInputValue(newInputValue)
        }
        renderInput={(params) => (
          <TextField
            {...params}
            onChange={handleTextChange}
            onBlur={handleBlurCBO}
            inputRef={autoCompleteRef}
            name={nameField}
            data-ga="setup-cbo"
            value={values.cbo}
            label="Especialização"
            error={!!(errors.cbo && touched.cbo)}
            helperText={touched.cbo && errors.cbo ? errors.cbo : ''}
          />
        )}
      />
    </SearchBoxContainer>
  );
};

export default SearchBox;
