export const GENERIC_ERROR_MESSAGE =
  'Ops, ocorreu um erro. Pedimos desculpas, por favor entre em contato com nosso suporte pelo chat.';

interface ErrorMessages<T> {
  [Key: string]: T;
}

export const patientRecordingDownloadError = '400701';

export const errorMessages: ErrorMessages<string> = {
  400000:
    'A quantidade de saída deve ser menor que a quantidade disponível do lote ou produto',
  400100:
    'Não foi selecionado um lote, clique em adicionar ou selecione um existente',
  400202:
    'Erro ao efetivar a assinatura. Os recursos adicionais podem estar incluídos no plano selecionado, verifique.',
  400501: 'Número de cartão de crédito inválido',
  400502: 'Data de validade do cartão inválida',
  400503: 'Código de segurança do cartão inválido',
  400504: 'CPF/CNPJ inválido',
  400701: 'Código de acesso inválido',
  400702: 'Não foi possível obter credenciais para teleconsulta',
  400703: 'Não foi possível obter informações do paciente',
  400704: 'Não foi possível definir paciente como conectado',
  400705: 'Não foi possível cadastrar dados do paciente',
  400721: 'Não foi possível criar sala de teleconsulta',
  400722: 'Não foi possível obter credenciais para teleconsulta',
  400723: 'Não foi possível obter informações do médico',
  400724: 'Não foi possível definir médico como conectado',
  400725: 'Não foi possível salvar as anotações',
  400741: 'Agendamento de teleconsulta não encontrada',
  400742: 'Não foi possível criar agendamento de teleconsulta',
  400743: 'Não foi possível cancelar agendamento de teleconsulta',
  400744: 'Não foi possível finalizar teleconsulta',
  400745:
    'Não foi possível alterar agendamento de teleconsulta (campos já existentes)',
  400746: 'Esta teleconsulta já terminou',
  400803: 'Prova de humano válida é requerida',
  400900: 'Você deve definir um assunto para a campanha',
  400901: 'Você deve preencher o corpo de e-mail para a campanha.',
  400902: 'Você deve informar o nome do remetente para a campanha',
  400903: 'Você deve informar o e-mail do remetente para a campanha',
  400904: 'Você deve informar o público alvo para a campanha',
  400905: 'Você deve escrever uma mensagem para a campanha',
  400906: 'Não pode criar campanhas enviadas',
  400907: 'Não pode agendar campanha com data retroativa',
  400908: 'Não pode criar campanha com mesmo nome',
  400909: 'Não foi possível carregar a lista de remetentes',
  400910: 'Campanha não foi encontrada',
  400911: 'Defina um assunto para o E-mail',
  400912: 'Defina uma mensagem para o E-mail',
  400913: 'Defina um intervalo para envio do E-mail',
  400914: 'Defina um assunto para o E-mail para ativar a sequência',
  400915: 'Intervalo duplicado',
  400916: 'Não foi possível encontrar o fluxo de E-mail',
  400917:
    'Você deve escrever ao menos uma mensagem para a sequência de e-mails',
  400918: 'Não foi possível encontrar a mensagem do fluxo de E-mail',
  400919: 'Você deve informar o e-mail do remetente para o teste',
  409003: 'Já existe um produto com esse código',
  500502: 'Pagamento não autorizado',
  500504:
    'Campanhas já enviadas não podem ser editadas. Duplique a campanha para utilizá-la novamente.',
};
