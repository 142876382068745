import { MaterialUICore, Select } from '@iclinic/design-system';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectPhysicianId } from '../state/dashboard';
import { getSelectedPhysicianId } from '../state/dashboard/selectors';
import { getSurveyPhysicians } from '../state/filters/selectors';
import * as S from './FiltersSurvey.styles';

const { MenuItem } = MaterialUICore;

type FilterProps = {
  onChange: (name: string, value: string) => void;
};

export const FiltersSurvey = ({ onChange }: FilterProps) => {
  const dispatch = useDispatch();
  const physicians = useSelector(getSurveyPhysicians);
  const physicianSelectId = useSelector(getSelectedPhysicianId);

  const onChangeFilter = (name: string, value: string) => {
    onChange(name, value);

    if (name === 'physician') {
      dispatch(selectPhysicianId(value));
    }
  };

  return (
    <S.ContentList>
      {physicians?.length ? (
        <S.SelectWrapper>
          <Select
            label="Profissional de saúde"
            onChange={(event) =>
              onChangeFilter('physician', event.target.value)
            }
            value={physicianSelectId}
            defaultValue=""
            SelectProps={{
              displayEmpty: true,
            }}
          >
            {physicians.map(({ id, name }) => (
              <MenuItem key={name} value={id}>
                {name}
              </MenuItem>
            ))}
            <MenuItem value=""> Todos </MenuItem>
          </Select>
        </S.SelectWrapper>
      ) : null}
      <S.SelectWrapper>
        <Select
          defaultValue="last_30_days"
          onChange={(event) => onChangeFilter('dateRange', event.target.value)}
          label="Data"
        >
          <MenuItem value="today">Hoje</MenuItem>
          <MenuItem value="current_week">Essa semana</MenuItem>
          <MenuItem value="last_15_days">Últimos 15 dias</MenuItem>
          <MenuItem value="this_month">Esse mês</MenuItem>
          <MenuItem value="last_30_days">Últimos 30 dias</MenuItem>
        </Select>
      </S.SelectWrapper>
      <S.SelectWrapper>
        <Select
          key=""
          label="Status"
          onChange={(event) => onChangeFilter('status', event.target.value)}
          SelectProps={{
            displayEmpty: true,
          }}
          defaultValue=""
        >
          <MenuItem value="enviado">Enviado</MenuItem>
          <MenuItem value="aguardando envio">Aguardando envio</MenuItem>
          <MenuItem value="">Todos</MenuItem>
        </Select>
      </S.SelectWrapper>
    </S.ContentList>
  );
};
