import paths from 'shared/constants/paths';
import { resolve } from './pathResolver';

/*
  usage:
  image('signup.doctorAlertMessage')
 */

type ImagePathsURLs = typeof paths.images;
type ImagePathDomain = keyof ImagePathsURLs;
type ImagePathSubDomain<Domain extends ImagePathDomain> =
  keyof ImagePathsURLs[Domain];

export const image = <PathDomain extends ImagePathDomain>(
  // @ts-ignore
  name: `${PathDomain}.${ImagePathSubDomain<PathDomain>}`,
  data = {},
): string => resolve(paths.images, name, data);

export const route = (name: string, data = {}) =>
  resolve(paths.routes, name, data);
