// External
import { combineReducers } from 'redux';

import authv2 from 'features/new-auth/state/reducer';
import signup from 'features/new-auth/signup/state';
import google from './google/reducer';
import shared from './shared/reducer';
import userInfo from './userInfo/reducer';
import campaignManager from './campaignManager/reducer';
import survey from 'features/survey/state/reducer';
import stepSignup from 'features/new-auth/signup/stepSignup/state/reducer';
import messaging from 'features/messaging/state/unauthenticated.reducer';

export const reducersMap = {
  authv2,
  google,
  shared,
  userInfo,
  campaignManager,
  signup,
  stepSignup,
  survey,
  messaging,
};

const rootReducer = combineReducers(reducersMap);

export default rootReducer;

export type StoreState = ReturnType<typeof rootReducer>;
