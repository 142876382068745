import React from 'react';
import { Body, Heading, MaterialIcons as Icons } from '@iclinic/design-system';

import { ConsumptionExceeded as ConsumptionExceededType } from '../../../../services/types';
import { kindLabelMap } from '../../../../constants';
import * as S from './Consumption.styles';
import {
  Divider,
  LineSeparatedText,
  ProgressBar,
  TitleWrapper,
} from '../Common.styles';
import { formatDate } from 'shared/utils/date';

interface ConsumptionExceededProps {
  consumptionExceeded: ConsumptionExceededType;
}

const ConsumptionExceeded = ({
  consumptionExceeded,
}: ConsumptionExceededProps) => (
  <S.Card>
    <TitleWrapper>
      <Icons.DataSaveronRounded />

      <Body variant="xs" bold>
        Consumo excedente
      </Body>
    </TitleWrapper>

    <Heading variant="xs">{consumptionExceeded.total_exceeded} envios</Heading>

    <div>
      <ProgressBar variant="determinate" value={100} />

      <LineSeparatedText variant="xxs">
        Início do consumo:{' '}
        {consumptionExceeded?.first_consumption_date_exceeded
          ? formatDate(
              consumptionExceeded.first_consumption_date_exceeded,
              'DD/MM/YYYY',
            )
          : 'Não enviado'}
      </LineSeparatedText>
    </div>

    {consumptionExceeded.kinds.length ? (
      <>
        <Divider />

        <div>
          {consumptionExceeded.kinds.map(({ kind, total }) => (
            <LineSeparatedText variant="xxs" key={kind}>
              <span>{kindLabelMap[kind]}</span>

              <strong>{total}</strong>
            </LineSeparatedText>
          ))}
        </div>
      </>
    ) : null}
  </S.Card>
);

export { ConsumptionExceeded };
