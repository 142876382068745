import { combineReducers } from 'redux';

import surveyPatientsReducer, { SurveyPatientState } from './patients';
import surveyDashboardReducer, { SurveyDashboardState } from './dashboard';
import surveyFiltersReducer, { SendSurveyState } from './filters';

export type SurveyState = {
  surveyPatients: SurveyPatientState;
  surveyDashboard: SurveyDashboardState;
  surveyFilters: SendSurveyState;
};

const surveyReducer = combineReducers<SurveyState>({
  surveyPatients: surveyPatientsReducer,
  surveyDashboard: surveyDashboardReducer,
  surveyFilters: surveyFiltersReducer,
});

export default surveyReducer;
