import axios from 'axios';

import {
  interceptorRequestAuthentication,
  interceptorRequestAuthenticationEmkt,
  interceptorResponseErrors,
  interceptorResponseTransformData,
  exportDataApiTokenInterceptor,
} from './interceptors';

const api = axios.create({
  baseURL: process.env.ICLINIC_API_URL,
  params: {},
});

/**
 * @deprecated
 * legacy to billing
 */
const apiV2 = axios.create({
  baseURL: process.env.ICLINIC_API_URL,
});

apiV2.interceptors.request.use(interceptorRequestAuthentication);
apiV2.interceptors.response.use(
  interceptorResponseTransformData,
  interceptorResponseErrors,
);

/**
 * @deprecated
 * legacy to prescriptions
 */
const apiV3 = axios.create({
  baseURL: process.env.ICLINIC_API_URL_V3,
});

apiV3.interceptors.request.use(interceptorRequestAuthentication);
apiV3.interceptors.response.use(interceptorResponseTransformData);

// api pdf serverless
const apiPdfServerless = axios.create({
  baseURL: process.env.ICLINIC_PDF_SERVERLESS_URL,
});

apiPdfServerless.interceptors.response.use(
  interceptorResponseTransformData,
  interceptorResponseErrors,
);

// api email-mkt
const apiEmkt = axios.create({
  baseURL: process.env.ICLINIC_EMAIL_MKT_URL,
});

const apiEmktPublic = axios.create({
  baseURL: process.env.ICLINIC_EMAIL_MKT_URL,
});

apiEmkt.interceptors.request.use(interceptorRequestAuthenticationEmkt);
apiEmkt.interceptors.response.use(
  interceptorResponseTransformData,
  interceptorResponseErrors,
);

apiEmktPublic.interceptors.response.use(
  interceptorResponseTransformData,
  interceptorResponseErrors,
);

const apiSlackServerless = axios.create({
  baseURL: process.env.ICLINIC_SLACK_URL_SERVERLESS,
});

apiSlackServerless.interceptors.request.use(interceptorRequestAuthentication);

// api campaign manager
const apiCampaignManager = axios.create({
  baseURL: process.env.ICLINIC_CAMPAIGN_MANAGER_URL,
});

apiCampaignManager.interceptors.response.use(interceptorResponseTransformData);

// api signature
const apiSignature = axios.create({
  baseURL: process.env.ICLINIC_SIGNATURE_URL,
});
apiSignature.interceptors.response.use(
  interceptorResponseTransformData,
  interceptorResponseErrors,
);

// api email
const apiEmail = axios.create({
  baseURL: process.env.ICLINIC_EMAIL_URL,
});
apiEmail.interceptors.response.use(
  interceptorResponseTransformData,
  interceptorResponseErrors,
);

// api export manager
const apiExportManager = axios.create({
  baseURL: process.env.ICLINIC_EXPORT_MANAGER_URL,
});
apiExportManager.interceptors.request.use(exportDataApiTokenInterceptor);
apiExportManager.interceptors.response.use(
  interceptorResponseTransformData,
  interceptorResponseErrors,
);

export default api;

export {
  apiV3,
  apiV2,
  apiEmkt,
  apiEmktPublic,
  apiSlackServerless,
  apiCampaignManager,
  apiPdfServerless,
  apiSignature,
  apiEmail,
  apiExportManager,
};
