import React from 'react';
import { Form } from 'formik';
import { Link } from '@iclinic/design-system';

import EmailInput from './EmailInput';
import NameInput from './NameInput';
import PhoneInput from './PhoneInput';
import ProfessionFields from './ProfessionFields';
import PasswordInput from './PasswordInput';
import { RequestStatus } from 'shared/constants/State';
import * as S from './EmbedForm.styles';

type Props = {
  status: RequestStatus;
  isDisabled: boolean;
};

export const EmbedForm = ({ status, isDisabled }: Props) => (
  <>
    <Form>
      <EmailInput />
      <NameInput />
      <PhoneInput />
      <ProfessionFields />
      <PasswordInput />
      <S.GradientButton
        color="primary"
        size="large"
        type="submit"
        fullWidth
        variant="contained"
        data-ga="submit_embed_signup"
        isLoading={status === RequestStatus.Pending}
        disabled={isDisabled}
      >
        Teste grátis
      </S.GradientButton>
    </Form>
    <S.Body align="center" variant="xxs">
      Ao clicar em &quot;Teste grátis&quot;, você aceita nossos{' '}
      <Link
        variant="caption"
        href="https://suporte.iclinic.com.br/iclinic-termos-de-uso"
        target="_blank"
      >
        Termos de Uso
      </Link>{' '}
      e{' '}
      <Link
        variant="caption"
        href="https://suporte.iclinic.com.br/politica-de-privacidade-iclinic"
        target="_blank"
      >
        Política de Privacidade.
      </Link>
    </S.Body>
  </>
);
