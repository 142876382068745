import { Heading, MaterialUICore, axiomTokens } from '@iclinic/design-system';

const { styled } = MaterialUICore;
const { color, spacing } = axiomTokens;

export const SummaryWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing[6],
});

export const TotalWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: spacing[2],
});

export const OverlineText = styled(Heading)({
  color: color.typeface.smooth,

  '& .MuiLink-root': {
    textDecoration: 'none',
  },
});
