import { createSelector } from '@reduxjs/toolkit';

import { RequestStatus } from 'shared/constants/State';
import { StoreState } from 'state/rootReducer';

export const isFetchingReminderTemplateMessage = ({ messaging }: StoreState) =>
  messaging.scheduleReminder.personalization.fetcherTemplateStatus ===
  RequestStatus.Pending;

export const getReminderTemplateMessage = ({ messaging }: StoreState) =>
  messaging.scheduleReminder.personalization.templateMessage;

export const isSubmittingReminderTemplateMessage = ({
  messaging,
}: StoreState) =>
  messaging.scheduleReminder.personalization.submitTemplateStatus ===
  RequestStatus.Pending;

export const getSubmitReminderTemplateMessageStatus = ({
  messaging,
}: StoreState) =>
  messaging.scheduleReminder.personalization.submitTemplateStatus;

export const getSubmitReminderErrorMessage = ({ messaging }: StoreState) =>
  messaging.scheduleReminder.personalization.submitMessage;

export const isFetchingReminderPreviweMessage = ({ messaging }: StoreState) =>
  messaging.scheduleReminder.personalization.fetcherPreviewStatus ===
  RequestStatus.Pending;

export const getReminderPreviewMessage = ({ messaging }: StoreState) =>
  messaging.scheduleReminder.personalization.previewMessage;

export const getScheduleReminderData = createSelector(
  [
    isFetchingReminderTemplateMessage,
    getReminderTemplateMessage,
    isSubmittingReminderTemplateMessage,
  ],
  (isFetchingTemplate, templateMessage, isSubmittingTemplate) => ({
    isFetchingTemplate,
    templateMessage,
    isSubmittingTemplate,
  }),
);
