import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit';

import {
  Consumptions,
  ContractorTemplate,
  EventStatus,
  Meta,
  PaginationParams,
  PaymentSummary,
} from '../../../services/types';
import { Message } from '../../types';
import { RequestStatus } from 'shared/constants/State';

export interface DetailsState {
  fetcherDetailsStatus?: RequestStatus;
  paymentSummary?: PaymentSummary;
  consumptions?: Consumptions;
  fetcherContractorsStatus?: RequestStatus;
  meta?: Meta;
  contractorTemplates?: ContractorTemplate[];
  contractorUpdateMessages: Message[];
  updateContractorsStatus?: RequestStatus;
}

export const initialState: DetailsState = {
  contractorUpdateMessages: [],
};

export const nameStore = '@webapp/messaging/messagePackage/details';

export const fetchMessagePackageDetails = createAction<string | undefined>(
  `${nameStore}/fetchMessagePackageDetails`,
);

export const fetchContractorTemplateDetails = createAction<PaginationParams>(
  `${nameStore}/fetchContractorTemplateDetails`,
);

export const setContractorTemplateEvents = createAction<EventStatus>(
  `${nameStore}/setContractorTemplateEvents`,
);

const DetailsSlice = createSlice({
  name: nameStore,
  initialState,
  reducers: {
    fetchMessagePackageDetailsStart: (state) => ({
      ...state,
      fetcherDetailsStatus: RequestStatus.Pending,
    }),
    fetchMessagePackageDetailsSuccess: (
      state,
      action: PayloadAction<{
        paymentSummary: PaymentSummary;
        consumptions: Consumptions;
      }>,
    ) => ({
      ...state,
      fetcherDetailsStatus: RequestStatus.Success,
      paymentSummary: action.payload.paymentSummary,
      consumptions: action.payload.consumptions,
    }),
    fetchMessagePackageDetailsFailure: (state) => ({
      ...state,
      fetcherDetailsStatus: RequestStatus.Error,
    }),
    fetchContractorTemplateDetailsStart: (state) => ({
      ...state,
      fetcherContractorsStatus: RequestStatus.Pending,
    }),
    fetchContractorTemplateDetailsSuccess: (
      state,
      action: PayloadAction<{
        meta: Meta;
        contractorTemplates: ContractorTemplate[];
      }>,
    ) => ({
      ...state,
      fetcherContractorsStatus: RequestStatus.Success,
      meta: action.payload.meta,
      contractorTemplates: action.payload.contractorTemplates,
    }),
    fetchContractorTemplateDetailsFailure: (state) => ({
      ...state,
      fetcherContractorsStatus: RequestStatus.Error,
    }),
    setContractorTemplateEventsStart: (state) => ({
      ...state,
      updateContractorsStatus: RequestStatus.Pending,
    }),
    setContractorTemplateEventsSuccess: (state) => ({
      ...state,
      updateContractorsStatus: RequestStatus.Success,
    }),
    setContractorTemplateEventsFailure: (state) => ({
      ...state,
      updateContractorsStatus: RequestStatus.Error,
    }),
    addContractorUpdateMessage: (state, action: PayloadAction<Message>) => ({
      ...state,
      contractorUpdateMessages: [
        ...state.contractorUpdateMessages,
        action.payload,
      ],
    }),
    removeContractorUpdateMessageById: (
      state,
      action: PayloadAction<string>,
    ) => ({
      ...state,
      contractorUpdateMessages: state.contractorUpdateMessages.filter(
        (message) => message.id !== action.payload,
      ),
    }),
  },
});

export const {
  fetchMessagePackageDetailsStart,
  fetchMessagePackageDetailsSuccess,
  fetchMessagePackageDetailsFailure,
  fetchContractorTemplateDetailsStart,
  fetchContractorTemplateDetailsSuccess,
  fetchContractorTemplateDetailsFailure,
  setContractorTemplateEventsStart,
  setContractorTemplateEventsSuccess,
  setContractorTemplateEventsFailure,
  addContractorUpdateMessage,
  removeContractorUpdateMessageById,
} = DetailsSlice.actions;

export default DetailsSlice.reducer;
