import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { checkStep } from '../state/reducer';
import { SignupSteps } from '../state/types';
import { progressSelector } from '../state/selectors';

type CheckStepType = {
  navigationStep: SignupSteps;
};

export const useCheckStep = ({ navigationStep }: CheckStepType) => {
  const dispatch = useDispatch();
  const progressStep = useSelector(progressSelector);

  useEffect(() => {
    dispatch(
      checkStep({
        navigationStep,
        progressStep,
      }),
    );
  }, [dispatch, navigationStep, progressStep]);
};
