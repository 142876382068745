import { StoreState } from 'state/rootReducerLogin';
import { RequestStatus } from 'shared/constants/State';

export const isFetchingScheduleInfo = (store: StoreState) =>
  store.messaging.schedulePatients.fetcherStatus === RequestStatus.Pending;

export const getScheduleInfoData = ({ messaging }: StoreState) =>
  messaging.schedulePatients.scheduleInfoData;

export const isSendScheduleResponse = ({ messaging }: StoreState) =>
  messaging.schedulePatients.submitStatus === RequestStatus.Pending;
