// External
import { Reducer, combineReducers, Store } from 'redux';
import { Saga, SagaMiddleware } from 'redux-saga';

import { keepAliveSaga } from 'state/shared/sagas';

export interface ReducersMap {
  [key: string]: Reducer;
}

interface SagasMap {
  [key: string]: Saga;
}

export const createInjectReducer = (
  replaceReducer: Store['replaceReducer'],
  initialReducersMap: ReducersMap,
) => {
  const injectedReducers: ReducersMap = {};

  return (key: string, reducer: Reducer) => {
    if (!injectedReducers[key] && !initialReducersMap[key]) {
      injectedReducers[key] = reducer;
      replaceReducer(
        combineReducers({ ...initialReducersMap, ...injectedReducers }),
      );
    }
  };
};

export const createInjectSaga = (runSaga: SagaMiddleware['run']) => {
  const injectedSagas: SagasMap = {};

  return (key: string, saga: Saga) => {
    if (!injectedSagas[key]) {
      injectedSagas[key] = saga;
      runSaga(keepAliveSaga, saga);
    }
  };
};
