import React from 'react';

import * as S from './Actions.styles';

type ActionsProps = {
  children: React.ReactNode;
};

export const Actions = ({ children }: ActionsProps) => (
  <S.Actions>{children}</S.Actions>
);
