import React from 'react';

import { CboActive, CboAutocomplete, SearchBackdrop } from '../Signup.styles';

export interface SearchBoxContainerProps {
  children: React.ReactNode;
  isXs: boolean;
  backdropEnabled: boolean;
  mt: string;
}

const SearchBoxContainer = ({
  children,
  isXs,
  backdropEnabled,
  mt,
}: SearchBoxContainerProps) => {
  const CboComponent = isXs && backdropEnabled ? CboActive : CboAutocomplete;

  return (
    <>
      <SearchBackdrop data-testid="backdrop" open={backdropEnabled} />
      <CboComponent id="cbo_select" mt={mt}>
        {children}
      </CboComponent>
    </>
  );
};

export default SearchBoxContainer;
