import {
  Button,
  axiomTokens,
  MaterialUICore,
  Heading as DSHeading,
  Body as DSBody,
} from '@iclinic/design-system';

const { spacing, border, color } = axiomTokens;

const { styled } = MaterialUICore;

export const FormWrapper = styled('div')({
  maxWidth: 432,
  padding: `${spacing['8']} ${spacing['6']}`,
  borderRadius: border.radius.lg,
  backgroundColor: color.background['1'],
});

export const GradientButton = styled(Button)({
  marginTop: spacing['2'],
  borderRadius: border.radius.md,
  background: 'linear-gradient(160deg, #E44D7A 22%, #CE0058 100%)',

  '&:hover': {
    background: 'linear-gradient(180deg, #CE0058 0%, #E44D7A 100%)',
  },

  '&.Mui-disabled': {
    background: color.interactive.disabled,
  },
});

export const Heading = styled(DSHeading)({
  color: color.typeface.smooth,
});

export const Body = styled(DSBody)({
  color: color.typeface.smooth,
  marginTop: spacing['4'],
});
