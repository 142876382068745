import {
  axiomTokens,
  MaterialUICore,
  TextArea as DSTextArea,
} from '@iclinic/design-system';

const { styled } = MaterialUICore;
const { spacing } = axiomTokens;

export const Root = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  rowGap: spacing[6],
});

export const TagsWrapper = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  gap: spacing[2],
});

export const TextArea = styled(DSTextArea)({
  '& .MuiFormHelperText-root': {
    textAlign: 'right',
  },
});
