import { useState } from 'react';

export function usePasswordToggle() {
  const [showPassword, setShowPassword] = useState(false);
  const handleTogglePassword = () => setShowPassword(!showPassword);

  return { showPassword, handleTogglePassword };
}

export default usePasswordToggle;
