import { MultiplesScheduleTemplateMessage } from 'features/messaging/state/types';
import { defaultMessageReminder } from '../../../constants';

export type FormValues = {
  physicianId?: number;
} & MultiplesScheduleTemplateMessage;

const factoryTemplateMessageInitialValues = (): FormValues => ({
  content: defaultMessageReminder,
  isActive: true,
  contactPhone: '',
  physicianId: 0,
  schedules: [
    {
      daysAdvance: 5,
      timeSend: '14:30:00',
    },
  ],
  schedulesDeleted: [],
});

export { factoryTemplateMessageInitialValues };
