import {
  axiomTokens,
  MaterialUICore,
  TextArea as DSTextArea,
} from '@iclinic/design-system';

const { styled } = MaterialUICore;

const { spacing } = axiomTokens;

export const CardContent = styled('div')({
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  letterSpacing: 0.44,
  marginTop: spacing['4'],
});

export const TextArea = styled(DSTextArea)(({ theme }) => ({
  display: 'flex',
  marginTop: spacing['6'],
  width: 366,
  [theme.breakpoints.down(900)]: {
    width: 'auto',
  },
}));

export const ButtonStyling = styled('div')({
  display: 'flex',
  flexDirection: 'row-reverse',
  marginTop: spacing['4'],
  position: 'relative',
});
