import {
  Grid,
  MaterialUICore,
  axiomTokens,
  Box,
  Logo,
  Button,
} from '@iclinic/design-system';

const { styled } = MaterialUICore;

const { spacing, color, shadow, border } = axiomTokens;

export const StepSignupGrid = styled(Grid)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  margin: '0 auto',
  minHeight: '100vh',
  padding: `${spacing[2]} 0`,
  flexDirection: 'column',
  [theme.breakpoints.up('md')]: {
    maxWidth: 658,
    padding: `${spacing[20]} ${spacing[4]}`,
    flexDirection: 'row',
  },
}));

export const SignupFormContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  maxWidth: '100%',
  background: color.background[1],
  flexShrink: 0,
  margin: '0 auto',
  [theme.breakpoints.up('md')]: {
    margin: 0,
    boxShadow: shadow.level[4],
    borderRadius: border.radius.md,
  },
}));

export const SignupFormHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'left',
  marginBottom: spacing[2],
  padding: `${spacing[6]} ${spacing[4]}`,
  [theme.breakpoints.up('md')]: {
    padding: `${spacing[4]} ${spacing[8]} ${spacing[2]}`,
  },
}));

export const ContentWrapper = styled(Box)(({ theme }) => ({
  padding: `${spacing[8]} ${spacing[4]}`,
  [theme.breakpoints.up('md')]: {
    margin: 0,
    padding: `${spacing[8]} ${spacing[8]} ${spacing[4]}`,
  },
}));

export const AlertWrapper = styled(Box)({
  marginBottom: spacing['4'],
});

export const SignupFormLogo = styled(Logo)(() => ({
  margin: '0 auto',
}));

const colorButton = {
  0: '#E00060',
  1: '#B20047',
};

export const ButtonSubmit = styled(Button)({
  background: `linear-gradient(135deg, ${colorButton[0]} 0%, transparent) ${colorButton[1]}`,
  transition: 'background-color 1s',

  '&:hover': {
    background: `linear-gradient(135deg, ${colorButton[0]} 0%, transparent) ${colorButton[1]}`,
    backgroundColor: colorButton[0],
  },

  '&.Mui-disabled': {
    background: color.interactive.disabled,
    color: color.typeface.onInteractive.disabled,
  },
});
