import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit';

import {
  ScheduleInfoData,
  ScheduleStatus,
} from 'features/messaging/services/types';
import { RequestStatus } from 'shared/constants/State';

export interface SchedulePatientState {
  fetcherStatus: RequestStatus;
  scheduleInfoData?: ScheduleInfoData;
  submitStatus?: RequestStatus;
}

export const initialState: SchedulePatientState = {
  fetcherStatus: RequestStatus.Pending,
};

export const nameStore = '@webapp/schedule-patients';

export const fetchScheduleInfo = createAction(`${nameStore}/fetchScheduleInfo`);

export const sendSchedulingAnswer = createAction<ScheduleStatus>(
  `${nameStore}/sendSchedulingAnswer`,
);

const SchedulePatientSlice = createSlice({
  name: nameStore,
  initialState,
  reducers: {
    fetchScheduleInfoSuccess: (
      state,
      action: PayloadAction<ScheduleInfoData>,
    ) => ({
      ...state,
      fetcherStatus: RequestStatus.Success,
      scheduleInfoData: action.payload,
    }),
    fetchScheduleInfoFailure: (state) => ({
      ...state,
      fetcherStatus: RequestStatus.Error,
    }),
    sendSchedulingAnswerStart: (state) => ({
      ...state,
      submitStatus: RequestStatus.Pending,
    }),
    sendSchedulingAnswerSuccess: (state) => ({
      ...state,
      submitStatus: RequestStatus.Success,
    }),
    sendSchedulingAnswerFailure: (state) => ({
      ...state,
      submitStatus: RequestStatus.Error,
    }),
  },
});

export const {
  fetchScheduleInfoSuccess,
  fetchScheduleInfoFailure,
  sendSchedulingAnswerStart,
  sendSchedulingAnswerSuccess,
  sendSchedulingAnswerFailure,
} = SchedulePatientSlice.actions;

export default SchedulePatientSlice.reducer;
