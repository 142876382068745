import React from 'react';

import * as S from './SignupBackground.styles';
import { image } from 'shared/utils/urlGetter';

export function SignupBackground() {
  return (
    <S.BackgroundWrapper>
      <S.Backdrop />
      <img src={image('signup.scheduleBackground')} alt="Agenda" />
    </S.BackgroundWrapper>
  );
}

export default SignupBackground;
