import {
  takeLatest,
  put,
  call,
} from 'redux-saga/effects';

import iclinic from 'services/iclinic';
import { handlerErrors } from 'state/shared/sagas';
import * as types from './constants';
import * as actions from './actions';

export function* workerActiveCampaign() {
  try {
    const { getResponseData, errors } = yield call(iclinic.campaignManager.getCampaigns);

    if (errors) throw errors;
    const campaigns = getResponseData();

    yield put(actions.getActiveCampaignSuccess(campaigns));
  } catch (error) {
    yield call(handlerErrors, error, actions.getActiveCampaignFailure);
  }
}

export default function* campaignManagerSagas() {
  yield takeLatest(types.FETCH_ACTIVE_CAMPAIGN, workerActiveCampaign);
}
